import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import ContentsGroup from 'components/cms/ContentsGroup';
import types from './types';

function FormInputType({ type, data: { info, required, ...data }, ...rest }) {
  const Component = types[type];
  if (Component) {
    return (
      <Component
        {...{ ...data, ...rest }}
        info={
          !isEmpty(info) &&
          // eslint-disable-next-line react/no-unstable-nested-components
          map(info, ({ contents_group: contentsGroupData }) => (
            <ContentsGroup
              data={contentsGroupData}
              propsBag={rest.propsBag}
              colorType={rest.colorType}
            />
          ))
        }
      />
    );
  }
  return null;
}

FormInputType.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  info: PropTypes.array,
};

export default FormInputType;
