import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import get from 'lodash/get';
import withColorType from 'components/HOC/withColorType';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import FlowButton from 'containers/Authentication/components/FlowButton';
import defaultMessages from './messages';
import flowMessages from './flowMessages';

function Purpose({ colorType, data, authenticationId }) {
  const dispatch = useDispatch();
  const { status, props } = data;
  const beforeVisible = get(props, 'before.visible');
  const afterVisible = get(props, 'after.visible');
  const sellVisible = get(props, 'sell.visible');

  const overrideMessages = flowMessages[data?.flow] || {};
  const messages = useMemo(
    () => ({
      ...defaultMessages,
      ...overrideMessages,
    }),
    [data],
  );

  useEffect(() => {
    dispatch(
      setBack({
        back: flowRequest({
          action: 'prev',
          status,
          authenticationId,
        }),
      }),
    );
  }, []);

  const handleSubmit = (choise) => {
    if (choise) {
      dispatch(
        flowRequest({
          action: 'next',
          status,
          authenticationId,
          data: { purpose: choise },
        }),
      );
    }
  };

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />
      {beforeVisible && (
        <FlowButton onClick={() => handleSubmit('before')} colorType={colorType} noMargin>
          <FormattedMessage {...messages.before} />
        </FlowButton>
      )}
      {afterVisible && (
        <FlowButton onClick={() => handleSubmit('after')} colorType={colorType} noMargin>
          <FormattedMessage {...messages.after} />
        </FlowButton>
      )}
      {sellVisible && (
        <FlowButton
          buttonType="link"
          arrow={false}
          onClick={() => handleSubmit('sell')}
          colorType={colorType}
        >
          <FormattedMessage {...messages.sell} />
        </FlowButton>
      )}
    </>
  );
}

Purpose.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    flow: PropTypes.string,
    props: PropTypes.object,
  }),
};

export default withColorType(Purpose);
