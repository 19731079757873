import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, FileUpload, Div } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { Wrapper, PhotoWrapper, Caption } from './styles';
import messages from './messages';
import ImageEditor from './ImageEditor';
import DtButton from '../../dataTypes/Button';

function PictureSocialShare({
  id,
  button,
  style,
  colorType,
  logEventName = 'share-picture',
  stickers,
  propsBag: { logger } = {},
  propsBag,
  componentType,
  gtm,
}) {
  const [image, setImage] = useState(null);
  const imageEditorRef = useRef();

  const sendLog = ({ action }) => {
    if (logger) {
      logger({
        action: 'click',
        step: 'finish',
        start: 1,
        finish: 1,
        type: componentType,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'certificate' },
        params: { action },
      });
    }
  };

  const handleChange = (file) => {
    setImage(URL.createObjectURL(file));
  };

  const handleOnSave = () => {
    const downloadURI = (uri, name) => {
      const link = document.createElement('a');
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const editedImage = imageEditorRef.current.getImage();

    downloadURI(editedImage, 'image.png');

    sendLog({ action: 'save' });
  };

  return (
    <Div {...style}>
      <FileUpload
        colorType={colorType}
        onChange={handleChange}
        accept="image/*"
        name={`upload-image-${id}`}
        buttonComponent={DtButton}
        buttonProps={{
          ...button,
          text: button.text || messages.cta.defaultMessage,
          colorType: button.colorType || colorType,
        }}
      />
      <Modal colorType={colorType} isOpen={!!image} onClose={() => setImage(null)} fullScreen close>
        <Wrapper>
          <PhotoWrapper>
            <ImageEditor
              image={image}
              ref={imageEditorRef}
              stickers={stickers}
              colorType={colorType}
            />
          </PhotoWrapper>
          <Caption
            colorType={colorType}
            textAlign="center"
            margin={{ size: 'm', position: 'bottom' }}
          >
            <FormattedMessage {...messages.caption} values={propsBag} />
          </Caption>
          <Button colorType={colorType} onClick={handleOnSave}>
            <FormattedMessage {...messages.saveBtn} />
          </Button>
        </Wrapper>
      </Modal>
    </Div>
  );
}

PictureSocialShare.propTypes = {
  componentType: PropTypes.string,
  id: PropTypes.string,
  button: PropTypes.object,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
  stickers: PropTypes.array,
};

export default PictureSocialShare;
