import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ebay-certilogo/design-system-web';
import ModalPolicy from 'components/ModalPolicy';

import { getPrivacyPolicySignupConfigRequest } from 'containers/Auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrivacyPolicySignupConfig } from 'containers/Auth/selectors';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import { get } from 'lodash';

function TermsAndConditions({
  colorType,
  size,
  type = 'signup',
  primaryBrand,
  secondaryBrand,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);
  const privacyPolicySignupConfig = useSelector(selectPrivacyPolicySignupConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPrivacyPolicySignupConfigRequest({
        primaryBrand,
        secondaryBrand,
      }),
    );
  }, []);

  const text = get(privacyPolicySignupConfig, `text${type === 'code' ? 'CodePage' : ''}`);

  return privacyPolicySignupConfig && text ? (
    <>
      <Text colorType={colorType} size={size} {...rest}>
        <FormattedMessageMarkdown
          id={text}
          defaultMessage={text}
          colorType={colorType}
          values={{
            linebreak: '<br/>',
            termsUrl: privacyPolicySignupConfig?.tcDocument?.url,
            privacyUrl: privacyPolicySignupConfig?.privacyDocument?.url,
          }}
        />
      </Text>
      <ModalPolicy
        bodyMessage={isOpen && { id: `certilogo.${isOpen}` }}
        isOpen={!!isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  ) : null;
}

TermsAndConditions.propTypes = {
  colorType: PropTypes.string,
  type: PropTypes.object,
  size: PropTypes.string,
  primaryBrand: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  secondaryBrand: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
};

export default TermsAndConditions;
