import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Text } from '@ebay-certilogo/design-system-web';
import { useSelector } from 'react-redux';
import { selectColorTypes, selectDefaultTheme, selectPoweredBy } from 'containers/App/selectors';
import { ThemeProvider } from 'styled-components';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import { Info } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { composeTheme } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/utils';
import { Wrapper, LogoClg, LogoSvg, InfoButton, Header } from './styles';
import messages from '../messages';

const Card = forwardRef(({ children }, ref) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const isPoweredBy = useSelector(selectPoweredBy);
  const colorTypes = useSelector(selectColorTypes);
  const defaultTheme = useSelector(selectDefaultTheme);
  const colorTypeLoginWrapper = colorTypes.authLoginForm;

  const theme = composeTheme('card', defaultTheme);
  return (
    <ThemeProvider theme={theme}>
      <Wrapper ref={ref} colorType={colorTypeLoginWrapper}>
        {!!isPoweredBy && (
          <Header marginBottom="m">
            <LogoClg>
              <LogoSvg colorType={colorTypeLoginWrapper} />
            </LogoClg>
            <InfoButton
              onClick={() => setIsInfoModalOpen(true)}
              colorType={colorTypeLoginWrapper === 'light' ? 'dark' : 'light'}
            >
              <Info
                colorType={colorTypeLoginWrapper}
                width={20}
                height={20}
                style={{ width: '20px', height: '20px' }}
              />
            </InfoButton>
          </Header>
        )}
        {children({ colorType: colorTypeLoginWrapper, theme: defaultTheme })}
        <Modal
          isOpen={isInfoModalOpen}
          onClose={() => setIsInfoModalOpen(false)}
          close
          colorType={colorTypeLoginWrapper}
        >
          <Text colorType={colorTypeLoginWrapper} marginBottom="l">
            <FormattedMessageMarkdown
              colorType={colorTypeLoginWrapper}
              {...messages.infoModalBody}
            />
          </Text>
        </Modal>
      </Wrapper>
    </ThemeProvider>
  );
});

Card.propTypes = {
  children: PropTypes.node,
};

export default Card;
