import React, { useEffect } from 'react';
import { Button, Box, Flex } from '@ebay-certilogo/design-system-web';
import { contentWrapper } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import withColorType from 'components/HOC/withColorType';
import styled from 'styled-components';
import Triangle from 'components/Triangle';
import messages from './messages';
import { Title } from '../styles';

const AvailabilityDate = styled(Flex)`
  justify-content: flex-end;
  padding-top: 5px;
  padding-right: 5px;
  z-index: 100;
  ${contentWrapper}
`;

function Closed({ colorType, authenticationId, data }) {
  const { type, reopenDate } = data;
  const soaTypeMessages = messages[type || 'vacation'];
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: null,
        }),
      );
    }
  }, [data]);

  const handleSubmit = () => {
    dispatch(flowRequest({ action: 'next', authenticationId, status: 'soa' }));
  };

  return (
    <>
      <AvailabilityDate>
        <Triangle
          text={
            <FormattedMessage
              {...soaTypeMessages.availableDate}
              values={{
                date: <FormattedDate value={reopenDate} month="short" day="numeric" />,
              }}
              tagName=""
            />
          }
        />
      </AvailabilityDate>
      <Title colorType={colorType}>
        <FormattedMessage {...soaTypeMessages.title} />
      </Title>
      <Box colorType={colorType} subtitle={<FormattedMessage {...soaTypeMessages.text} />} />
      {authenticationId && (
        <Button onClick={handleSubmit} colorType={colorType}>
          <FormattedMessage {...soaTypeMessages.button} />
        </Button>
      )}
    </>
  );
}

Closed.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    page: PropTypes.string,
    type: PropTypes.string,
    reopenDate: PropTypes.string,
    id: PropTypes.number,
  }),
};

export default withColorType(Closed);
