import { defineMessages } from 'react-intl';

export default defineMessages({
  cta: {
    id: 'authentic_1.button1',
    defaultMessage: 'Share a photo of your product',
  },
  caption: {
    id: 'authentic_share.text1',
    defaultMessage: 'Save the photo and share it with the hashtag #wechooseauthentic',
  },
  saveBtn: {
    id: 'authentic_share.button1',
    defaultMessage: 'Save',
  },
});
