import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { List, Text } from '@ebay-certilogo/design-system-web';
import isFunction from 'lodash/isFunction';

const RightText = styled(Text)`
  white-space: nowrap;
`;

function TwoRow({
  colorType,
  title,
  description,
  left,
  right,
  onClick,
  accessoryRowRight,
  bordered,
  rowPadding,
  item,
  titleProps,
  descriptionProps,
}) {
  return (
    <List.Row
      colorType={colorType}
      padding={rowPadding || { size: 's', position: 'vertical' }}
      noMargin={bordered}
      onClick={onClick}
      clickable
      left={isFunction(left) ? left(item) : left}
      right={
        accessoryRowRight ? (
          <RightText colorType={colorType} weight="extrabold" size="s">
            {right}
          </RightText>
        ) : (
          right
        )
      }
      body={
        <>
          <Text
            colorType={colorType}
            weight="extrabold"
            margin={{ size: 's', position: 'horizontal' }}
            marginBottom="s"
            {...titleProps}
          >
            {title}
          </Text>
          <Text
            colorType={colorType}
            weight="normal"
            size="s"
            margin={{ size: 's', position: 'horizontal' }}
            {...descriptionProps}
          >
            {description}
          </Text>
        </>
      }
    />
  );
}

TwoRow.propTypes = {
  colorType: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  left: PropTypes.node,
  right: PropTypes.node,
  onClick: PropTypes.func,
  accessoryRowRight: PropTypes.string,
  bordered: PropTypes.bool,
  rowPadding: PropTypes.object,
  item: PropTypes.object,
  titleProps: PropTypes.object,
  descriptionProps: PropTypes.object,
};

export default TwoRow;
