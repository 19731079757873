import { Div } from '@ebay-certilogo/design-system-web';
import { margin, fontSize } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { Field } from 'formik';
import styled from 'styled-components';

export const Wrapper = styled(Div)``;

export const Question = styled(Div)`
  ${margin('m', 'bottom')}
`;
export const Answer = styled(Div)`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: ${({ answerInline }) => (answerInline ? 'nowrap' : 'wrap')};
`;
export const FormInputWrapper = styled(Div)`
  width: 100%;
  ${({ slim }) => slim && fontSize('s')};
  ${({ multipleAnswers }) => multipleAnswers && margin('s', 'right')};
  ${Field} {
    width: 100%;
  }
`;
