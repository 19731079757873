import React, { useEffect, useState } from 'react';
import { Stepper, Text, Box, Flex, Button } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Soa/actions';
import { setBack } from 'containers/App/actions';
import { filter, get } from 'lodash';
import messages from './messages';
import { actualStep, totalSteps } from '../utils';
import MarketplaceListForm from '../MarketplaceListForm';
import ImportantModal from './ImportantModal';
import { selectMarketplace, selectSoaLoading } from '../selectors';

function Marketplace({ colorType, authenticationId, data }) {
  const [importantModalIsOpen, setImportantModalIsOpen] = useState(false);
  const { id, page, status, code } = data;
  const certilogoCode = get(code, 'certilogoCode');
  const customerDescription = get(code, 'product.customerDescription');
  const primaryBrandName = get(code, 'brandName');

  const isLoading = useSelector(selectSoaLoading);
  const marketplace = useSelector(selectMarketplace);

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            authenticationId,
            id,
            status,
            page,
            data: { id: data.id },
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = () => {
    dispatch(
      flowRequest({
        action: 'next',
        authenticationId,
        id,
        status,
        page,
        data: { id },
      }),
    );
  };

  const isButtonEnabled = filter(marketplace, { enabled: true }).length > 0;

  return (
    <>
      <Box colorType={colorType} title={<FormattedMessage {...messages.title} />}>
        <Flex flexDirection="column">
          <Flex>
            <Text colorType={colorType}>
              {primaryBrandName} {customerDescription}
            </Text>
          </Flex>
          <Flex>
            <Text colorType={colorType}>CLG {certilogoCode}</Text>
          </Flex>
        </Flex>
      </Box>
      <Stepper totalSteps={totalSteps} actualStep={actualStep[page]} colorType={colorType} />
      <Text colorType={colorType} margin={{ size: 'm', position: 'bottom' }}>
        <FormattedMessage {...messages.text} />
      </Text>
      <MarketplaceListForm
        colorType={colorType}
        soaId={data.id}
        certilogoCode={certilogoCode}
        onSubmit={handleSubmit}
      />
      <Button
        onClick={() => setImportantModalIsOpen(true)}
        colorType={colorType}
        loading={isLoading}
        type="button"
        disabled={!isButtonEnabled}
      >
        <FormattedMessage {...messages.button} />
      </Button>
      {importantModalIsOpen && (
        <ImportantModal
          colorType="light"
          isOpen={importantModalIsOpen}
          setIsOpen={setImportantModalIsOpen}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}

Marketplace.propTypes = {
  colorType: PropTypes.string,
  status: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    page: PropTypes.string,
    id: PropTypes.number,
    labelImageUploaded: PropTypes.number,
    code: PropTypes.shape({
      certilogoCode: PropTypes.string,
      product: PropTypes.object,
    }),
  }),
};

export default Marketplace;
