import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { TextInput, Div, Flex, Button, Text } from '@ebay-certilogo/design-system-web';
import {
  ChevronRight,
  DotsHorizontal,
  Search,
} from '@ebay-certilogo/design-system-web/dist/components/icons';
import Lists from 'components/Lists';
import { isGoogleMapsEnabled } from 'utils/utils';
import styled from 'styled-components';
import { checkGeolocationAvailability } from 'utils/Api/geolocation';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import messages from './messages';
import NearBy from '../NearBy';

const ButtonNearBy = styled(Button)`
  height: 30px;
  min-height: auto;
  padding: 0 10px;
  border-radius: 15px;
  text-transform: initial;
`;

const ButtonLoadMore = styled(Button).attrs({
  buttonType: 'transparent',
  arrow: false,
})`
  min-height: 30px;
  padding: 0;
`;

const SeatchInputWrapper = styled(Div)`
  color: ${color};
`;

const Wrapper = Div;

function GeoSuggest({
  colorType,
  searchData,
  searchLoading,
  searchNext,
  searchType,
  setSearchType,
  searchCallBack,
  selectedCallBack,
  compact,
  value,
  setInputValue,
  position,
  getPosition,
  positionCall,
  handleSubmit,
  required,
  header,
  footer,
  isGeolocationActive,
}) {
  const inputRef = useRef();

  const onChange = () => {
    const inputValue = get(inputRef, 'current.value');
    setSearchType('textSearch');
    setInputValue(inputValue);
    searchCallBack({ inputValue, position, positionIP: positionCall });
  };

  const [geolocationAvailable, setGeolocationAvailable] = useState(isGeolocationActive);

  useEffect(() => {
    if (isGeolocationActive) {
      checkGeolocationAvailability((availability) => {
        setGeolocationAvailable(availability);
      });
    }
  }, [isGeolocationActive]);

  return (
    <Wrapper>
      <Lists
        header={
          <>
            {header}
            <SeatchInputWrapper colorType={colorType} marginBottom="m">
              <FormattedMessage {...messages.placeholder}>
                {(message) => (
                  <TextInput
                    onChange={onChange}
                    id="autocomplete"
                    placeholder={message[0]}
                    type="text"
                    autoComplete="off"
                    colorType={colorType}
                    value={value}
                    ref={inputRef}
                    icon={<Search width={22} height={22} colorType={colorType} />}
                  />
                )}
              </FormattedMessage>
            </SeatchInputWrapper>
            <Flex justyfyContent="flex-end" alignItems="center" marginBottom="m">
              {(geolocationAvailable &&
                (position && searchType === 'nearby' ? (
                  <Text colorType={colorType}>
                    <NearBy colorType={colorType} width={20} height={20} />
                  </Text>
                ) : (
                  <ButtonNearBy
                    colorType={colorType}
                    noMargin
                    widthAuto
                    onClick={() => {
                      setInputValue('');
                      setSearchType('nearby');
                      getPosition();
                    }}
                    arrow={false}
                    size="s"
                    icon={<NearBy colorType="dark" width={20} height={20} />}
                  >
                    <Text
                      colorType={colorType}
                      themeField="button.color"
                      padding={{ size: 's', direction: 'horizzontal' }}
                    >
                      <FormattedMessage {...messages.viewNearBy} />
                    </Text>
                  </ButtonNearBy>
                ))) || <Div />}
              {required === false && (
                <Button
                  buttonType="transparent"
                  colorType={colorType}
                  noPadding
                  noMargin
                  widthAuto
                  onClick={handleSubmit}
                  style={{ minHeight: 0, height: 30 }}
                >
                  <FormattedMessage {...messages.skip} />
                </Button>
              )}
            </Flex>
          </>
        }
        footer={
          <>
            {searchNext && (
              <ButtonLoadMore colorType={colorType} onClick={searchNext} isLoading={searchLoading}>
                <DotsHorizontal colorType={colorType} />
              </ButtonLoadMore>
            )}
            {footer}
          </>
        }
        colorType={colorType}
        clickable
        onClick={(item) => {
          selectedCallBack({ type: 'places', item });
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        right={({ colorType: cT, themeField }) => (
          <ChevronRight width={40} height={40} colorType={cT} themeField={themeField} />
        )}
        accessoryRow={
          searchType === 'nearby'
            ? ['name', 'distance', 'vicinity']
            : ['name', 'distance', isGoogleMapsEnabled() ? 'formatted_address' : 'formattedAddress']
        }
        type="placeRow"
        data={compact ? searchData.slice(0, 2) : searchData || []}
        isLoading={searchLoading}
        // onPageBottom={searchNext}
        marginTop="m"
        marginBottom="m"
        highlightFirst={!!position}
        // eslint-disable-next-line react/jsx-no-useless-fragment
        notFound={<></>}
        onEndReachedThreshold={0.001}
        showsVerticalScrollIndicator={false}
      />
    </Wrapper>
  );
}

GeoSuggest.propTypes = {
  colorType: PropTypes.string,
  placesLoading: PropTypes.bool,
  isGeolocationActive: PropTypes.bool,
  compact: PropTypes.bool,
  places: PropTypes.array,
  setSearchType: PropTypes.func,
  searchData: PropTypes.bool,
  searchLoading: PropTypes.array,
  searchNext: PropTypes.func,
  searchType: PropTypes.string,
  searchCallBack: PropTypes.func,
  selectedCallBack: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  value: PropTypes.string,
  setInputValue: PropTypes.func,
  intl: PropTypes.object,
  position: PropTypes.object,
  handleSubmit: PropTypes.func,
  getPosition: PropTypes.func,
  positionCall: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
  required: PropTypes.bool,
  header: PropTypes.node,
  footer: PropTypes.node,
};

export default GeoSuggest;
