/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { Text, FormFormik, Div } from '@ebay-certilogo/design-system-web';
import {
  padding,
  margin,
  fontSize,
  regular,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';
import config from 'config/index';

const { TextInput } = FormFormik;

export const CodeInput = styled(TextInput).attrs({
  type: 'tel',
  size: config.clgInputQty,
  maxLength: config.clgInputQty,
  autoComplete: 'off',
  keyboardType: 'numeric',
})`
  display: flex;
  align-items: center;
  width: auto;
  height: auto;
  width: 100%;
  ${fontSize('l')}
  ${regular}
  font-size: ${({ field }) =>
    // eslint-disable-next-line no-unsafe-optional-chaining
    field?.value && field?.value.length > 12 ? `${1.8 - field?.value.length * 0.023}em` : ''};
  input {
    border-bottom: 0;
  }
  /* Remove arrows inside input */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const CodeInputWrapper = styled(Div)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${margin('l', 'bottom')}
  border-bottom: 2px solid ${color};
`;

export const CodeInputLabel = styled(Text).attrs({ size: 'l', weight: 'extrabold' })`
  ${padding('s')}
`;
