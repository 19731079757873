import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_creation_pictures1.title1',
    defaultMessage: 'Get the Certilogo Seal of Authentication',
  },
  description: {
    id: 'soa_creation_pictures1.text2',
    defaultMessage:
      'Please send us some clear and well lit photos of the CLG Code on your product.',
  },
  descriptionMore: {
    id: 'soa_creation_pictures1.text2',
    defaultMessage:
      'Please send us some clear and well lit photos of the CLG Code on your product.',
  },
  uploadPictures: {
    id: 'soa_creation_pictures1.button1',
    defaultMessage: 'Upload pictures',
  },
  moreUploadPictures: {
    id: 'soa_creation_pictures1_more.button1',
    defaultMessage: 'Upload more pictures',
  },
  done: {
    id: 'soa_creation_pictures1_more.button2',
    defaultMessage: 'Done',
  },
  sizeError: {
    id: 'soa_creation_pictures1.sizeError',
    defaultMessage: 'We only accept files up to 15MB in size',
  },
  typeError: {
    id: 'soa_creation_pictures1.typeError',
    defaultMessage: 'We only accept image files',
  },
});
