import React, {
  createRef,
  useState,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { Image as KonvaImage, Layer, Stage } from 'react-konva';
import useImage from 'use-image';
import { useSize } from 'ahooks';
import { isEmpty } from 'lodash';
import IndividualSticker from './IndividualSticker';
import { StickersWrapper, Sticker, ImageWrapper } from './styles';
import { calculateImageDimensions } from './utils';

const ImageEditor = forwardRef(({ image: imageSrc, stickers, colorType }, ref) => {
  const stageRef = useRef();
  const wrapperRef = useRef();
  const wrapperDimensions = useSize(wrapperRef);
  const [background] = useImage(imageSrc);
  const [images, setImages] = useState([]);

  const addStickerToPanel = ({ src, width, x, y }) => {
    setImages((currentImages) => [
      ...currentImages,
      {
        width,
        x,
        y,
        src,
        resetButtonRef: createRef(),
      },
    ]);
  };

  const resetAllButtons = useCallback(() => {
    images.forEach((image) => {
      if (image.resetButtonRef.current) {
        image.resetButtonRef.current();
      }
    });
  }, [images]);

  const handleCanvasClick = useCallback(
    (event) => {
      if (event.target.attrs.id === 'backgroundImage') {
        resetAllButtons();
      }
    },
    [resetAllButtons],
  );

  const { height: bgCalcHeight, width: bgCalcWidth } = calculateImageDimensions({
    image: background,
    maxHeight: 260,
    maxWidth: wrapperDimensions?.width,
    scale: 1,
  });

  const getImage = () => wrapperRef.current.getElementsByTagName('canvas')[0].toDataURL();

  useImperativeHandle(ref, () => ({
    getImage,
  }));

  return (
    <>
      <ImageWrapper ref={wrapperRef}>
        <Stage
          width={bgCalcWidth}
          height={bgCalcHeight}
          onClick={handleCanvasClick}
          onTap={handleCanvasClick}
          ref={stageRef}
        >
          <Layer>
            <KonvaImage
              image={background}
              width={bgCalcWidth}
              height={bgCalcHeight}
              id="backgroundImage"
            />
            {images.map((image, i) => {
              return (
                <IndividualSticker
                  onDelete={() => {
                    const newImages = [...images];
                    newImages.splice(i, 1);
                    setImages(newImages);
                  }}
                  onDragEnd={(event) => {
                    image.x = event.target.x();
                    image.y = event.target.y();
                  }}
                  key={i}
                  image={image}
                />
              );
            })}
          </Layer>
        </Stage>
      </ImageWrapper>
      {!isEmpty(stickers) && (
        <StickersWrapper colorType={colorType}>
          {stickers.map(({ image, width = 80, name }) => {
            return (
              <Sticker
                onClick={() => {
                  addStickerToPanel({
                    src: image.url,
                    width: width || 80,
                    x: 100,
                    y: 100,
                  });
                }}
              >
                <img alt={name} src={image.url} />
              </Sticker>
            );
          })}
        </StickersWrapper>
      )}
    </>
  );
});

ImageEditor.propTypes = {
  colorType: PropTypes.string,
  image: PropTypes.string,
  stickers: PropTypes.array,
};

export default ImageEditor;
