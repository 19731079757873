import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Slider as _Slider, Text } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { useSize } from 'ahooks';
import ContentsGroup from 'components/cms/ContentsGroup';
import _Image from 'components/cms/dataTypes/Image';
import isEmpty from 'lodash/isEmpty';

const Image = styled(_Image)`
  width: 100%;
  img {
    margin: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const SliderDS = styled(_Slider)`
  overflow: hidden;
  flex: 1;
  .slick-slider {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;
  }
  .slick-list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .slick-track {
    display: flex !important;
    flex: 1;
  }
  .slick-slide {
    height: inherit !important;
    flex-direction: column;
    > div {
      height: 100%;
    }
  }
`;

const Wrapper = styled(Flex)`
  justify-content: center;
  flex-shrink: 0;
`;

const Slide = styled(Flex)`
  position: relative;
  display: flex !important;
  flex: 1;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  overflow: hidden;
  ${({ isLast, gutterSpace }) => !isLast && gutterSpace && padding(gutterSpace, 'right')}
`;

function Slider({
  colorType,
  images,
  contents,
  lastFullWidth,
  propsBag,
  completeAction,
  nextImageTotalPercent = 30,
  gutterSpace,
  style,
}) {
  const ref = useRef();
  const dimensions = useSize(ref);
  const { width } = dimensions || {};

  useEffect(() => {
    if (propsBag.setSwipeable) {
      propsBag.setSwipeable(false);
    }
    return () => {
      if (propsBag.setSwipeable) {
        propsBag.setSwipeable(true);
      }
    };
  }, []);

  return (
    <Wrapper ref={ref} {...style}>
      <SliderDS dots={false} arrows={false} infinite={false} variableWidth>
        {images.map(
          ({ image, imageVar, description }) =>
            (imageVar || image) && (
              <Slide
                style={{ width: width - width * (nextImageTotalPercent / 100) }}
                justifyContent="flex-start"
                gutterSpace={gutterSpace}
              >
                <Image image={imageVar || image} />
                {!isEmpty(description) && (
                  <Text colorType={colorType} marginTop="s" weight="extrabold">
                    <FormattedMessage id={description} defaultMessage={description} />
                  </Text>
                )}
              </Slide>
            ),
        )}
        {!isEmpty(contents) &&
          contents.map(({ contents_group: contentsGroup }, index) => {
            const isLast = index + 1 === contents.length;
            return (
              <Slide
                isLast={lastFullWidth && isLast}
                style={{
                  width:
                    lastFullWidth && isLast ? width : width - width * (nextImageTotalPercent / 100),
                }}
                justifyContent="flex-start"
                gutterSpace={gutterSpace}
              >
                <ContentsGroup
                  colorType={colorType}
                  data={contentsGroup}
                  propsBag={propsBag}
                  completeAction={completeAction}
                />
              </Slide>
            );
          })}
      </SliderDS>
    </Wrapper>
  );
}

Slider.propTypes = {
  colorType: PropTypes.string,
  cover: PropTypes.object,
  images: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  propsBag: PropTypes.object,
  contents: PropTypes.array,
  lastFullWidth: PropTypes.bool,
  completeAction: PropTypes.func,
  nextImageTotalPercent: PropTypes.number,
  gutterSpace: PropTypes.string,
};

export default Slider;
