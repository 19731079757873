import { defineMessages } from 'react-intl';

export default defineMessages({
  buyer: {
    title: {
      id: 'fake_report.recap.buyer.title',
      defaultMessage: 'Buyer information',
    },
    givenName: {
      id: 'fake_report.recap.buyer.givenName',
      defaultMessage: 'First name',
    },
    familyName: {
      id: 'fake_report.recap.buyer.familyName',
      defaultMessage: 'Last name',
    },
    dateOfBirth: {
      id: 'fake_report.recap.buyer.dateOfBirth',
      defaultMessage: 'Date of birth',
    },
  },
  retailer: {
    title: {
      id: 'fake_report.recap.retailer.title',
      defaultMessage: 'Retailer information',
    },
    retailerType: {
      label: {
        id: 'fake_report.recap.retailer.retailerType',
        defaultMessage: 'Retailer',
      },
      online: {
        id: 'fake_report.recap.retailer.retailerType.online',
        defaultMessage: 'Online',
      },
      inStore: {
        id: 'fake_report.recap.retailer.retailerType.inStore',
        defaultMessage: 'In store',
      },
    },
    retailerInfo: {
      id: 'fake_report.recap.retailer.retailerInfo',
      defaultMessage: "Retailer's",
    },
  },
  purchase: {
    title: {
      id: 'fake_report.recap.purchase.title',
      defaultMessage: 'Retailer information',
    },
    price: {
      id: 'fake_report.recap.purchase.price',
      defaultMessage: 'Price',
    },
    receipt: {
      id: 'fake_report.recap.purchase.receipt',
      defaultMessage: 'Receipt',
    },
  },
  text1: {
    id: 'fake_report.recap.text1',
    defaultMessage:
      'Sending the request I declare that the information provided is true and correct',
  },
  send: {
    id: 'fake_report.recap.button1',
    defaultMessage: 'Send request',
  },
  edit: {
    id: 'fake_report.recap.button2',
    defaultMessage: 'Edit information',
  },
});
