import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormFormik, Text } from '@ebay-certilogo/design-system-web';
import { selectBrand, selectPrivacyPolicySignupConfig } from 'containers/Auth/selectors';
import { useSelector } from 'react-redux';
import { Field, Formik, useFormikContext } from 'formik';
import * as Yup from 'yup';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import TermsAndConditions from 'components/TermsAndConditions';
import { isChina } from 'utils/utils';
import FormComponent from 'components/form/FormComponent';
import ErrorMessage from 'components/form/ErrorMessage';
import messages from './messages';

function AutoSubmit() {
  const formik = useFormikContext();

  useEffect(() => {
    if (formik.values !== formik.initialValues) {
      formik.submitForm(); // or onSubmit if you want to do validations before submitting
    }
  }, [formik.values]);
  return null;
}

const validationSchema = Yup.object().shape({
  pipl: Yup.bool()
    .required(<ErrorMessage type="required" />)
    .oneOf([true], <ErrorMessage type="required" />),
  cnPrivacyTerms: Yup.bool()
    .required(<ErrorMessage type="required" />)
    .oneOf([true], <ErrorMessage type="required" />),
});

function ChinaConstraints({ colorType, onChange }) {
  const brand = useSelector(selectBrand);
  const privacyPolicySignupConfig = useSelector(selectPrivacyPolicySignupConfig);

  return (
    isChina && (
      <Formik
        initialValues={{
          pipl: false,
          cnPrivacyTerms: false,
        }}
        validationSchema={validationSchema}
        validateOnChange
        onSubmit={() => {}}
      >
        {({ isValid, dirty }) => {
          useEffect(() => {
            if (dirty) {
              onChange(isValid);
            }
          }, [isValid, dirty]);
          return (
            <FormComponent>
              <Field
                name="pipl"
                component={FormFormik.Checkbox}
                colorType={colorType}
                text={
                  <Text colorType={colorType}>
                    <FormattedMessageMarkdown
                      {...messages.pipl}
                      colorType={colorType}
                      values={{
                        termsUrl: privacyPolicySignupConfig?.tcDocument?.url,
                        privacyUrl: privacyPolicySignupConfig?.privacyDocument?.url,
                      }}
                    />
                  </Text>
                }
              />
              <Field
                name="cnPrivacyTerms"
                component={FormFormik.Checkbox}
                colorType={colorType}
                text={
                  <TermsAndConditions
                    colorType={colorType}
                    type="signup"
                    primaryBrand={brand?.primaryBrand?.id}
                    secondaryBrand={brand?.secondaryBrand?.id}
                  />
                }
              />
              <AutoSubmit />
            </FormComponent>
          );
        }}
      </Formik>
    )
  );
}

ChinaConstraints.propTypes = {
  colorType: PropTypes.string,
  onChange: PropTypes.func,
};

export default ChinaConstraints;
