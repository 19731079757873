import React, { useMemo } from 'react';
import { Button, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { Link as _link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import {
  selectUser,
  selectAuthLoading,
  selectProfile,
  selectProfileLoading,
} from 'containers/Auth/selectors';

import { setProfileRequest } from 'containers/Auth/actions';
import FormComponent from 'components/form/FormComponent';
import LoadingIndicator from 'components/LoadingIndicator';
import messages from './messages';
import Fields from './Fields';

export const Link = styled(Text).attrs({
  as: _link,
})`
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  firstName: Yup.string().nullable(),
  lastName: Yup.string().nullable(),
});

function Form({ colorType, profilePage }) {
  const dispatch = useDispatch();

  const email = useSelector(selectUser);
  const buttonLoading = useSelector(selectAuthLoading);
  const profile = useSelector(selectProfile);
  const pageType = useMemo(() => (profilePage ? 'profilePage' : 'login'), [profilePage]);

  const handleSubmit = (values) => {
    dispatch(setProfileRequest({ email, redirect: !profilePage, ...values }));
  };

  const isLoading = useSelector(selectProfileLoading);
  if (isLoading) return <LoadingIndicator />;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        firstName: get(profile, 'name'),
        lastName: get(profile, 'lastName'),
        birthdate: get(profile, 'birthdate') || undefined,
        gender: get(profile, 'gender') || undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty }) => (
        <FormComponent>
          <Fields colorType={colorType} />
          <Button
            type="submit"
            colorType={colorType}
            isLoading={buttonLoading}
            marginBottom="m"
            disabled={profilePage && !dirty}
          >
            <FormattedMessage {...messages[pageType].button} />
          </Button>
        </FormComponent>
      )}
    </Formik>
  );
}

Form.propTypes = {
  colorType: PropTypes.string,
  profilePage: PropTypes.bool,
};

export default Form;
