import styled from 'styled-components';
import { Heading, Text as _text, CompleteBackground } from '@ebay-certilogo/design-system-web';

export const Wrapper = styled(CompleteBackground)`
  position: fixed;
`;

export const Title = styled(Heading)`
  text-align: center;
`;

export const Text = styled(_text)`
  text-align: center;
  margin-top: 50px;
`;
