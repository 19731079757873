import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { flowRequest, getStoresRequest, addStoreRequest } from 'containers/Authentication/actions';
import {
  selectAuthenticationStoresLoading,
  selectAuthenticationStores,
} from 'containers/Authentication/selectors';
import { setBack } from 'containers/App/actions';
import Form from 'components/form/FormComponent';
import withColorType from 'components/HOC/withColorType';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import FlowButton from 'containers/Authentication/components/FlowButton';
import StoreSuggest from '../StoreSuggest';
import messages from './messages';

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

const validationSchemaInsert = Yup.object().shape({
  url: Yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /^(http:\/\/|https:\/\/|HTTP:\/\/|HTTPS:\/\/|HTTP:\/\/www\.|HTTP:\/\/WWW\.|HTTPS:\/\/www\.|HTTPS:\/\/WWW\.)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
    {
      message: <FormattedMessage {...messages.urlError} />,
    },
  ),
});

const validationSchemaSearch = Yup.object().shape({
  url: Yup.string(),
});

function RetailerOnline({ colorType, data, authenticationId, intl }) {
  const { status } = data;
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(null);
  const storesLoading = useSelector(selectAuthenticationStoresLoading);
  const stores = useSelector(selectAuthenticationStores);

  const { url } = useRouteMatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = ({ url: urlValue }) => {
    if (!storesLoading) {
      dispatch(
        addStoreRequest({ params: { url: urlValue, online: true }, authenticationId, status }),
      );
    }
  };

  const selectedStore = (item) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: item
          ? {
              store: item.id,
            }
          : {},
      }),
    );
  };

  const placeStoreCalls = (value) => {
    dispatch(getStoresRequest({ url: value, online: true, authenticationId }));
  };

  const debounceCallback = useCallback(debounce(placeStoreCalls, 500), []);
  const required = get(data, 'props.required');

  return (
    <>
      <HeaderBox
        monochrome
        colorType={colorType}
        text={<FormattedMessage {...messages.title} />}
        minHeight="60px"
      />

      <Formik
        initialValues={{}}
        validationSchema={
          stores && isEmpty(stores) ? validationSchemaInsert : validationSchemaSearch
        }
        onSubmit={handleSubmit}
      >
        <Form>
          <Field
            name="url"
            autoCapitalize="none"
            component={StoreSuggest}
            url={url}
            colorType={colorType}
            searchCallBack={debounceCallback}
            selectedCallBack={({ item }) => {
              selectedStore(item);
            }}
            storesLoading={storesLoading}
            stores={stores}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            iconInsertActive={stores && isEmpty(stores)}
            notFound={
              <FlowButton colorType={colorType} type="submit" noMargin>
                <FormattedMessage {...messages.go} />
              </FlowButton>
            }
            placeholder={intl.formatMessage(messages.placeholder)}
            skip={required === false && selectedStore}
          />
        </Form>
      </Formik>
    </>
  );
}

RetailerOnline.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
  intl: PropTypes.object,
};

export default withColorType(injectIntl(RetailerOnline));
