import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FileUpload, FormFormik, Loader } from '@ebay-certilogo/design-system-web';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { useSelector } from 'react-redux';
import { selectJwt } from 'containers/Auth/selectors';
import { omit } from 'lodash';

export const uploadImage = async ({ file, jwtToken }) => {
  const apiUrl = 'image/simple/upload';

  const formData = new FormData();
  formData.append('file', file);

  try {
    const res = await fetchWrapper({
      url: apiUrl,
      formData,
      options: {
        method: 'POST',
        headers: { jwtToken },
      },
    });
    return res?.objectUrl;
  } catch (error) {
    return false;
  }
};

function FileUploadInput({ form, buttonProps, name, ...rest }) {
  const jwtToken = useSelector(selectJwt);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpload = async (file) => {
    setIsLoading(true);
    const res = await uploadImage({ file, jwtToken });
    form.setFieldValue(name, res);
    setIsLoading(false);
  };

  return (
    <FileUpload
      name={name}
      buttonProps={{
        ...buttonProps,
        button: isLoading ? <Loader /> : buttonProps.button,
      }}
      onChange={handleUpload}
      {...omit(rest, ['onChange'])}
    />
  );
}

FileUploadInput.propTypes = {
  form: PropTypes.object,
  field: PropTypes.object,
  buttonProps: PropTypes.object,
  name: PropTypes.string,
};

export default FormFormik.withLogic(FileUploadInput);
