import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FormFormik, Flex, Button } from '@ebay-certilogo/design-system-web';
import {
  Search,
  Plus,
  StoreOnline,
  ChevronRight,
} from '@ebay-certilogo/design-system-web/dist/components/icons';
import Lists from 'components/Lists';
import { FormattedMessage } from 'react-intl';
import withColorType from 'components/HOC/withColorType';
import messages from './messages';

const { TextInput } = FormFormik;

function StoreSuggest({
  colorType,
  storesLoading,
  stores,
  searchCallBack,
  selectedCallBack,
  isOpen,
  setIsOpen,
  notFound,
  form,
  field,
  iconInsertActive,
  setInputValue,
  placeholder,
  skip,
}) {
  const inputRef = useRef();
  const onChange = () => {
    const inputValue = get(inputRef, 'current.value');

    setIsOpen(!!inputValue);
    form.setFieldValue(field.name, inputValue);
    searchCallBack(inputValue);
    if (setInputValue) setInputValue(inputValue);
  };

  return (
    <>
      <TextInput
        form={form}
        field={field}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
        colorType={colorType}
        ref={inputRef}
        icon={
          iconInsertActive && field.value ? (
            <Plus width={16} height={16} colorType={colorType} />
          ) : (
            <Search width={22} height={22} colorType={colorType} />
          )
        }
        onKeyPress={(e) => {
          if (e.which === 13) e.preventDefault();
        }}
      />
      {skip && (
        <Flex justifyContent="flex-end" alignItems="center" noMargin>
          <Button
            buttonType="transparent"
            colorType={colorType}
            marginBottom="s"
            noPadding
            widthAuto
            onClick={skip}
            style={{ minHeight: 0, height: 30 }}
          >
            <FormattedMessage {...messages.skip} />
          </Button>
        </Flex>
      )}

      {isOpen && (
        <Lists
          colorType={colorType}
          onClick={(item) => {
            selectedCallBack({ item });
          }}
          left={<StoreOnline width={18} height={18} colorType={colorType} />}
          // eslint-disable-next-line react/no-unstable-nested-components
          right={({ colorType: cT }) => <ChevronRight width={40} height={40} colorType={cT} />}
          accessoryRow={['name', '', 'url']}
          type="placeRow"
          data={stores || []}
          isLoading={storesLoading}
          margin="s"
          marginBottom="s"
          notFound={notFound}
        />
      )}
    </>
  );
}

StoreSuggest.propTypes = {
  colorType: PropTypes.string,
  storesLoading: PropTypes.bool,
  stores: PropTypes.array,
  searchCallBack: PropTypes.func,
  selectedCallBack: PropTypes.func,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  value: PropTypes.string,
  setInputValue: PropTypes.func,
  notFound: PropTypes.node,
  form: PropTypes.object,
  field: PropTypes.object,
  name: PropTypes.string,
  iconInsertActive: PropTypes.bool,
  placeholder: PropTypes.string,
  skip: PropTypes.func,
};

export default withColorType(StoreSuggest);
