import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import Survey from 'containers/Survey';
import { FormattedMessage } from 'react-intl';
import withColorType from 'components/HOC/withColorType';
import messages from './messages';

function AdditionalInfo({ colorType, data, authenticationId }) {
  const { status } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = () => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
      }),
    );
  };

  return (
    <Survey
      colorType={colorType}
      authenticationId={authenticationId}
      onSuccess={handleSubmit}
      submitLabel={<FormattedMessage {...messages.submit} />}
    />
  );
}

AdditionalInfo.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default withColorType(AdditionalInfo);
