/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { addCssProp, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { Text, Div, Image as _Image } from '@ebay-certilogo/design-system-web';
import { Download } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { getField } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

const imageSize = '260px';
export const Image = styled(_Image).attrs({
  resizeMode: 'contain',
})`
  width: auto;
  max-width: 100%;
  object-fit: contain;
`;

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  ${addCssProp};
`;

export const DownloadIcon = styled(Download).attrs({
  width: 20,
  height: 20,
})`
  margin-right: -50px;
  margin-left: 20px;
  color: ${(props) => getField('button.color', props)};
`;

export const Caption = styled(Text)`
  max-width: ${imageSize};
  align-self: center;
`;

export const PhotoWrapper = styled(Div)`
  display: block;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  ${margin('m', 'bottom')}
`;
