import { defineMessages } from 'react-intl';

export default defineMessages({
  link: {
    id: 'confirm_email.change_email_modal.link',
    defaultMessage: 'Change email',
  },
  title: {
    id: 'confirm_email.change_email_modal.title',
    defaultMessage: 'Change email',
  },
  text1: {
    id: 'confirm_email.change_email_modal.text1',
    defaultMessage: 'Write your new email address',
  },
});
