import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { Wrapper, Divider } from './styles';

function Header({ colorType, title, text, propsBag }) {
  return (
    <Wrapper colorType={colorType}>
      <Text colorType={colorType} weight="semibold" textAlign="center">
        <FormattedMessage id={title || 'id'} defaultMessage={title} values={propsBag} />
      </Text>
      <Divider colorType={colorType} />
      <Text colorType={colorType} weight="semibold" size="l" textAlign="center">
        <FormattedMessage id={text || 'id'} defaultMessage={text} values={propsBag} />
      </Text>
    </Wrapper>
  );
}

Header.propTypes = {
  colorType: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  propsBag: PropTypes.object,
};

export default Header;
