import React from 'react';
import PropTypes from 'prop-types';
import SurveyContainer from 'containers/Survey';
import get from 'lodash/get';
import { map } from 'lodash';
import ContentsGroup from 'components/cms/ContentsGroup';
import { Wrapper } from './styles';
import messages from './messages';

function Survey({
  style,
  colorType,
  logEventName = 'survey',
  type = 'authentic',
  survey,
  completeContents,
  completeAction,
  propsBag: { logger, id, service } = {},
  propsBag,
  componentType,
  gtm,
}) {
  const surveyId = survey?.id;

  const handleSubmit = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'survey' },
      });
    }
  };

  const messagesType = messages[type] || {};
  const submitLabel = get(messagesType, 'button.id');
  const completeMessage = get(messagesType, 'done.title.id');

  return (
    <Wrapper {...style}>
      <SurveyContainer
        colorType={colorType}
        id={surveyId || id}
        service={surveyId ? 'survey' : service}
        sessionId={id}
        onSuccess={handleSubmit}
        slim
        submitLabel={submitLabel}
        completeBody={
          !!completeContents?.length &&
          // eslint-disable-next-line react/no-unstable-nested-components
          map(completeContents, ({ contents_group: data }) => (
            <ContentsGroup
              data={data}
              propsBag={propsBag}
              colorType={colorType}
              completeAction={completeAction}
            />
          ))
        }
        completeMessage={completeMessage}
        propsBag={propsBag}
      />
    </Wrapper>
  );
}

Survey.propTypes = {
  componentType: PropTypes.string,
  buttonType: PropTypes.string,
  showTitle: PropTypes.string,
  showText: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
  survey: PropTypes.object,
  completeAction: PropTypes.func,
  completeContents: PropTypes.array,
};

export default Survey;
