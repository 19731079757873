import React from 'react';
import PropTypes from 'prop-types';
import { useInjectSaga } from 'utils/injectSaga';
import { Switch, useRouteMatch } from 'react-router-dom';
import Route from 'components/Route';
import useColorType from 'components/hooks/useColorType';
import saga from './saga';
import Create from './Create';
import Status from './Status';

function CodeReview() {
  const colorType = useColorType('codeReview');
  useInjectSaga({ key: 'code-review', saga });
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create/:authenticationId`}>
        <Create colorType={colorType} />
      </Route>
      <Route path={`${path}/:token`}>
        <Status colorType={colorType} />
      </Route>
    </Switch>
  );
}

CodeReview.propTypes = {
  colorType: PropTypes.string,
};

export default CodeReview;
