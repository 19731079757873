import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';

function ModalPolicy({ bodyMessage, isOpen, setIsOpen }) {
  return (
    <Modal colorType="dark" isOpen={isOpen} onClose={() => setIsOpen(false)} fullScreen close>
      {bodyMessage && <FormattedMessageMarkdown colorType="dark" {...bodyMessage} />}
    </Modal>
  );
}

ModalPolicy.propTypes = {
  labelMessage: PropTypes.string,
  bodyMessage: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default ModalPolicy;
