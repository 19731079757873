import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { warrantyResponse } from '../actions';

export default function* create({ payload: { authenticationId } }) {
  const url = 'warranty/v1/create';

  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        authenticationId,
      },
    });

    yield put(warrantyResponse(response));

    yield put(push(`/authentication/${authenticationId}/warranty/${response.data.page}`));
  } catch ({ response }) {
    if (response.status === 400) {
      yield put(push('/'));
    }
  }
}
