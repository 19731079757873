import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Div, Text, Button as _Button } from '@ebay-certilogo/design-system-web';
import { margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import EbayIcon from '../EbayIcon';

export const Wrapper = styled(Div)`
  border: 1px solid #000000;
`;

export const Button = styled(_Button).attrs({
  colorType: 'light',
})`
  padding: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #ffffff;
`;

export const SocialIcon = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  ${margin('s', 'left')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

const EbayButton = ({ children, colorType, onClick, size = 'm', ...rest }) => {
  return (
    <Wrapper colorType={colorType}>
      <Button
        type="button"
        colorType={colorType}
        size={size}
        onClick={onClick}
        icon={
          <SocialIcon style={{ width: 'auto' }}>
            <EbayIcon color="#000000" height={18} />
          </SocialIcon>
        }
        iconRight={<Div style={{ minWidth: '45px' }} />}
        noMargin
        {...rest}
      >
        {children}
      </Button>
    </Wrapper>
  );
};

EbayButton.propTypes = {
  children: PropTypes.element,
  colorType: PropTypes.string,
  size: PropTypes.string,
  onClick: PropTypes.func,
};

export default EbayButton;
