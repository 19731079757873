import React from 'react';
import PropTypes from 'prop-types';
import { Text as TextDS } from '@ebay-certilogo/design-system-web';
import { format, parseISO } from 'date-fns';

const Date = ({ value, colorType, ...rest }) => (
  <TextDS colorType={colorType} style={{ textTransform: 'none' }} {...rest}>
    {value && format(parseISO(value), 'dd/MM/yyyy')}
  </TextDS>
);

Date.propTypes = {
  colorType: PropTypes.string,
  value: PropTypes.string,
};

export default Date;
