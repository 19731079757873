import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fake_report.purchase.title1',
    defaultMessage: 'Purchase information',
  },
  text1: {
    id: 'fake_report.purchase.text1',
    defaultMessage:
      'Specify the price the product was purchased for. (Optional, but highly recommended).',
  },
  productPageUrl: {
    id: 'fake_report.purchase.productPageUrl',
    defaultMessage: 'Product page (URL)',
  },
  price: {
    id: 'fake_report.purchase.priceCurrency',
    defaultMessage: 'PRICE',
  },
  receiptImagePath: {
    id: 'fake_report.purchase.receiptImagePath',
    defaultMessage: 'UPLOAD RECEIPT',
  },
  continue: {
    id: 'fake_report2.button1',
    defaultMessage: 'continue',
  },
  saveForLater: {
    id: 'fake_report1.text2',
    defaultMessage: 'Save for later',
  },
});
