import { fetchWrapper } from 'utils/Api/fetchWrapper';

export const getStores = async ({ value, jwtToken }) => {
  const apiUrl = 'store/v3/search';

  try {
    const res = await fetchWrapper({
      url: apiUrl,
      withQuery: true,
      options: {
        headers: { jwtToken },
        body: {
          url: value,
          online: true,
        },
      },
    });
    return res?.stores;
  } catch (error) {
    return [];
  }
};

export default {
  getStores,
};
