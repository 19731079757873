import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'viume_survey.demo_modal.text',
    defaultMessage: 'In a real experience this link would take to your e-commerce.',
  },
  button: {
    id: 'viume_survey.demo_modal.button',
    defaultMessage: 'Ok, continue',
  },
});
