import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import history from 'utils/history';
import { Wrapper, Link, Icon } from './styles';
import types from './types';

function Social({
  size = 'm',
  style,
  logEventName,
  propsBag: { logger } = {},
  componentType,
  gtm,
  ...rest
}) {
  const handleOnClick = ({ social, target }) => {
    if (target === '_self') {
      history.push(rest[`${social}Url`]);
    }
    if (logger) {
      const link = rest[`${social}Url`];
      logger({
        action: 'click',
        type: componentType,
        link,
        gtm,
        step: 'finish',
        start: 1,
        finish: 1,
        event: { type: 'link', name: logEventName, category: 'social' },
        params: { target: link, social },
      });
    }
  };
  return (
    <Wrapper {...style} {...rest}>
      {map(
        types,
        ({ icon, target }, key) =>
          rest[key] === true && (
            <Link
              key={key}
              size={size}
              href={rest[`${key}Url`]}
              target={target}
              onClick={() => handleOnClick({ social: key, target })}
            >
              <Icon as={icon} />
            </Link>
          ),
      )}
    </Wrapper>
  );
}

Social.propTypes = {
  text: PropTypes.string,
  size: PropTypes.string,
  componentType: PropTypes.string,
  weight: PropTypes.string,
  style: PropTypes.object,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default Social;
