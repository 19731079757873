import styled from 'styled-components';
import { Flex, Image, Div, ButtonSimple } from '@ebay-certilogo/design-system-web';
import {
  secondaryColor,
  fontFamily,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { fontSize, padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import Svg from 'components/Svg';
import logoClgDark from './certilogo-logo-dark.svg';
import logoClgLight from './certilogo-logo-light.svg';

export const Wrapper = styled(Flex)`
  flex-direction: column;
  background-color: ${secondaryColor};
  border-radius: 6px;
  margin-left: -10px;
  margin-right: -10px;
  ${padding('m')}
  font-family: ${fontFamily};
  ${fontSize()}
`;

export const InfoButton = styled(ButtonSimple).attrs({
  backgroundOpacity: 1,
})`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
`;

export const Header = styled(Flex)`
  flex-direction: row;
  justify-content: center;
  position: relative;
`;

export const LogoClg = styled(Div)`
  width: auto;
  height: auto;
  display: flex;
  align-items: center;
  max-height: 28px;
  max-width: 122px;
  ${Image} {
    height: 100%;
  }
`;

export const LogoSvg = styled(Svg).attrs(({ colorType }) => ({
  src: colorType === 'dark' ? logoClgDark : logoClgLight,
  source: colorType === 'dark' ? logoClgDark : logoClgLight,
  height: 28,
  containerStyle: { height: 28, width: 76 },
}))`
  height: 28px;
`;
