import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'where_active_soa_3.title1',
    defaultMessage: 'Who is the seller you want to buy from?',
  },
  link: {
    id: 'where_active_soa_3.text1',
    defaultMessage: 'None of the above?',
  },
});
