import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ContentsGroup from 'components/cms/ContentsGroup';
import useIsVisible from 'components/hooks/useIsVisible';
import map from 'lodash/map';
import { ExternalWrapper, Wrapper, Cell, OverlayContents } from './styles';

function Grid({
  colorType,
  style,
  cellContents,
  rowSize = 3,
  overlayContents,
  propsBag,
  completeAction,
  isSticky,
}) {
  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (propsBag.setSwipeable) {
      propsBag.setSwipeable(false);
    }
    return () => {
      if (propsBag.setSwipeable) {
        propsBag.setSwipeable(true);
      }
    };
  }, []);
  return (
    <div ref={ref}>
      <ExternalWrapper isSticky={isSticky} isVisible={isVisible}>
        <Wrapper colorType={colorType} {...style} isSticky={isSticky} isVisible={isVisible}>
          {map(cellContents, ({ colSize, contents_group: contentsGroup, id }) => (
            <Cell width={`${(colSize / rowSize) * 100}%`} key={id}>
              <ContentsGroup
                data={contentsGroup}
                colorType={colorType}
                propsBag={propsBag}
                completeAction={completeAction}
              />
            </Cell>
          ))}
          {!isEmpty(overlayContents) && (
            <OverlayContents>
              {overlayContents.map(({ contents_group: contentsGroup }) => (
                <ContentsGroup
                  colorType={colorType}
                  data={contentsGroup}
                  propsBag={propsBag}
                  completeAction={completeAction}
                />
              ))}
            </OverlayContents>
          )}
        </Wrapper>
      </ExternalWrapper>
    </div>
  );
}

Grid.propTypes = {
  colorType: PropTypes.string,
  cellContents: PropTypes.array,
  overlayContents: PropTypes.array,
  type: PropTypes.string,
  style: PropTypes.object,
  propsBag: PropTypes.object,
  rowSize: PropTypes.number,
  completeAction: PropTypes.func,
  isSticky: PropTypes.bool,
};

export default Grid;
