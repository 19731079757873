import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useRouteMatch } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import Form from 'components/form/FormComponent';
import { useSelector } from 'react-redux';
import { selectJwt } from 'containers/Auth/selectors';
import StoreSuggest from './StoreSuggest';
import messages from './messages';
import { getStores } from './api';

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

const validationSchemaInsert = Yup.object().shape({
  url: Yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /^(http:\/\/|https:\/\/|HTTP:\/\/|HTTPS:\/\/|HTTP:\/\/www\.|HTTP:\/\/WWW\.|HTTPS:\/\/www\.|HTTPS:\/\/WWW\.)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
    {
      message: <FormattedMessage {...messages.urlError} />,
    },
  ),
});

const validationSchemaSearch = Yup.object().shape({
  url: Yup.string(),
});

function RetailerOnlineInput({ colorType, intl, onSelect }) {
  const [isOpen, setIsOpen] = useState(null);
  const [storesLoading, setStoresLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const jwtToken = useSelector(selectJwt);

  const { url } = useRouteMatch();

  // const handleSubmit = ({ url: urlValue }) => {
  //   dispatch(
  //     addStoreRequest({ params: { url: urlValue, online: true } }),
  //   );
  // };

  const selectedStore = (item) => {
    onSelect(item);
  };

  const placeStoreCalls = async (value) => {
    setStoresLoading(true);
    const res = await getStores({ value, jwtToken });
    setStores(res);
    setStoresLoading(false);
  };

  const debounceCallback = useCallback(debounce(placeStoreCalls, 500), []);
  return (
    <Formik
      initialValues={{}}
      validationSchema={stores && isEmpty(stores) ? validationSchemaInsert : validationSchemaSearch}
    >
      <Form>
        <Field
          name="url"
          autoCapitalize="none"
          component={StoreSuggest}
          url={url}
          colorType={colorType}
          searchCallBack={debounceCallback}
          selectedCallBack={({ item }) => {
            selectedStore(item);
          }}
          storesLoading={storesLoading}
          stores={stores}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          iconInsertActive={stores && isEmpty(stores)}
          // notFound={
          //   <FlowButton colorType={colorType} type="submit" noMargin>
          //     <FormattedMessage {...messages.go} />
          //   </FlowButton>
          // }
          placeholder={intl.formatMessage(messages.placeholder)}
        />
      </Form>
    </Formik>
  );
}

RetailerOnlineInput.propTypes = {
  colorType: PropTypes.string,
  onSelect: PropTypes.func,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
  intl: PropTypes.object,
};

export default injectIntl(RetailerOnlineInput);
