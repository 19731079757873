import React from 'react';
import PropTypes from 'prop-types';
import { getField } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { withTheme } from 'styled-components';
import AnimationComplete from '../AnimationComplete';

function AnimationFake(props) {
  return (
    <AnimationComplete
      explosionGradient={getField('response.fake.explosionGradient', props)}
      assistantStateStartExplosion="bw_black"
      assistantStateEndExplosion="bw_white"
      assistantStateHiddenExplosion="bw_white"
      {...props}
    />
  );
}

AnimationFake.propTypes = {
  colorType: PropTypes.string,
  assistant: PropTypes.object,
  explosion: PropTypes.object,
  onAnimationEnd: PropTypes.func,
  onLoaderAnimationEnd: PropTypes.func,
  hideExplosionOnEnd: PropTypes.bool,
};

export default withTheme(AnimationFake);
