import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion as AccordionDS } from '@ebay-certilogo/design-system-web';
import map from 'lodash/map';
import ContentsGroup from 'components/cms/ContentsGroup';
import { Trigger } from './styles';

function Accordion({
  bodyContents,
  logEventName = 'open accordion',
  colorType,
  trigger,
  propsBag,
  propsBag: { logger } = {},
  style,
  bodyStyle,
  completeAction,
  componentType,
  gtm,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnChange = (action) => {
    setIsOpen(action === 'open');
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        label: trigger?.text,
        locked: trigger?.locked,
        gtm,
        start: action === 'open' ? 1 : 0,
        finish: action === 'close' ? 1 : 0,
        event: { type: 'accordion', name: logEventName, category: 'accordion' },
        params: { label: trigger?.text },
      });
    }
  };

  const triggerComponent = (
    <Trigger
      noMargin
      isOpen={isOpen}
      {...trigger}
      colorType={trigger?.colorType || colorType}
      buttonType={trigger?.buttonType}
      arrowStyle={{ transition: 'transform 300ms', transform: `rotate(${isOpen ? '-' : ''}90deg)` }}
    />
  );

  return (
    <AccordionDS
      colorType={colorType}
      trigger={triggerComponent}
      onOpen={() => {
        handleOnChange('open');
      }}
      onClose={() => {
        handleOnChange('close');
      }}
      bodyProps={bodyStyle}
      lazyLoad
      {...style}
    >
      {map(bodyContents, ({ contents_group: data }) => (
        <ContentsGroup
          data={data}
          propsBag={propsBag}
          colorType={colorType}
          completeAction={completeAction}
        />
      ))}
    </AccordionDS>
  );
}

Accordion.propTypes = {
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  trigger: PropTypes.object,
  bodyContents: PropTypes.array,
  close: PropTypes.bool,
  back: PropTypes.bool,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  page: PropTypes.bool,
  fullBody: PropTypes.bool,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  style: PropTypes.object,
  bodyStyle: PropTypes.object,
  completeAction: PropTypes.func,
  gtm: PropTypes.object,
};

export default Accordion;
