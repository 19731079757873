/* eslint-disable global-require */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap, Power2 } from 'gsap';
import Lottie from 'lottie-react';
import { Spring } from '@react-spring/web';
import isString from 'lodash/isString';
import LinearGradient from 'components/LinearGradient';
import animationDataAssistant from '../lottie/Assistant.json';
import animationDataCollect from '../lottie/Collect.json';

const AssistantActions = {
  colored_black: {
    start: 0,
    end: 238,
  },
  colored_black_2_bw_black: {
    start: 238,
    end: 255,
    transitionTo: 'bw_black',
  },
  bw_black: {
    start: 255,
    end: 480,
  },
  bw_black_2_bw_white: {
    start: 480,
    end: 495,
    transitionTo: 'bw_white',
  },
  bw_white: {
    start: 495,
    end: 720,
  },
  bw_white_2_colored_white: {
    start: 720,
    end: 735,
    transitionTo: 'colored_white',
  },
  colored_white: {
    start: 735,
    end: 960,
  },
  colored_white_2_colored_black: {
    start: 960,
    end: 975,
    transitionTo: 'colored_black',
  },
  colored_black_2_colored_white: {
    start: 1200,
    end: 1215,
    transitionTo: 'colored_white',
  },
};

const endPoint = {
  x: 200,
  y: 70,
};

const ASSISTANT_SIZE = 100;
const ASSISTANT_SIZE_SMALL = 40;

const Assistant = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-${ASSISTANT_SIZE / 2}px, -${ASSISTANT_SIZE / 2}px);
  width: ${ASSISTANT_SIZE}px;
  height: ${ASSISTANT_SIZE}px;
  z-index: 1;
`;

const Collect = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 400px;
  width: 400px;
  transform: translate(-285px, -123px);
  z-index: 2;
`;

const AnimationWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
  z-index: 0;
`;

const Explosion = styled.div`
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

function AnimationLight({
  assistantStateStartExplosion = 'colored_black',
  assistantStateEndExplosion = 'colored_black',
  assistantStateHiddenExplosion = 'colored_white',
  onLoaderAnimationEnd,
  onAnimationEnd,
  explosionGradient: _explosionGradient = 'linear-gradient(315deg, #7d55c7, #00b5e2 100%)',
  hideExplosionOnEnd,
  assistant: { end = endPoint },
}) {
  const explosionGradient = isString(_explosionGradient)
    ? _explosionGradient
    : 'linear-gradient(315deg, #7d55c7, #00b5e2 100%)';
  const [timelineMaster, setTimelineMaster] = useState();
  const [explosionVisibility, setExplosionVisibility] = useState(true);
  const [explosionAnimationVisibility, setExplosionAnimationVisibility] = useState(false);
  const [assistantState, setAssistantState] = useState('colored_black');
  const assistantRef = useRef();
  const assistantLottieRef = useRef();
  useEffect(() => {
    setTimelineMaster(gsap.timeline());
  }, []);

  useEffect(() => {
    if (assistantLottieRef.current) {
      const assistantAction = AssistantActions[assistantState];
      assistantLottieRef.current.playSegments([assistantAction.start, assistantAction.end], true);
    }
  }, [assistantLottieRef.current, assistantState]);

  const moveAssistant = () => {
    timelineMaster.to(assistantRef.current, {
      duration: 1,
      delay: 0,
      ease: Power2.easeInOutCubic,
      left: end.x,
      top: end.y,
      width: ASSISTANT_SIZE_SMALL,
      height: ASSISTANT_SIZE_SMALL,
      onComplete: () => {
        if (onAnimationEnd) onAnimationEnd();
      },
    });
    return timelineMaster;
  };

  const onCollectEnd = () => {
    setExplosionAnimationVisibility(true);
    setAssistantState(assistantStateStartExplosion);
    if (onLoaderAnimationEnd) onLoaderAnimationEnd();
  };

  const onAssistantAnimationFinish = () => {
    const { transitionTo } = AssistantActions[assistantState];
    if (transitionTo) setAssistantState(transitionTo);
  };

  const onExplosionEnd = () => {
    timelineMaster.add(moveAssistant(timelineMaster));
    setAssistantState(assistantStateEndExplosion);
    if (hideExplosionOnEnd) {
      setTimeout(() => {
        setAssistantState(assistantStateHiddenExplosion);
        setExplosionVisibility(false);
      }, 2000);
    }
  };

  return (
    <AnimationWrapper>
      <Assistant ref={assistantRef}>
        <Lottie
          loop
          autoPlay
          lottieRef={assistantLottieRef}
          animationData={animationDataAssistant}
          onComplete={onAssistantAnimationFinish}
        />
      </Assistant>
      <Collect>
        <Lottie
          loop={false}
          autoplay
          animationData={animationDataCollect}
          onComplete={onCollectEnd}
        />
      </Collect>
      <Spring
        from={{
          opacity: 1,
        }}
        to={{
          opacity: explosionVisibility ? 1 : 0,
        }}
        onRest={onExplosionEnd}
      >
        {(style) => (
          <Explosion style={style}>
            <Spring
              from={{
                opacity: 0,
              }}
              to={{
                opacity: explosionAnimationVisibility ? 1 : 0,
              }}
              onRest={onExplosionEnd}
            >
              {(styleAnimation) => (
                <LinearGradient
                  style={{ height: '100%', ...styleAnimation }}
                  color={explosionGradient}
                />
              )}
            </Spring>
          </Explosion>
        )}
      </Spring>
    </AnimationWrapper>
  );
}

AnimationLight.propTypes = {
  assistant: PropTypes.object,
  onLoaderAnimationEnd: PropTypes.func,
  onAnimationEnd: PropTypes.func,
  hideExplosionOnEnd: PropTypes.bool,
  explosionGradient: PropTypes.array,
  assistantStateStartExplosion: PropTypes.string,
  assistantStateEndExplosion: PropTypes.string,
  assistantStateHiddenExplosion: PropTypes.string,
};

export default AnimationLight;
