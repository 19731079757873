import React from 'react';
import { FacebookColor } from '@ebay-certilogo/design-system-web/dist/components/icons';
import FacebookLoginRN from 'react-facebook-login/dist/facebook-login-render-props';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { loginRequest } from 'containers/Auth/actions';
import config from 'config';
import { isMobile } from 'utils/utils';
import { FacebookButton, SocialIcon } from '../styles';
import messages from '../messages';

const {
  oauth: { facebook },
} = config;

function FacebookLogin({ colorType, type, setError }) {
  const dispatch = useDispatch();

  const responseFacebook = (response) => {
    setError(null);
    if (response.status !== 'unknown')
      dispatch(
        loginRequest({
          network: 'facebook',
          token: response.accessToken,
          version: 2,
          redirect: true,
        }),
      );
    else setError({ facebook: { error: 'default' } });
  };

  return (
    <FacebookLoginRN
      isMobile={isMobile}
      disableMobileRedirect={isMobile}
      appId={facebook.appId}
      fields={facebook.fields.toString()}
      callback={responseFacebook}
      render={({ onClick }) => (
        <FacebookButton
          type="button"
          colorType={colorType}
          onClick={onClick}
          icon={
            <SocialIcon>
              <FacebookColor width={30} height={30} />
            </SocialIcon>
          }
        >
          <FormattedMessage {...messages[type].facebook} />
        </FacebookButton>
      )}
    />
  );
}

FacebookLogin.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  type: PropTypes.string,
  setError: PropTypes.func,
};

export default FacebookLogin;
