import { defineMessages } from 'react-intl';

export default defineMessages({
  vacation: {
    title: {
      id: 'soa_vacation.title1',
      defaultMessage: "Buyers want to be sure they're buying authentic products!",
    },
    text: {
      id: 'soa_vacation.text1',
      defaultMessage:
        'With a Certilogo Seal of Authentication your customers can authenticate before they buy. Reassure them and sell more, faster.',
    },
    button: {
      id: 'soa_vacation.button1',
      defaultMessage: 'Continue authentication',
    },
    availableDate: {
      id: 'soa_vacation.text3',
      defaultMessage: 'Available from {date}',
    },
  },
});
