import React from 'react';
import PropTypes from 'prop-types';
import { Button, Image, Modal, Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import image from './image.jpg';

function DemoModal({ colorType, setModal, ...rest }) {
  return (
    <Modal colorType={colorType} {...rest} close onClose={() => setModal(false)}>
      <Text colorType={colorType} weight="semibold" textAlign="center" marginBottom="m">
        <FormattedMessage {...messages.text} />
      </Text>
      <Image src={image} />
      <Button size="s" colorType={colorType} onClick={() => setModal(false)}>
        <FormattedMessage {...messages.button} />
      </Button>
    </Modal>
  );
}

DemoModal.propTypes = {
  colorType: PropTypes.string,
  isOpen: PropTypes.bool,
  setModal: PropTypes.func,
};

export default DemoModal;
