import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectWarrantyDomain = () => (state) => state.warranty;

export const selectWarrantyLoading = createSelector(selectWarrantyDomain(), (state) =>
  get(state, 'loading'),
);

export const selectWarrantyData = createSelector(selectWarrantyDomain(), (state) =>
  get(state, 'data'),
);

export const selectDownloadLoading = createSelector(selectWarrantyDomain(), (state) =>
  get(state, 'downloadLoading'),
);
