import styled, { css } from 'styled-components';
import { styles, Div } from '@ebay-certilogo/design-system-web';
import {
  padding,
  margin,
  addCssProp,
  media,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';
import {
  color,
  getField,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

export const Wrapper = styled(Div)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  color: ${color};
`;

export const Style = styled(Div)`
  ${({ cardType }) => {
    switch (cardType) {
      case 'cards':
        return css`
          transition: all 0.6s;
          opacity: 0.6;
          background: ${({ response = 'authentic', ...props }) =>
            getField(`response.${response}.card.backgroundColor`, props)};
          border-radius: 6px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        `;
      default:
        return null;
    }
  }}
  ${margin('s', 'bottom')}
  ${styles.contentWrapper}
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  height: ${({ height }) => height}px;
  ${media.tablet`
    height: ${({ height }) => height - 20}px;
  `}
  justify-content: ${({ verticalCenter }) => (verticalCenter ? 'center' : 'initial')};
  ${padding('m')}
  ${addCssProp}
`;
