/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { margin, padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { Div } from '@ebay-certilogo/design-system-web';
import ImageComponent from 'components/Image';

export const Wrapper = styled(Div)`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  ${padding('m')}
  ${margin('m', 'bottom')}
`;

export const Image = styled(ImageComponent)`
  max-height: 200px;
  width: auto;
  max-width: 100%;
`;
