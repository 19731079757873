import React from 'react';
import PropTypes from 'prop-types';
import { Image as ImageDS } from '@ebay-certilogo/design-system-web';

const Image = ({ value, colorType, ...rest }) => (
  <ImageDS src={value} colorType={colorType} {...rest} style={{ maxWidth: '150px' }} />
);

Image.propTypes = {
  colorType: PropTypes.string,
  value: PropTypes.string,
};

export default Image;
