import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SurveyComponent from 'components/Survey';
import { useInjectSaga } from 'utils/injectSaga';
import { useDispatch, useSelector } from 'react-redux';
import LoadingIndicator from 'components/LoadingIndicator';
import saga from './saga';
import { getRequest, sendRequest } from './actions';
import { selectSurveyLoading, selectSurveyData, selectSurveySendLoading } from './selectors';

function Survey({ colorType, id, service, sessionId, onSuccess, ...rest }) {
  useInjectSaga({ key: 'survey', saga });
  const [submitted, setSubmitted] = useState(false);
  const [complete, setComplete] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectSurveyLoading);
  const sendLoading = useSelector(selectSurveySendLoading);
  const data = useSelector(selectSurveyData({ id, service }));

  useEffect(() => {
    if (id && service) dispatch(getRequest({ id, service, sessionId }));
  }, []);

  useEffect(() => {
    if (submitted && !sendLoading) {
      setComplete(true);
      onSuccess();
      setSubmitted(false);
    }
  }, [sendLoading]);

  const handleSubmit = (answer) => {
    if (data) {
      setSubmitted(true);
      const { name, type } = data;
      dispatch(sendRequest({ id, service, answer, surveyId: name, sessionId, type }));
    }
  };

  if (isLoading || !data) return <LoadingIndicator />;

  return (
    <SurveyComponent
      colorType={colorType}
      data={data}
      onSubmit={handleSubmit}
      submitLoading={sendLoading}
      complete={complete}
      {...rest}
    />
  );
}

Survey.propTypes = {
  colorType: PropTypes.string,
  id: PropTypes.string,
  service: PropTypes.string,
  sessionId: PropTypes.string,
  onSuccess: PropTypes.func,
  survey: PropTypes.object,
};

export default Survey;
