import { defineMessages } from 'react-intl';

const errors = {
  email: defineMessages({
    exists: {
      id: 'confirm_email.change_email_modal.errors.email_exists',
      defaultMessage: 'Error, already exist',
    },
  }),
};

const defaultMessages = defineMessages({
  submitButton: {
    id: 'confirm_email.change_email_modal.button',
    defaultMessage: 'Resend',
  },
});

export default {
  ...defaultMessages,
  errors,
};
