import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'warranty_recap.form_title',
    defaultMessage: 'Confirm your data',
  },
  buttonSubmit: {
    id: 'warranty_recap.button1',
    defaultMessage: 'Done, send me the warranty',
  },
  buttonEdit: {
    id: 'warranty_recap.button3',
    defaultMessage: 'Edit',
  },
  buttonSave: {
    id: 'warranty_recap.button2',
    defaultMessage: 'Save for later',
  },
  certilogoCode: {
    id: 'warranty_recap.text1',
    defaultMessage: 'Certilogo code',
  },
  response: {
    id: 'warranty_recap.text2',
    defaultMessage: 'Authentication result',
  },
  brand: {
    id: 'warranty_recap.text3',
    defaultMessage: 'Associated brand',
  },
  salesChannel: {
    id: 'warranty_recap.text4',
    defaultMessage: 'Sales channel',
  },
  authenticationDate: {
    id: 'warranty_recap.text5',
    defaultMessage: 'Authentication date',
  },
});
