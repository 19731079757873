import * as THREE from 'three';
import { getLocalImage } from 'utils/images';

/* eslint-disable */
class Particles {
    constructor(e) {
        this._parent = e.parent, this._play = !1, this._particleSystem = null, this._mouseX = 0, this._mouseY = 0, this._fogValue = -150, this._startCamera = {
            x: 0,
            y: 0,
            z: 10
        }, this._actualCurve = [{
            x: 0,
            y: 12,
            z: -25
        }, {
            x: -10,
            y: 4,
            z: -8
        }, {
            x: 0,
            y: 0,
            z: 0
        }, {
            x: 3,
            y: -1,
            z: 3
        }, {
            x: 2,
            y: -1,
            z: 12
        }], this._camera = new THREE.PerspectiveCamera(45, this._parent.clientWidth / this._parent.clientHeight, 1, 1e3), this._camera.position.x = this._startCamera.x, this._camera.position.y = this._startCamera.y, this._camera.position.z = this._startCamera.z, this._scene = new THREE.Scene, this._scene.fog = new THREE.Fog(430618, this._fogValue, 50);
        new THREE.AxesHelper(5);
        this._renderer = new THREE.WebGLRenderer({
            antialias: !0,
            alpha: !0
        }), this._renderer.shadowMap.enabled = !0, this._renderer.setPixelRatio(window.devicePixelRatio), this._renderer.setSize(this._parent.clientWidth, this._parent.clientHeight), this._parent.appendChild(this._renderer.domElement), this._init()
    }
    start() {
        this._render();
    }
    stop() {
        window.cancelAnimationFrame(this.requestId);
        this.requestId = undefined;
    }
    setCurveTo(e) {
        e && (this._curve = e)
    }
    _init() {
        let e = this;
        e._addLight(), e._initParticles()
    }
    _addLight() {
        var e = new THREE.AmbientLight(4210752);
        this._scene.add(e)
    }
    _initParticles() {
        this._curve = "base", this._generateCurve(this._curve, !1), this._generateParticles()
    }
    _generateParticles() {
        let e = this;
        e._particleSystem = new Array;
        new Array;
        let t = window.innerWidth > 1024 ? new THREE.SphereGeometry(5, 65, 65) : new THREE.SphereGeometry(5, 50, 50);
        for (let a = 0; a < t.vertices.length; a++) {
            let r = e._randomPointInCircle(1);
            t.vertices[a] = {
                x: t.vertices[a].x,
                y: t.vertices[a].y,
                z: t.vertices[a].z,
                pathPosition: Math.random(),
                pathSpeed: .001 + 5e-4 * Math.random(),
                displacementX: r.x,
                displacementY: r.y,
                displacementZ: 3 * Math.random() - 1.5
            }, t.colors.push(new THREE.Color(0, 3, 3))
        }
        let a = new THREE.PointsMaterial({
            size: .06,
            transparent: !0,
            blending: THREE.NoBlending,
            vertexColors: THREE.VertexColors,
            map: THREE.ImageUtils.loadTexture(getLocalImage('/images/particle.png'))
        });
        e._particles = new THREE.Points(t, a), e._scene.add(e._particles)
    }
    _generateCurve(e, t = !1) {
        let a = this,
            r = [{
                x: 0,
                y: 12,
                z: -25
            }, {
                x: -10,
                y: 4,
                z: -8
            }, {
                x: 0,
                y: 0,
                z: 0
            }, {
                x: 3,
                y: -1,
                z: 3
            }, {
                x: 2,
                y: -1,
                z: 12
            }],
            i = [{
                x: 0,
                y: 12,
                z: -25
            }, {
                x: -10,
                y: 1,
                z: -8
            }, {
                x: -4,
                y: 0,
                z: 0
            }, {
                x: .5,
                y: 1,
                z: 3
            }, {
                x: -3,
                y: 4,
                z: 12
            }],
            n = [{
                x: 0,
                y: 12,
                z: -25
            }, {
                x: -10,
                y: 4,
                z: -8
            }, {
                x: 0,
                y: 0,
                z: 0
            }, {
                x: 0,
                y: -1,
                z: 3
            }, {
                x: -4,
                y: -1,
                z: 12
            }],
            s = [{
                x: 3.5,
                y: 14.5,
                z: -25
            }, {
                x: -2.5,
                y: 4,
                z: -1
            }, {
                x: .5,
                y: 0,
                z: 0
            }, {
                x: .5,
                y: -1,
                z: 3
            }, {
                x: -3.5,
                y: -1,
                z: 12
            }];
        switch (e) {
            case "base":
                a._createCurve(r, t);
                break;
            case "auth":
                a._createCurve(i, t);
                break;
            case "web":
                a._createCurve(n, t);
                break;
            case "mobile":
                a._createCurve(s, t)
        }
    }
    _createCurve(e, t = !1) {
        let a = this,
            r = new Array;
        t ? (r[0] = new THREE.Vector3(a._giveMeZenone(a._actualCurve[0].x, e[0].x, .08), a._giveMeZenone(a._actualCurve[0].y, e[0].y, .08), a._giveMeZenone(a._actualCurve[0].z, e[0].z, .08)), r[1] = new THREE.Vector3(a._giveMeZenone(a._actualCurve[1].x, e[1].x, .04), a._giveMeZenone(a._actualCurve[1].y, e[1].y, .04), a._giveMeZenone(a._actualCurve[1].z, e[1].z, .04)), r[2] = new THREE.Vector3(a._giveMeZenone(a._actualCurve[2].x, e[2].x, .06), a._giveMeZenone(a._actualCurve[2].y, e[2].y, .06), a._giveMeZenone(a._actualCurve[2].z, e[2].z, .06)), r[3] = new THREE.Vector3(a._giveMeZenone(a._actualCurve[3].x, e[3].x, .04), a._giveMeZenone(a._actualCurve[3].y, e[3].y, .04), a._giveMeZenone(a._actualCurve[3].z, e[3].z, .04)), r[4] = new THREE.Vector3(a._giveMeZenone(a._actualCurve[4].x, e[4].x, .09), a._giveMeZenone(a._actualCurve[4].y, e[4].y, .09), a._giveMeZenone(a._actualCurve[4].z, e[4].z, .09))) : (r[0] = new THREE.Vector3(e[0].x, e[0].y, e[0].z), r[1] = new THREE.Vector3(e[1].x, e[1].y, e[1].z), r[2] = new THREE.Vector3(e[2].x, e[2].y, e[2].z), r[3] = new THREE.Vector3(e[3].x, e[3].y, e[3].z), r[4] = new THREE.Vector3(e[4].x, e[4].y, e[4].z));
        let i = new THREE.CatmullRomCurve3(r);
        i.curveType = "centripetal", a._mainCurve = new THREE.CurvePath, a._mainCurve.add(i), a._actualCurve = [], a._actualCurve = r
    }
    _moveCamera() {
        let e = this._giveMeZenone(this._camera.position.x, this._startCamera.x + .5 * this._mouseX, .08),
            t = this._giveMeZenone(this._camera.position.y, this._startCamera.y + .5 * this._mouseY, .08),
            a = this._giveMeZenone(this._camera.position.z, this._startCamera.z + -2 * this._mouseX, .08);
        this._camera.position.x = e, this._camera.position.y = t, this._camera.position.z = a
    }
    _moveParticles() {
        let e = this;
        for (let t = 0; t < e._particles.geometry.vertices.length; t++) e._moveParticle(e._particles.geometry.vertices[t], t);
        e._particles.geometry.verticesNeedUpdate = !0, e._particles.geometry.colorsNeedUpdate = !0
    }
    _moveParticle(e, t) {
        e.pathPosition += e.pathSpeed;
        var a = this._mainCurve.getPointAt(e.pathPosition);
        this._particles.geometry.colors[t] = new THREE.Color(parseInt(this._colorParticleFull(e.pathPosition))), a ? (e.x = a.x + e.displacementX * e.pathPosition, e.y = a.y + e.displacementY * e.pathPosition, e.z = a.z + e.displacementZ * e.pathPosition) : (e.x = -10, e.y = -10, e.z = -10, e.pathPosition = 0)
    }
    _colorParticle(e) {
        let t = this,
            a = null;
        if (e >= .51) {
            let r = [0, 181, 226],
                i = [255, 193, 63],
                n = (e - .5) * 1 / .5 + 0,
                s = t._pickRgb(r, i, n);
            a = "0x" + t._rgbToHex(s[0], s[1], s[2])
        } else a = "0xCCCCCC";
        return a
    }
    _colorParticleFull(e) {
        let t = this,
            a = null;
        if (e >= .5) {
            let r = [0, 181, 226],
                i = [255, 193, 63],
                n = (e - .5) * 1 / .5 + 0,
                s = t._pickRgb(r, i, n);
            a = "0x" + t._rgbToHex(s[0], s[1], s[2])
        } else a = "0xffc13f";
        return a
    }
    _giveMeZenone(e, t, a) {
        return e + .5 * (t - e) * a
    }
    _randomPointInCircle(e) {
        let t = 2 * Math.random() * Math.PI,
            a = Math.random() * e * e;
        return {
            x: Math.sqrt(a) * Math.cos(t),
            y: Math.sqrt(a) * Math.sin(t)
        }
    }
    _pickRgb(e, t, a) {
        let r = a,
            i = 1 - r;
        return [Math.round(e[0] * r + t[0] * i), Math.round(e[1] * r + t[1] * i), Math.round(e[2] * r + t[2] * i)]
    }
    _componentToHex(e) {
        let t = e.toString(16);
        return 1 == t.length ? "0" + t : t
    }
    _rgbToHex(e, t, a) {
        return this._componentToHex(e) + this._componentToHex(t) + this._componentToHex(a)
    }
    _render() {
        this.requestId = requestAnimationFrame(this._render.bind(this));
        this._animate();
    }
    _animate() {
        let e = this;
        switch (e._moveParticles(), e._moveCamera(), e._fogValue < 12 && (e._fogValue = e._fogValue + 1, e._scene.fog = new THREE.Fog(1646888, e._fogValue, 30)), e._curve) {
            case "base":
                e._generateCurve("base", !0);
                break;
            case "auth":
                e._generateCurve("auth", !0);
                break;
            case "web":
                e._generateCurve("web", !0);
                break;
            case "mobile":
                e._generateCurve("mobile", !0)
        }
        e._camera.lookAt(e._scene.position), e._renderer.render(e._scene, e._camera)
    }
}
/* eslint-enable */

export default Particles;
