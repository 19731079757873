import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '@ebay-certilogo/design-system-web';

function Background({ color = `linear-gradient(315deg, #7d55c7, #00b5e2 100%)`, style, ...rest }) {
  return <Div style={{ background: color, ...style }} {...rest} />;
}

Background.propTypes = {
  color: PropTypes.string,
  style: PropTypes.object,
};

export default Background;
