import React, { useEffect } from 'react';
import { Button, Box, Flex, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest as authenticationFlowRequest } from 'containers/Authentication/actions';
import { flowRequest } from 'containers/Soa/actions';
import { setBack } from 'containers/App/actions';
import { push } from 'connected-react-router';
import messages from './messages';
import { Title } from '../styles';

function Request({ colorType, authenticationId, data }) {
  const { id, page, status, code, authentication } = data;
  const certilogoCode = get(code, 'certilogoCode');
  const customerDescription = get(code, 'product.customerDescription');
  const primaryBrandName = get(code, 'brandName');
  const dispatch = useDispatch();

  useEffect(() => {
    const isSoaInsideAuthenticationFlow = authentication && authentication.status === 'soa';

    if (data) {
      dispatch(
        setBack({
          back: isSoaInsideAuthenticationFlow
            ? authenticationFlowRequest({
                action: 'prev',
                status: 'soa',
                authenticationId,
              })
            : push(`/authentication/${authenticationId}`),
        }),
      );
    }
  }, [authentication]);

  const handleSubmit = () => {
    dispatch(flowRequest({ action: 'next', authenticationId, id, status, page }));
  };

  return (
    <>
      <Title type="h1" colorType={colorType}>
        <FormattedMessage {...messages.title} />
      </Title>
      <Box colorType={colorType} subtitle={<FormattedMessage {...messages.text} />}>
        <Flex flexDirection="column" width="100%">
          <Flex>
            <Text colorType={colorType}>
              {primaryBrandName} {customerDescription}
            </Text>
          </Flex>
          <Flex>
            <Text colorType={colorType}>CLG {certilogoCode}</Text>
          </Flex>
        </Flex>
      </Box>

      <Button onClick={handleSubmit} colorType={colorType} marginBottom="s">
        <FormattedMessage {...messages.button} />
      </Button>

      <Text colorType={colorType} size="s" weight="extrabold">
        <FormattedMessage {...messages.takePhotoMessage} />
      </Text>
    </>
  );
}

Request.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    page: PropTypes.string,
    id: PropTypes.number,
    authentication: PropTypes.object,
    code: PropTypes.shape({
      certilogoCode: PropTypes.string,
      product: PropTypes.object,
    }),
  }),
};

export default Request;
