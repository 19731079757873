import React, { useEffect } from 'react';
import {
  FormFormik,
  Button,
  Heading,
  ButtonLabel,
  Flex,
  Text,
  Image,
} from '@ebay-certilogo/design-system-web';
import { Field, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import { selectProfile } from 'containers/Auth/selectors';
import { getProfileRequest } from 'containers/Auth/actions';
import { updateRequest } from 'containers/FakeReport/actions';
import Form from 'components/form/FormComponent';
import { Plus } from '@ebay-certilogo/design-system-web/dist/components/icons';
import CardClg from 'components/CardClg';
import messages from './messages';
import { currencyOptions } from '../utils';
import FileUploadInput from './FileUploadInput';

const { TextInput, Select } = FormFormik;

const validationSchema = Yup.object().shape({
  productPageUrl: Yup.string().nullable(),
  price: Yup.number().nullable(),
  currency: Yup.string().nullable(),
  receiptImagePath: Yup.string().nullable(),
});

function Purchase({ id, status, data }) {
  const dispatch = useDispatch();
  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: updateRequest({
            action: 'prev',
            id,
            status,
          }),
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (isEmpty(profile)) {
      dispatch(getProfileRequest());
    }
  }, []);

  const handleSubmit = ({ goToHome, ...values }) => {
    const params = pick(values, ['productPageUrl', 'currency', 'price', 'receiptImagePath']);
    dispatch(updateRequest({ params, action: 'next', id, status, goToHome }));
  };

  return (
    <CardClg>
      {({ colorType: cardColorType }) => (
        <>
          <Heading type="h2" colorType={cardColorType}>
            <FormattedMessage {...messages.title} />
          </Heading>
          <Text colorType={cardColorType} size="s" marginBottom="m">
            <FormattedMessage {...messages.text1} />
          </Text>
          <Formik
            initialValues={{
              ...data,
              givenName: data.givenName || (profile || {}).name,
              familyName: data.familyName || (profile || {}).lastName,
              birthdate: data.birthdate || (profile || {}).birthdate,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                {data?.store?.online && (
                  <Field
                    name="productPageUrl"
                    component={TextInput}
                    label={<FormattedMessage {...messages.productPageUrl} />}
                    colorType={cardColorType}
                    marginBottom="l"
                    placeholder="www.website.com/product-page"
                  />
                )}
                <Text colorType={cardColorType} uppercase>
                  <FormattedMessage {...messages.price} />
                </Text>
                <Flex marginBottom="s" alignItems="center">
                  <Field
                    name="currency"
                    component={Select}
                    options={currencyOptions}
                    colorType={cardColorType}
                  />
                  <Field
                    marginLeft="s"
                    name="price"
                    component={TextInput}
                    colorType={cardColorType}
                    type="number"
                    placeholder="0,00"
                  />
                </Flex>
                <Field
                  name="receiptImagePath"
                  component={FileUploadInput}
                  colorType={cardColorType}
                  buttonComponent={ButtonLabel}
                  buttonProps={{
                    button: <Plus colorType={cardColorType} />,
                    label: <FormattedMessage {...messages.receiptImagePath} />,
                    accent: true,
                  }}
                />
                {values.receiptImagePath && (
                  <Image marginTop="s" width="71px" src={values.receiptImagePath} />
                )}
                <Button colorType={cardColorType} marginTop="l" type="submit">
                  <FormattedMessage {...messages.continue} />
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </CardClg>
  );
}

Purchase.propTypes = {
  colorType: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object,
};

export default Purchase;
