import { defineMessages } from 'react-intl';

export default defineMessages({
  formTitle: {
    id: 'warranty_product_data.form_title',
    defaultMessage: 'Product information',
  },
  buttonSubmit: {
    id: 'warranty_product_data.button1',
    defaultMessage: 'Continue',
  },
  buttonSave: {
    id: 'warranty_product_data.button2',
    defaultMessage: 'Save for later',
  },
});
