import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'clg_info.title',
    defaultMessage: 'Certilogo info',
  },
  titleAuth: {
    id: 'clg_info.title_auth',
    defaultMessage: 'Certilogo settings & info',
  },
  back: {
    id: 'clg_info.back',
    defaultMessage: 'Back',
  },
  myProfile: {
    id: 'clg_info.myProfile',
    defaultMessage: 'My profile',
  },
  faq: {
    id: 'clg_info.faq',
    defaultMessage: 'FAQ',
  },
  moreAboutService: {
    id: 'clg_info.moreAboutService',
    defaultMessage: 'More about the service',
  },
});
