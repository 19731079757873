import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { ModalAssistant, Text } from '@ebay-certilogo/design-system-web';

function StartModal({ colorType, messages }) {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsOpen(false), 3000);
  }, []);

  return (
    <ModalAssistant
      colorType={colorType}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      text={
        <Text
          size="m"
          weight="extrabold"
          noMargin
          colorType={colorType}
          themeField="modalAssistant.color"
        >
          <FormattedMessage id={messages.startModal} defaultMessage={messages.startModal} />
        </Text>
      }
    />
  );
}

StartModal.propTypes = {
  colorType: PropTypes.string,
  messages: PropTypes.object,
};

export default StartModal;
