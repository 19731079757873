import { defineMessages } from 'react-intl';

export default defineMessages({
  titleGeo: {
    id: 'where_offline_geo.title1',
    defaultMessage: 'Are you in the store right now?',
  },
  titleNoGeo: {
    id: 'where_offline_no_geo.title1',
    defaultMessage: 'These are the stores near you, select…',
  },
  titleManualGeo: {
    id: 'where_offline_post_purchase.title1',
    defaultMessage: 'These are the stores near you, select…',
  },
  nearYou: {
    id: 'where_is_it_store.text1',
    defaultMessage: 'View all the stores near you',
  },
  notListed: {
    id: 'where_is_it_store.text2',
    defaultMessage: 'Not listed? Write it down',
  },
  viewAllStore: {
    id: 'where_offline_geo.text1',
    defaultMessage: 'View all store',
  },
});
