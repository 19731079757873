import React from 'react';
import styled, { css } from 'styled-components';
import { styles, Div, Button } from '@ebay-certilogo/design-system-web';
import {
  padding,
  margin,
  contentWrapper,
  spacing,
  media,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Div)`
  ${contentWrapper}
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  margin: auto;
  padding-top: calc(60px + ${spacing.padding.mobile.m}px);
  ${media.tablet`
    padding-top: calc(75px + ${spacing.padding.mobile.m}px);
  `};
  ${padding('m', 'horizontal')}
  ${({ background }) =>
    background &&
    css`
      background-color: ${background};
    `};
`;

export const Style = styled(Div)`
  ${styles.contentWrapper}
  overflow: scroll;
  max-height: calc(100vh - 130px);
  ${media.tablet`
    max-height: calc(100vh - 240px);
  `};
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: ${({ verticalCenter }) => (verticalCenter ? 'center' : 'initial')};
  ${margin('s', 'bottom')}
`;

export function Skip(props) {
  return (
    <Button
      buttonType="transparent"
      marginBottom="s"
      {...props}
      style={{ padding: 0, minHeight: 'auto' }}
    />
  );
}
