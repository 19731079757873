import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Flex } from '@ebay-certilogo/design-system-web';
import ContentsGroup from 'components/cms/ContentsGroup';
import { getImage } from 'utils/images';
import DtImage from 'components/cms/dataTypes/Image';
import DtText from 'components/cms/dataTypes/Text';
import {
  Wrapper,
  Slider,
  SlideImage,
  OverlayContents,
  Overlay,
  OverlayImage,
  OverlayText,
} from './styles';

function ConnectionPanel({
  colorType,
  propsBag: { logger } = {},
  propsBag,
  completeAction,
  images = [],
  contents1 = [],
  contents2 = [],
  contentsDirection = 'column',
  blurred,
  overlayImage,
  overlayImageVar,
  overlayText,
  overlayTemporization,
  style,
  componentType,
}) {
  const [showOverlay, setShowOverlay] = useState(!!overlayTemporization);
  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(false);
    }, overlayTemporization);
  }, []);

  useEffect(() => {
    if (propsBag.setSwipeable) {
      propsBag.setSwipeable(false);
    }
    return () => {
      if (propsBag.setSwipeable) {
        propsBag.setSwipeable(true);
      }
    };
  }, []);

  const handleAfterChange = () => {
    logger({
      type: componentType,
      action: 'swipe',
      label: 'swipe_slideshow',
      step: 'finish',
      start: 1,
      finish: 1,
    });
  };

  return (
    <Wrapper colorType={colorType} {...style}>
      <Slider
        blurred={blurred}
        arrows={false}
        infinite={false}
        afterChange={handleAfterChange}
        colorType={colorType}
      >
        {images
          .filter(({ image, imageVar }) => image || imageVar)
          .map(({ image, imageVar, description }) => (
            <SlideImage src={getImage(imageVar || image)} alt={description} />
          ))}
      </Slider>
      <OverlayContents side={1} contentsDirection={contentsDirection}>
        {contents1.map(({ contents_group: contentsGroup }) => (
          <Flex>
            <ContentsGroup
              colorType={colorType}
              data={contentsGroup}
              propsBag={propsBag}
              completeAction={completeAction}
            />
          </Flex>
        ))}
      </OverlayContents>
      <OverlayContents side={2} contentsDirection={contentsDirection}>
        {contents2.map(({ contents_group: contentsGroup }) => (
          <Flex>
            <ContentsGroup
              colorType={colorType}
              data={contentsGroup}
              propsBag={propsBag}
              completeAction={completeAction}
            />
          </Flex>
        ))}
      </OverlayContents>
      {(overlayImage || overlayText) && showOverlay && (
        <Overlay>
          {overlayImage && (
            <OverlayImage>
              <DtImage image={overlayImage} imageVar={overlayImageVar} />
            </OverlayImage>
          )}

          {overlayText && (
            <OverlayText>
              {map(overlayText, (props) => (
                <DtText {...props} colorType={colorType} propsBag={propsBag} />
              ))}
            </OverlayText>
          )}
        </Overlay>
      )}
    </Wrapper>
  );
}

ConnectionPanel.propTypes = {
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  style: PropTypes.object,
  propsBag: PropTypes.object,
  images: PropTypes.array,
  contents1: PropTypes.array,
  contents2: PropTypes.array,
  completeAction: PropTypes.func,
  contentsDirection: PropTypes.string,
  blurred: PropTypes.bool,
  overlayImage: PropTypes.object,
  overlayImageVar: PropTypes.string,
  overlayText: PropTypes.object,
  overlayTemporization: PropTypes.number,
};

export default ConnectionPanel;
