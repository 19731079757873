import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'warranty.title',
    defaultMessage: 'Warranty registration',
  },
  text1: {
    id: 'warranty_product_data.text1',
    defaultMessage: 'Fill in the form, and we will send you the Product warranty via email.',
  },
});
