import { defineMessages } from 'react-intl';

export default defineMessages({
  box: {
    title: {
      id: 'code_input.title1',
      defaultMessage: 'Please enter your CLG code or scan QR Code to start',
    },
  },
  submitButton: {
    id: 'code_input.button1',
    defaultMessage: 'Start',
  },
  text2: {
    id: 'code_input.text2',
    defaultMessage: 'No Certilogo Code?',
  },
  text3: {
    id: 'code_input.text3',
    defaultMessage: 'Try our new service for luxury handbags!',
  },
  rightsDescription: {
    id: 'code_input.text4',
    defaultMessage:
      'By starting the authentication, You declare to accept our {terms} and {privacy}.',
  },
});
