import React, { useEffect } from 'react';
import { Button, Text } from '@ebay-certilogo/design-system-web';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Warranty/actions';
import { setBack } from 'containers/App/actions';
import history from 'utils/history';
import FormComponent from 'components/form/FormComponent';
import { createValidationSchema } from 'components/cms/form/dynamicValidation';
import { map } from 'lodash';
import FormInputType from 'components/cms/form/FormInputType';
import { Card } from 'containers/Warranty/styles';
import messages from './messages';

function UserData({ colorType, authenticationId, data }) {
  const {
    data: { page, productData },
    props: { productFields },
  } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBack({
        back: flowRequest({
          action: 'prev',
          authenticationId,
        }),
      }),
    );
  }, [authenticationId]);

  const handleSubmit = (values) => {
    dispatch(flowRequest({ action: 'next', authenticationId, page, data: values }));
  };

  const validationSchema = createValidationSchema(productFields);

  return (
    <Card colorType={colorType}>
      <Text colorType={colorType} size="m" textAlign="center" marginBottom="m">
        <FormattedMessage {...messages.formTitle} />
      </Text>
      <Formik
        initialValues={{ ...productData }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <FormComponent>
            {map(productFields, ({ __component, _id, ...rest }) => (
              <FormInputType
                type={__component}
                data={rest}
                key={_id}
                colorType={colorType}
                name={rest.name}
              />
            ))}

            <Button colorType={colorType} type="submit" noMargin>
              <FormattedMessage {...messages.buttonSubmit} />
            </Button>
            <Button
              buttonType="link"
              colorType={colorType}
              onClick={() => history.push(`/authentication/${authenticationId}`)}
              arrow={false}
              noMargin
            >
              <FormattedMessage {...messages.buttonSave} />
            </Button>
          </FormComponent>
        )}
      </Formik>
    </Card>
  );
}

UserData.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    data: PropTypes.object,
    props: PropTypes.object,
  }),
};

export default UserData;
