import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ebay-certilogo/design-system-web';
import { getCurrentPosition } from 'utils/Api/geolocation';
import { getNearByPlaces, getPlaceSearch, nextPage } from './googlePlacesCalls';
import GeoSuggest from '../GeoSuggest';
import messages from '../messages';

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

function StoreRetailerInput({
  colorType,
  authenticationId,
  getCurrentPositionActive,
  positionCall,
  required,
  googlePlaceType,
  searchType = 'textSearch',
  isGeolocationActive,
  onSelect = () => {},
}) {
  const [inputValue, setInputValue] = useState('');
  const [position, setPosition] = useState(null);
  const [type, setType] = useState(searchType); // nearby or textSearch
  const [compact, setCompact] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [searchPagination, setSearchPagination] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);

  const { url } = useRouteMatch();
  const history = useHistory();

  const resetSearchWrapper = () => {
    setSearchData([]);
    setSearchLoading(false);
    setSearchPagination(null);
  };

  const setSearchDataWrapper = (data, oldData) => {
    setSearchData([...oldData, ...data.results]);
    setSearchPagination(data.nextPageToken);
    setSearchLoading(false);
  };

  const searchNext = async () => {
    setSearchLoading(true);
    nextPage({
      nextPageToken: searchPagination,
      position,
      type,
      callBack: (data) => setSearchDataWrapper(data, searchData),
    });
  };

  const geoSuccess = ({ coords: { latitude, longitude } }) => {
    getNearByPlaces({
      position: {
        latitude,
        longitude,
      },
      type: googlePlaceType,
      callBack: (data) => setSearchDataWrapper(data, []),
    });

    setPosition({
      latitude,
      longitude,
    });
  };

  const geoError = () => {
    setSearchLoading(false);
    history.replace(`/authentication/${authenticationId}/retailer_store/where_search`);
  };

  const getPosition = () => {
    setSearchLoading(true);
    getCurrentPosition(geoSuccess, geoError);
  };

  useEffect(() => {
    resetSearchWrapper();
    setCompact(type === 'nearby');
  }, [type]);

  useEffect(() => {
    if (type === 'nearby' && getCurrentPositionActive) {
      getPosition();
    }
  }, []);

  const handleSubmit = (data) => {
    onSelect(data);
  };

  const placesCalls = async ({ inputValue: value, position: pos, positionIP }) => {
    if (isEmpty(value)) {
      resetSearchWrapper();
    } else {
      setSearchLoading(true);
      getPlaceSearch({
        position: pos,
        positionIP,
        value,
        type: googlePlaceType,
        callBack: (data) => setSearchDataWrapper(data, []),
      });
    }
  };

  const debounceCallback = useCallback(debounce(placesCalls, 2000), []);

  return (
    <GeoSuggest
      footer={
        !searchLoading &&
        searchData.length > 2 &&
        !!compact && (
          <Button
            colorType={colorType}
            buttonType="link"
            onClick={() => {
              setCompact(false);
            }}
            arrow={false}
          >
            <FormattedMessage {...messages.viewAllStore} />
          </Button>
        )
      }
      url={url}
      value={inputValue}
      colorType={colorType}
      setInputValue={setInputValue}
      searchCallBack={debounceCallback}
      selectedCallBack={({ item }) => {
        handleSubmit(item);
      }}
      searchData={searchData}
      searchLoading={searchLoading}
      searchNext={!searchLoading && !compact && !!searchPagination && searchNext}
      searchType={type}
      setSearchType={setType}
      compact={compact}
      position={position}
      getPosition={getPosition}
      positionCall={positionCall}
      handleSubmit={handleSubmit}
      required={required}
      isGeolocationActive={isGeolocationActive}
    />
  );
}

StoreRetailerInput.propTypes = {
  colorType: PropTypes.string,
  googlePlaceType: PropTypes.string,
  authenticationId: PropTypes.string,
  required: PropTypes.bool,
  isGeolocationActive: PropTypes.bool,
  getCurrentPositionActive: PropTypes.bool,
  title: PropTypes.node,
  searchType: PropTypes.string,
  positionCall: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
  onSelect: PropTypes.func,
};

export default StoreRetailerInput;
