import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { getDistance } from 'geolib';

function distanceFormatting(value, dp) {
  return `${parseFloat(value).toFixed(dp)} m`;
}

const arrayWithDistance = (data, myPosition) =>
  data.map(({ geometry: { location, ...geometry }, ...rest }) => {
    if (location && myPosition) {
      const distance = getDistance({ latitude: location.lat, longitude: location.lng }, myPosition);
      return {
        distance: distanceFormatting(distance, 0),
        geometry: { location, ...geometry },
        ...rest,
      };
    }
    return {
      ...rest,
    };
  });

export const getPlaceSearch = ({
  position = {},
  positionIP,
  radius = 1000000,
  type,
  callBack,
  value,
  jwtToken,
}) => {
  fetchWrapper({
    url: 'store/v4/google/textSearch',
    options: {
      headers: { jwtToken },
      body: {
        latitude: (position || {}).latitude || positionIP.latitude,
        longitude: (position || {}).longitude || positionIP.longitude,
        radius,
        type,
        text: value,
      },
    },
    withQuery: true,
  })
    .then((data) => {
      let resultsOverride = data;
      if ((position || {}).latitude && (position || {}).longitude) {
        resultsOverride = {
          ...resultsOverride,
          results: arrayWithDistance(resultsOverride.results, position),
        };
      }
      callBack(resultsOverride);
    })
    .catch(() => {
      callBack({ results: [] });
    });
};

export const nextPage = ({ position, type, nextPageToken, jwtToken, callBack }) => {
  fetchWrapper({
    url: `store/v4/google/${type}`,
    options: {
      headers: { jwtToken },
      body: {
        nextPageToken,
      },
    },
    withQuery: true,
  }).then((data) => {
    let resultsOverride = data;
    if (position) {
      resultsOverride = {
        ...resultsOverride,
        results: arrayWithDistance(resultsOverride.results, position),
      };
    }
    callBack(resultsOverride);
  });
};

export const getNearByPlaces = ({
  position: { latitude, longitude },
  radius,
  type,
  callBack,
  jwtToken,
}) => {
  fetchWrapper({
    url: 'store/v4/google/nearby',
    options: {
      headers: { jwtToken },
      body: {
        latitude,
        longitude,
        radius,
        type,
      },
    },
    withQuery: true,
  }).then((data) => {
    let resultsOverride = data;
    if (latitude && longitude) {
      resultsOverride = {
        ...resultsOverride,
        results: arrayWithDistance(resultsOverride.results, { latitude, longitude }),
      };
    }
    callBack(resultsOverride);
  });
};
