import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthenticationLoading } from 'containers/Authentication/selectors';
import AppLoader from 'components/AppLoader';
import { resetAuthentication } from 'containers/Authentication/actions';
import { unsetBack, setMenuVisibility } from 'containers/App/actions';
import { Text, Box } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useInjectSaga } from 'utils/injectSaga';
import authenticationSaga from 'containers/Authentication/saga';
import { selectQueryParams } from 'containers/App/selectors';
import TermsAndConditions from 'components/TermsAndConditions';
import useColorType from 'components/hooks/useColorType';
import saga from './saga';
import CodeInput from './CodeInput';
import messages from './messages';
import { serviceRequest } from './actions';

function Proxy() {
  const colorType = useColorType('code');
  useInjectSaga({ key: 'code', saga });
  useInjectSaga({ key: 'authentication', saga: authenticationSaga });
  const dispatch = useDispatch();
  const { origin, code } = useParams();
  const params = useSelector(selectQueryParams);
  const dispatchRequest = code || ['extqr', 'extnfc', 'tid'].includes(origin);
  useEffect(() => {
    dispatch(unsetBack());
    dispatch(setMenuVisibility(true));
    dispatch(resetAuthentication());
    if (dispatchRequest) {
      dispatch(serviceRequest({ code, origin, ...params }));
    }
  }, []);
  const isLoading = useSelector(selectAuthenticationLoading);

  if (isLoading || dispatchRequest) return <AppLoader />;

  return (
    <>
      <Box
        monochrome
        colorType={colorType}
        text={<FormattedMessage {...messages.box.title} />}
        minHeight="0"
      />
      <CodeInput colorType={colorType} flow={params.flow} />
      <Text
        colorType={colorType}
        weight="semibold"
        style={{ textAlign: 'center' }}
        marginBottom="l"
      >
        <FormattedMessage {...messages.text2} />
      </Text>
      <TermsAndConditions
        style={{ textAlign: 'center' }}
        colorType={colorType}
        type="code"
        size="xxxs"
      />
    </>
  );
}

Proxy.propTypes = {
  colorType: PropTypes.string,
};

export default Proxy;
