export const calculateGradientDirection = (angle, { width, height }) => {
  const radian = ((180 + angle) * Math.PI) / 180;
  const HALF_WIDTH = width * 0.5;
  const HALF_HEIGHT = height * 0.5;
  const lineLength = Math.abs(width * Math.sin(radian)) + Math.abs(height * Math.cos(radian));
  const HALF_LINE_LENGTH = lineLength / 2;

  const x0 = HALF_WIDTH + Math.sin(radian) * HALF_LINE_LENGTH;
  const y0 = HALF_HEIGHT - Math.cos(radian) * HALF_LINE_LENGTH;
  const x1 = width - x0;
  const y1 = height - y0;

  return {
    x0,
    x1,
    y0,
    y1,
  };
};

export const isAppeal = (reason) =>
  [
    'imp_suspect_appeal',
    'imp_info_not_consistent_01_appeal',
    'imp_info_not_consistent_02_appeal',
    'imp_new_owner_claim_appeal',
    'imp_desc_not_matching_appeal',
    'imp_sec_element_not_matching_appeal',
    'imp_desc_not_matching_protected_store_appeal',
    'imp_sec_element_not_matching_protected_store_appeal',
    'imp_not_active_appeal',
    'imp_partner_appeal',
    'imp_not_owned_unsure_protected_store_appeal',
    'imp_not_owned_unsure_appeal',
    'imp_not_owned_mismatch_protected_store_appeal',
    'imp_not_owned_mismatch_appeal',
    'imp_not_authentic_anymore_appeal',
    'imp_soa_wrong_product_wrong_alias_appeal',
    'imp_soa_wrong_product_wrong_marketplace_appeal',
    'imp_soa_wrong_product_appeal',
    'imp_wrong_marketplace_appeal',
    'imp_wrong_alias_appeal',
    'imp_suspect_unsure_appeal',
    'imp_cloned_mismatch_appeal',
    'imp_cloned_unsure_appeal',
    'imp_mismatch_appeal',
    'imp_mismatch_protected_store_appeal',
    'imp_unsure_appeal',
    'imp_unsure_protected_store_appeal',
  ].includes(reason);
