import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fake_report_request.title1',
    defaultMessage: 'We can help!',
  },
  text: {
    id: 'fake_report_request.text1',
    defaultMessage:
      'Get a Certilogo Fake Report now. Use this document, which officially confirms that your product is fake, to underpin any request for refund you might want to file with the seller, with the payment service or with your credit card company.',
  },
  button: {
    id: 'fake_report_request.button1',
    defaultMessage: 'Request a Fake Report',
  },
  buttonDownload: {
    id: 'fake_report_download.button1',
    defaultMessage: 'DOWNLOAD REFUND ASSISTANCE REPORT',
  },
});
