import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch, Redirect } from 'react-router-dom';
import withColorType from 'components/HOC/withColorType';
import usePageType from 'components/hooks/usePageType';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Settings from './Settings';

function Profile({ colorType }) {
  usePageType('authProfile');
  const { path } = useRouteMatch();

  return (
    <Switch>
      <AuthenticatedRoute exact path={path}>
        <Redirect to={`${path}/settings`} />;
      </AuthenticatedRoute>
      <AuthenticatedRoute path={`${path}/settings`}>
        <Settings colorType={colorType} />
      </AuthenticatedRoute>
    </Switch>
  );
}

Profile.propTypes = {
  colorType: PropTypes.string,
};

export default withColorType(Profile);
