import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import styled, { withTheme } from 'styled-components';

const AnimationWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  svg {
    width: auto !important;
  }
`;

function AnimationCustom({ customAnimation, onAnimationEnd, onLoaderAnimationEnd }) {
  const [animationData, setAnimationData] = useState();

  useEffect(() => {
    onLoaderAnimationEnd(true);
    if (customAnimation?.url) {
      fetch(customAnimation.url)
        .then((response) => response.json())
        .then((data) => setAnimationData(data))
        .catch((error) => console.log(error));
    }
  }, []);

  return (
    animationData && (
      <AnimationWrapper>
        <Lottie loop={false} autoplay animationData={animationData} onComplete={onAnimationEnd} />
      </AnimationWrapper>
    )
  );
}

AnimationCustom.propTypes = {
  colorType: PropTypes.string,
  customAnimation: PropTypes.object,
  onAnimationEnd: PropTypes.func,
  onLoaderAnimationEnd: PropTypes.func,
};

export default withTheme(AnimationCustom);
