import React from 'react';
import PropTypes from 'prop-types';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { isEmpty } from 'lodash';

function Text({ id, name, placeholder, label, colorType, ...rest }) {
  const intl = useIntl();

  return (
    <Field
      name={name || id}
      component={FormFormik.TextInput}
      colorType={colorType}
      placeholder={
        !isEmpty(placeholder) &&
        intl.formatMessage({ id: placeholder, defaultMessage: placeholder })
      }
      label={!isEmpty(label) && <FormattedMessage id={label} defaultMessage={label} />}
      {...rest}
    />
  );
}

Text.propTypes = {
  id: PropTypes.string,
  colorType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default Text;
