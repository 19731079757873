import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Text, Image as _Image, Div, Flex } from '@ebay-certilogo/design-system-web';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';

const Button = styled(Flex)`
  justify-content: left;
  cursor: pointer;
  flex-direction: column;
  width: 40%;
  color: ${color};
  border: 0;
  ${padding('s', 'vertical')}
  ${padding('m', 'horizontal')}
`;

const Wrapper = styled(Flex)`
  justify-content: center;
  margin: 5px;
`;

const ImageWrapper = styled(Div)`
  display: flex;
  align-items: center;
  margin: 10px;
`;

const Image = styled(_Image)`
  width: 100%;
  margin: auto;
`;

const Description = styled(Text)`
  font-style: italic;
  text-align: center;
`;

function DynamicComponent({ colorType, responses, propsBag, onChange }) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selected !== null) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <Wrapper>
      {responses.map(({ text, id, imgSrc }) => (
        <Button
          colorType={colorType}
          borderWidth="1px"
          size="s"
          marginBottom="m"
          onClick={() => setSelected(id)}
          style={{ minHeight: 'auto', maxHeight: 'none' }}
          uppercase={false}
        >
          <ImageWrapper>
            <Image src={imgSrc} />
          </ImageWrapper>
          <Description colorType={colorType}>
            <FormattedMessage id={text || 'id'} defaultMessage={text} values={propsBag} />
          </Description>
        </Button>
      ))}
    </Wrapper>
  );
}

DynamicComponent.propTypes = {
  colorType: PropTypes.string,
  propsBag: PropTypes.object,
  responses: PropTypes.array,
  onChange: PropTypes.func,
};

export default DynamicComponent;
