import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'personal_profile.title1',
    defaultMessage: 'My profile',
  },
  description: {
    id: 'personal_profile.text1',
    defaultMessage: 'My personal data',
  },
  otherSettings: {
    id: 'personal_profile.other_settings',
    defaultMessage: 'Other settings',
  },
  viewAllSettings: {
    id: 'personal_profile.view_all_settings',
    defaultMessage: 'View all settings',
  },
  back: {
    id: 'personal_profile.back',
    defaultMessage: 'Back',
  },
});
