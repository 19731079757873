import React, { useState } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { Flex } from '@ebay-certilogo/design-system-web';
import ContentsGroup from 'components/cms/ContentsGroup';
import styled, { css } from 'styled-components';
import { isMedia } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import VideoComponent from './VideoComponent';

const Wrapper = styled(Flex)`
  flex-direction: column;
  flex-shrink: 0;
  ${({ cover }) =>
    cover &&
    css`
      flex-direction: row;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      flex: 1;
    `}
`;

function Video({
  colorType,
  style,
  url: _url,
  urlDesktop,
  cover: _cover,
  coverDesktop,
  onEndContents,
  propsBag,
  propsBag: { logger } = {},
  completeAction,
  componentType,
  gtm,
  ...rest
}) {
  const [videoVisibility, setVideoVisibility] = useState(true);
  const isTablet = isMedia('tablet');
  const url = isTablet && urlDesktop ? urlDesktop : _url;
  const cover = isTablet && coverDesktop ? coverDesktop : _cover;
  const defaultLogObj = {
    type: componentType,
    link: url,
    gtm,
  };
  const handleOnStart = () => {
    if (logger) {
      logger({
        ...defaultLogObj,
        action: 'play',
        step: 'step_1',
        start: 1,
      });
    }
  };
  const handleOnPause = () => {
    if (logger) {
      logger({
        ...defaultLogObj,
        action: 'pause',
      });
    }
  };
  const handleOnHalf = () => {
    if (logger) {
      logger({
        ...defaultLogObj,
        action: 'auto',
        step: 'step_2',
      });
    }
  };
  const handleOnEnd = () => {
    if (onEndContents) {
      setVideoVisibility(false);
    }
    if (logger) {
      logger({
        ...defaultLogObj,
        action: 'auto',
        step: 'finish',
        finish: 1,
      });
    }
  };
  return (
    <Wrapper colorType={colorType} cover={cover && videoVisibility} {...style}>
      {videoVisibility ? (
        <VideoComponent
          url={url}
          onStart={handleOnStart}
          onPause={handleOnPause}
          onEnd={handleOnEnd}
          onHalf={handleOnHalf}
          cover={cover}
          {...rest}
        />
      ) : (
        map(onEndContents, ({ contents_group: data }) => (
          <ContentsGroup
            data={data}
            propsBag={propsBag}
            colorType={colorType}
            completeAction={completeAction}
          />
        ))
      )}
    </Wrapper>
  );
}

Video.propTypes = {
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  style: PropTypes.object,
  url: PropTypes.string,
  urlDesktop: PropTypes.string,
  cover: PropTypes.bool,
  coverDesktop: PropTypes.bool,
  onEndContents: PropTypes.array,
  propsBag: PropTypes.object,
  completeAction: PropTypes.func,
  gtm: PropTypes.object,
};

export default Video;
