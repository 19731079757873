import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Button, ModalAssistant, Text } from '@ebay-certilogo/design-system-web';
import { useDispatch } from 'react-redux';
import LoadingIndicator from 'components/LoadingIndicator';
import { deleteUser } from 'containers/Auth/actions';
import messages from './messages';

function UserDataForm({ data }) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  if (!data) return <LoadingIndicator />;

  const handleSubmit = () => {
    setIsOpen(false);
    dispatch(deleteUser(data.id));
  };

  return (
    <>
      <Button colorType="light" align="left" size="s" onClick={() => setIsOpen(true)}>
        <FormattedMessage {...messages.buttonOpen} />
      </Button>
      <ModalAssistant
        colorType="light"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        text={
          <Text size="m" weight="extrabold" themeField="modalAssistant.color" noMargin>
            <FormattedMessage {...messages.title} values={{ email: data.email }} />
          </Text>
        }
        footer={
          <>
            <Button colorType="light" buttonType="transparent" onClick={handleSubmit} noMargin>
              <FormattedMessage {...messages.buttonYes} />
            </Button>
            <Button colorType="light" onClick={() => setIsOpen(false)} noMargin>
              <FormattedMessage {...messages.buttonNo} />
            </Button>
          </>
        }
      />
    </>
  );
}

UserDataForm.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.object,
};

export default injectIntl(UserDataForm);
