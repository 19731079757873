import styled from 'styled-components';
import { Heading, Div } from '@ebay-certilogo/design-system-web';

export const Wrapper = styled(Div)`
  position: relative;
`;

export const Title = styled(Heading).attrs({ type: 'h2' })`
  text-align: center;
`;
