import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import DynamicComponent from 'components/cms/DynamicComponent';
import { Flex } from '@ebay-certilogo/design-system-web';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

function ContentsGroup({ data, propsBag, completeAction, colorType, ...rest }) {
  const { fullHeight, verticalCenter, contents, style, colorType: dataColorType } = data || {};
  return (
    <Wrapper
      flex={fullHeight && 1}
      justifyContent={verticalCenter ? 'center' : 'flex-start'}
      {...style}
      {...rest}
    >
      {map(contents, ({ __component, _id, ...restComponentData }) => (
        <DynamicComponent
          type={__component}
          data={restComponentData}
          key={_id}
          propsBag={propsBag}
          colorType={dataColorType || colorType}
          completeAction={completeAction}
        />
      ))}
    </Wrapper>
  );
}

ContentsGroup.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.array,
  fullHeight: PropTypes.bool,
  verticalCenter: PropTypes.bool,
  propsBag: PropTypes.bool,
  completeAction: PropTypes.func,
};

export default ContentsGroup;
