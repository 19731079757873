import React, { useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import { Text, Flex } from '@ebay-certilogo/design-system-web';
import { typography } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { ChevronRight } from '@ebay-certilogo/design-system-web/dist/components/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { setBack } from 'containers/App/actions';
import withColorType from 'components/HOC/withColorType';
import ButtonHighlight from '../ButtonHighlight';
import messages from './messages';

export const Link = styled.a`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  color: ${({ theme, colorType }) => theme[colorType].button.color};
  background-color: ${({ theme, colorType }) => theme[colorType].button.backgroundColor};
  font-weight: ${get(typography, 'weight.extrabold')};
  min-height: 75px;
  text-decoration: none;
  text-transform: uppercase;
`;

function AccountBlocked({ colorType }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  return (
    <>
      <Flex margin={{ size: 'l', position: 'bottom' }}>
        <ButtonHighlight
          url="/auth/sign-up"
          direction="left"
          label={<FormattedMessage {...messages.signup} />}
          colorType={colorType}
        />
        <ButtonHighlight
          url="/auth/sign-up"
          direction="right"
          label={<FormattedMessage {...messages.login} />}
          colorType={colorType}
          isSelected
        />
      </Flex>

      <Text colorType={colorType} themeField="form.errorColor" marginBottom="m">
        <FormattedMessage {...messages.error} />
      </Text>

      <Link href="mailto:help@certilogo.com" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.button} />
        <ChevronRight width="40px" height="40px" colorType={colorType} />
      </Link>
    </>
  );
}

AccountBlocked.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  type: PropTypes.string,
};

export default withColorType(injectIntl(AccountBlocked));
