/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { Image as ClgImage, Flex, Div } from '@ebay-certilogo/design-system-web';
import { addCssProp } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Div)`
  ${addCssProp}
`;

export const Image = styled(ClgImage)`
  margin-left: auto;
  margin-right: auto;
  height: 130px;
  width: auto;
  ${({ reopenDate }) =>
    reopenDate &&
    css`
      margin-top: -60px;
    `}
`;

export const ButtonWrapper = styled(Flex)`
  position: relative;
`;

export const AvailabilityDate = styled(Flex)`
  width: 100%;
  z-index: 100;
  color: #fff;
  justify-content: flex-end;
`;

export const ImageWrapper = styled(Flex)`
  flex-direction: column;
  ${AvailabilityDate} {
    ${({ reopenDate }) =>
      reopenDate &&
      css`
        margin-bottom: 20px;
      `}
  }
`;
