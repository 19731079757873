import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fake_report1.title1',
    defaultMessage: 'Fake report',
  },
  text1: {
    id: 'fake_report1.text1',
    defaultMessage:
      'Complete the form to get a report from Certilogo that will speed up your refund claim',
  },
});
