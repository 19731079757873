import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    title: {
      id: 'fake_report.recallModal.create.title',
      defaultMessage: 'Get help with your refund',
    },
    text: {
      id: 'fake_report.recallModal.create.text',
      defaultMessage:
        'Follow the instructions to the end to complete your Refund Assistance Report',
    },
  },
  continue: {
    title: {
      id: 'fake_report.recallModal.continue.title',
      defaultMessage: 'Get help with your refund',
    },
    text: {
      id: 'fake_report.recallModal.continue.text',
      defaultMessage:
        'Follow the instructions to the end to complete your Refund Assistance Report',
    },
  },
});
