import React from 'react';
import { Apple } from '@ebay-certilogo/design-system-web/dist/components/icons';
import AppleSignin from 'react-apple-signin-auth';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { loginRequest } from 'containers/Auth/actions';
import config from 'config';
import { Div } from '@ebay-certilogo/design-system-web';
import get from 'lodash/get';
import { AppleButton, SocialIcon } from '../styles';
import messages from '../../messages';

const {
  oauth: { apple },
} = config;

function AppleLogin({ colorType, type, setError }) {
  const dispatch = useDispatch();

  const onSuccess = (response) => {
    setError(null);
    dispatch(
      loginRequest({
        network: 'apple',
        token: get(response, 'authorization.id_token'),
        firstName: get(response, 'user.name.firstName'),
        lastName: get(response, 'user.name.lastName'),
        email: get(response, 'user.email'),
        redirect: true,
      }),
    );
  };
  const onError = () => {
    setError({ apple: { error: 'default' } });
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: apple.clientId,
        scope: 'email name',
        redirectURI: window.location.href,
        usePopup: true,
      }}
      onSuccess={onSuccess}
      onError={onError}
      render={(renderProps) => (
        <Div {...renderProps}>
          <AppleButton
            colorType={colorType}
            icon={
              <SocialIcon>
                <Apple color="#000000" width={18} height={18} />
              </SocialIcon>
            }
          >
            <FormattedMessage {...messages[type].apple} />
          </AppleButton>
        </Div>
      )}
    />
  );
}

AppleLogin.propTypes = {
  colorType: PropTypes.string,
  type: PropTypes.string,
  setError: PropTypes.func,
};

export default AppleLogin;
