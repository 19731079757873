import styled from 'styled-components';
import { addCssProp } from '@ebay-certilogo/design-system-web/dist/shared/styles';

const sizes = {
  s: '20px',
  m: '30px',
  l: '40px',
};

export const Wrapper = styled.a`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  ${addCssProp}
`;

export const Icon = styled.svg``;
export const Link = styled.a`
  margin: 10px;
  ${Icon} {
    height: ${({ size = 'm' }) => sizes[size]};
    width: ${({ size = 'm' }) => sizes[size]};
  }
`;
