import { defineMessages } from 'react-intl';

export default defineMessages({
  signup: {
    id: 'login_signup.title1',
    defaultMessage: 'Sign Up',
  },
  login: {
    id: 'login_signup.title2',
    defaultMessage: 'Login',
  },
  error: {
    id: 'login_forgot_psw_error3.text2',
    defaultMessage: 'Your account has been blocked due to anomalous usage',
  },
  button: {
    id: 'login_forgot_psw_error3.button1',
    defaultMessage: 'Contact us',
  },
});
