/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Div, Flex } from '@ebay-certilogo/design-system-web';
import { addCssProp, padding, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${addCssProp}
`;

export const Header = styled(Div)`
  background: #ebebeb;
  ${padding('m')}
`;

export const Divider = styled(Div)`
  border: 1px solid ${color};
  ${margin('s')}
`;

export const Pagination = styled(Flex)`
  justify-content: center;
  align-items: center;
  ${margin('s')}
  margin-top: auto;
`;
