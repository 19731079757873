import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import saga from 'containers/Warranty/saga';
import Button from 'components/cms/dataTypes/Button';
import { push } from 'connected-react-router';
import { useInjectSaga } from 'utils/injectSaga';
import { useDispatch } from 'react-redux';
import { downloadRequest } from 'containers/Warranty/actions';
import messages from './messages';

function Warranty({
  status = 'create',
  style,
  colorType,
  logEventName = 'soa',
  propsBag: { logger, authentication } = {},
  propsBag,
  componentType,
  button,
  gtm,
  data,
}) {
  useInjectSaga({ key: 'warranty', saga });
  const dispatch = useDispatch();
  const messagesStatus = messages[status] || {};
  const isDownload = status === 'download';

  const handleOnClick = () => {
    if (logger) {
      logger({
        action: 'click',
        step: 'finish',
        start: 1,
        finish: 1,
        type: componentType,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'certificate' },
        params: { certificate: 'warranty', action: 'request' },
      });
    }
  };

  return (
    <Button
      onClick={() => {
        handleOnClick();
        if (isDownload) {
          dispatch(
            downloadRequest({
              token: data?.token,
            }),
          );
        } else {
          dispatch(push(`/authentication/${authentication?.id}/warranty`));
        }
      }}
      style={style}
      colorType={colorType}
      {...button}
    >
      <FormattedMessage {...messagesStatus.label} values={propsBag} />
    </Button>
  );
}

Warranty.propTypes = {
  componentType: PropTypes.string,
  button: PropTypes.object,
  showTitle: PropTypes.string,
  showImage: PropTypes.string,
  status: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  reopenDate: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
  data: PropTypes.object,
};

export default Warranty;
