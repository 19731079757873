import React from 'react';
import { Switch } from 'react-router-dom';

import Authentication from 'containers/Authentication';
import Auth from 'containers/Auth';
import Connect from 'containers/Connect';
import FakeReport from 'containers/FakeReport';
import Proxy from 'containers/Proxy';
import Soa from 'containers/Soa';
import Warranty from 'containers/Warranty';

import Route from 'components/Route';
import FakeReportDownload from 'containers/FakeReport/Download';
import AuthenticationConfirm from 'containers/Authentication/Confirm';
import AuthenticationResult from 'containers/Authentication/Result';
import NotFoundPage from 'containers/NotFoundPage';
import Terms from 'containers/Terms';
import Privacy from 'containers/Privacy';
import Cookie from 'containers/Cookie';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import CodeReview from 'containers/CodeReview';
import ManageSoa from 'containers/Soa/Manage';
import SoaSold from 'containers/Soa/Sold';
import SoaCompletedSeller from 'containers/Soa/CompletedSeller';
import YourSoa from 'containers/Soa/YourSoa';
import ResellWithEbay from 'containers/ResellWithEbay';
import ClaimOwnership from 'containers/ClaimOwnership';
import EbayCallback from 'containers/Auth/Login/EbayLogin/Callback';
import ErrorPage from './ErrorPage';
import origins from './origins';

export default function Routing() {
  return (
    <Switch>
      <Route path="/auth/sign-up/ebay/callback">
        <EbayCallback
          setError={() => {
            console.log('ebay oauth error');
          }}
        />
      </Route>
      <Route path="/auth" render={(props) => <Auth {...props} colorType="dark" />} />
      <Route path="/terms" render={(props) => <Terms {...props} colorType="dark" />} />
      <Route path="/privacy" render={(props) => <Privacy {...props} colorType="dark" />} />
      <Route path="/cookie-policy" render={(props) => <Cookie {...props} colorType="dark" />} />
      <Route
        path="/connect/:sessionId"
        render={(props) => <Connect {...props} colorType="light" />}
      />
      <Route
        path="/authentication/confirm"
        render={(props) => <AuthenticationConfirm {...props} colorType="light" />}
      />
      <AuthenticatedRoute
        path="/authentication/result/:hash"
        render={(props) => <AuthenticationResult {...props} colorType="light" />}
      />
      <Route
        path="/authentication/:authenticationId/fake-report"
        render={(props) => <FakeReport {...props} colorType="dark" />}
      />
      <Route
        path="/fake-report/download"
        render={(props) => <FakeReportDownload {...props} colorType="light" />}
      />
      <Route
        path="/authentication/:authenticationId/warranty"
        render={(props) => <Warranty {...props} colorType="light" />}
      />
      <Route
        path="/authentication/:authenticationId/soa/manage"
        render={(props) => <ManageSoa {...props} colorType="dark" />}
      />
      <Route
        path="/authentication/:authenticationId/soa/sold"
        render={(props) => <SoaSold {...props} colorType="accent" />}
      />
      <Route
        path="/authentication/:authenticationId/soa/completed-seller"
        render={(props) => <SoaCompletedSeller {...props} colorType="dark" />}
      />
      <Route
        path="/authentication/:authenticationId/soa"
        render={(props) => <Soa {...props} colorType="dark" />}
      />
      <Route path="/your-soa/:id" render={(props) => <YourSoa {...props} colorType="light" />} />
      <Route
        path="/authentication/:authenticationId?"
        render={(props) => <Authentication {...props} colorType="light" />}
      />
      <Route
        path="/resell-with-ebay/:resellWithEbayId?"
        render={(props) => <ResellWithEbay {...props} colorType="light" />}
      />
      <Route
        path="/claim-ownership/:claimOwnershipId?"
        render={(props) => <ClaimOwnership {...props} colorType="light" />}
      />
      <Route
        path={`/:origin(${origins.join('|')})/:code?`}
        render={(props) => <Proxy {...props} colorType="light" />}
      />
      <Route path="/code-review" render={(props) => <CodeReview {...props} colorType="light" />} />
      <Route path="/generic-error" render={(props) => <ErrorPage {...props} colorType="dark" />} />
      <Route path="" component={NotFoundPage} />
    </Switch>
  );
}
