import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ebay-certilogo/design-system-web';

function DynamicComponent({ colorType, responses, propsBag, onChange }) {
  const [selected, setSelected] = useState(null);
  const invertedColorType = colorType === 'light' ? 'dark' : 'light';

  useEffect(() => {
    if (selected !== null) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <>
      {responses.map(({ text, id }) => (
        <Button
          colorType={invertedColorType}
          borderWidth="1px"
          size="s"
          marginBottom="m"
          arrow={false}
          onClick={() => setSelected(id)}
        >
          <FormattedMessage id={text || 'id'} defaultMessage={text} values={propsBag} />
        </Button>
      ))}
    </>
  );
}

DynamicComponent.propTypes = {
  colorType: PropTypes.string,
  propsBag: PropTypes.object,
  responses: PropTypes.array,
  onChange: PropTypes.func,
};

export default DynamicComponent;
