import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fake_report.retailer.title1',
    defaultMessage: 'Retailer information',
  },
  text1: {
    id: 'fake_report.retailer.text1',
    defaultMessage: 'Specify the store where the product was purchased.',
  },
  text2: {
    id: 'fake_report.retailer.text2',
    defaultMessage: 'RETAILER’S NAME',
  },
  toggle: {
    id: 'fake_report.retailer.toggle',
    defaultMessage: "I confirm the purchase was not made from one of the brand's own stores.",
  },
  continue: {
    id: 'fake_report2.retailer.button1',
    defaultMessage: 'Continue',
  },
  changeStoreButton: {
    id: 'fake_report1.retailer.button2',
    defaultMessage: 'Change store',
  },
});
