import React from 'react';
import PropTypes from 'prop-types';
import { Prompt, useHistory } from 'react-router-dom';

function NavigationPrompt({ when, shouldBlockNavigation, backCallback, promptIsActive }) {
  const history = useHistory();
  const handleBlockedNavigation = (nextLocation, action) => {
    if (shouldBlockNavigation(nextLocation) && action === 'POP' && when) {
      backCallback();
    }
    if (promptIsActive && action === 'POP') {
      history.go(1);
      return false;
    }
    return true;
  };
  return <Prompt when={promptIsActive || when} message={handleBlockedNavigation} />;
}

NavigationPrompt.propTypes = {
  when: PropTypes.bool,
  shouldBlockNavigation: PropTypes.func,
  backCallback: PropTypes.func,
  promptIsActive: PropTypes.bool,
};

export default NavigationPrompt;
