import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/LoadingIndicator';
import SoaRwe from './SoaRwe';
import Soa from './Soa';

const TopBox = ({ colorType, data }) => {
  if (!data) return <LoadingIndicator />;
  const soaType = data?.props?.soaType;
  const topBox = useMemo(
    () =>
      soaType === 'rwe' ? (
        <SoaRwe colorType={colorType} data={data} />
      ) : (
        <Soa colorType={colorType} data={data} />
      ),
    [soaType],
  );

  return topBox;
};

TopBox.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    props: PropTypes.shape({
      soaType: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default TopBox;
