import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { getImage } from 'utils/images';
import PoweredBy from 'components/PoweredBy';
import { useLocation } from 'react-router-dom';
import forEach from 'lodash/forEach';
import history from 'utils/history';
import Page from './Page';
import { Wrapper, Slider } from './styles';

function Onboarding({
  colorType,
  data,
  completeAction,
  poweredBy,
  propsBag: { logger },
  propsBag: _propsBag,
}) {
  if (!data) return null;
  const { pages, skipUrl, backgroundImage, style } = data;
  const sliderRef = useRef();
  const [activeSlide, setActiveSlide] = useState(0);
  const [swipeable, setSwipeable] = useState(true);
  const backgroundImageUrl = getImage(backgroundImage);
  const handleNext = (slideIndex) => {
    if (slideIndex === pages.length - 1 && completeAction) completeAction();
    else if (sliderRef.current) sliderRef.current.slickNext();
  };
  const { hash } = useLocation();

  const mapPages = {};
  forEach(pages, ({ name }, index) => {
    mapPages[`#${name}`] = index;
  });

  const goToPage = (tag) => {
    sliderRef.current.slickGoTo(mapPages[tag]);
  };

  useEffect(() => {
    if (hash) {
      goToPage(hash);
      logger({
        action: 'swipe',
        event: { type: 'swipe', name: 'swipe-ob-page', category: 'swipe' },
        params: { target: hash },
      });
    }
  }, [sliderRef, hash]);

  const propsBag = {
    ..._propsBag,
    swipeable,
    setSwipeable,
  };

  return (
    <Wrapper backgroundImage={backgroundImageUrl} style={style}>
      <Slider
        colorType={colorType}
        ref={sliderRef}
        beforeChange={(oldIndex, newIndex) => setActiveSlide(newIndex)}
        afterChange={(index) => history.push(`#${pages[index].name}`)}
        swipe={swipeable}
      >
        {map(pages, ({ _id, ...page }, index) => (
          <Page
            colorType={colorType}
            data={page}
            completeAction={completeAction}
            skipUrl={skipUrl}
            poweredBy={poweredBy}
            key={_id}
            onNext={() => handleNext(index)}
            active={index === activeSlide}
            propsBag={propsBag}
          />
        ))}
      </Slider>
      {poweredBy && <PoweredBy colorType={colorType} />}
    </Wrapper>
  );
}

Onboarding.propTypes = {
  data: PropTypes.object,
  propsBag: PropTypes.object,
  completeAction: PropTypes.func,
  colorType: PropTypes.string,
  poweredBy: PropTypes.bool,
};

export default Onboarding;
