import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonCta, Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage, FormattedDate } from 'react-intl';
import Triangle from 'components/Triangle';
import ButtonComponent from 'components/ButtonComponent';
import messages from './messages';
import { Wrapper, Image, AvailabilityDate, ImageWrapper } from './styles';
import soaImage from './soa.png';

const buttonTypes = {
  button: Button,
  button_cta: ButtonCta,
};

function Soa({
  buttonType = 'button',
  showTitle,
  showImage,
  status = 'create',
  style,
  colorType,
  logEventName = 'soa',
  reopenDate,
  propsBag: { logger, authentication } = {},
  propsBag,
  componentType,
  gtm,
}) {
  const ButtonTypeComponent = buttonTypes[buttonType];
  const messagesStatus = messages[status] || {};
  const isDisabled = status === 'vacation';

  const handleOnClick = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'certificate' },
        params: { certificate: 'soa', action: 'request' },
      });
    }
  };

  return (
    <Wrapper {...style}>
      {showTitle && (
        <Text
          colorType={colorType}
          size="m"
          textAlign="center"
          margin={{ size: 's', position: 'bottom' }}
        >
          <FormattedMessage {...messagesStatus.title} values={propsBag} />
        </Text>
      )}
      <ImageWrapper reopenDate={isDisabled && reopenDate}>
        {isDisabled && reopenDate && (
          <AvailabilityDate>
            <Triangle
              text={
                <FormattedMessage
                  {...messagesStatus.availableDate}
                  values={{
                    ...propsBag,
                    date: <FormattedDate value={reopenDate} month="short" day="numeric" />,
                  }}
                  tagName=""
                />
              }
            />
          </AvailabilityDate>
        )}
        {showImage && (
          <Image
            src={soaImage}
            source={soaImage}
            alt="soa-image"
            margin={{ size: 'm', position: 'bottom' }}
            reopenDate={reopenDate}
          />
        )}
      </ImageWrapper>

      <ButtonComponent
        colorType={colorType}
        to={!isDisabled && `/authentication/${authentication?.id}/soa/${status}`}
        onClick={handleOnClick}
        component={ButtonTypeComponent}
        disabled={isDisabled}
        noMargin
      >
        <FormattedMessage {...messagesStatus.button} values={propsBag} />
      </ButtonComponent>
    </Wrapper>
  );
}

Soa.propTypes = {
  componentType: PropTypes.string,
  buttonType: PropTypes.string,
  showTitle: PropTypes.string,
  showImage: PropTypes.string,
  status: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  reopenDate: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default Soa;
