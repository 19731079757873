import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams, Switch, useRouteMatch } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import Route from 'components/Route';
import useColorType from 'components/hooks/useColorType';
import { Stepper, Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import usePageType from 'components/hooks/usePageType';
import { createRequest } from './actions';
import saga from './saga';
import { selectWarrantyLoading, selectWarrantyData } from './selectors';
import ProductData from './pages/ProductData';
import UserData from './pages/UserData';
import messages from './messages';
import { Wrapper } from './styles';
import Recap from './pages/Recap';
import Complete from './pages/Complete';

function Warranty() {
  useInjectSaga({ key: 'warranty', saga });
  const colorType = useColorType('warranty');
  usePageType('warranty');
  const { path } = useRouteMatch();
  const { authenticationId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(createRequest({ authenticationId }));
  }, []);

  const isLoading = useSelector(selectWarrantyLoading);
  const data = useSelector(selectWarrantyData);
  const cardColorType = 'dark';

  if (isLoading || !data) return <LoadingIndicator />;

  const pages = {
    'product-data': {
      step: 1,
    },
    'user-data': {
      step: 2,
    },
    recap: {
      step: 3,
    },
    complete: {
      step: null,
    },
  };

  return (
    <Wrapper>
      <Text colorType={colorType} size="m" textAlign="center" marginBottom="m">
        <FormattedMessage {...messages.title} />
      </Text>
      {pages[data?.data?.page]?.step && (
        <>
          <Stepper
            totalSteps={3}
            actualStep={pages[data?.data?.page]?.step}
            colorType={colorType}
          />
          <Text colorType={colorType} marginBottom="m" textAlign="center">
            <FormattedMessage {...messages.text1} />
          </Text>
        </>
      )}
      <Switch>
        <Route path={`${path}/product-data`}>
          <ProductData
            colorType={cardColorType}
            authenticationId={authenticationId}
            data={data || {}}
          />
        </Route>
        <Route path={`${path}/user-data`}>
          <UserData
            colorType={cardColorType}
            authenticationId={authenticationId}
            data={data || {}}
          />
        </Route>
        <Route path={`${path}/recap`}>
          <Recap colorType={cardColorType} authenticationId={authenticationId} data={data || {}} />
        </Route>
        <Route path={`${path}/complete`}>
          <Complete colorType={colorType} authenticationId={authenticationId} data={data || {}} />
        </Route>
      </Switch>
    </Wrapper>
  );
}

Warranty.propTypes = {
  colorType: PropTypes.string,
};

export default Warranty;
