/* eslint-disable import/prefer-default-export */
import TagManager from 'react-gtm-module';
import config from 'config';

const tagManagerArgs = {
  gtmId: config.googleTagManagerId,
  preview: config.googleTagManagerPreview,
  auth: config.googleTagManagerAuth,
};

const active = process.env.NODE_ENV !== 'test';

const initGtm = () => {
  if (active) TagManager.initialize(tagManagerArgs);
};

export { initGtm };
