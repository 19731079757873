import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from '@ebay-certilogo/design-system-web';
import { getCurrentPosition, getNearByPlaces, getPlaceSearch } from 'utils/Api/geolocation';
import { FormattedMessage } from 'react-intl';
import GeoSuggest from '../GeoSuggest';
import messages from '../messages';

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

function StoreRetailerInput({
  colorType,
  authenticationId,
  getCurrentPositionActive,
  positionCall,
  required,
  googlePlaceType,
  isGeolocationActive,
  onSelect = () => {},
}) {
  const [inputValue, setInputValue] = useState('');
  const [position, setPosition] = useState(null);
  const [type, setType] = useState('nearby'); // nearby or textSearch
  const [compact, setCompact] = useState(true);
  const [searchData, setSearchData] = useState([]);
  const [searchPagination, setSearchPagination] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const { url } = useRouteMatch();
  const history = useHistory();

  const searchNext = async () => {
    setSearchLoading(true);
    searchPagination.nextPage();
  };

  const resetSearchWrapper = () => {
    setSearchData([]);
    setSearchLoading(false);
    setSearchPagination(null);
  };

  const setSearchDataWrapper = (data) => {
    setSearchLoading(false);
    if (data.status === 'OK') {
      setSearchData(data.results);
      setSearchPagination(data.pagination);
    }
  };

  const geoSuccess = ({ coords: { latitude, longitude } }) => {
    getNearByPlaces({
      position: {
        latitude,
        longitude,
      },
      type: googlePlaceType,
      callBack: setSearchDataWrapper,
    });

    setPosition({
      latitude,
      longitude,
    });
  };

  const geoError = () => {
    setSearchLoading(false);
    history.replace(`/authentication/${authenticationId}/retailer_store/where_search`);
  };

  const getPosition = () => {
    setSearchLoading(true);
    getCurrentPosition(geoSuccess, geoError);
  };

  useEffect(() => {
    resetSearchWrapper();
    setCompact(type === 'nearby');
  }, [type]);

  useEffect(() => {
    if (type === 'nearby' && getCurrentPositionActive) {
      getPosition();
    }
  }, []);

  const handleSubmit = (data) => {
    onSelect(data);
  };

  const placesCalls = async ({ inputValue: value, position: pos, positionIP }) => {
    if (isEmpty(value)) {
      resetSearchWrapper();
    } else {
      setSearchLoading(true);
      getPlaceSearch({
        position: pos,
        positionIP,
        value,
        type: googlePlaceType,
        callBack: setSearchDataWrapper,
      });
    }
  };

  const debounceCallback = useCallback(debounce(placesCalls, 2000), []);

  return (
    <GeoSuggest
      footer={
        !searchLoading &&
        searchData.length > 2 &&
        !!compact && (
          <Button
            colorType={colorType}
            buttonType="link"
            onClick={() => {
              setCompact(false);
            }}
            arrow={false}
          >
            <FormattedMessage {...messages.viewAllStore} />
          </Button>
        )
      }
      compact={compact}
      url={url}
      value={inputValue}
      colorType={colorType}
      setInputValue={setInputValue}
      searchCallBack={debounceCallback}
      selectedCallBack={({ item }) => {
        handleSubmit(item);
      }}
      searchData={searchData}
      searchLoading={searchLoading}
      searchNext={
        !searchLoading && !compact && !!(searchPagination || {}).hasNextPage && searchNext
      }
      searchType={type}
      setSearchType={setType}
      position={position}
      getPosition={getPosition}
      positionCall={positionCall}
      handleSubmit={handleSubmit}
      required={required}
      isGeolocationActive={isGeolocationActive}
    />
  );
}

StoreRetailerInput.propTypes = {
  colorType: PropTypes.string,
  googlePlaceType: PropTypes.string,
  authenticationId: PropTypes.string,
  required: PropTypes.bool,
  isGeolocationActive: PropTypes.bool,
  getCurrentPositionActive: PropTypes.bool,
  title: PropTypes.node,
  positionCall: PropTypes.shape({
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
  onSelect: PropTypes.func,
};

export default StoreRetailerInput;
