import { isMobile } from 'utils/utils';

export default {
  basename: '',
  channel: isMobile ? 'chnmobile' : 'chnweb',
  api: {
    hostname: 'https://api.certilogo.biz',
    cms: '/cms-fury',
  },
  oauth: {
    facebook: {
      appId: '291589737561980',
      fields: ['id', 'email'],
    },
    google: {
      clientId: '504927295040-8l4in3p8bpbcak9sc1tgbd5u8a0e1urv.apps.googleusercontent.com',
    },
    apple: {
      clientId: 'com.certilogo.authenticator-mystique',
    },
  },
  googleAnalyticsId: 'UA-8083427-2',
};
