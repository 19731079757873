import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Button, FormFormik } from '@ebay-certilogo/design-system-web';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'components/form/ErrorMessage';
import { Email } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { selectChangeEmailErrors, selectChangeEmailLoading } from 'containers/Auth/selectors';
import Form from 'components/form/FormComponent';
import { changeEmailRequest } from 'containers/Auth/actions';
import { isEmpty, get } from 'lodash';
import messages from './messages';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(<ErrorMessage type="required" />),
});

function SendEmailForm({ colorType, onSuccess }) {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [errorsServer, setErrorsServer] = useState(false);
  const sendLoading = useSelector(selectChangeEmailLoading);
  const changeEmailErrors = useSelector(selectChangeEmailErrors);

  useEffect(() => {
    if (changeEmailErrors) {
      setErrorsServer(changeEmailErrors);
    }
  }, [changeEmailErrors]);

  useEffect(() => {
    if (submitted && !sendLoading) {
      if (onSuccess) onSuccess();
      setSubmitted(false);
    }
  }, [sendLoading]);

  const handleSubmit = ({ email }) => {
    dispatch(changeEmailRequest({ email }));
    setSubmitted(true);
  };

  return (
    <Formik
      initialValues={{}}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ errors, setFieldError, dirty }) => {
        if (isEmpty(errors) && get(errorsServer, 'email[0]')) {
          setFieldError(
            'email',
            <FormattedMessage {...messages.errors.email[get(errorsServer, 'email[0]')]} />,
          );
        }
        if (dirty) {
          setErrorsServer(null);
        }
        return (
          <Form>
            <Field
              name="email"
              component={FormFormik.TextInput}
              colorType={colorType}
              icon={<Email height="18px" width="18px" colorType={colorType} />}
            />
            <Button
              loading={sendLoading}
              type="submit"
              margin={{ size: 'l', position: 'bottom' }}
              colorType={colorType}
            >
              <FormattedMessage {...messages.submitButton} />
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

SendEmailForm.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default SendEmailForm;
