import React from 'react';
import PropTypes from 'prop-types';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { Field } from 'formik';
import { injectIntl } from 'react-intl';
import messages from './messages';

const { TextInput } = FormFormik;

function Fields({ colorType, intl }) {
  return (
    <>
      <Field
        name="firstName"
        component={TextInput}
        colorType={colorType}
        placeholder={intl.formatMessage(messages.placeholderName)}
      />
      <Field
        name="lastName"
        component={TextInput}
        colorType={colorType}
        placeholder={intl.formatMessage(messages.placeholderLastname)}
      />
      {/* <Field
        name="birthdate"
        component={DatePicker}
        colorType={colorType}
        placeholder={intl.formatMessage(messages.placeholderBirthDate)}
        locale={locale}
        datePickerProps={{
          maxDate: addYears(startOfDay(new Date()), -18),
        }}
      />
      <Field
        name="gender"
        component={RadioGroup}
        colorType={colorType}
        direction="row"
        justifyContent="flex-start"
        marginBottom="s"
      >
        <Radio
          key={1}
          text={intl.formatMessage(messages.placeholderGenderMale)}
          value="m"
          colorType={colorType}
          horizontal
        />
        <Radio
          text={intl.formatMessage(messages.placeholderGenderFemale)}
          value="f"
          colorType={colorType}
          horizontal
        />
        <Radio
          text={intl.formatMessage(messages.placeholderGenderNotListed)}
          value="not_listed"
          colorType={colorType}
          horizontal
        />
      </Field> */}
    </>
  );
}

Fields.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Fields);
