import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '@ebay-certilogo/design-system-web';
import isEmpty from 'lodash/isEmpty';
import Text from '../Text';
import Image from '../Image';

const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: start;
`;

function ImageTextRow({ colorType, image, text, style }) {
  return (
    !isEmpty(text) && (
      <Wrapper colorType={colorType} {...style}>
        <Image {...image} colorType={image?.colorType || colorType} />
        <Text {...text} colorType={text?.colorType || colorType} paddingLeft="s" />
      </Wrapper>
    )
  );
}

ImageTextRow.propTypes = {
  colorType: PropTypes.string,
  image: PropTypes.object,
  text: PropTypes.string,
  style: PropTypes.object,
};

export default ImageTextRow;
