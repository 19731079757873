import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    title: {
      id: 'authentic2.title1',
      defaultMessage: 'Selling in an online marketplace? A Certilogo Seal will help',
    },
    button: {
      id: 'authentic2.button1',
      defaultMessage: 'Get the seal',
    },
  },
  manage: {
    title: {
      id: 'authentic2_soa_manage.title1',
      defaultMessage: 'Click below to manage your Certilogo Seal',
    },
    button: {
      id: 'authentic2_soa_manage.button1',
      defaultMessage: 'Go to my Seal',
    },
  },
  vacation: {
    title: {
      id: 'authentic2_vacation.title1',
      defaultMessage: 'Click below to manage your Certilogo Seal',
    },
    button: {
      id: 'authentic2_vacation.button1',
      defaultMessage: 'Go to my Seal',
    },
    availableDate: {
      id: 'soa_vacation.text3',
      defaultMessage: 'Available from {date}',
    },
  },
});
