import { Flex, Image, Slider as _Slider } from '@ebay-certilogo/design-system-web';
import styled, { css } from 'styled-components';
import { margin, addCssProp } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Flex)`
  position: relative;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-shrink: 0;
  ${addCssProp}
`;

export const OverlayContents = styled(Flex)`
  position: absolute;
  flex-wrap: wrap;
  z-index: 1;
  ${margin('s')}
  ${({ contentsDirection, side }) =>
    contentsDirection === 'column'
      ? css`
          flex-direction: column;
          ${side === 1 ? 'left: 0;' : 'right: 0;'}
        `
      : css`
          flex-direction: row;
          ${side === 1 ? 'top: 0;' : 'bottom: 0;'}
        `}
`;

export const Overlay = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-wrap: wrap;
  z-index: 2;
`;

export const OverlayImage = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const OverlayText = styled(Flex)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex-direction: column;
  justify-content: center;
  z-index: 4;
`;

export const Slider = styled(_Slider)`
  min-height: 200px;
  width: 100%;
  ${({ blurred }) =>
    blurred &&
    css`
      filter: blur(4px);
    `}
  .slick-dots {
    margin-top: 10px;
    li {
      width: 5px;
      height: 5px;
      margin: 0 5px;
      button {
        &:before {
          height: 5px;
          width: 5px;
          border-radius: 50%;
        }
      }
    }
  }
`;

export const SlideImage = styled(Image)`
  vertical-align: middle;
`;
