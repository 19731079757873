import React, { useEffect, useState } from 'react';
import { Text, Heading } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import get from 'lodash/get';
import {
  selectConfirmEmailErrors,
  selectConfirmEmailLoading,
  selectUser,
} from 'containers/Auth/selectors';
import LoadingIndicator from 'components/LoadingIndicator';
import withColorType from 'components/HOC/withColorType';
import { useParams } from 'react-router-dom';
import messages from './messages';
import Card from '../Card';
import { CodeValidation, Header } from './styles';
import { confirmEmailRequest } from '../actions';
import ChangeEmail from './ChangeEmail';
import ResendCode from './ResendCode';

function ConfirmEmail({ colorType }) {
  const dispatch = useDispatch();
  const { hash } = useParams();
  const email = useSelector(selectUser);
  const errors = useSelector(selectConfirmEmailErrors);
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState('');

  const handleOnChange = (val) => {
    setValue(val);
  };

  useEffect(() => {
    if (hash) {
      dispatch(confirmEmailRequest({ hash }));
    }
  }, [hash]);

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(errors)) {
      setValue('');
      setIsError(true);
    }
  }, [errors]);

  useEffect(() => {
    if (value?.length === 4) {
      dispatch(confirmEmailRequest({ pin: value }));
    }
  }, [value]);

  const isLoading = useSelector(selectConfirmEmailLoading);

  if (hash) {
    return <LoadingIndicator colorType={colorType} />;
  }

  return (
    <>
      <Header>
        <Heading type="h1" colorType={colorType} marginBottom="m">
          <FormattedMessage {...messages.header.title} />
        </Heading>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.header.description} />
        </Text>
      </Header>
      <Card>
        {({ colorType: colorTypeLoginWrapper }) => (
          <>
            <Heading
              type="h2"
              colorType={colorTypeLoginWrapper}
              textAlign="center"
              marginBottom="m"
            >
              <FormattedMessage {...messages.title} />
            </Heading>
            <Text colorType={colorTypeLoginWrapper} textAlign="center">
              <FormattedMessage {...messages.text1} />
            </Text>
            <Text
              weight="extrabold"
              colorType={colorTypeLoginWrapper}
              textAlign="center"
              marginBottom="m"
            >
              {email}
            </Text>
            {isLoading ? (
              <LoadingIndicator colorType={colorTypeLoginWrapper} />
            ) : (
              <CodeValidation
                isError={isError}
                colorType={colorTypeLoginWrapper}
                isInputNum
                shouldAutoFocus
                onChange={handleOnChange}
                value={value}
              />
            )}
            {get(errors, 'pin[0]') && (
              <Text themeField="form.errorColor" textAlign="center" marginTop="m">
                <FormattedMessage {...get(messages, `errors.pin.${errors.pin[0]}`)} />
              </Text>
            )}
            <ResendCode colorType={colorTypeLoginWrapper} />
            <ChangeEmail colorType={colorTypeLoginWrapper} />
          </>
        )}
      </Card>
    </>
  );
}

ConfirmEmail.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
};

export default withColorType(ConfirmEmail);
