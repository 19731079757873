import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { downloadFakeReportRequest } from 'containers/FakeReport/actions';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/FakeReport/saga';
import messages from './messages';
import { Wrapper } from './styles';
import DtButton from '../../dataTypes/Button';

function FakeReport({
  buttonType,
  button,
  buttonDownloadText,
  showTitle,
  showText,
  style,
  colorType,
  logEventName = 'fake-report',
  propsBag: { logger, authentication } = {},
  propsBag,
  componentType,
  gtm,
  data,
}) {
  useInjectSaga({ key: 'fakeReport', saga });
  const dispatch = useDispatch();
  const defaultText = !isEmpty(button?.text) ? button?.text : messages.button.id;
  const isComplete = data?.status === 'complete';
  const downloadText = !isEmpty(buttonDownloadText)
    ? buttonDownloadText
    : messages.buttonDownload.id;
  const handleOnClick = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'certificate' },
        params: { certificate: 'fake-report', action: 'request' },
      });
    }
  };

  return (
    <Wrapper {...style}>
      {!!showTitle && (
        <Text
          colorType={colorType}
          size="m"
          textAlign="center"
          margin={{ size: 'm', position: 'bottom' }}
        >
          <FormattedMessage {...messages.title} values={propsBag} />
        </Text>
      )}
      {!!showText && (
        <Text
          colorType={colorType}
          size="base"
          textAlign="center"
          margin={{ size: 'm', position: 'bottom' }}
        >
          <FormattedMessage {...messages.text} values={propsBag} />
        </Text>
      )}
      <DtButton
        onClick={() => {
          handleOnClick();
          if (isComplete) {
            dispatch(
              downloadFakeReportRequest({
                token: data?.token,
              }),
            );
          } else {
            dispatch(push(`/authentication/${authentication?.id}/fake-report`));
          }
        }}
        noMargin
        {...button}
        text={isComplete ? downloadText : defaultText}
        colorType={button?.colorType || colorType}
        buttonType={button?.buttonType || buttonType || 'button'}
      />
    </Wrapper>
  );
}

FakeReport.propTypes = {
  componentType: PropTypes.string,
  button: PropTypes.object,
  buttonDownloadText: PropTypes.string,
  buttonType: PropTypes.string,
  showTitle: PropTypes.string,
  showText: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
  data: PropTypes.object,
};

export default FakeReport;
