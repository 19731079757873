import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Flex, Modal, Slider as _Slider, Text } from '@ebay-certilogo/design-system-web';
import styled, { css } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { padding, media, isMedia } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { useSize } from 'ahooks';
import ContentsGroup from 'components/cms/ContentsGroup';
import _Image from 'components/cms/dataTypes/Image';
import isEmpty from 'lodash/isEmpty';

const Wrapper = styled(Flex)`
  flex-wrap: wrap;
  justify-content: flex-start;
`;

const ImageWrapper = styled(_Image)`
  flex-grow: 1;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width};
    `}
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    vertical-align: bottom;
  }
`;

const Image = styled(_Image)`
  width: 100%;
  img {
    margin: 0;
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;

const Slider = styled(_Slider)`
  padding-top: 70px;
  ${media.tablet`
    padding-top: 94px;
  `};
  overflow: hidden;
  flex: 1;
  .slick-slider {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: hidden;
    height: 100%;
  }
  .slick-list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .slick-track {
    display: flex !important;
    flex: 1;
  }
  .slick-slide {
    height: inherit !important;
    flex-direction: column;
    > div {
      height: 100%;
    }
  }
`;

const Slide = styled(Flex)`
  position: relative;
  display: flex !important;
  flex: 1;
  height: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  margin: auto;
  overflow: hidden;
  ${({ isLast }) => (!isLast ? padding('s', 'right') : padding('m'))}
`;

function Lookbook({
  colorType,
  name,
  cover,
  images,
  contents,
  propsBag: { logger } = {},
  propsBag,
  completeAction,
}) {
  const isTablet = isMedia('tablet');
  const [isOpen, setIsOpen] = useState();
  const ref = useRef();
  const dimensions = useSize(ref);
  const { width } = dimensions || {};

  useEffect(() => {
    if (propsBag.setSwipeable) {
      propsBag.setSwipeable(false);
    }
    return () => {
      if (propsBag.setSwipeable) {
        propsBag.setSwipeable(true);
      }
    };
  }, []);

  const handleOnClick = () => {
    setIsOpen(true);
    if (logger) {
      logger({
        action: 'click',
        event: { type: 'modal', name, category: 'lookbook' },
      });
    }
  };

  return (
    <Flex>
      <Wrapper colorType={colorType} onClick={handleOnClick}>
        <ImageWrapper image={cover.imageVar || cover.image} />
      </Wrapper>
      <Modal
        isOpen={isOpen}
        close
        onClose={() => setIsOpen(false)}
        fullScreen
        fullBody
        colorType={colorType}
      >
        <Flex ref={ref} justifyContent="center" flex="1">
          <Slider dots={false} arrows={false} infinite={false} variableWidth>
            {images.map(({ image, imageVar, description }) => (
              <Slide style={{ width: width - (isTablet ? 180 : 40) }} justifyContent="flex-start">
                <Image image={imageVar || image} />
                {!isEmpty(description) && (
                  <Text colorType={colorType} marginTop="s" weight="extrabold">
                    <FormattedMessage id={description} defaultMessage={description} />
                  </Text>
                )}
              </Slide>
            ))}
            {!isEmpty(contents) && (
              <Slide isLast style={{ width }} justifyContent="center">
                {contents.map(({ contents_group: contentsGroup }) => (
                  <ContentsGroup
                    colorType={colorType}
                    data={contentsGroup}
                    propsBag={propsBag}
                    completeAction={completeAction}
                  />
                ))}
              </Slide>
            )}
          </Slider>
        </Flex>
      </Modal>
    </Flex>
  );
}

Lookbook.propTypes = {
  colorType: PropTypes.string,
  cover: PropTypes.object,
  images: PropTypes.array,
  name: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  propsBag: PropTypes.object,
  contents: PropTypes.array,
  completeAction: PropTypes.func,
};

export default Lookbook;
