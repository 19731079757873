import React, { useEffect, useMemo } from 'react';
import { StoreOnline } from '@ebay-certilogo/design-system-web/dist/components/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import Lists from 'components/Lists';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import SearchStores from '../SearchStores';
import defaultMessages from './messages';
import flowMessages from './flowMessages';

function StartSoa({
  colorType,
  inputValue,
  setInputValue,
  selectMarketplace,
  groupData,
  status,
  authenticationId,
  data,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBack({
        back: flowRequest({
          action: 'prev',
          status,
          authenticationId,
        }),
      }),
    );
  }, []);

  const overrideMessages = flowMessages[data?.flow] || {};
  const messages = useMemo(
    () => ({
      ...defaultMessages,
      ...overrideMessages,
    }),
    [data],
  );

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />
      {!inputValue && (
        <Lists
          colorType={colorType}
          clickable
          onClick={({ name }) => selectMarketplace(name)}
          left={<StoreOnline width={18} height={18} colorType={colorType} />}
          accessoryRow="name"
          type="oneRow"
          bordered
          data={groupData || []}
          marginTop="l"
          marginBottom="l"
        />
      )}
      <SearchStores
        colorType={colorType}
        authenticationId={authenticationId}
        data={data || {}}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </>
  );
}

StartSoa.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    flow: PropTypes.string,
    props: PropTypes.shape({
      marketplaceAlias: PropTypes.array,
    }),
  }),
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func,
  selectMarketplace: PropTypes.func,
  groupData: PropTypes.array,
  status: PropTypes.string,
};

export default StartSoa;
