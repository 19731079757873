import React from 'react';
import * as Yup from 'yup';
import { forEach, isEmpty } from 'lodash';
import ErrorMessage from 'components/form/ErrorMessage';
import { useIntl } from 'react-intl';

/* eslint-disable import/prefer-default-export */
const withRequired = (conf, { required, multiple, __component, regExp, regExpError = 'Error' }) => {
  const intl = useIntl();
  let newConf = conf;
  if (!isEmpty(regExp) && __component === 'form-input-types.text') {
    newConf = conf.matches(
      RegExp(regExp),
      intl.formatMessage({ id: regExpError, defaultMessage: regExpError }),
    );
  }
  if (required) {
    if (multiple) {
      newConf = newConf.min(1);
    }
    if (__component === 'form-input-types.checkbox') {
      newConf = newConf
        .oneOf([true], 'Field must be checked')
        .required(<ErrorMessage type="required" />);
    }
    newConf = newConf.required(<ErrorMessage type="required" />);
  }
  return newConf;
};

export const createValidationSchema = (obj) => {
  const validationShape = {};
  forEach(obj, (answerProps) => {
    const { name, __component, multiple } = answerProps;
    switch (__component) {
      case 'form-input-types.radio':
        validationShape[name] = withRequired(Yup.mixed(), answerProps);
        break;
      case 'form-input-types.checkbox':
        validationShape[name] = withRequired(Yup.bool().nullable(), answerProps);
        break;
      case 'form-input-types.select':
        validationShape[name] = withRequired(multiple ? Yup.array() : Yup.mixed(), answerProps);
        break;
      case 'form-input-types.slider':
        validationShape[name] = withRequired(Yup.mixed(), answerProps);
        break;
      case 'form-input-types.text':
        validationShape[name] = withRequired(Yup.string(), answerProps);
        break;
      default:
        validationShape[name] = withRequired(Yup.mixed(), answerProps);
        break;
    }
  });
  return Yup.object().shape(validationShape);
};
