import * as React from 'react';
import PropTypes from 'prop-types';

function Triangle({ text, ...props }) {
  return (
    <svg width="88" height="94" viewBox="0 0 75 80" {...props}>
      <title>Triangle</title>
      <defs>
        <linearGradient x1="0%" y1="17.482%" x2="100%" y2="82.518%" id="prefix__a">
          <stop stopColor="#7D55C7" offset="0%" />
          <stop stopColor="#00B5E2" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M58.56-1.762l35.734 57.569a6.014 6.014 0 01-5.114 9.185l-71.386-.05a6.014 6.014 0 01-5.107-9.183L48.339-1.759a6.014 6.014 0 0110.221-.003z"
        transform="rotate(35 53.5 27.5)"
        fill="url(#prefix__a)"
        fillRule="evenodd"
      />
      {text && (
        <foreignObject x="16" y="20" width="58" height="30">
          <div
            xmlns="http://www.w3.org/1999/xhtml"
            style={{ textAlign: 'center', fontSize: '10px', fontWeight: '800' }}
          >
            {text}
          </div>
        </foreignObject>
      )}
    </svg>
  );
}

Triangle.propTypes = {
  text: PropTypes.node,
};

export default Triangle;
