import { defineMessages } from 'react-intl';

const header = defineMessages({
  title: {
    id: 'signup.title1',
    defaultMessage: 'Sign up',
  },
  description: {
    id: 'signup_v2.text1',
    defaultMessage: 'You’re almost there, please register to proceed',
  },
});

const errors = {
  pin: defineMessages({
    expired: {
      id: 'confirm_email.errors.hash.expired',
      defaultMessage: 'This code has been expired, click “resend code” to have a new one',
    },
    invalid: {
      id: 'confirm_email.errors.hash.invalid',
      defaultMessage: 'Invalid code',
    },
  }),
};

const defaultMessages = defineMessages({
  title: {
    id: 'confirm_email.title1',
    defaultMessage: 'Verify your identity',
  },
  text1: {
    id: 'new_confirm_email.text1',
    defaultMessage: 'Type the code sent to',
  },
});

export default {
  ...defaultMessages,
  header,
  errors,
};
