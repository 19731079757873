import styled from 'styled-components';
import { Slider as _Slider, Div } from '@ebay-certilogo/design-system-web';

export const Wrapper = styled(Div)`
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage})`};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  .slick-slider {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: hidden;
  }
  .slick-list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
  .slick-track {
    display: flex !important;
    flex: 1;
  }
  .slick-slide {
    height: inherit !important;
    flex: 1;
    flex-direction: column;
    > div {
      height: 100%;
    }
  }
`;

export const Slider = styled(_Slider)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
