import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getImage } from 'utils/images';
import defaultColorTypes from './colorTypes';
import defaultPageTypes from './pageTypes';

const selectGlobalDomain = (state) => state.global;

const selectConfDomain = (state, _pageType) => {
  const pageType = _pageType || get(state, 'global.pageType');
  const confTypesStateMap = {
    default: 'global.defaultConf',
    clgFeature: 'global.clgFeatureConf',
  };

  const confDomain = get(state, confTypesStateMap[pageType] || 'global.conf');
  const defaultConfDomain = get(state, 'global.defaultConf');

  return confDomain?.data ? confDomain : defaultConfDomain;
};

export const selectAppLoading = createSelector(selectGlobalDomain, (state) =>
  get(state, 'appLoading'),
);

export const selectConf = createSelector(selectConfDomain, (state) => get(state, 'data'));

export const selectDefaultConf = createSelector(
  (state) => selectConfDomain(state, 'default'),
  (state) => get(state, 'data'),
);

export const selectBrandedConf = createSelector(
  (state) => selectConfDomain(state, 'brand'),
  (state) => get(state, 'data'),
);

export const selectClgFeatureConf = createSelector(
  (state) => selectConfDomain(state, 'clgFeature'),
  (state) => get(state, 'data'),
);

export const selectBackgroundImage = createSelector(selectConfDomain, (state) =>
  getImage(get(state, 'data.backgroundImage')),
);

export const selectLogo = createSelector(selectGlobalDomain, selectConfDomain, (global, conf) => {
  const colorType = get(global, 'colorType');
  const defaultLogo = get(conf, 'data.logoLight');
  const logo = {
    light: get(conf, 'data.logoLight'),
    dark: get(conf, 'data.logoDark'),
    accent: get(conf, 'data.logoLight'),
  };
  return getImage(logo[colorType] || defaultLogo);
});

export const selectLogoLight = createSelector(selectConfDomain, (state) =>
  getImage(get(state, 'data.logoLight')),
);

export const selectLogoDark = createSelector(selectConfDomain, (state) =>
  getImage(get(state, 'data.logoDark')),
);

export const selectPoweredBy = createSelector(
  selectGlobalDomain,
  selectConfDomain,
  (global, conf) => {
    const pageType = get(global, 'pageType');
    if (['certilogo', 'whitelabel'].includes(pageType)) {
      return null;
    }
    return get(conf, 'data.poweredBy');
  },
);

export const selectTheme = createSelector(selectConfDomain, (state) => get(state, 'data.theme'));

export const selectDefaultTheme = createSelector(
  (state) => selectConfDomain(state, 'default'),
  (state) => get(state, 'data.theme'),
);

export const selectColorTypes = createSelector(selectConfDomain, (state) => ({
  ...defaultColorTypes,
  ...get(state, 'data.colorTypes', {}),
}));

export const selectPageTypes = createSelector(selectGlobalDomain, (state) => ({
  ...defaultPageTypes,
  ...(get(state, 'conf.data.pageTypes') || get(state, 'defaultConf.data.pageTypes')),
}));

export const selectGooglePlaceType = createSelector(selectConfDomain, (state) =>
  get(state, 'data.googlePlaceType', 'clothing_store'),
);

export const selectPageType = createSelector(selectGlobalDomain, (state) => get(state, 'pageType'));

export const selectGlobalColorType = createSelector(selectGlobalDomain, (state) =>
  get(state, 'colorType'),
);

export const selectGlobalBack = createSelector(selectGlobalDomain, (state) => get(state, 'back'));

export const selectMenuVisible = createSelector(selectGlobalDomain, (state) =>
  get(state, 'menuVisible'),
);

export const selectPromptIsActive = createSelector(selectGlobalDomain, (state) =>
  get(state, 'promptActive'),
);

export const selectMenuIsOpen = createSelector(selectGlobalDomain, (state) =>
  get(state, 'menuIsOpen'),
);

const selectRouterDomain = () => (state) => state.router;

export const selectQueryParams = createSelector(selectRouterDomain(), (state) =>
  get(state, 'location.query'),
);

export const selectPathname = createSelector(selectRouterDomain(), (state) =>
  get(state, 'location.pathname'),
);

export const selectBackgroundColor = createSelector(selectGlobalDomain, (state) =>
  get(state, 'backgroundColor'),
);

export const selectHeaderUrl = createSelector(selectGlobalDomain, (state) =>
  get(state, 'headerUrl'),
);
