import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import { useIntl } from 'react-intl';
import { isEmpty } from 'lodash';
import FileUploadInput from 'containers/FakeReport/Purchase/FileUploadInput';
import { ButtonLabel, Div, Image } from '@ebay-certilogo/design-system-web';
import { Plus } from '@ebay-certilogo/design-system-web/dist/components/icons';

function ImageInput({ id, name, placeholder, label, colorType, ...rest }) {
  const intl = useIntl();

  return (
    <Field name={name || id}>
      {(fieldProps) => {
        return (
          <Div marginBottom="s">
            <FileUploadInput
              name={name || id}
              colorType={colorType}
              placeholder={
                !isEmpty(placeholder) &&
                intl.formatMessage({ id: placeholder, defaultMessage: placeholder })
              }
              buttonComponent={ButtonLabel}
              buttonProps={{
                button: <Plus colorType={colorType} />,
                label: !isEmpty(label) && intl.formatMessage({ id: label, defaultMessage: label }),
                accent: true,
              }}
              {...rest}
              {...fieldProps}
            />
            {fieldProps.field.value && (
              <Image marginTop="s" width="71px" src={fieldProps.field.value} />
            )}
          </Div>
        );
      }}
    </Field>
  );
}

ImageInput.propTypes = {
  id: PropTypes.string,
  colorType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default ImageInput;
