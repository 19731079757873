import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import map from 'lodash/map';
import DynamicComponent from 'components/cms/DynamicComponent';
import { Wrapper } from './styles';
import reasons from './reasons';

function Reason({ style, colorType, propsBag: { authentication } = {}, propsBag }) {
  const { title, subtitle, description, cta } = reasons[get(authentication, 'reason')] || {};

  return (
    <Wrapper {...style}>
      {!!title && (
        <Text
          colorType={colorType}
          size="xl"
          weight="extrabold"
          textAlign="center"
          margin={{ size: 'm', position: 'bottom' }}
        >
          <FormattedMessage id={title} defaultMessage={title} values={propsBag} />
        </Text>
      )}
      {!!subtitle && (
        <Text
          colorType={colorType}
          size="l"
          weight="extrabold"
          textAlign="center"
          margin={{ size: 'm', position: 'bottom' }}
        >
          <FormattedMessage id={subtitle} defaultMessage={subtitle} values={propsBag} />
        </Text>
      )}
      {!!description && (
        <Text
          colorType={colorType}
          size="base"
          textAlign="center"
          margin={{ size: 'm', position: 'bottom' }}
        >
          <FormattedMessage id={description} defaultMessage={description} values={propsBag} />
        </Text>
      )}

      {map(cta, ({ type, ...rest }, key) => (
        <DynamicComponent
          propsBag={propsBag}
          colorType={colorType}
          type={type}
          data={rest}
          key={key}
        />
      ))}
    </Wrapper>
  );
}

Reason.propTypes = {
  style: PropTypes.object,
  colorType: PropTypes.string,
  propsBag: PropTypes.object,
};

export default Reason;
