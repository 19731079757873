import React from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import mila from 'markdown-it-link-attributes';
import styled from 'styled-components';
import {
  margin,
  padding,
  lineHeight,
  letterSpacing,
  addCssProp,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';

// eslint-disable-next-line
const MyLink = ({ children, ...props }) => {
  // eslint-disable-next-line
  const target = (props.href.includes('http://') || props.href.includes('https://')) && '_blank';
  return (
    <a {...props} target={target}>
      {children}
    </a>
  );
};

const Wrapper = styled.span`
  color: inherit;
  font-weight: inherit;
  ${lineHeight('base')}
  ${letterSpacing()}
  p {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    ${margin('s', 'bottom')}
  }
  ul,
  ol {
    ${margin('s', 'bottom')}
    ${padding('m', 'horizontal')}
  li {
      ${margin('s', 'bottom')}
    }
  }
  a {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }
  table {
    border-collapse: collapse;
    td,
    th {
      border-bottom: 1px solid;
      ${padding('s')}
    }
  }
  ${addCssProp}
`;

const Markdown = ({ children, isInline = true, styleProps }) => {
  const parser = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  });

  parser.use(mila, {
    matcher(href) {
      return href.startsWith('http:') || href.startsWith('https:');
    },
    attrs: {
      target: '_blank',
    },
  });

  const methodsMap = {
    inline: 'renderInline',
    default: 'render',
  };

  const html = parser[methodsMap[isInline ? 'inline' : 'default']](children);

  return <Wrapper dangerouslySetInnerHTML={{ __html: html }} {...styleProps} />;
};

Markdown.propTypes = {
  children: PropTypes.node,
  isInline: PropTypes.bool,
  styleProps: PropTypes.object,
};

export default Markdown;
