import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'where_offline_geo.placeholder1',
    defaultMessage: 'Placeholder',
  },
  skip: {
    id: 'where_offline_geo.button1',
    defaultMessage: 'Skip',
  },
  viewNearBy: {
    id: 'where_offline_no_geo.button1',
    defaultMessage: 'View nearby stores',
  },
});
