import React, { useEffect } from 'react';
import { Button, Text, Div } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { setBack } from 'containers/App/actions';
import { selectQueryParams } from 'containers/App/selectors';
import { selectAuthLoginLoading, selectAuthData } from 'containers/Auth/selectors';
import { setMailgun } from 'containers/Auth/actions';
import _Link from 'components/Link';
import LoadingIndicator from 'components/LoadingIndicator';
import messages from './messages';

export const Link = styled(_Link)`
  display: block;
  text-align: center;
`;

function MailgunError({ colorType }) {
  const dispatch = useDispatch();

  const { email } = useSelector(selectQueryParams);
  const data = useSelector(selectAuthData);
  const mailgunMail = get(data, 'mailgun.didYouMean');

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  const handleSubmit = (selectedEmail) => {
    dispatch(setMailgun(selectedEmail));
  };

  const isLoading = useSelector(selectAuthLoginLoading);
  if (isLoading) return <LoadingIndicator />;

  return (
    <>
      <Text colorType={colorType} themeField="form.errorColor" marginBottom="m">
        <FormattedMessage {...messages.error} />
      </Text>
      <Text colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.description} />
      </Text>
      <Button colorType={colorType} noMargin onClick={() => handleSubmit(mailgunMail)}>
        {mailgunMail}
      </Button>
      <Button colorType={colorType} marginBottom="m" onClick={() => handleSubmit(email)}>
        {email}
      </Button>
      <Div textAlign="center">
        <Text weight="extrabold" colorType={colorType} as={Link} to="/auth/sign-up">
          <FormattedMessage {...messages.link} />
        </Text>
      </Div>
    </>
  );
}

MailgunError.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  type: PropTypes.string,
};

export default injectIntl(MailgunError);
