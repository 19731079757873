import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DynamicComponent from 'components/cms/DynamicComponent';
import ButtonComponent from 'components/ButtonComponent';
import ProgressBarTimer from 'components/ProgressBarTimer';
import { FormattedMessage } from 'react-intl';
import { transparentize } from 'polished';
import { useTheme } from 'styled-components';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { Wrapper, Style, Skip } from './styles';

function Page({
  data: { contents, background, skip, style, verticalCenter, temporization },
  poweredBy,
  skipUrl,
  completeAction,
  propsBag: { logger } = {},
  propsBag,
  onNext,
  active,
  multipage,
  colorType,
}) {
  const theme = useTheme();
  const progressBarTimer = useRef();

  useEffect(() => {
    if (temporization && progressBarTimer && progressBarTimer.current) {
      if (active) {
        progressBarTimer.current.start();
      } else {
        progressBarTimer.current.reset();
      }
    }
  }, [active]);

  const handleSkip = () => {
    if (completeAction) completeAction();
    if (logger) {
      logger({
        type: 'action',
        action: 'click',
        event: { type: 'link', name: 'OB - skip', category: 'skip' },
      });
    }
  };

  return (
    <Wrapper
      poweredBy={poweredBy}
      background={background}
      style={{ ...style }}
      multipage={multipage}
    >
      <Style verticalCenter={verticalCenter}>
        {_.map(contents, ({ __component, _id, ...rest }) => (
          <DynamicComponent
            completeAction={completeAction}
            type={__component}
            data={rest}
            key={_id}
            propsBag={propsBag}
            colorType={colorType}
          />
        ))}
      </Style>
      {!!skip && (
        <ButtonComponent colorType={colorType} component={Skip} href={skipUrl} onClick={handleSkip}>
          <FormattedMessage id="onboarding_std.button1" defaultMessage="Skip" />
        </ButtonComponent>
      )}
      {temporization && (
        <ProgressBarTimer
          colorType={colorType}
          ref={progressBarTimer}
          interval={active && temporization}
          onComplete={active && onNext}
          strokeColor={color({ theme, colorType })}
          trailColor={transparentize(0.8, color({ theme, colorType }))}
        />
      )}
    </Wrapper>
  );
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
  colorType: PropTypes.string,
  skipUrl: PropTypes.string,
  verticalCenter: PropTypes.bool,
  multipage: PropTypes.bool,
  poweredBy: PropTypes.bool,
  completeAction: PropTypes.func,
  propsBag: PropTypes.object,
  temporization: PropTypes.number,
  onNext: PropTypes.func,
  active: PropTypes.bool,
};

export default Page;
