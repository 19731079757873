import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import withColorType from 'components/HOC/withColorType';
import FlowButton from 'containers/Authentication/components/FlowButton';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import { Button } from '@ebay-certilogo/design-system-web';
import messages from './messages';

function SoaRequest({ colorType, data, authenticationId }) {
  const { status } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = (value) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: { soaRequest: value },
      }),
    );
  };

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />
      <FlowButton onClick={() => handleSubmit(true)} colorType={colorType} noMargin>
        <FormattedMessage {...messages.buttonYes} />
      </FlowButton>
      <Button
        onClick={() => handleSubmit(false)}
        colorType={colorType}
        buttonType="link"
        arrow={false}
        noMargin
      >
        <FormattedMessage {...messages.buttonNo} />
      </Button>
    </>
  );
}

SoaRequest.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    certilogoCode: PropTypes.string,
  }),
};

export default withColorType(SoaRequest);
