import React, { useEffect, useState } from 'react';
import { TextInput } from '@ebay-certilogo/design-system-web';
import { Search } from '@ebay-certilogo/design-system-web/dist/components/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import Lists from 'components/Lists';
import withColorType from 'components/HOC/withColorType';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import messages from './messages';
import { ListWrapper } from './styles';

function SelectBrand({ colorType, data, authenticationId, intl }) {
  const { status } = data;
  const dispatch = useDispatch();
  const [searchValues, setSearchValues] = useState();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = ({ id }) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: {
          invalidBrand: id,
        },
      }),
    );
  };

  const onChange = (e) => {
    const { primaryBrands } = data.props;

    const value = e.currentTarget.value;
    if (value && primaryBrands) {
      const otherValue = primaryBrands.find(({ name }) => name === 'Other');
      const search = primaryBrands.filter(
        ({ name }) => name.toLowerCase().includes(value.toLowerCase()) && name !== 'Other',
      );
      search.push(otherValue);
      setSearchValues(search);
    } else {
      setSearchValues(undefined);
    }
  };

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />

      <TextInput
        placeholder={intl.formatMessage(messages.placeholder)}
        colorType={colorType}
        icon={<Search width={22} height={22} colorType={colorType} />}
        onChange={onChange}
      />
      {searchValues && (
        <ListWrapper>
          <Lists
            colorType={colorType}
            clickable
            onClick={handleSubmit}
            accessoryRow="name"
            rowPadding={{ size: 's', position: 'vertical' }}
            type="oneRow"
            data={searchValues}
            marginTop="m"
            rowProps={{
              height: 20,
            }}
          />
        </ListWrapper>
      )}
    </>
  );
}

SelectBrand.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    props: PropTypes.object,
  }),
  intl: PropTypes.object,
};

export default withColorType(injectIntl(SelectBrand));
