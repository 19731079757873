import fetchWrapper from 'utils/Api/fetchWrapper';
import packageJson from 'containers/../../package.json';
import { push } from 'connected-react-router';
import { getBrandDomainName, isBrandedDomain } from 'utils/utils';
import { call, put } from 'redux-saga/effects';
import { loginResponse, setAuthErrors } from '../actions';

export default function* loginCall({
  payload: {
    email,
    password,
    login,
    userData,
    network,
    token,
    code,
    firstName,
    lastName,
    version,
    redirect,
  },
}) {
  const url = `users/v2/registration/`;

  try {
    const response = yield call(fetchWrapper, {
      url,
      method: login ? 'PUT' : 'POST',
      params: {
        email,
        password,
        token,
        code,
        firstName,
        lastName,
        version,
        applicationVersion: !login ? packageJson.version : undefined,
        domain: isBrandedDomain() ? getBrandDomainName() : null,
        network: network || 'local',
      },
    });

    yield put(loginResponse({ data: response, network, userData, redirect }));
  } catch ({ packet, response }) {
    yield put(loginResponse({ data: {} }));
    if ([400, 422].includes(response.status)) yield put(setAuthErrors(packet.errors));
    if (response.status === 403) yield put(push(`/auth/account-blocked`));
  }
}
