import { defineMessages } from 'react-intl';

export default defineMessages({
  signup: {
    id: 'login_signup.title1',
    defaultMessage: 'Sign Up',
  },
  login: {
    id: 'login_signup.title2',
    defaultMessage: 'Login',
  },
  placeholder_login: {
    id: 'login_3rd_psw.placeholder1',
    defaultMessage: 'Enter password',
  },
  placeholder_signUp: {
    id: 'login_create_psw.placeholder1',
    defaultMessage: 'Create password',
  },
  submitButton_login: {
    id: 'login_2nd_psw.button1',
    defaultMessage: 'Login',
  },
  submitButton_signUp: {
    id: 'login_1st.button1',
    defaultMessage: 'Sign Up',
  },
  anotherEmail: {
    id: 'login_enter_psw.text3',
    defaultMessage: 'Sign in with another email address',
  },
  forgot: {
    id: 'login_3rd_psw.text2',
    defaultMessage: 'Forgot password?',
  },
  pswErrorSignUp: {
    id: 'login_create_psw_error.text',
    defaultMessage: 'Password is too short - should be 6 chars minimum.',
  },
  pswErrorLogin: {
    id: 'login_enter_psw_error.text',
    defaultMessage: 'Incorrect password',
  },
});
