import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useInterval from '@rooks/use-interval';
import styled from 'styled-components';
import { addCssProp, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import Progress from './Progress';

const ProgressStyled = styled(Progress)`
  ${margin('s', 'bottom')}
  ${addCssProp}
`;

function ProgressBarTimer({ interval, onComplete, startImmediate, ...props }, ref) {
  const [percent, setPercent] = useState(0);
  const [st, setSt] = useState(new Date().getTime());
  const { start, stop } = useInterval(
    () => {
      const diff = new Date().getTime() - st;
      let val = (diff / interval) * 100;
      val = val > 100 ? 100 : val;
      setPercent(val);
    },
    50,
    { startImmediate },
  );

  useEffect(() => {
    if (_.isFunction(onComplete) && percent >= 100) {
      onComplete();
    }
  }, [percent]);

  useImperativeHandle(ref, () => ({
    start: () => {
      setSt(new Date().getTime());
      start();
    },
    stop: () => {
      stop();
    },
    reset: () => {
      stop();
      setPercent(0);
    },
  }));

  return <ProgressStyled {...props} percent={percent} />;
}

ProgressBarTimer.propTypes = {
  interval: PropTypes.number,
  onComplete: PropTypes.func,
  startImmediate: PropTypes.bool,
};

export default forwardRef(ProgressBarTimer);
