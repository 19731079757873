import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonCta } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import ButtonComponent from 'components/ButtonComponent';

const buttonTypes = {
  button: Button,
  button_cta: ButtonCta,
};

function CodeReview({
  buttonType = 'button',
  style,
  colorType,
  text,
  logEventName,
  propsBag: { logger, authentication } = {},
  propsBag,
  componentType,
  gtm,
}) {
  const handleOnClick = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'code-review' },
      });
    }
  };
  const Btn = buttonTypes[buttonType];

  return (
    <ButtonComponent
      colorType={colorType}
      to={`/code-review/create/${authentication?.id}`}
      onClick={handleOnClick}
      component={Btn}
      {...style}
    >
      <FormattedMessage id={text} defaultMessage={text} values={propsBag} />
    </ButtonComponent>
  );
}

CodeReview.propTypes = {
  componentType: PropTypes.string,
  buttonType: PropTypes.string,
  showTitle: PropTypes.string,
  text: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  logger: PropTypes.func,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default CodeReview;
