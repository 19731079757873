import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SVG from 'react-inlinesvg';
import { addCssProp, omitCssProps } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import omitProps from '@ebay-certilogo/design-system-web/dist/omitProps';
import nearBy from './assets/nearby.svg';

export const Svg = styled(omitProps(SVG, omitCssProps))`
  ${addCssProp}
`;

function NearBy({ colorType, description, style, ...rest }) {
  return <Svg src={nearBy} alt={description} {...rest} />;
}

NearBy.propTypes = {
  colorType: PropTypes.string,
  description: PropTypes.string,
  style: PropTypes.object,
};

export default NearBy;
