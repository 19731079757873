import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { push } from 'connected-react-router';
import { warrantyResponse } from '../actions';

export default function* flow({ payload: { data, authenticationId, action, page } }) {
  const url = 'warranty/v1/flow/:authenticationId';

  try {
    const response = yield call(fetchWrapper, {
      method: 'PUT',
      url: url.replace(':authenticationId', authenticationId),
      params: {
        action,
        page,
        data,
      },
    });

    yield put(warrantyResponse(response));

    yield put(push(`/authentication/${authenticationId}/warranty/${response.data.page}`));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
