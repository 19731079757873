import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import types from './types';

function DynamicComponent({
  type,
  data: { gtm },
  data,
  propsBag: { logger = () => {} } = {},
  propsBag,
  colorType,
  ...rest
}) {
  const Component = types[type];

  useEffect(() => {
    if (Component) {
      const { text, button, url, locked: _locked } = data;
      const label = text || button?.text;
      const locked = _locked || button?.locked;
      logger({
        action: 'view',
        type,
        gtm,
        step: 'view',
        view: 1,
        label,
        link: url,
        locked,
      });
    }
  }, []);

  if (!Component) {
    return null;
  }

  return (
    <Component
      {...{ ...rest, ...data }}
      propsBag={propsBag}
      colorType={data?.colorType || colorType}
      componentType={type}
    />
  );
}

DynamicComponent.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  propsBag: PropTypes.object,
  colorType: PropTypes.string,
};

export default DynamicComponent;
