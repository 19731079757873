import React from 'react';
import PropTypes from 'prop-types';
import { Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectColorTypes } from 'containers/App/selectors';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Route from 'components/Route';
import NotFoundPage from 'containers/NotFoundPage';
import Login from './Login';
import AccountBlocked from './AccountBlocked';
import Profile from './Profile';
import RouteAuth from './RouteAuth';
import ConfirmEmail from './ConfirmEmail';
import Info from './Info';
import CodeLogin from './CodeLogin';
import SetData from './SetData';

function Auth({ colorType }) {
  const { path } = useRouteMatch();
  const colorTypes = useSelector(selectColorTypes);
  return (
    <Switch>
      <RouteAuth isWhitelisted path={`${path}/sign-up`}>
        <Login colorType={colorTypes.authLogin} type="signUp" />
      </RouteAuth>
      <RouteAuth path={`${path}/create-password`}>
        <Login colorType={colorTypes.authLogin} type="signUp" status="password" />
      </RouteAuth>
      <RouteAuth path={`${path}/code-login/:token?`}>
        <CodeLogin colorType={colorTypes.authLogin} />
      </RouteAuth>
      <RouteAuth isWhitelisted path={`${path}/set-data`}>
        <SetData colorType={colorTypes.authLogin} />
      </RouteAuth>
      <RouteAuth path={`${path}/login-sign-up-error-mailgun`}>
        <Login colorType={colorTypes.authLogin} type="signUp" status="mailgunError" />
      </RouteAuth>
      <RouteAuth isWhitelisted path={`${path}/confirm-email/:hash?`}>
        <ConfirmEmail colorType={colorTypes.authLogin} />
      </RouteAuth>
      <RouteAuth path={`${path}/account-blocked`}>
        <AccountBlocked colorType={colorTypes.authLogin} />
      </RouteAuth>
      <AuthenticatedRoute path={`${path}/profile`}>
        <Profile colorType={colorType} />
      </AuthenticatedRoute>
      <Route path={`${path}/info`}>
        <Info colorType={colorTypes.authLogin} />
      </Route>
      <Route path="" component={NotFoundPage} />
    </Switch>
  );
}

Auth.propTypes = {
  colorType: PropTypes.string,
};

export default Auth;
