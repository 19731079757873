import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormFormik, Radio as ClgRadio } from '@ebay-certilogo/design-system-web';
import { isEmpty, map, shuffle } from 'lodash';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';

const FieldStyle = styled(Field)`
  width: 100%;
`;

const RadioGroup = styled(FormFormik.RadioGroup)`
  display: flex;
  flex-direction: ${({ inline }) => (inline ? 'row' : 'column')};
`;

const ClgRadioStyle = styled(ClgRadio)`
  flex: 1;
  ${margin('m', 'bottom')}
`;

function Radio({
  id,
  name,
  label,
  options: _options,
  shuffleOptions,
  inline,
  colorType,
  propsBag,
  ...rest
}) {
  const [options, setOptions] = useState(_options);
  useEffect(() => {
    if (shuffleOptions) {
      setOptions(shuffle(_options));
    }
  }, [_options]);

  return (
    <FieldStyle
      name={name || id}
      component={RadioGroup}
      colorType={colorType}
      inline={inline}
      label={!isEmpty(label) && <FormattedMessage id={label} defaultMessage={label} />}
      {...rest}
    >
      {map(options, ({ label: optionLabel, value, _id }) => (
        <ClgRadioStyle
          key={_id}
          text={
            !isEmpty(optionLabel) && (
              <FormattedMessage id={optionLabel} defaultMessage={optionLabel} values={propsBag} />
            )
          }
          value={value}
          colorType={colorType}
        />
      ))}
    </FieldStyle>
  );
}

Radio.propTypes = {
  colorType: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  inline: PropTypes.bool,
  options: PropTypes.array,
  shuffleOptions: PropTypes.bool,
  propsBag: PropTypes.object,
};

export default Radio;
