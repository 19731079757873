import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'where_active_soa_2.title1',
    defaultMessage: 'Please tell us about the online retailer',
  },
  placeholder: {
    id: 'where_active_soa_2.placeholder1',
    defaultMessage: 'Enter website name or address',
  },
  go: {
    id: 'where_online_more.button2',
    defaultMessage: 'Go',
  },
  urlError: {
    id: 'where_online_error.text1',
    defaultMessage: 'Your search is not valid, try writing the URL of the shop',
  },
});
