import React from 'react';
import PropTypes from 'prop-types';
import { ModalAssistant, Button, Heading } from '@ebay-certilogo/design-system-web';
import { getField } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { useTheme } from 'styled-components';

function LaterPopup({
  colorType,
  isOpen,
  setIsOpen,
  handleSubmit,
  message,
  primaryButtonMessage,
  secondaryButtonMessage,
}) {
  const theme = useTheme();
  const buttonStyle = {
    backgroundColor: getField('modalAssistant.button.backgroundColor', { theme, colorType }),
    color: getField('modalAssistant.button.color', { theme, colorType }),
  };
  return (
    <ModalAssistant
      colorType={colorType}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      text={
        <Heading type="h2" colorType={colorType} themeField="modalAssistant.color" noMargin>
          {message}
        </Heading>
      }
      footer={
        <>
          <Button
            colorType={colorType}
            onClick={() => setIsOpen(false)}
            style={buttonStyle}
            noMargin
          >
            {primaryButtonMessage}
          </Button>
          <Button
            colorType={colorType}
            buttonType="link"
            onClick={handleSubmit}
            noMargin
            arrow={false}
            themeField="modalAssistant.color"
          >
            {secondaryButtonMessage}
          </Button>
        </>
      }
    />
  );
}

LaterPopup.propTypes = {
  colorType: PropTypes.string,
  flow: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  handleSubmit: PropTypes.func,
  message: PropTypes.node,
  primaryButtonMessage: PropTypes.node,
  secondaryButtonMessage: PropTypes.node,
};

export default LaterPopup;
