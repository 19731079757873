import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { isEmpty, map, shuffle } from 'lodash';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

function Select({
  id,
  name,
  placeholder,
  label,
  options: _options,
  shuffleOptions,
  multiple,
  colorType,
  elevation,
  propsBag,
  ...rest
}) {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const selectOptions = map(_options, ({ label: optionLabel, value }) => ({
      label: optionLabel && <FormattedMessage id={optionLabel} defaultMessage={optionLabel} />,
      value,
    }));
    if (shuffleOptions) {
      setOptions(shuffle(selectOptions));
    } else {
      setOptions(selectOptions);
    }
  }, [_options]);

  return (
    <Field
      name={name || id}
      component={FormFormik.Select}
      options={options}
      colorType={colorType}
      isMulti={multiple}
      placeholder={
        !isEmpty(placeholder) && (
          <FormattedMessage id={placeholder} defaultMessage={placeholder} values={propsBag} />
        )
      }
      label={!isEmpty(label) && <FormattedMessage id={label} defaultMessage={label} />}
      isSearchable={false}
      elevation={elevation}
      {...rest}
    />
  );
}

Select.propTypes = {
  id: PropTypes.string,
  colorType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  shuffleOptions: PropTypes.bool,
  multiple: PropTypes.bool,
  elevation: PropTypes.number,
  propsBag: PropTypes.object,
};

export default Select;
