import AnimationAuthentic from './animations/AnimationAuthentic';
import AnimationFake from './animations/AnimationFake';
import AnimationImpossible from './animations/AnimationImpossible';
import AnimationCustom from './animations/AnimationCustom';

export default {
  authentic: {
    title: 'authentic.text1',
    animation: {
      complete: AnimationAuthentic,
      intro: AnimationAuthentic,
      custom: AnimationCustom,
    },
  },
  fake: {
    title: 'fake_response.text1',
    animation: {
      complete: AnimationFake,
      intro: AnimationFake,
    },
  },
  impossible: {
    title: 'impossible_response.title1',
    animation: {
      complete: AnimationImpossible,
      intro: AnimationImpossible,
    },
  },
};
