import React from 'react';
import PropTypes from 'prop-types';
import { CodeInputLabel, CodeInputWrapper, CodeInput } from './styles';

function CodeInputField({ colorType, ...rest }) {
  return (
    <CodeInputWrapper colorType={colorType}>
      <CodeInputLabel colorType={colorType}>CLG:</CodeInputLabel>
      <CodeInput colorType={colorType} {...rest} noMargin />
    </CodeInputWrapper>
  );
}

CodeInputField.propTypes = {
  colorType: PropTypes.string,
  field: PropTypes.object,
};

export default CodeInputField;
