import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Text, Image as _Image, Div, Flex } from '@ebay-certilogo/design-system-web';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';

const Button = styled(Flex)`
  border: 1px solid ${color};
  justify-content: left;
  cursor: pointer;
  ${padding('s', 'vertical')}
  ${padding('m', 'horizontal')}
`;

const ImageWrapper = styled(Div)`
  display: flex;
  justify-content: center;
  width: 30%;
  margin-right: 20px;
`;

const Image = styled(_Image)`
  height: 80px;
  width: auto;
`;

const BodyWrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Title = styled(Text)`
  margin-bottom: 5px;
`;

const Description = styled(Text)`
  font-style: italic;
`;

function DynamicComponent({ colorType, responses, propsBag, onChange }) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (selected !== null) {
      onChange(selected);
    }
  }, [selected]);

  return (
    <>
      {responses.map(({ title, text, id, imgSrc }) => (
        <Button
          colorType={colorType}
          borderWidth="1px"
          size="s"
          marginBottom="m"
          onClick={() => setSelected(id)}
          style={{ minHeight: 'auto', maxHeight: 'none' }}
          uppercase={false}
        >
          <ImageWrapper>
            <Image src={imgSrc} />
          </ImageWrapper>
          <BodyWrapper>
            <Title colorType={colorType} weight="semibold">
              <FormattedMessage id={title || 'id'} defaultMessage={title} values={propsBag} />
            </Title>
            <Description colorType={colorType}>
              <FormattedMessage id={text || 'id'} defaultMessage={text} values={propsBag} />
            </Description>
          </BodyWrapper>
        </Button>
      ))}
    </>
  );
}

DynamicComponent.propTypes = {
  colorType: PropTypes.string,
  propsBag: PropTypes.object,
  responses: PropTypes.array,
  onChange: PropTypes.func,
};

export default DynamicComponent;
