import React, { useRef, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { imageUploadRequest, imageDeleteRequest } from 'containers/Authentication/actions';
import { Button, ButtonSimple, Modal, Text, Div } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import {
  selectAuthenticationImageUploadCount,
  selectAuthenticationImageUploadData,
  selectAuthenticationImageUploadLoading,
} from 'containers/Authentication/selectors';
import { ChevronLeft, SendPhoto } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { forEach, get } from 'lodash';
import LoadingIndicator from 'components/LoadingIndicator';
import history from 'utils/history';
import { useLocation } from 'react-router-dom';
import useColorType from 'components/hooks/useColorType';
import _FlowButton from 'containers/Authentication/components/FlowButton';
import styled from 'styled-components';
import QrScanner from 'qr-scanner';
import { useTimeoutFn } from 'react-use';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { selectJwt } from 'containers/Auth/selectors';
import LaterPopup from 'components/Camera/LaterPopup';
import {
  Header,
  Body,
  Footer,
  Shot,
  Help,
  Camera,
  ImageWrapper,
  ImagePreview,
  ModalBody,
  ProgressBar,
  Thumbnails,
  ThumbnailWrapper,
  Thumbnail,
  FrameWrapper,
  FrameSvg,
  ButtonFooter,
  ScanAnimationSvg,
} from './styles';
import LibrarySelect from './LibrarySelect';
import UploadSorryModal from '../UploadSorryModal';
import HelpModal from '../HelpModal';
import PreprocessingTimeoutModal from '../PreprocessingTimeoutModal';
import Portal from './Portal';
import Overlay from './Overlay';
import frameDefault from './assets/frame.svg';
import ScanAnimation from './scan-animation.svg';

const FlowButton = styled(_FlowButton)`
  width: 100%;
`;

const uploadSimpleImage = async ({ file, metadata, jwtToken }) => {
  const apiUrl = 'image/simple/upload';
  const formData = new FormData();
  formData.append('file', file);
  forEach(metadata, (value, key) => {
    formData.append(`metadata[${key}]`, value);
  });

  try {
    const res = await fetchWrapper({
      url: apiUrl,
      formData,
      options: {
        method: 'POST',
        headers: { jwtToken },
      },
    });
    return res?.objectUrl;
  } catch (error) {
    return false;
  }
};

const CameraRoute = ({ authenticationId, data, flowNext, messages, log }) => {
  const jwtToken = useSelector(selectJwt);
  const shotTimeoutRef = React.useRef(null);
  const [cameraReady, setCameraReady] = useState(false);
  const colorType = useColorType('authenticationImageUploadCamera');
  const { props } = data;
  const minImages = get(props, 'image.min');
  const maxImages = get(props, 'image.max');
  const isPreprocessingActive = get(props, 'config.camera.preprocessing');
  const captions = get(props, 'config.camera.captions', []);
  const frame = get(props, 'config.frame');
  const dispatch = useDispatch();
  const tryAgain = get(useLocation(), 'state.tryAgain');
  const [laterPopupIsOpen, setLaterPopupIsOpen] = useState(false);
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);
  const [isShooting, setIsShooting] = useState(false);
  const [qrDetected, setQrDetected] = useState();
  const [qrDetectedData, setQrDetectedData] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [preprocessingScanTimeout, setPreprocessingScanTimeout] = useState(false);
  const cameraRef = useRef();
  const isLoading = useSelector(selectAuthenticationImageUploadLoading);
  const uploadedImages = useSelector(selectAuthenticationImageUploadData);
  const uploadedImagesCount = useSelector(selectAuthenticationImageUploadCount);
  const actualPosition = uploadedImagesCount;
  const prevPosition = actualPosition - 1;
  const actualCaptionLabel =
    messages.captions[actualPosition]?.label || messages.captions[0]?.label || '-';
  const actualFrame =
    captions[actualPosition]?.frame?.url || captions[0]?.frame?.url || frame?.url || frameDefault;

  const sendImage = (file, acquisitionType, qrString) => {
    log('upload', { acquisitionType });
    dispatch(
      imageUploadRequest({
        file,
        acquisitionType,
        source: 'authentication',
        sourceId: authenticationId,
        position: actualPosition,
        qrString,
      }),
    );
  };
  const deleteImage = () => {
    dispatch(
      imageDeleteRequest({
        source: 'authentication',
        sourceId: authenticationId,
        imageId: uploadedImages[prevPosition].imageId,
      }),
    );
  };
  const handleShot = () => {
    setIsShooting(true);
    cameraRef.current.capture(async ({ blob, uri }) => {
      setImagePreview({
        src: URL.createObjectURL(blob),
        file: blob,
        uri,
        acquisitionType: 'camera',
      });
      setIsShooting(false);
    });
  };

  const qrScanner = useMemo(() => {
    if (cameraReady) {
      const [videoElem] = document.getElementsByTagName('video');
      if (videoElem) {
        const scanner = new QrScanner(
          videoElem,
          (res) => {
            setQrDetectedData(res);
            setQrDetected(true);
          },
          {
            returnDetailedScanResult: true,
            highlightCodeOutline: false,
            highlightScanRegion: false,
            maxScansPerSecond: 5,
          },
        );

        return scanner;
      }
    }
    return null;
  }, [cameraReady]);

  useEffect(() => {
    if (qrDetected) {
      clearTimeout(shotTimeoutRef.current);
      shotTimeoutRef.current = setTimeout(() => {
        if (cameraRef.current) {
          const qtyImagesToCollect = get(props, 'config.camera.collectSamples') ? 3 : 1;
          for (let i = 1; i <= qtyImagesToCollect; i += 1) {
            setTimeout(() => {
              cameraRef.current.capture(async ({ blob }) => {
                if (i === 1) {
                  sendImage(blob, 'camera', qrDetectedData?.data);
                } else if (get(props, 'config.camera.collectSamples')) {
                  const metadata = {
                    authenticationId,
                    type: get(props, 'config.camera.samplesType', 'preprocessing_samples'),
                  };
                  uploadSimpleImage({ file: blob, metadata, jwtToken });
                }
                if (i === qtyImagesToCollect) qrScanner.stop();
              });
            }, i * 150);
          }
        }
      }, 800);
    }
    return () => clearTimeout(shotTimeoutRef.current);
  }, [qrDetected]);

  const [, cancelPreprocessingScanTimeout, resetPreprocessingScanTimeout] = useTimeoutFn(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (isPreprocessingActive && qrScanner?._active) {
      qrScanner.stop();
      setPreprocessingScanTimeout(true);
      log('preprocessingTimeout');
    }
  }, 30000);

  const preprocessingScanStart = () => {
    setQrDetected(null);
    setPreprocessingScanTimeout(false);
    resetPreprocessingScanTimeout();
    qrScanner.start();
  };

  useEffect(() => {
    if (cameraReady && !imagePreview && qrScanner && isPreprocessingActive && !qrDetected) {
      preprocessingScanStart();
    }
  }, [cameraReady, qrScanner, isPreprocessingActive, imagePreview, qrDetected]);

  useEffect(() => {
    log('start');
    return () => {
      log('end');
      if (qrScanner) {
        qrScanner.destroy();
      }
      clearTimeout(shotTimeoutRef.current);
      cancelPreprocessingScanTimeout();
    };
  }, []);

  const handleCameraReady = () => {
    setCameraReady(true);
  };

  const handleRetake = () => {
    setImagePreview(null);
    setQrDetected(null);
  };

  const handleSelectImage = (file) => {
    setImagePreview({
      file,
      src: !file.uri && URL.createObjectURL(file),
      uri: file.uri,
      acquisitionType: 'library',
    });
  };
  const handleConfirm = () => {
    if (imagePreview)
      sendImage(imagePreview.file, imagePreview.acquisitionType, imagePreview.qrString);
  };
  const handleBack = () => {
    if (actualPosition > 0) {
      deleteImage();
    } else {
      history.replace(`/authentication/${authenticationId}/image_upload`);
    }
  };

  const handleFlowNext = () => {
    if (qrScanner) {
      qrScanner.destroy();
    }
    flowNext();
  };

  const skip = () => {
    log('skip');
    handleFlowNext();
  };

  const handleSkip = () => {
    if (uploadedImagesCount < minImages) {
      setLaterPopupIsOpen(true);
    } else {
      skip();
    }
  };

  useEffect(() => {
    if (!isLoading && imagePreview) {
      setImagePreview(null);
    }
  }, [isLoading]);

  useEffect(() => {
    if (!imagePreview && qrScanner && !qrDetected) {
      qrScanner.start();
    }
  }, [imagePreview]);

  return (
    <Portal>
      <Camera
        ref={cameraRef}
        captureAudio={false}
        orientation="portrait"
        imageSmoothing
        onReady={handleCameraReady}
      >
        <div id="qr-reader" />
        <Overlay>
          <Header>
            <ButtonSimple
              colorType={colorType}
              onClick={handleBack}
              width={30}
              height={30}
              backgroundOpacity={1}
            >
              <Text colorType={colorType}>
                <ChevronLeft
                  colorType={colorType}
                  width="30px"
                  height="30px"
                  style={{ minWidth: 30, minHeight: 30 }}
                />
              </Text>
            </ButtonSimple>
            <Button
              colorType={colorType}
              rounded
              noPadding
              noMargin
              widthAuto
              onClick={handleSkip}
              style={{ minHeight: 0, height: 30, padding: '0 10px' }}
            >
              <FormattedMessage id={messages.skip} defaultMessage={messages.skip} />
            </Button>
          </Header>
          <Body>
            {isLoading || isShooting ? (
              <LoadingIndicator />
            ) : (
              <>
                {maxImages > 1 && (
                  <Thumbnails colorType={colorType}>
                    {uploadedImages &&
                      uploadedImages.map(({ imageId }) => (
                        <ThumbnailWrapper colorType={colorType}>
                          <Thumbnail url={`/image/v4/show?imageId=${imageId}&size=thumb`} />
                        </ThumbnailWrapper>
                      ))}
                    {[
                      ...Array(
                        uploadedImagesCount < maxImages ? maxImages - uploadedImagesCount : 0,
                      ),
                    ].map(() => (
                      <ThumbnailWrapper />
                    ))}
                  </Thumbnails>
                )}
                <FrameWrapper colorType={colorType}>
                  {isPreprocessingActive && !qrDetected && (
                    <ScanAnimationSvg src={ScanAnimation} source={{ uri: ScanAnimation }} />
                  )}
                  <FrameSvg
                    src={actualFrame}
                    source={{ uri: actualFrame }}
                    colorType={colorType}
                    active={qrDetected}
                  />
                </FrameWrapper>
                <Text colorType={colorType} textAlign="center">
                  <FormattedMessage id={actualCaptionLabel} defaultMessage={actualCaptionLabel} />
                </Text>
              </>
            )}
          </Body>
          <Footer colorType={colorType}>
            <LibrarySelect colorType={colorType} onChange={handleSelectImage} />
            {!isPreprocessingActive ? (
              <ButtonFooter
                colorType={colorType}
                onClick={handleShot}
                width={70}
                height={70}
                backgroundOpacity={1}
              >
                <Shot colorType={colorType} />
              </ButtonFooter>
            ) : (
              <Div style={{ width: '70px', height: '70px' }} />
            )}
            <ButtonFooter
              onClick={() => setHelpModalIsOpen(true)}
              width={45}
              height={45}
              backgroundOpacity={1}
              colorType={colorType}
            >
              <Help colorType={colorType} />
            </ButtonFooter>
          </Footer>
          <LaterPopup
            colorType={colorType}
            isOpen={laterPopupIsOpen}
            setIsOpen={setLaterPopupIsOpen}
            handleSubmit={skip}
            message={
              <FormattedMessage
                id={messages.skipPopup.message}
                defaultMessage={messages.skipPopup.message}
              />
            }
            primaryButtonMessage={
              <FormattedMessage
                id={messages.skipPopup.primaryButton}
                defaultMessage={messages.skipPopup.primaryButton}
              />
            }
            secondaryButtonMessage={
              <FormattedMessage
                id={messages.skipPopup.secondaryButton}
                defaultMessage={messages.skipPopup.secondaryButton}
              />
            }
          />
          <Modal fullScreen page isOpen={isLoading} onClose={handleRetake}>
            <ModalBody>
              <SendPhoto width={72} height={72} />
              <ProgressBar interval={3000} />
              <Text>
                <FormattedMessage id={messages.progress} defaultMessage={messages.progress} />
              </Text>
            </ModalBody>
          </Modal>
          <Modal fullScreen page isOpen={imagePreview && !isLoading} onClose={handleRetake}>
            <ModalBody>
              <ImageWrapper marginBottom="m">
                <ImagePreview src={imagePreview?.src} source={{ uri: imagePreview?.uri }} />
              </ImageWrapper>
              <FlowButton
                onClick={handleConfirm}
                noMargin
                style={{ width: '100%' }}
                disabled={!imagePreview}
              >
                <FormattedMessage id={messages.confirm} defaultMessage={messages.confirm} />
              </FlowButton>
              <Button buttonType="link" arrow={false} onClick={handleRetake} noMargin>
                <FormattedMessage id={messages.retake} defaultMessage={messages.retake} />
              </Button>
            </ModalBody>
          </Modal>
          <PreprocessingTimeoutModal
            isOpen={preprocessingScanTimeout}
            messages={messages}
            onRetry={() => {
              log('preprocessingRetry');
              preprocessingScanStart();
            }}
            onSkip={handleSkip}
          />
          <UploadSorryModal
            isOpen={tryAgain}
            closeModal={() => {
              setQrDetected(false);
              setQrDetectedData(null);
              history.push('?', { tryAgain: false });
            }}
            messages={messages}
          />
          <HelpModal
            isOpen={helpModalIsOpen}
            onClose={() => setHelpModalIsOpen(false)}
            messages={messages.help}
          />
        </Overlay>
      </Camera>
    </Portal>
  );
};

CameraRoute.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.object,
  flowNext: PropTypes.func,
  messages: PropTypes.object,
  log: PropTypes.func,
};

export default CameraRoute;
