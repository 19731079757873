import { defineMessages } from 'react-intl';

export default defineMessages({
  link: {
    id: 'confirm_email.resend_code_modal.link',
    defaultMessage: 'Resend code',
  },
  title: {
    id: 'confirm_email.resend_code_modal.title',
    defaultMessage: 'Check your email',
  },
  text1: {
    id: 'confirm_email.resend_code_modal.text1',
    defaultMessage: 'We just sent you an email to {email} to confirm your address.',
  },
});
