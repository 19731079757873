import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetHeaderUrl, setHeaderUrl } from 'containers/App/actions';
import { selectHeaderUrl } from 'containers/App/selectors';

const serviceUrlMap = {
  protect: '/code',
  connect: '/connect/:id',
};

function useHeaderUrl({ service, sessionId }) {
  const dispatch = useDispatch();
  const headerUrl = useSelector(selectHeaderUrl);

  useEffect(() => {
    if (service) {
      const url = serviceUrlMap[service] ? serviceUrlMap[service].replace(':id', sessionId) : '/';
      dispatch(setHeaderUrl(url));
    }
    return () => dispatch(resetHeaderUrl());
  }, [service, sessionId]);

  return headerUrl;
}

export default useHeaderUrl;
