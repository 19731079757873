import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  selectIsAuthenticated,
  selectRedirectState,
  selectRedirectUrl,
} from 'containers/Auth/selectors';
import { push } from 'connected-react-router';
import Route from 'components/Route';

function RouteAuth({ children, isWhitelisted, path, ...rest }) {
  const dispatch = useDispatch();
  const redirectUrl = useSelector(selectRedirectUrl);
  const redirectState = useSelector(selectRedirectState);
  const isAuthenticated = useSelector(selectIsAuthenticated);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isAuthenticated && !isWhitelisted) return dispatch(push(redirectUrl || '/', redirectState));
  }, [path]);

  return <Route {...rest}>{children}</Route>;
}

RouteAuth.propTypes = {
  children: PropTypes.node,
  isWhitelisted: PropTypes.bool,
  path: PropTypes.string,
};

export default RouteAuth;
