import React from 'react';
import { Flex, Tabs, Div } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import RetailerInput from 'components/RetailerInput';
import RetailerOnlineInput from 'components/RetailerOnlineInput';
import { Store, StoreOnline } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { color, extrabold, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const ButtonLabel = styled(Div)`
  text-transform: uppercase;
  ${extrabold()}
  ${margin('s', 'left')}
`;

const Tab = styled(Tabs.Tab)`
  border: none;
  background-color: ${color.lightGrey};
  color: ${color.white};
  outline: none;
`;

function SelectStore({ colorType, onSelect = () => {} }) {
  return (
    <Tabs.Tabs size="s" colorType={colorType}>
      <Tabs.TabList colorType={colorType}>
        <Tab colorType={colorType} style={{ width: '50%', marginRight: 10 }}>
          <Flex alignItems="center" justifyContent="left" marginLeft="s">
            <StoreOnline width={18} colorType={colorType} />
            <ButtonLabel>
              <FormattedMessage {...messages.online} />
            </ButtonLabel>
          </Flex>
        </Tab>
        <Tab colorType={colorType} style={{ width: '50%', marginLeft: 10, marginRight: 0 }}>
          <Flex alignItems="center" justifyContent="left" marginLeft="s">
            <Store width={18} colorType={colorType} />
            <ButtonLabel>
              <FormattedMessage {...messages.inStore} />
            </ButtonLabel>
          </Flex>
        </Tab>
      </Tabs.TabList>
      <Tabs.TabPanel colorType={colorType}>
        <RetailerOnlineInput colorType={colorType} onSelect={onSelect} />
      </Tabs.TabPanel>
      <Tabs.TabPanel colorType={colorType}>
        <RetailerInput colorType={colorType} onSelect={onSelect} />
      </Tabs.TabPanel>
    </Tabs.Tabs>
  );
}

SelectStore.propTypes = {
  colorType: PropTypes.string,
  onSelect: PropTypes.func,
};

export default SelectStore;
