import React, { useEffect } from 'react';
import { Heading, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { Link as _link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { setBack, setMenuVisibility } from 'containers/App/actions';
import { selectUser, selectProfileLoading, selectRedirectUrl } from 'containers/Auth/selectors';

import LoadingIndicator from 'components/LoadingIndicator';
import { push } from 'connected-react-router';
import messages from './messages';
import Card from '../Card';
import { Header } from '../styles';
import Form from './Form';

export const Link = styled(Text).attrs({
  as: _link,
})`
  text-align: center;
`;

function SetData({ colorType }) {
  const dispatch = useDispatch();

  const email = useSelector(selectUser);
  const redirectUrl = useSelector(selectRedirectUrl);

  useEffect(() => {
    dispatch(setMenuVisibility(false));
    dispatch(
      setBack({
        // state prop "action" to call "prev" in authentication flow
        back: redirectUrl ? push(redirectUrl, { action: 'prev' }) : 'history',
      }),
    );
    return () => dispatch(setMenuVisibility(true));
  }, []);

  const isLoading = useSelector(selectProfileLoading);
  if (isLoading) return <LoadingIndicator />;

  return (
    <>
      <Header>
        <Heading type="h1" colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.title} />
        </Heading>
      </Header>
      <Card>
        {({ colorType: colorTypeLoginWrapper }) => (
          <>
            <Text
              weight="extrabold"
              colorType={colorTypeLoginWrapper}
              textAlign="center"
              marginBottom="m"
            >
              {email}
            </Text>
            <Text
              colorType={colorTypeLoginWrapper}
              textAlign="center"
              marginBottom="m"
              style={{ fontStyle: 'italic' }}
            >
              <FormattedMessage {...messages.text1} />
            </Text>
            <Form colorType={colorTypeLoginWrapper} />
          </>
        )}
      </Card>
    </>
  );
}

SetData.propTypes = {
  colorType: PropTypes.string,
  profilePage: PropTypes.bool,
  signup: PropTypes.bool,
};

export default SetData;
