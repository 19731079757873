import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'personal_profile.title2',
    defaultMessage: 'Privacy settings',
  },
  description: {
    id: 'personal_profile.text5',
    defaultMessage: 'I authorise the following brands to use my personal information for:',
  },
  privacyUrl: {
    id: 'personal_profile.brand.text5',
    defaultMessage: 'Privacy policy',
  },
  viewAllBrands: {
    id: 'personal_profile.brand.button1',
    defaultMessage: 'View all brands',
  },
});
