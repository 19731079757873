import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectColorTypes, selectDefaultTheme, selectPoweredBy } from 'containers/App/selectors';
import { ThemeProvider } from 'styled-components';
import { composeTheme } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/utils';
import { Wrapper, LogoClg, LogoSvg, Header } from './styles';

const CardClg = forwardRef(({ children, hidePoweredBy }, ref) => {
  const isPoweredBy = useSelector(selectPoweredBy);
  const colorTypes = useSelector(selectColorTypes);
  const defaultTheme = useSelector(selectDefaultTheme);
  const colorTypeLoginWrapper = colorTypes.authLoginForm;

  const theme = composeTheme('card', defaultTheme);
  return (
    <ThemeProvider theme={theme}>
      <Wrapper ref={ref} colorType={colorTypeLoginWrapper}>
        {!!isPoweredBy && !hidePoweredBy && (
          <Header marginBottom="m">
            <LogoClg>
              <LogoSvg colorType={colorTypeLoginWrapper} />
            </LogoClg>
          </Header>
        )}
        {children({ colorType: colorTypeLoginWrapper, theme: defaultTheme })}
      </Wrapper>
    </ThemeProvider>
  );
});

CardClg.propTypes = {
  children: PropTypes.node,
  hidePoweredBy: PropTypes.bool,
};

export default CardClg;
