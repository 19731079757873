import React, { useState } from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { Modal as ModalCertilogo, Div, Flex } from '@ebay-certilogo/design-system-web';
import map from 'lodash/map';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import ContentsGroup from 'components/cms/ContentsGroup';
import isEmpty from 'lodash/isEmpty';
import DtButton from '../Button';

function Modal({
  body,
  bodyContents,
  logEventName = 'open modal',
  close,
  back,
  title,
  fullScreen,
  page,
  fullBody,
  colorType,
  button,
  propsBag,
  propsBag: { logger } = {},
  style,
  bodyStyle,
  completeAction,
  componentType,
  gtm,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const handleOnClick = () => {
    setIsOpen(true);
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        label: button?.text,
        locked: button?.locked,
        gtm,
        start: 1,
        event: { type: 'modal', name: logEventName, category: 'modal' },
        params: { label: button?.text },
      });
    }
  };

  return (
    <Div {...pick(style, ['margin', 'noMargin'])}>
      <DtButton
        onClick={handleOnClick}
        noMargin
        {...button}
        colorType={button?.colorType || colorType}
        buttonType={button?.buttonType}
      />
      <ModalCertilogo
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        colorType={colorType}
        title={title}
        close={close}
        back={back}
        fullScreen={fullScreen}
        page={page}
        fullBody={fullBody}
        lazyLoad
        {...bodyStyle}
      >
        {!isEmpty(body) && (
          <FormattedMessageMarkdown
            id={body}
            defaultMessage={body}
            colorType={colorType}
            values={propsBag}
          />
        )}
        {bodyContents?.length && (
          <Flex style={{ flexShrink: 0, height: page && '100%' }} {...bodyStyle}>
            {map(bodyContents, ({ contents_group: data }) => (
              <ContentsGroup
                data={data}
                propsBag={{ ...propsBag, showModal: setIsOpen }}
                colorType={colorType}
                completeAction={completeAction}
              />
            ))}
          </Flex>
        )}
      </ModalCertilogo>
    </Div>
  );
}

Modal.propTypes = {
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  button: PropTypes.object,
  body: PropTypes.string,
  bodyContents: PropTypes.array,
  close: PropTypes.bool,
  back: PropTypes.bool,
  title: PropTypes.string,
  fullScreen: PropTypes.bool,
  page: PropTypes.bool,
  fullBody: PropTypes.bool,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  style: PropTypes.object,
  bodyStyle: PropTypes.object,
  completeAction: PropTypes.func,
  gtm: PropTypes.object,
};

export default Modal;
