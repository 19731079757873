export default {
  startModal: 'upload_popup.message1',
  title: 'upload1.title1',
  introImagesDescription: 'upload1.text1',
  buttonStart: 'upload1.button1',
  buttonLater: 'upload1.text1',
  laterPopup: {
    message: 'upload1_later_popup.message1',
    primaryButton: 'upload1_later_popup.button1',
    secondaryButton: 'upload1_later_popup.text1',
  },
  camera: {
    help: {
      title1: 'upload1_help.title1',
      text1: 'upload1_help.text1',
      title2: 'upload1_help.title2',
      text2: 'upload1_help.text2',
      title3: 'upload1_help.title3',
      text3: 'upload1_help.text3',
    },
    skipPopup: {
      message: 'upload1_skip_popup.message1',
      primaryButton: 'upload1_skip_popup.button1',
      secondaryButton: 'upload1_skip_popup.text1',
    },
    confirm: 'upload1_prev.button1',
    retake: 'upload1_prev.text1',
    progress: 'upload2_uploading.text1',
    skip: 'upload1_camera1.text2',
    captions: [
      {
        label: 'upload1_camera1.text1',
      },
    ],
    sorry: {
      title: 'upload1_sorry.title1',
      text: 'upload1_sorry.text1',
      button: 'upload1_sorry.button1',
      link: 'upload1_sorry.text2',
    },
    preprocessingTimeoutModal: {
      text: 'preprocessingTimeout.text',
      retry: 'preprocessingTimeout.button',
      skip: 'preprocessingTimeout.link',
    },
  },
};
