/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { ButtonCta, Div } from '@ebay-certilogo/design-system-web';

export const Cta = styled(ButtonCta)`
  align-self: flex-end;
`;

export const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
`;
