import { takeLatest, all } from 'redux-saga/effects';
import { createRequest, flowRequest, downloadRequest } from '../actions';
import create from './create';
import flow from './flow';
import download from './download';

export default function* rootSaga() {
  yield all([takeLatest(createRequest, create)]);
  yield all([takeLatest(flowRequest, flow)]);
  yield all([takeLatest(downloadRequest, download)]);
}
