import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';
import Text from 'components/cms/dataTypes/Text';
import Image from 'components/cms/dataTypes/Image';

const Wrapper = styled(Div)`
  position: relative;
`;

const TextWrapper = styled(Div)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const TopText = styled(Div)`
  justify-self: flex-start;
`;

const BottomText = styled(Div)`
  margin-top: auto;
`;

function ImageTextOverlay({
  colorType,
  image,
  imageVar,
  topText,
  bottomText,
  style,
  propsBag,
  componentType,
  ...rest
}) {
  return (
    <Wrapper {...style} {...rest}>
      <Image image={image} imageVar={imageVar} componentType={componentType} />
      <TextWrapper>
        <TopText>
          {map(topText, (props) => (
            <Text {...props} colorType={colorType} propsBag={propsBag} />
          ))}
        </TopText>
        <BottomText>
          {map(bottomText, (props) => (
            <Text {...props} colorType={colorType} propsBag={propsBag} />
          ))}
        </BottomText>
      </TextWrapper>
    </Wrapper>
  );
}

ImageTextOverlay.propTypes = {
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  image: PropTypes.object,
  imageVar: PropTypes.object,
  topText: PropTypes.array,
  bottomText: PropTypes.array,
  style: PropTypes.object,
  propsBag: PropTypes.object,
};

export default ImageTextOverlay;
