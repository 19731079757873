import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams, Switch, useRouteMatch } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import authenticationSaga from 'containers/Authentication/saga';
import { selectAuthenticationLoading } from 'containers/Authentication/selectors';
import Route from 'components/Route';

import usePageType from 'components/hooks/usePageType';
import useColorType from 'components/hooks/useColorType';
import { createRequest, resetSoa } from './actions';
import saga from './saga';
import { selectSoaLoading, selectSoaData } from './selectors';
import Request from './Request';
import LabelImg from './LabelImg';
import ProductImg from './ProductImg';
import Marketplace from './Marketplace';
import Completed from './Completed';
import Closed from './Closed';
import { Wrapper } from './styles';

function Soa() {
  const colorType = useColorType('soa');
  usePageType('soa');
  useInjectSaga({ key: 'soa', saga });
  useInjectSaga({ key: 'authentication', saga: authenticationSaga });
  const { path } = useRouteMatch();

  const { authenticationId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createRequest({ authenticationId, redirect: true }));
    dispatch(resetSoa());
  }, []);

  const isLoading = useSelector(selectSoaLoading);
  const isAuthenticationLoading = useSelector(selectAuthenticationLoading);
  const data = useSelector(selectSoaData);

  if (isLoading || isAuthenticationLoading || !data) return <LoadingIndicator />;

  return (
    <Wrapper>
      <Switch>
        <Route path={`${path}/request`}>
          <Request colorType={colorType} authenticationId={authenticationId} data={data || {}} />
        </Route>
        <Route path={`${path}/label-img`}>
          <LabelImg colorType={colorType} authenticationId={authenticationId} data={data || {}} />
        </Route>
        <Route path={`${path}/product-img`}>
          <ProductImg colorType={colorType} authenticationId={authenticationId} data={data || {}} />
        </Route>
        <Route path={`${path}/alias`}>
          <Marketplace
            colorType={colorType}
            authenticationId={authenticationId}
            data={data || {}}
          />
        </Route>
        <Route path={`${path}/closed`}>
          <Closed colorType={colorType} authenticationId={authenticationId} data={data || {}} />
        </Route>
        <Route path={`${path}/completed`}>
          <Completed colorType="light" data={data || {}} />
        </Route>
      </Switch>
    </Wrapper>
  );
}

Soa.propTypes = {
  colorType: PropTypes.string,
};

export default Soa;
