import React from 'react';
import { isGoogleMapsEnabled } from 'utils/utils';

import WithoutGoogle from './WithoutGoogle';
import WithGoogle from './WithGoogle';

function RetailerInput(props) {
  if (isGoogleMapsEnabled()) return <WithGoogle {...props} />;

  return <WithoutGoogle {...props} />;
}

export default RetailerInput;
