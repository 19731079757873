import React, { useEffect, useState } from 'react';
import { Text, Heading, Button } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import {
  selectCodeLoginData,
  selectCodeLoginError,
  selectCodeLoginLoading,
  selectUserData,
} from 'containers/Auth/selectors';
import LoadingIndicator from 'components/LoadingIndicator';
import { useParams } from 'react-router-dom';
import { codeLoginCheckRequest, codeLoginCheckResponse } from 'containers/Auth/actions';
import * as get from 'lodash/get';
import history from 'utils/history';
import { isChina } from 'utils/utils';
import { useCountDown } from 'ahooks';
import messages from './messages';
import { CodeValidation, Header } from './styles';
import ResendCode from './ResendCode';
import Card from '../Card';
import ChinaConstraints from './ChinaConstraints';

function CodeLogin({ colorType }) {
  const dispatch = useDispatch();
  const { token } = useParams();
  const userData = useSelector(selectUserData);
  const email = userData?.email;
  const codeLoginData = useSelector(selectCodeLoginData);
  const error = useSelector(selectCodeLoginError);
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState('');
  const [chinaConstraints, setChinaConstraints] = useState(!isChina);

  const handleOnChange = (val) => {
    setValue(val);
  };

  const handleChangeEmail = () => {
    history.goBack();
  };

  const handleChinaConstraints = (val) => {
    setChinaConstraints(val);
  };

  useEffect(() => {
    if (token) {
      dispatch(codeLoginCheckRequest({ token, redirect: true }));
    }
  }, [token]);

  useEffect(() => {
    if (isChina) {
      setChinaConstraints(!codeLoginData?.isNew);
    }
  }, [isChina, codeLoginData?.isNew]);

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(error)) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [error]);

  useEffect(() => {
    if (value?.length === 4 && chinaConstraints) {
      dispatch(codeLoginCheckRequest({ email, pin: value, redirect: true }));
      setValue('');
    }
  }, [value]);

  useCountDown({
    leftTime: () => (codeLoginData?.timeToWait ? codeLoginData.timeToWait * 1000 : null),
    onEnd: () => dispatch(codeLoginCheckResponse({ error: null, data: { timeToWait: null } })),
  });

  const isLoading = useSelector(selectCodeLoginLoading);

  if (token) {
    return <LoadingIndicator colorType={colorType} />;
  }

  return (
    <>
      <Header>
        <Heading type="h1" colorType={colorType} marginBottom="m">
          <FormattedMessage {...messages.title} />
        </Heading>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.description} />
        </Text>
      </Header>
      <Card>
        {({ colorType: colorTypeLoginWrapper }) => (
          <>
            {codeLoginData?.isNew && (
              <ChinaConstraints
                colorType={colorTypeLoginWrapper}
                onChange={handleChinaConstraints}
              />
            )}
            {isLoading ? (
              <LoadingIndicator colorType={colorTypeLoginWrapper} />
            ) : (
              chinaConstraints && (
                <>
                  <Heading
                    type="h2"
                    colorType={colorTypeLoginWrapper}
                    textAlign="center"
                    marginBottom="m"
                  >
                    <FormattedMessage {...messages.card.title} />
                  </Heading>
                  <Text colorType={colorTypeLoginWrapper} textAlign="center">
                    <FormattedMessage {...messages.card.text1} />
                  </Text>
                  <Text
                    weight="extrabold"
                    colorType={colorTypeLoginWrapper}
                    textAlign="center"
                    marginBottom="m"
                  >
                    {email}
                  </Text>
                  {error !== 'too_many_requests' && (
                    <CodeValidation
                      isError={isError}
                      colorType={colorTypeLoginWrapper}
                      isInputNum
                      shouldAutoFocus
                      onChange={handleOnChange}
                      value={value}
                      disa
                    />
                  )}
                  {error && (
                    <Text themeField="form.errorColor" textAlign="center" marginTop="m">
                      <FormattedMessage
                        {...get(messages, `errors.pin.${error}`)}
                        values={{ timeToWait: codeLoginData?.timeToWait }}
                      />
                    </Text>
                  )}
                  <ResendCode colorType={colorTypeLoginWrapper} />
                  <Button
                    buttonType="link"
                    colorType={colorTypeLoginWrapper}
                    arrow={false}
                    noMargin
                    onClick={handleChangeEmail}
                  >
                    <FormattedMessage {...messages.card.changeEmail} />
                  </Button>
                </>
              )
            )}
          </>
        )}
      </Card>
    </>
  );
}

CodeLogin.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  type: PropTypes.string,
  primaryBrand: PropTypes.string,
  secondaryBrand: PropTypes.string,
};

export default CodeLogin;
