import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fake_report.user.title1',
    defaultMessage: 'Buyer information',
  },
  text1: {
    id: 'fake_report.user.text1',
    defaultMessage:
      'Specify who made the purchase. (In the case of a gift this may be different to your details). *Required',
  },
  givenName: {
    id: 'fake_report.user.givenName',
    defaultMessage: 'First name',
  },
  familyName: {
    id: 'fake_report.user.familyName',
    defaultMessage: 'Last name',
  },
  birthdate: {
    id: 'fake_report.user.birthdate',
    defaultMessage: 'Birth date',
  },
  continue: {
    id: 'fake_report.user.button1',
    defaultMessage: 'continue',
  },
});
