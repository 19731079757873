import React, { useEffect } from 'react';
import { Box, Stepper, Text, FormFormik, Flex } from '@ebay-certilogo/design-system-web';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest, imageUploadRequest } from 'containers/Soa/actions';
import { setBack } from 'containers/App/actions';
import { selectSoaUploadLoading } from 'containers/Soa/selectors';
import FormikAutoSave from 'components/FormikAutoSave';
import Form from 'components/form/FormComponent';
import withColorType from 'components/HOC/withColorType';
import messages from './messages';
import { actualStep, totalSteps } from '../utils';

const FILE_SIZE = 14999999;

const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const { FileUpload } = FormFormik;

const validationSchema = Yup.object().shape({
  file: Yup.mixed()
    .test(
      'fileSize',
      <FormattedMessage {...messages.sizeError} />,
      (value) => value && (value.size || value.fileSize) <= FILE_SIZE,
    )
    .test(
      'fileFormat',
      <FormattedMessage {...messages.typeError} />,
      (value) => value && SUPPORTED_FORMATS.includes(value.type),
    ),
});

function ProductImg({ colorType, authenticationId, data }) {
  const { id, page, status, code } = data;
  const certilogoCode = get(code, 'certilogoCode');
  const customerDescription = get(code, 'product.customerDescription');
  const primaryBrandName = get(code, 'brandName');
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            authenticationId,
            id,
            status,
            page,
            data: { id: data.id },
          }),
        }),
      );
    }
  }, [data]);

  const sendImage = ({ file }) => {
    dispatch(
      imageUploadRequest({
        file,
        soaId: id,
        type: 'product',
        nextOption: { action: 'next', authenticationId, id, status, page, data: { id: data.id } },
      }),
    );
  };

  const isLoading = useSelector((state) => selectSoaUploadLoading(state));

  return (
    <>
      <Box colorType={colorType} title={<FormattedMessage {...messages.title} />}>
        <Flex flexDirection="column" width="100%">
          <Flex>
            <Text colorType={colorType}>
              {primaryBrandName} {customerDescription}
            </Text>
          </Flex>
          <Flex>
            <Text colorType={colorType}>CLG {certilogoCode}</Text>
          </Flex>
        </Flex>
      </Box>

      <Stepper totalSteps={totalSteps} actualStep={actualStep[page]} colorType={colorType} />

      <Text colorType={colorType} margin={{ size: 'm', position: 'bottom' }}>
        <FormattedMessage {...messages.description} />
      </Text>

      <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={sendImage}>
        <Form>
          <Field
            name="file"
            component={FileUpload}
            colorType={colorType}
            onChangeCallback={sendImage}
            buttonLabel={<FormattedMessage {...messages.uploadPictures} />}
            isLoading={isLoading}
            overrideSubmitted
            noMargin
          />

          <FormikAutoSave onSave={sendImage} />
        </Form>
      </Formik>
    </>
  );
}

ProductImg.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    page: PropTypes.string,
    id: PropTypes.number,
    productImageLoaded: PropTypes.string,
    code: PropTypes.shape({
      certilogoCode: PropTypes.string,
      product: PropTypes.object,
    }),
  }),
};

export default withColorType(ProductImg);
