/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import ImageComp from 'components/Image';
import { margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { Div } from '@ebay-certilogo/design-system-web';

export const ImageWrapper = styled(Div)`
  width: calc(100% + 40px);
  margin-left: -20px;
  height: 300px;
  position: relative;
  overflow: hidden;
  ${margin('s', 'bottom')}
`;

export const Image = styled(ImageComp)`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
