import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import withColorType from 'components/HOC/withColorType';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import ButtonHomeConnect from 'components/ButtonHomeConnect';
import { Title, Text, Wrapper } from './styles';
import messages from './messages';

const ButtonHome = styled(ButtonHomeConnect)`
  position: fixed;
  bottom: 40px;
  right: 0;
  z-index: 1000;
`;

function Completed({ colorType, data }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(unsetBack());
  }, []);
  return (
    <Wrapper>
      <Title type="big" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.done} />
      </Title>
      <Title type="h1" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.checkYourEmail} />
      </Title>
      <Text colorType={colorType}>
        <FormattedMessage {...messages.sentEmailMessages} />
      </Text>
      <ButtonHome sessionId={data?.authentication?.sessionId} />
    </Wrapper>
  );
}

Completed.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.object,
};

export default withColorType(Completed);
