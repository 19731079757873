import { defineMessages } from 'react-intl';

export default defineMessages({
  placeholder: {
    id: 'where_offline_geo.placeholder1',
    defaultMessage: 'Enter website name or URL',
  },
  skip: {
    id: 'where_offline_geo.button1',
    defaultMessage: 'Skip',
  },
});
