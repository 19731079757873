import produce from 'immer';
import { handleActions } from 'redux-actions';
import {
  signUpLoginRequest,
  signUpLoginResponse,
  loginRequest,
  loginResponse,
  forgotPasswordRequest,
  forgotPasswordResponse,
  tokenVerifyRequest,
  tokenVerifyResponse,
  passwordUpdateRequest,
  getProfileRequest,
  setProfileRequest,
  profileResponse,
  getPrivacyPolicySignupConfigRequest,
  getPrivacyPolicySignupConfigResponse,
  getOptinRequest,
  optinResponse,
  setRedirect,
  setAuthErrors,
  setIsAuthenticated,
  logout,
  resetLogin,
  confirmEmailRequest,
  confirmEmailResponse,
  changeEmailRequest,
  changeEmailResponse,
  codeLoginRequest,
  codeLoginResponse,
  codeLoginCheckRequest,
  codeLoginCheckResponse,
  setBrand,
  setRedirectError,
} from './actions';

export const initialState = {
  loading: false,
  data: null,
  loginLoading: false,
  forgotLoading: false,
  tokenVerifyLoading: false,
  tokenVerifyMail: null,
  passwordUpdateLoading: false,
  profileLoading: false,
  errors: null,

  isAuthenticated: false,
  isConfirmed: null,
  jwt: null,
  user: null,
  roles: null,
  qaBrands: [],
  redirect: {
    url: null,
    state: null,
  },
  redirectError: {
    url: null,
    state: null,
  },
  profile: null,

  brand: {
    primaryBrand: null,
    secondaryBrand: null,
  },

  privacyPolicySignupConfig: {
    loading: false,
    data: null,
  },

  optin: {
    loading: false,
    data: null,
  },

  confirmEmail: {
    loading: false,
    errors: null,
  },

  changeEmail: {
    loading: false,
    errors: null,
  },

  codeLogin: {
    loading: false,
    data: null,
    error: null,
    requestError: {
      error: null,
      timeToWait: null,
    },
  },
};

const mutableReducer = handleActions(
  {
    [setBrand]: produce((draft, { payload: { primaryBrand, secondaryBrand } }) => {
      draft.brand.primaryBrand = primaryBrand;
      draft.brand.secondaryBrand = secondaryBrand;
    }),
    [loginRequest]: produce((draft) => {
      draft.loginLoading = true;
    }),
    [loginResponse]: produce((draft) => {
      draft.loginLoading = false;
    }),
    [codeLoginRequest]: produce((draft) => {
      draft.codeLogin.loading = true;
      draft.codeLogin.error = null;
      draft.codeLogin.requestError.error = null;
    }),
    [codeLoginResponse]: produce((draft, { payload: { email, error, timeToWait, data } }) => {
      draft.codeLogin.loading = false;
      draft.codeLogin.requestError.error = error;
      draft.codeLogin.requestError.timeToWait = timeToWait;
      draft.codeLogin.data = data;
      if (email) draft.data = { user: { email } };
    }),
    [codeLoginCheckRequest]: produce((draft) => {
      draft.codeLogin.loading = true;
    }),
    [codeLoginCheckResponse]: produce((draft, { payload: { error, data } }) => {
      draft.codeLogin.loading = false;
      draft.codeLogin.error = error;
      draft.codeLogin.data = data;
    }),
    [signUpLoginRequest]: produce((draft) => {
      draft.loading = true;
    }),
    [signUpLoginResponse]: produce((draft, { payload: { setPasword, setData, user, mailgun } }) => {
      draft.loading = false;
      draft.passwordUpdateLoading = false;
      draft.data = { setPasword, setData, user, mailgun };
    }),
    [forgotPasswordRequest]: produce((draft) => {
      draft.forgotLoading = true;
    }),
    [forgotPasswordResponse]: produce((draft) => {
      draft.forgotLoading = false;
    }),
    [tokenVerifyRequest]: produce((draft) => {
      draft.tokenVerifyLoading = true;
    }),
    [tokenVerifyResponse]: produce((draft, { payload: { email } }) => {
      draft.tokenVerifyLoading = false;
      draft.tokenVerifyMail = email;
    }),
    [passwordUpdateRequest]: produce((draft) => {
      draft.passwordUpdateLoading = true;
    }),
    [getProfileRequest]: produce((draft) => {
      draft.profileLoading = true;
    }),
    [setProfileRequest]: produce((draft) => {
      draft.profileLoading = true;
    }),
    [profileResponse]: produce((draft, { payload: { data } }) => {
      draft.profileLoading = false;
      draft.profile = data;
    }),
    [getPrivacyPolicySignupConfigRequest]: produce((draft) => {
      draft.privacyPolicySignupConfig.loading = true;
    }),
    [getPrivacyPolicySignupConfigResponse]: produce((draft, { payload: { data } }) => {
      draft.privacyPolicySignupConfig.loading = false;
      draft.privacyPolicySignupConfig.data = data;
    }),
    [getOptinRequest]: produce((draft) => {
      draft.optin.loading = true;
    }),
    [optinResponse]: produce((draft, { payload: { data } }) => {
      draft.optin.loading = false;
      draft.optin.data = data;
    }),
    [setRedirect]: produce((draft, { payload: { url, state } }) => {
      draft.redirect.url = url;
      draft.redirect.state = state;
    }),
    [setRedirectError]: produce((draft, { payload: { url, state } }) => {
      draft.redirectError.url = url;
      draft.redirectError.state = state;
    }),
    [setAuthErrors]: produce((draft, { payload: { errors } }) => {
      draft.errors = errors;
    }),

    [setIsAuthenticated]: produce(
      (draft, { payload: { token, roles, user, qaBrands, isConfirmed } }) => {
        draft.isAuthenticated = !!token;
        draft.isConfirmed = isConfirmed;
        draft.jwt = token;
        draft.user = user;
        draft.roles = roles;
        draft.qaBrands = qaBrands;
      },
    ),
    [logout]: produce(() => {
      return initialState;
    }),
    [resetLogin]: produce(() => {
      return initialState;
    }),
    [confirmEmailRequest]: produce((draft) => {
      draft.confirmEmail.loading = true;
    }),
    [confirmEmailResponse]: produce((draft, { payload: { errors } }) => {
      draft.confirmEmail.loading = false;
      draft.confirmEmail.errors = errors;
    }),
    [changeEmailRequest]: produce((draft) => {
      draft.changeEmail.loading = true;
    }),
    [changeEmailResponse]: produce((draft, { payload: { errors } }) => {
      draft.changeEmail.loading = false;
      draft.changeEmail.errors = errors;
    }),
  },
  initialState,
);

export default mutableReducer;
