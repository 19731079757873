import { defineMessages } from 'react-intl';

export default defineMessages({
  pipl: {
    id: 'login_signup.pipl',
    defaultMessage:
      '我同意按照《[个人信息处理规则-标准版]({privacyUrl})》告知的内容跨境 转移本人的个人信息,以确保全球服务标准的一致性',
  },
  cnPrivacyTerms: {
    id: 'login_signup.cnPrivacyTerms',
    defaultMessage:
      '注册即表示你同意[我们的条款]({termsUrl})以及[隐私政策]({privacyUrl})。请确认你已满18周岁。未经你的许可',
  },
});
