import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import history from 'utils/history';
import { Link } from 'react-router-dom';
import useColorType from 'components/hooks/useColorType';
import usePageType from 'components/hooks/usePageType';
import { get } from 'lodash';
import messages from './messages';
import { selectIsAuthenticated } from '../selectors';

function Info({ colorType }) {
  usePageType('authInfo');
  useColorType('light');
  const dispatch = useDispatch();
  const isAutenticated = useSelector(selectIsAuthenticated);

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  return (
    <>
      <Box
        colorType={colorType}
        text={<FormattedMessage {...get(messages, `title${isAutenticated ? 'Auth' : ''}`)} />}
        margin={{ size: 'm', position: 'bottom' }}
        monochrome
      />
      {isAutenticated && (
        <Button to="/auth/profile" as={Link} colorType={colorType} noMargin>
          <FormattedMessage {...messages.myProfile} />
        </Button>
      )}
      <Button
        as="a"
        href="https://discover.certilogo.com/faq-people"
        target="_blank"
        colorType={colorType}
        noMargin
      >
        <FormattedMessage {...messages.faq} />
      </Button>
      <Button
        as="a"
        href="https://discover.certilogo.com/people"
        target="_blank"
        colorType={colorType}
        noMargin
      >
        <FormattedMessage {...messages.moreAboutService} />
      </Button>
      <Button
        colorType={colorType}
        buttonType="link"
        onClick={() => history.goBack()}
        arrow={false}
        noMargin
      >
        <FormattedMessage {...messages.back} />
      </Button>
    </>
  );
}

Info.propTypes = {
  colorType: PropTypes.string,
};

export default Info;
