import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import { Div, Select, Tabs as TabsDS } from '@ebay-certilogo/design-system-web';
import { margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import map from 'lodash/map';
import ContentsGroup from 'components/cms/ContentsGroup';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { TabList } from './styles';

const SelectWrapper = styled(Div)`
  ${margin('m', 'bottom')}
  [class$='-control'] {
    border: 2px solid;
    &:hover {
      border: 2px solid;
    }
  }
`;

function Tabs({
  tab,
  logEventName = 'open tab',
  colorType,
  propsBag,
  propsBag: { logger } = {},
  style,
  componentType,
  completeAction,
  gtm,
  ...rest
}) {
  const selectorType = rest.selectorType === null ? 'tab' : rest.selectorType;
  const [tabIndex, setTabIndex] = useState(selectorType === 'select' ? null : 0);
  const [rendered, setRendered] = useState(false);
  const [showTabs, setShowTabs] = useState(tab.map((obj) => !!obj));
  const handleOnSelect = (index) => {
    setTabIndex(index);
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        label: tab[index].title,
        gtm,
        start: 1,
        end: 1,
        event: { type: 'tabs', name: logEventName, category: 'tabs' },
      });
    }
  };

  const filteredTabs = _(tab)
    .map(
      ({ title }, index) =>
        showTabs[index] && <FormattedMessage id={title} defaultMessage={title} />,
    )
    .filter((val) => val !== false)
    .value();

  return (
    <TabsDS.Tabs
      colorType={colorType}
      {...style}
      selectedIndex={!_.isNull(tabIndex) ? tabIndex : -1}
      onSelect={handleOnSelect}
      domRef={(nd) => {
        if (!rendered && nd) {
          _.forEach(nd.getElementsByClassName('tab-panel'), (el, index) => {
            if (el.querySelectorAll('div').length === 1) {
              const newTabs = showTabs;
              newTabs[index] = false;
              setShowTabs(newTabs);
            }
          });
        }
        setRendered(true);
      }}
    >
      {selectorType === 'tab' && (
        <TabList colorType={colorType}>
          {map(filteredTabs, (label) => (
            <TabsDS.Tab>{label}</TabsDS.Tab>
          ))}
        </TabList>
      )}
      {selectorType === 'select' && (
        <SelectWrapper>
          <Select
            colorType={colorType}
            options={map(filteredTabs, (label, index) => ({
              value: index,
              label,
            }))}
            onChange={({ value }) => handleOnSelect(value)}
            isSearchable={false}
            placeholder={
              tabIndex ? (
                tab[tabIndex]?.title && (
                  <FormattedMessage
                    id={tab[tabIndex]?.title}
                    defaultMessage={tab[tabIndex]?.title}
                  />
                )
              ) : (
                <FormattedMessage {...messages.selectPlaceholder} />
              )
            }
          />
        </SelectWrapper>
      )}

      {map(
        tab,
        ({ bodyContents }, index) =>
          showTabs[index] && (
            <TabsDS.TabPanel colorType={colorType} className="tab-panel" forceRender>
              {map(bodyContents, ({ contents_group: data }) => (
                <ContentsGroup
                  data={data}
                  propsBag={propsBag}
                  colorType={colorType}
                  completeAction={completeAction}
                />
              ))}
            </TabsDS.TabPanel>
          ),
      )}
    </TabsDS.Tabs>
  );
}

Tabs.propTypes = {
  colorType: PropTypes.string,
  componentType: PropTypes.string,
  tab: PropTypes.array,
  selectorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  style: PropTypes.object,
  completeAction: PropTypes.func,
  gtm: PropTypes.object,
};

export default Tabs;
