import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unsetPrompt, setPrompt } from 'containers/App/actions';
import { selectPromptIsActive } from 'containers/App/selectors';

function usePrompt(data) {
  const dispatch = useDispatch();
  const promptIsActive = useSelector(selectPromptIsActive);

  const componentWillUnmount = () => {
    dispatch(unsetPrompt());
  };

  useEffect(() => {
    if (data) dispatch(setPrompt());
    return componentWillUnmount;
  }, [data]);

  return promptIsActive;
}

export default usePrompt;
