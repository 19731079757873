import { defineMessages } from 'react-intl';

export default defineMessages({
  done: {
    id: 'soa_complete.title1',
    defaultMessage: 'Done',
  },
  checkYourEmail: {
    id: 'soa_complete.text1',
    defaultMessage: 'You have created a Certilogo Seal for your listing.',
  },
  sentEmailMessages: {
    id: 'soa_complete.text2',
    defaultMessage: 'We’ll send you an email when your Seal is active for use by buyers.',
  },
});
