import React from 'react';
import PropTypes from 'prop-types';
import { Text as TextDS } from '@ebay-certilogo/design-system-web';

const Text = ({ value, colorType, ...rest }) => (
  <TextDS colorType={colorType} style={{ textTransform: 'none' }} {...rest}>
    {value}
  </TextDS>
);

Text.propTypes = {
  colorType: PropTypes.string,
  value: PropTypes.string,
};

export default Text;
