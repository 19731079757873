import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getResponse } from '../actions';

export default function* get({ payload: { id, service, sessionId } }) {
  const url = 'survey/load';
  try {
    const data = yield call(fetchWrapper, {
      method: 'GET',
      url,
      withQuery: true,
      params: {
        id,
        service,
        sessionId,
      },
    });

    yield put(getResponse({ id, service, data }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
    yield put(getResponse(null));
  }
}
