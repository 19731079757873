import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { getChannel } from 'utils/utils';
import { createResponse } from '../actions';

export default function* create({ payload: { authenticationId } }) {
  const url = 'fakeReport/v3/create';
  try {
    const data = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        authenticationId,
        fakeReportVersion: 3,
        channel: getChannel(),
      },
    });

    yield put(createResponse(data));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
