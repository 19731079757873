export default {
  imp_not_owned: {
    title: '',
    subtitle: 'imp_not_owned.title1',
    description: 'imp_not_owned.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_owned.button1',
        url: 'mailto:?subject=New buyer - Request for a Certilogo Seal of Authentication&body=Hi,%0D%0A%0D%0AI am interested in purchasing your product, which you have published together with the photo of its Certilogo code.%0D%0A%0D%0AI tried to authenticate it on Certilogo and learned that items advertised online can only be authenticated with a Certilogo Seal of Authentication (SOA) https://discover.certilogo.com/en/seal-of-authentication. The SOA is a sort of “online Avatar” of the CLG Code and is designed to avoid the publication of the CLG Code itself, which violates the service’s Terms and Conditions.%0D%0A%0D%0AJust authenticate the product again and create a Seal of Authentication (SOA) when asked. Once you receive it, you’ll be able to publish it in your listing and allow buyers like me to verify authenticity.%0D%0A%0D%0AI really appreciate your availability and I look forward to being able to authenticate and make my purchase with you!%0D%0A%0D%0AMany thanks,%0D%0A%0D%0A',
      },
    ],
  },
  imp_suspect: {
    title: '',
    subtitle: 'imp_suspect.title1',
    description: 'imp_suspect.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_suspect.button1',
        url: '/code',
      },
    ],
  },
  imp_service_closed: {
    title: '',
    subtitle: 'imp_service_closed.title1',
    description: 'imp_service_closed.text1',
  },
  imp_invalid: {
    title: '',
    subtitle: 'imp_invalid.title1',
    description: 'imp_invalid.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_invalid.button1',
        url: '/code',
      },
    ],
  },
  imp_stolen: {
    title: '',
    subtitle: 'imp_stolen.title1',
    description: 'imp_stolen.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_stolen.button1',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_blocked: {
    title: '',
    subtitle: 'imp_blocked.title1',
    description: 'imp_blocked.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_blocked.button1',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_not_active: {
    title: '',
    subtitle: 'imp_not_active.title1',
    description: 'imp_not_active.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_active.button1',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_suspect_appeal: {
    title: '',
    subtitle: 'imp_suspect_appeal.title1',
    description: 'imp_suspect_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_suspect_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_suspect_fake_appeal: {
    title: '',
    subtitle: 'imp_suspect_fake_appeal.title1',
    description: 'imp_suspect_fake_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_suspect_fake_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_expired: {
    title: '',
    subtitle: 'imp_expired.title1',
    description: 'imp_expired.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_expired.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_not_active_campagnolo_fulcrum: {
    title: '',
    subtitle: 'imp_not_active_campagnolo_fulcrum.title1',
    description: 'imp_not_active_campagnolo_fulcrum.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_active_campagnolo_fulcrum.button1',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_wrong_marketplace: {
    title: '',
    subtitle: 'imp_wrong_marketplace.title1',
    description: 'imp_wrong_marketplace.text1',
  },
  imp_wrong_alias: {
    title: '',
    subtitle: 'imp_wrong_alias.title1',
    description: 'imp_wrong_alias.text1',
  },
  imp_new_owner_claim: {
    title: '',
    subtitle: 'imp_new_owner_claim.title1',
    description: 'imp_new_owner_claim.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_new_owner_claim.button1',
        url: '/code',
      },
    ],
  },
  imp_soa_wrong_product: {
    title: '',
    subtitle: 'imp_soa_wrong_product.title1',
    description: 'imp_soa_wrong_product.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_soa_wrong_product.button1',
        url: '/code',
      },
    ],
  },
  imp_new_owner_claim_appeal: {
    title: '',
    subtitle: 'imp_new_owner_claim_appeal.title1',
    description: 'imp_new_owner_claim_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_new_owner_claim_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_soa_not_active_yet: {
    title: '',
    subtitle: 'imp_soa_not_active_yet.title1',
    description: 'imp_soa_not_active_yet.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_soa_not_active_yet.button1',
        url: 'https://discover.certilogo.com/en/certilogo-seal-of-authentication',
      },
    ],
  },
  imp_invalid_protected_store: {
    title: '',
    subtitle: 'imp_invalid_protected_store.title1',
    description: 'imp_invalid_protected_store.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_invalid_protected_store.button1',
        url: '/code',
      },
    ],
  },
  imp_marked_protected_store: {
    title: '',
    subtitle: 'imp_marked_protected_store.title1',
    description: 'imp_marked_protected_store.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_marked_protected_store.button1',
        url: '/code',
      },
    ],
  },
  imp_blocked_nobis_korea: {
    title: '',
    subtitle: 'imp_blocked_nobis_korea.title1',
    description: 'imp_blocked_nobis_korea.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_blocked_nobis_korea.button1',
        url: 'https://nobis.ca',
      },
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_blocked_nobis_korea.button2',
        url: 'mailto:info@nobis.ca',
      },
    ],
  },
  imp_promo_code: {
    title: '',
    subtitle: 'imp_promo_code.title1',
    description: 'imp_promo_code.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_promo_code.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_invalid_parajumpers: {
    title: '',
    subtitle: 'imp_invalid_parajumpers.title1',
    description: 'imp_invalid_parajumpers.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_invalid_parajumpers.button1',
        url: 'http://www.parajumpersstopfakes.com',
      },
    ],
  },
  imp_not_owned_protected_store: {
    title: '',
    subtitle: 'imp_not_owned_protected_store.title1',
    description: 'imp_not_owned_protected_store.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_owned_protected_store.button1',
        url: '/code',
      },
    ],
  },
  imp_promo_expired: {
    title: '',
    subtitle: 'imp_promo_expired.title1',
    description: 'imp_promo_expired.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_promo_expired.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_not_active_appeal: {
    title: '',
    subtitle: 'imp_not_active_appeal.title1',
    description: 'imp_not_active_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_active_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_partner_appeal: {
    title: '',
    subtitle: 'imp_partner_appeal.title1',
    description: 'imp_partner_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_partner_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_promo_code_fossil: {
    title: '',
    subtitle: 'imp_promo_code_fossil.title1',
    description: 'imp_promo_code_fossil.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_promo_code_fossil.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_nfc_expired: {
    title: '',
    subtitle: 'imp_nfc_expired.title1',
    description: 'imp_nfc_expired.text1',
    cta: [
      {
        type: 'data-types.modal',
        triggerType: 'button_cta', // ['button', 'button_cta', 'text']
        triggerLabel: 'imp_nfc_expired.button1',
        body: 'imp_nfc_expired_modal.text1',
      },
    ],
  },
  imp_nfc_fake: {
    title: '',
    subtitle: 'imp_nfc_fake.title1',
    description: 'imp_nfc_fake.text1',
    cta: [
      {
        type: 'data-types.modal',
        triggerType: 'button_cta', // ['button', 'button_cta', 'text']
        triggerLabel: 'imp_nfc_expired.button1',
        body: 'imp_nfc_expired_modal.text1',
      },
    ],
  },
  imp_human_review: {
    title: '',
    subtitle: 'imp_human_review.title1',
    description: 'imp_human_review.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_human_review.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_invalid_80db: {
    title: '',
    subtitle: 'imp_invalid_80db.title1',
    description: 'imp_invalid_80db.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_invalid_80db.button1',
        url: '/code',
      },
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_invalid_80db.button2',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_demo_cta_nfc: {
    title: '',
    subtitle: 'imp_demo_cta_nfc.title1',
    description: 'imp_demo_cta_nfc.text1',
  },
  imp_other_brand: {
    title: '',
    subtitle: 'imp_other_brand.title1',
    description: 'imp_other_brand.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_other_brand.button1',
        url: 'https://www.certilogo.com',
      },
    ],
  },
  imp_b2c_code: {
    title: '',
    subtitle: 'imp_b2c_code.title1',
    description: 'imp_b2c_code.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_b2c_code.button1',
        url: 'https://www.certilogo.com',
      },
    ],
  },
  imp_b2b_code_qr: {
    title: '',
    subtitle: 'imp_b2b_code_qr.title1',
    description: 'imp_b2b_code_qr.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_b2b_code_qr.button1',
        url: 'https://www.certilogo.com',
      },
    ],
  },
  imp_b2b_code: {
    title: '',
    subtitle: 'imp_b2b_code.title1',
    description: 'imp_b2b_code.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_b2b_code.button1',
        url: 'http://b2b.certilogo.com',
      },
    ],
  },
  imp_invalid_brand_not_covered: {
    title: '',
    subtitle: 'imp_invalid_brand_not_covered.title1',
    description: 'imp_invalid_brand_not_covered.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_invalid_brand_not_covered.button1',
        url: 'https://discover.certilogo.com/en/certilogo-brands',
      },
    ],
  },
  imp_nfc_null: {
    title: '',
    subtitle: 'imp_nfc_null.title1',
    description: 'imp_nfc_null.text1',
    cta: [
      {
        type: 'data-types.modal',
        triggerType: 'button_cta', // ['button', 'button_cta', 'text']
        triggerLabel: 'imp_nfc_expired.button1',
        body: 'imp_nfc_expired_modal.text1',
      },
    ],
  },
  imp_suspended_manual: {
    title: '',
    subtitle: 'imp_suspended_manual.title1',
    description: 'imp_suspended_manual.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_suspended_manual.button1',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_not_associated: {
    title: '',
    subtitle: 'imp_not_associated.title1',
    description: 'imp_not_associated.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_associated.button1',
        url: 'mailto:help@certilogo.com',
      },
    ],
  },
  imp_suspect_unsure_nodpf: {
    title: '',
    subtitle: 'imp_suspect_unsure_nodpf.title1',
    description: 'imp_suspect_unsure_nodpf.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_suspect_unsure_nodpf.button1',
        url: '/code',
      },
    ],
  },
  imp_soa_wrong_product_wrong_alias: {
    title: '',
    subtitle: 'imp_soa_wrong_product_wrong_alias.title1',
    description: 'imp_soa_wrong_product_wrong_alias.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_soa_wrong_product_wrong_alias.button1',
        url: '/code',
      },
    ],
  },
  imp_soa_wrong_product_wrong_marketplace: {
    title: '',
    subtitle: 'imp_soa_wrong_product_wrong_marketplace.title1',
    description: 'imp_soa_wrong_product_wrong_marketplace.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_soa_wrong_product_wrong_marketplace.button1',
        url: '/code',
      },
    ],
  },
  imp_not_owned_unsure_protected_store_nodpf: {
    title: '',
    subtitle: 'imp_not_owned_unsure_protected_store_nodpf.title1',
    description: 'imp_not_owned_unsure_protected_store_nodpf.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_not_owned_unsure_protected_store_nodpf.button1',
        url: '/code',
      },
    ],
  },
  imp_not_owned_unsure_nodpf: {
    title: '',
    subtitle: 'imp_not_owned_unsure_nodpf.title1',
    description: 'imp_not_owned_unsure_nodpf.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_not_owned_unsure_nodpf.button1',
        url: '/code',
      },
    ],
  },
  imp_not_authentic_anymore: {
    title: '',
    subtitle: 'imp_not_authentic_anymore.title1',
    description: 'imp_not_authentic_anymore.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button',
        text: 'imp_not_authentic_anymore.button1',
        url: '/code',
      },
    ],
  },
  imp_not_owned_mismatch_appeal: {
    title: '',
    subtitle: 'imp_not_owned_mismatch_appeal.title1',
    description: 'imp_not_owned_mismatch_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_owned_mismatch_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_not_owned_mismatch_protected_store_appeal: {
    title: '',
    subtitle: 'imp_not_owned_mismatch_protected_store_appeal.title1',
    description: 'imp_not_owned_mismatch_protected_store_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_owned_mismatch_protected_store_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_nfc_expired_appeal: {
    title: '',
    subtitle: 'imp_nfc_expired_appeal.title1',
    description: 'imp_nfc_expired_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_nfc_expired_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
    '': {},
  },
  imp_nfc_fake_appeal: {
    title: '',
    subtitle: 'imp_nfc_fake_appeal.title1',
    description: 'imp_nfc_fake_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_nfc_fake_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  fake_soa_suspended: {
    title: '',
    subtitle: 'fake_soa_suspended.title1',
    description: 'fake_soa_suspended.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'fake_soa_suspended.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_soa_wrong_product_appeal: {
    title: '',
    subtitle: 'imp_soa_wrong_product_appeal.title1',
    description: 'imp_soa_wrong_product_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_soa_wrong_product_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_soa_wrong_alias_appeal: {
    title: '',
    subtitle: 'imp_soa_wrong_alias_appeal.title1',
    description: 'imp_soa_wrong_alias_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_soa_wrong_alias_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_wrong_marketplace_appeal: {
    title: '',
    subtitle: 'imp_wrong_marketplace_appeal.title1',
    description: 'imp_wrong_marketplace_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_wrong_marketplace_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_soa_wrong_product_wrong_alias_appeal: {
    title: '',
    subtitle: 'imp_soa_wrong_product_wrong_alias_appeal.title1',
    description: 'imp_soa_wrong_product_wrong_alias_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_soa_wrong_product_wrong_alias_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_soa_wrong_product_wrong_marketplace_appeal: {
    title: '',
    subtitle: 'imp_soa_wrong_product_wrong_marketplace_appeal.title1',
    description: 'imp_soa_wrong_product_wrong_marketplace_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_soa_wrong_product_wrong_marketplace_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_not_owned_unsure_appeal: {
    title: '',
    subtitle: 'imp_not_owned_unsure_appeal.title1',
    description: 'imp_not_owned_unsure_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_owned_unsure_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_suspect_unsure_appeal: {
    title: '',
    subtitle: 'imp_suspect_unsure_appeal.title1',
    description: 'imp_suspect_unsure_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_suspect_unsure_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_mismatch_appeal: {
    title: '',
    subtitle: 'imp_mismatch_appeal.title1',
    description: 'imp_mismatch_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_mismatch_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_mismatch_protected_store_appeal: {
    title: '',
    subtitle: 'imp_mismatch_protected_store_appeal.title1',
    description: 'imp_mismatch_protected_store_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_mismatch_protected_store_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_cloned_mismatch_appeal: {
    title: '',
    subtitle: 'imp_cloned_mismatch_appeal.title1',
    description: 'imp_cloned_mismatch_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_cloned_mismatch_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_cloned_unsure_appeal: {
    title: '',
    subtitle: 'imp_cloned_unsure_appeal.title1',
    description: 'imp_cloned_unsure_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_cloned_unsure_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_unsure_appeal: {
    title: '',
    subtitle: 'imp_unsure_appeal.title1',
    description: 'imp_unsure_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_unsure_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  imp_unsure_protected_store_appeal: {
    title: '',
    subtitle: 'imp_unsure_protected_store_appeal.title1',
    description: 'imp_unsure_protected_store_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_unsure_protected_store_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },

  imp_not_owned_unsure_protected_store_appeal: {
    title: '',
    subtitle: 'imp_not_owned_unsure_protected_store_appeal.title1',
    description: 'imp_not_owned_unsure_protected_store_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_owned_unsure_protected_store_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
  auth_not_owned: {
    title: '',
    subtitle: 'authentic_not_owned.title1',
    description: 'authentic_not_owned.text1',
  },
  imp_not_authentic_anymore_appeal: {
    title: '',
    subtitle: 'imp_not_authentic_anymore_appeal.title1',
    description: 'imp_not_authentic_anymore_appeal.text1',
    cta: [
      {
        type: 'data-types.button',
        buttonType: 'button_cta',
        text: 'imp_not_authentic_anymore_appeal.button1',
        url: 'https://discover.certilogo.com',
      },
    ],
  },
};
