import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Button, FormFormik } from '@ebay-certilogo/design-system-web';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { confirmAuthenticationRequest } from 'containers/Authentication/actions';
import { selectConfirmAuthenticationLoading } from 'containers/Authentication/selectors';
import { useInjectSaga } from 'utils/injectSaga';
import saga from 'containers/Authentication/saga';
import ErrorMessage from 'components/form/ErrorMessage';
import { Email } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { selectUser } from 'containers/Auth/selectors';
import Form from 'components/form/FormComponent';
import messages from './messages';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email()
    .required(<ErrorMessage type="required" />),
});

function SendEmailForm({ colorType, authenticationId, onSuccess }) {
  useInjectSaga({ key: 'authentication', saga });
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const sendLoading = useSelector(selectConfirmAuthenticationLoading);
  const userEmail = useSelector(selectUser);

  useEffect(() => {
    if (submitted && !sendLoading) {
      onSuccess();
      setSubmitted(false);
    }
  }, [sendLoading]);

  const handleSubmit = ({ email }) => {
    dispatch(confirmAuthenticationRequest({ authenticationId, email }));
    setSubmitted(true);
  };

  return (
    <Formik
      initialValues={{ email: userEmail }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      <Form>
        <FormattedMessage {...messages.emailPlaceholder}>
          {(msgs) => (
            <Field
              name="email"
              component={FormFormik.TextInput}
              colorType={colorType}
              placeholder={''.concat(...msgs)}
              icon={<Email height="18px" width="18px" colorType={colorType} />}
            />
          )}
        </FormattedMessage>
        <Button
          loading={sendLoading}
          type="submit"
          margin={{ size: 'l', position: 'bottom' }}
          colorType={colorType}
        >
          <FormattedMessage {...messages.submitButton} />
        </Button>
      </Form>
    </Formik>
  );
}

SendEmailForm.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default SendEmailForm;
