import React, { useEffect, useState, useRef } from 'react';
import { ButtonSimple, Text, FormFormik, Div, Heading } from '@ebay-certilogo/design-system-web';
import {
  ChevronRight,
  Close,
  Email,
} from '@ebay-certilogo/design-system-web/dist/components/icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import {
  selectCodeLoginLoading,
  selectErrors,
  selectRedirectUrl,
  selectUserData,
} from 'containers/Auth/selectors';
import { codeLoginRequest, resetLogin, setBrand } from 'containers/Auth/actions';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import ErrorMessage from 'components/form/ErrorMessage';
import { push } from 'connected-react-router';
import withColorType from 'components/HOC/withColorType';
import Form from 'components/form/FormComponent';
import { isEmpty } from 'lodash';
import TermsAndConditions from 'components/TermsAndConditions';
import history from 'utils/history';
import { useLocation } from 'react-router-dom';
import { isChina } from 'utils/utils';
import { selectConf } from 'containers/App/selectors';
import { Wrapper, Header } from './styles';
import AppleLogin from './AppleLogin';
import EbayLogin from './EbayLogin';
import FacebookLogin from './FacebookLogin';
import GoogleLogin from './GoogleLogin';
import messages from './messages';
import PSWLogin from './PSWLogin';
import Card from '../Card';
import MailgunError from './MailgunError';

const { TextInput } = FormFormik;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(<FormattedMessage {...messages.error_email_format} />)
    .required(<ErrorMessage type="required" />),
});

function Login({ colorType, intl, type, status }) {
  const dispatch = useDispatch();
  const cardRef = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const redirectUrl = useSelector(selectRedirectUrl);
  const errorsServer = useSelector(selectErrors);
  const user = useSelector(selectUserData);
  const authenticatorConf = useSelector(selectConf);
  const email = user?.email;
  const location = useLocation();
  const primaryBrand = location?.state?.primaryBrand;
  const secondaryBrand = location?.state?.secondaryBrand;
  const countryCode = location?.state?.countryCode;

  const defaultOauthServices = [
    {
      service: 'google',
    },
    {
      service: 'facebook',
    },
  ];

  const oauthServices = isEmpty(authenticatorConf.oauthServices)
    ? defaultOauthServices
    : authenticatorConf.oauthServices;

  const oauthServicesMapping = {
    google: <GoogleLogin type={type} setError={setError} />,
    facebook: <FacebookLogin type={type} setError={setError} />,
    ebay: (
      <EbayLogin
        type={type}
        countryCode={countryCode}
        setError={setError}
        onSubmit={() => setError(null)}
      />
    ),
    apple: <AppleLogin type={type} setError={setError} />,
  };

  useEffect(() => {
    dispatch(
      setBrand({
        primaryBrand,
        secondaryBrand,
      }),
    );
  }, [primaryBrand, secondaryBrand]);

  useEffect(() => {
    dispatch(
      setBack({
        // state prop "action" to call "prev" in authentication flow
        back: redirectUrl ? push(redirectUrl, { action: 'prev' }) : 'history',
      }),
    );
  }, [redirectUrl]);

  useEffect(() => {
    if (status) {
      cardRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [status]);

  useEffect(() => {
    if (errorsServer) {
      setError(errorsServer);
    }
  }, [errorsServer]);

  const handleSubmit = (values) => {
    setError(null);
    setSubmitted(true);
    dispatch(codeLoginRequest({ ...values, redirect: true }));
  };

  const handleResetLogin = () => {
    dispatch(resetLogin());
    history.goBack();
  };

  const isLoading = useSelector(selectCodeLoginLoading) && submitted;

  return (
    <Wrapper>
      <Header>
        <Heading type="h1" colorType={colorType} marginBottom="m">
          <FormattedMessage {...messages[type].title} />
        </Heading>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages[type].description} />
        </Text>
      </Header>
      <Card ref={cardRef}>
        {({ colorType: colorTypeLoginWrapper }) => (
          <>
            <Div>
              {status !== 'setData' && (
                <>
                  {!!error && (
                    <Text
                      colorType={colorTypeLoginWrapper}
                      themeField="form.errorColor"
                      size="xs"
                      marginBottom="m"
                    >
                      <FormattedMessage
                        {...messages.errors[`${Object.keys(error)[0]}`][
                          error[Object.keys(error)[0]].error
                        ]}
                      />
                    </Text>
                  )}
                  {!isChina && (
                    <Div marginBottom="m">
                      {oauthServices.map(({ service }) => oauthServicesMapping[service])}
                    </Div>
                  )}
                  <Formik
                    initialValues={status ? { email } : { email: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    enableReinitialize
                  >
                    {({ setFieldError, errors, values }) => {
                      if (isEmpty(errors) && (errorsServer || {}).email)
                        setFieldError('email', <FormattedMessage {...messages.error_email} />);
                      return (
                        <Form noValidate>
                          <Field
                            name="email"
                            component={TextInput}
                            colorType={colorTypeLoginWrapper}
                            placeholder={intl.formatMessage(
                              isChina ? messages.placeholderCn : messages.placeholder,
                            )}
                            icon={
                              <Email width={22} height={22} colorType={colorTypeLoginWrapper} />
                            }
                            rightAccessory={
                              status ? (
                                <ButtonSimple
                                  onClick={handleResetLogin}
                                  colorType={colorTypeLoginWrapper === 'light' ? 'dark' : 'light'}
                                  backgroundOpacity={1}
                                  style={{ padding: 0 }}
                                >
                                  <Close colorType={colorTypeLoginWrapper} width={18} height={18} />
                                </ButtonSimple>
                              ) : (
                                <ButtonSimple
                                  type="submit"
                                  colorType={colorTypeLoginWrapper}
                                  loading={isLoading}
                                  disabled={!values.email}
                                  backgroundOpacity={!values.email && 0.92}
                                  style={{ padding: 0 }}
                                >
                                  <ChevronRight
                                    colorType={values.email ? colorType : colorTypeLoginWrapper}
                                    width={27}
                                    height={27}
                                  />
                                </ButtonSimple>
                              )
                            }
                            autoCapitalize="none"
                            type="email"
                            disabled={status && true}
                          />
                        </Form>
                      );
                    }}
                  </Formik>
                </>
              )}
              {status === 'password' && (
                <PSWLogin
                  type={type}
                  colorType={colorTypeLoginWrapper}
                  primaryBrand={primaryBrand?.id}
                  secondaryBrand={secondaryBrand?.id}
                />
              )}
              {status === 'mailgunError' && (
                <MailgunError type={type} colorType={colorTypeLoginWrapper} />
              )}
            </Div>
            {(!isChina || (isChina && status !== 'password')) && (
              <TermsAndConditions
                colorType={colorTypeLoginWrapper}
                type="signup"
                size="xxs"
                marginTop="m"
                primaryBrand={primaryBrand?.id}
                secondaryBrand={secondaryBrand?.id}
              />
            )}
          </>
        )}
      </Card>
    </Wrapper>
  );
}

Login.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  type: PropTypes.string,
  status: PropTypes.string,
};

export default withColorType(injectIntl(Login));
