import React from 'react';
import PropTypes from 'prop-types';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { forEach, max, min } from 'lodash';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';

function Slider({ id, name, options, colorType, propsBag, ...rest }) {
  const handles = {};
  const marks = {};
  const values = [];

  forEach(options, ({ label, value, description }) => {
    const intValue = parseInt(value);
    values.push(intValue);
    handles[intValue] = {
      label: label && <FormattedMessage id={label} defaultMessage={label} values={propsBag} />,
      value: intValue,
    };
    marks[intValue] = (
      <FormattedMessage id={description || 'id'} defaultMessage={description} values={propsBag} />
    );
  });

  return (
    <Field
      name={name || id}
      min={min(values)}
      max={max(values)}
      component={FormFormik.RangeInput}
      handles={handles}
      marks={marks}
      colorType={colorType}
      {...rest}
    />
  );
}

Slider.propTypes = {
  id: PropTypes.string,
  colorType: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  propsBag: PropTypes.object,
};

export default Slider;
