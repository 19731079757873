import { defineMessages } from 'react-intl';

export default defineMessages({
  completeMessage: {
    id: 'survey_default.title1',
    defaultMessage: 'Done',
  },
  submitLabel: {
    id: 'survey_default.button1',
    defaultMessage: 'Send',
  },
});
