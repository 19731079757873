import { defineMessages } from 'react-intl';

const defaultMessages = defineMessages({
  title: {
    id: 'complete_unconfirmed.title1',
    defaultMessage: 'Done',
  },
  subtitle: {
    id: 'complete_unconfirmed.text1',
    defaultMessage: 'Check your email',
  },
  description: {
    id: 'complete_unconfirmed.text2',
    defaultMessage: 'We just sent you an email to view the result of the authentication.',
  },
  link: {
    id: 'complete_unconfirmed.text3',
    defaultMessage: 'Resend email',
  },
});

const modal = {
  box: defineMessages({
    title: {
      id: 'complete_unconfirmed_check.title1',
      defaultMessage: "Ok, we're sending an email with your response to the address below",
    },
    description: {
      id: 'complete_unconfirmed_check.title2',
      defaultMessage: 'Confirm or edit the address',
    },
  }),
};

export default {
  ...defaultMessages,
  modal,
};
