import { isMobile } from 'utils/utils';

export default {
  basename: '',
  channel: isMobile ? 'chnmobile' : 'chnweb',
  api: {
    hostname: 'https://apistage.certilogo.biz',
    cms: '/cms-fury',
  },
  oauth: {
    facebook: {
      appId: '290879381442703',
      fields: ['id', 'email'],
    },
    google: {
      clientId: '504927295040-d3olhsppaehd187ggi5o4am79bqln3l4.apps.googleusercontent.com',
    },
    apple: {
      clientId: 'com.certilogo.authenticator-mystique.dev',
    },
  },
  googleTagManagerPreview: 'env-172',
  googleTagManagerAuth: '8ADXWwsdV31N4HlSHsNJ_g',
};
