/* eslint-disable import/prefer-default-export */
import { Div } from '@ebay-certilogo/design-system-web';
import { primaryColor } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { transparentize } from 'polished';
import styled from 'styled-components';

const imageSize = '260px';

export const StickersWrapper = styled(Div)`
  background: ${(props) => transparentize(0.8, primaryColor(props))};
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 15px;
`;

export const Sticker = styled(Div)`
  display: inline-block;
  height: 80px;
  img {
    height: 100%;
  }
`;

export const ImageWrapper = styled(Div).attrs({
  marginBottom: 'm',
})`
  min-height: ${imageSize};
  display: flex;
  justify-content: center;
  flex-direction: column;

  .konvajs-content {
    margin: auto;
  }
`;
