import { Tabs as TabsDS } from '@ebay-certilogo/design-system-web';
import { addCssProp } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { getField } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import Button from '../Button';

export const TabList = styled(TabsDS.TabList)`
  flex-wrap: wrap;
`;

export const Trigger = styled(Button).attrs({
  buttonComponentClassName: 'button-component',
})`
  .button-component {
    background-color: ${(props) => getField('accordion.trigger.backgroundColor', props)};
    color: ${(props) => getField('accordion.trigger.color', props)};
    border-width: ${(props) => getField('accordion.trigger.borderWidth', props)};
    border-color: ${(props) => getField('accordion.trigger.borderColor', props)};
    ${({ disabled, ...props }) =>
      disabled &&
      css`
        cursor: default;
        background-color: ${transparentize(
          0.85,
          getField('accordion.trigger.backgroundColor', props),
        )};
        color: ${transparentize(0.6, getField('accordion.trigger.color', props))};
      `}
    ${({ locked, ...props }) =>
      locked &&
      css`
        background-color: ${transparentize(
          0.85,
          getField('accordion.trigger.backgroundColor', props),
        )};
      `}
      ${addCssProp}
  }

  .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

export default {
  Trigger,
};
