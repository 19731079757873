import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Warranty/actions';
import { setBack } from 'containers/App/actions';
import { get, map } from 'lodash';
import ViewInput from '@ebay-certilogo/design-system-web/dist/components/ViewInput';
import { format, parseISO } from 'date-fns';
import { Card } from 'containers/Warranty/styles';
import FormView from 'components/cms/form/FormView';
import messages from './messages';

const ViewInputStyled = styled(ViewInput)`
  text-transform: none !important;
`;

function Recap({ colorType, authenticationId, data }) {
  const {
    authentication,
    data: { page, userData, productData },
    props: { userFields, productFields },
  } = data;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            authenticationId,
            page,
          }),
        }),
      );
    }
  }, [authenticationId]);

  const handleSubmit = () => {
    dispatch(flowRequest({ action: 'next', authenticationId, page }));
  };

  const handleEdit = () => {
    dispatch(
      flowRequest({
        action: 'edit',
        authenticationId,
        page,
      }),
    );
  };

  return (
    <Card colorType={colorType}>
      <Text colorType={colorType} size="m" textAlign="center" marginBottom="m">
        <FormattedMessage {...messages.formTitle} />
      </Text>

      <ViewInput.BoxWrapper colorType={colorType}>
        {map(userFields, ({ label, name, ...conf }) => (
          <ViewInputStyled
            colorType={colorType}
            label={<FormattedMessage id={label} defaultMessage={label} />}
          >
            <FormView data={conf} value={get(userData, name)} colorType={colorType} size="xs" />
          </ViewInputStyled>
        ))}
      </ViewInput.BoxWrapper>

      <ViewInput.BoxWrapper colorType={colorType}>
        {map(productFields, ({ label, name, ...conf }) => (
          <ViewInputStyled
            colorType={colorType}
            label={<FormattedMessage id={label} defaultMessage={label} />}
          >
            <FormView data={conf} value={get(productData, name)} colorType={colorType} size="xs" />
          </ViewInputStyled>
        ))}
      </ViewInput.BoxWrapper>

      <ViewInput.BoxWrapper colorType={colorType}>
        <ViewInputStyled
          colorType={colorType}
          label={<FormattedMessage {...messages.certilogoCode} />}
        >
          <Text textTransform="none" colorType={colorType} size="xs">
            {authentication?.certilogoCode}
          </Text>
        </ViewInputStyled>
        <ViewInputStyled colorType={colorType} label={<FormattedMessage {...messages.response} />}>
          <Text textTransform="none" colorType={colorType} size="xs">
            {authentication?.response}
          </Text>
        </ViewInputStyled>
        <ViewInputStyled colorType={colorType} label={<FormattedMessage {...messages.brand} />}>
          <Text textTransform="none" colorType={colorType} size="xs">
            {authentication?.primaryBrand.name}
          </Text>
        </ViewInputStyled>
        <ViewInputStyled
          colorType={colorType}
          label={<FormattedMessage {...messages.salesChannel} />}
        >
          <Text textTransform="none" colorType={colorType} size="xs">
            {authentication?.bought}
          </Text>
        </ViewInputStyled>
        <ViewInputStyled
          colorType={colorType}
          label={<FormattedMessage {...messages.authenticationDate} />}
        >
          <Text textTransform="none" colorType={colorType} size="xs">
            {authentication?.dateCreated &&
              format(parseISO(authentication?.dateCreated), 'dd/MM/yyyy')}
          </Text>
        </ViewInputStyled>
      </ViewInput.BoxWrapper>

      <Button type="submit" colorType={colorType} onClick={handleSubmit} noMargin>
        <FormattedMessage {...messages.buttonSubmit} />
      </Button>
      <Button
        type="button"
        buttonType="link"
        colorType={colorType}
        onClick={handleEdit}
        arrow={false}
        noMargin
      >
        <FormattedMessage {...messages.buttonEdit} />
      </Button>
    </Card>
  );
}

Recap.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    authentication: PropTypes.object,
    data: PropTypes.object,
    props: PropTypes.object,
  }),
};

export default Recap;
