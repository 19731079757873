import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectProfile, selectIsAuthenticated } from 'containers/Auth/selectors';
import { Box, Text, Flex, Button } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import LoadingIndicator from 'components/LoadingIndicator';
import { getProfileRequest } from 'containers/Auth/actions';
import { setBack } from 'containers/App/actions';
import history from 'utils/history';
import messages from './messages';
import UserDataForm from '../../SetData/Form';
import OptinSettings from './OptinSettings';
import DeleteUser from './DeleteUser';

function Settings({ colorType }) {
  const dispatch = useDispatch();
  const [showAllSettings, setShowAllSettings] = useState(false);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const profile = useSelector(selectProfile);

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
    if (isAuthenticated) dispatch(getProfileRequest());
  }, []);

  const user = useSelector(selectUser);

  if (!user) return <LoadingIndicator />;

  return (
    <>
      <Box
        colorType={colorType}
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
        margin={{ size: 'm', position: 'bottom' }}
      />
      <Text colorType={colorType} marginBottom="m">
        {user}
      </Text>
      <Flex margin={{ size: 'l', position: 'bottom' }} style={{ flexDirection: 'column' }}>
        <UserDataForm colorType={colorType} data={profile} profilePage />
      </Flex>
      <OptinSettings colorType={colorType} />
      <Box
        colorType={colorType}
        description={<FormattedMessage {...messages.otherSettings} />}
        margin={{ size: 'm', position: 'bottom' }}
        minHeight="0"
      />
      {showAllSettings ? (
        <DeleteUser colorType={colorType} data={profile} />
      ) : (
        <Button
          colorType={colorType}
          buttonType="link"
          arrow={false}
          onClick={() => setShowAllSettings(true)}
          size="s"
        >
          <FormattedMessage {...messages.viewAllSettings} />
        </Button>
      )}
      <Button colorType={colorType} onClick={() => history.goBack()} marginTop="l" reverse>
        <FormattedMessage {...messages.back} />
      </Button>
    </>
  );
}

Settings.propTypes = {
  colorType: PropTypes.string,
};

export default Settings;
