import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import EbayButton from 'components/EbayButton';
import messages from '../messages';
import ebayOauth from './ebayOauth';

function EbayLogin({ colorType, type = 'signUp', countryCode, onSubmit }) {
  const handleOnClick = () => {
    ebayOauth(countryCode);
    onSubmit();
  };

  return (
    <EbayButton colorType={colorType} onClick={handleOnClick} size="s">
      <FormattedMessage {...messages[type].ebay} />
    </EbayButton>
  );
}

EbayLogin.propTypes = {
  colorType: PropTypes.string,
  countryCode: PropTypes.string,
  type: PropTypes.string,
  setError: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default EbayLogin;
