import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { makeSelectLocale } from 'containers/LanguageProvider/selectors';
import { resetConf, setMenuVisibility } from 'containers/App/actions';
import { getLocalImage } from 'utils/images';
import { isBrandedDomain, isChina } from 'utils/utils';
import { ProgressBarTimer } from '@ebay-certilogo/design-system-web';
import { selectBackgroundImage } from 'containers/App/selectors';
import useColorType from 'components/hooks/useColorType';
import { resetProxy } from 'containers/Proxy/actions';
import { checkAutenticity } from 'utils/reduxBeaconGtm/actions/authentication';
import useDefaultTheme from 'components/hooks/useDefaultTheme';
import { ThemeProvider } from 'styled-components';
import history from 'utils/history';
import Header from 'containers/App/Layout/Header';
import Animation from './Animation';
import { HomeWrapper, HeaderLogo, ButtonAuthWrapper, HeaderHome, LicenceCn } from './styles';
import messages from './messages';

function Home() {
  const colorType = useColorType('home');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(resetConf());
    dispatch(resetProxy());
    dispatch(setMenuVisibility(false));
  }, []);
  const theme = useDefaultTheme('home');
  const backgroundImage = useSelector(selectBackgroundImage);
  const locale = useSelector(makeSelectLocale);
  const highFontClass = ['zh', 'ja', 'ko'].includes(locale) ? 'big-cta--lettere-alte' : '';
  const tooLongClass = ['ja', 'es', 'ru'].includes(locale) ? 'big-cta--too-long' : '';
  const isBranded = isBrandedDomain();
  return (
    <ThemeProvider theme={theme}>
      <HomeWrapper colorType={colorType} backgroundImage={isBranded && backgroundImage}>
        {!isBranded ? (
          <>
            <Animation />
            <HeaderHome colorType={colorType}>
              <HeaderLogo src={getLocalImage('/images/certilogo-logo-light.svg')} />
            </HeaderHome>
            <Link
              to="/code"
              className={`cta-auth big-cta active ${highFontClass} ${tooLongClass}`}
              onClick={() => dispatch(checkAutenticity())}
            >
              <FormattedMessage {...messages.ctaAuth.prefix} />
              <span className="primary-text">
                <FormattedMessage {...messages.ctaAuth.text} tagName="" />
              </span>
              <div className="big-cta__deco" />
              <div className="big-cta__canvas" />
            </Link>
            <a
              href="https://discover.certilogo.com"
              className={`cta-web big-cta active ${highFontClass} ${tooLongClass}`}
            >
              <FormattedMessage {...messages.ctaWeb.prefix} />
              <span className="primary-text">
                <FormattedMessage {...messages.ctaWeb.text} tagName="" />
              </span>
              <div className="big-cta__deco" />
              <div className="big-cta__canvas" />
            </a>
          </>
        ) : (
          <>
            <Header colorType={colorType} />
            <ButtonAuthWrapper colorType={colorType}>
              <ProgressBarTimer
                colorType={colorType}
                interval={3000}
                onComplete={() => {
                  history.push('/code');
                  dispatch(checkAutenticity());
                }}
              />
            </ButtonAuthWrapper>
          </>
        )}
        {isChina && (
          <LicenceCn colorType={colorType} href="https://beian.miit.gov.cn/">
            沪ICP备2022027077号
          </LicenceCn>
        )}
      </HomeWrapper>
    </ThemeProvider>
  );
}

Home.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Home);
