import React, { useEffect } from 'react';
import { Flex, Heading, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import withColorType from 'components/HOC/withColorType';
import styled from 'styled-components';
import FlowButton from 'containers/Authentication/components/FlowButton';
import messages from './messages';

const Wrapper = styled(Flex)`
  flex: 1;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;

function ShortResponse({ colorType, data, authenticationId }) {
  const { status } = data;
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = (choise) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: { bought: choise },
      }),
    );
  };

  return (
    <Wrapper>
      <Heading type="h1" textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.title} />
      </Heading>
      <Text textAlign="center" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.description} />
      </Text>
      <FlowButton colorType={colorType} onClick={handleSubmit}>
        <FormattedMessage {...messages.button} />
      </FlowButton>
    </Wrapper>
  );
}

ShortResponse.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default withColorType(ShortResponse);
