import React, { useEffect, useMemo } from 'react';
import { Field, Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { Button, FormFormik } from '@ebay-certilogo/design-system-web';
import { Plus } from '@ebay-certilogo/design-system-web/dist/components/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { injectIntl, FormattedMessage } from 'react-intl';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import { setBack } from 'containers/App/actions';
import Form from 'components/form/FormComponent';
import defaultMessages from './messages';
import flowMessages from './flowMessages';

const validationSchema = Yup.object().shape({
  soaAlias: Yup.string().required(),
});

const { TextInput } = FormFormik;

function InsertAlias({ colorType, handleSubmit, intl, data }) {
  const dispatch = useDispatch();

  const {
    state: { marketplaceId },
  } = useLocation();

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  const overrideMessages = flowMessages[data?.flow] || {};
  const messages = useMemo(
    () => ({
      ...defaultMessages,
      ...overrideMessages,
    }),
    [data],
  );

  const onSubmit = ({ soaAlias }) => {
    handleSubmit({ soaAlias, store: marketplaceId });
  };

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />
      <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, handleSubmit: formikHandleSubmit }) => (
          <Form>
            <Field
              name="soaAlias"
              colorType={colorType}
              component={TextInput}
              icon={<Plus width={18} height={18} colorType={colorType} />}
              placeholder={intl.formatMessage(messages.placeholder)}
            />

            <Button
              colorType={colorType}
              onClick={formikHandleSubmit}
              disabled={!values.soaAlias}
              noMargin
            >
              <FormattedMessage {...messages.continue} />
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}

InsertAlias.propTypes = {
  colorType: PropTypes.string,
  handleSubmit: PropTypes.func,
  intl: PropTypes.object,
  data: PropTypes.shape({
    flow: PropTypes.string,
  }),
};

export default injectIntl(InsertAlias);
