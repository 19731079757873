import React from 'react';
import PropTypes from 'prop-types';
import { ButtonCta } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import ButtonComponent from 'components/ButtonComponent';
import messages from './messages';

function UrlComposer({
  link,
  type = 'default',
  style,
  colorType,
  propsBag: { logger, ...propsBag } = {},
  componentType,
  gtm,
  ...rest
}) {
  const handleClick = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: 'link', name: type, category: 'link' },
        params: { target: link },
      });
    }
  };

  return (
    <ButtonComponent
      colorType={colorType}
      href={link}
      onClick={handleClick}
      component={ButtonCta}
      alignSelf="flex-end"
      target="_blank"
      {...style}
      {...rest}
    >
      <FormattedMessage {...messages[type]} values={propsBag} />
    </ButtonComponent>
  );
}

UrlComposer.propTypes = {
  componentType: PropTypes.string,
  colorType: PropTypes.string,
  link: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  style: PropTypes.object,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default UrlComposer;
