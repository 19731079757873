import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'invalid_select_brand.title1',
    defaultMessage:
      'The code entered may not be valid. Please tell us the brand and the product name',
  },
  placeholder: {
    id: 'invalid_select_brand.placeholder1',
    defaultMessage: "What's the brand?",
  },
});
