import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fetchWrapper } from 'utils/Api/fetchWrapper';
import { useSelector } from 'react-redux';
import { selectJwt } from 'containers/Auth/selectors';
import { useRequest } from 'ahooks';
import { ChevronLeft, ChevronRight } from '@ebay-certilogo/design-system-web/dist/components/icons';
import LoadingIndicator from 'components/LoadingIndicator';
import Question from './Question';
import Response from './Response';
import { Wrapper, Pagination } from './styles';

function ViumeSurvey({
  surveyId,
  instanceId,
  style,
  colorType,
  logEventName = 'viume-survey',
  componentType,
  gtm,
  propsBag: { logger } = {},
}) {
  const [question, setQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [response, setResponse] = useState();
  const jwtToken = useSelector(selectJwt);

  const sendLog = () => {
    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: ' service-clg', name: logEventName, category: 'viume-survey' },
      });
    }
  };

  const surveyRequest = () => {
    return new Promise((resolve) => {
      fetchWrapper({
        url: `viume-service/survey/${surveyId}/instance/${instanceId}`,
        options: {
          method: 'GET',
          headers: { jwtToken },
        },
      }).then((res) => {
        resolve(res);
      });
    });
  };
  const surveySendAnswers = (data) => {
    return new Promise((resolve) => {
      fetchWrapper({
        url: `viume-service/survey/${surveyId}/instance/${instanceId}`,
        options: {
          method: 'POST',
          headers: { jwtToken },
          body: {
            answers: data,
          },
        },
      }).then((res) => {
        resolve(res);
      });
    });
  };

  const survey = useRequest(surveyRequest);

  const handleSubmit = async (values) => {
    sendLog();
    const res = await surveySendAnswers(values);
    setResponse(res);
  };

  const handleBack = async () => {
    if (question > 0) {
      setQuestion(question - 1);
    }
  };

  const handleAnswer = (value) => {
    const newAnswers = answers;
    newAnswers[question] = {
      questionId: survey.data.questions[question].id,
      value,
    };
    setAnswers(newAnswers);
    if (survey.data.questions[question + 1]) {
      setQuestion(question + 1);
    } else {
      handleSubmit(answers);
    }
  };

  if (survey.loading) {
    return <LoadingIndicator />;
  }

  return (
    <Wrapper {...style}>
      {response ? (
        <Response colorType={colorType} data={response} title={survey.data.displayName} />
      ) : (
        <>
          <Question
            title={survey.data.displayName}
            colorType={colorType}
            data={survey.data.questions[question]}
            onSubmit={handleAnswer}
          />
          <Pagination justifyContent="center" alignItems="center">
            <ChevronLeft
              color={question <= 0 && 'transparent'}
              onClick={handleBack}
              colorType={colorType}
              width={33}
              height={33}
            />
            {`${question + 1}/${survey.data.questions.length}`}
            <ChevronRight color="transparent" colorType={colorType} width={33} height={33} />
          </Pagination>
        </>
      )}
    </Wrapper>
  );
}

ViumeSurvey.propTypes = {
  authenticationKey: PropTypes.string.isRequired,
  surveyId: PropTypes.string.isRequired,
  instanceId: PropTypes.string.isRequired,
  componentType: PropTypes.string,
  style: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default ViumeSurvey;
