import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'short_response.title',
    defaultMessage: 'Attention',
  },
  description: {
    id: 'short_response.text1',
    defaultMessage:
      'We have encountered a technical problem with the tag, please follow the instructions carefully',
  },
  button: {
    id: 'short_response.button',
    defaultMessage: 'Ok, got it',
  },
});
