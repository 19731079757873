import { defineMessages } from 'react-intl';

export default defineMessages({
  default: {
    id: '-',
    defaultMessage: '-',
  },
  versace_warranty: {
    id: 'versace.warranty.extension',
    defaultMessage: 'Extend your warranty',
  },
});
