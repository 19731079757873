export default {
  basename: '',
  api: {
    hostname: 'https://stage3.certilogo.com',
    cms: '/cms-fury',
  },
  oauth: {
    facebook: {
      appId: '290879381442703',
      fields: ['id', 'email'],
    },
    google: {
      clientId: '504927295040-d3olhsppaehd187ggi5o4am79bqln3l4.apps.googleusercontent.com',
    },
    apple: {
      clientId: 'com.certilogo.authenticator-mystique.dev',
    },
    // certilogo-app
    // ebay: {
    //   url: 'https://auth.ebay.com/oauth2',
    //   clientId: 'Certilog-certilog-PRD-75bb1c1d3-64c6fa64',
    //   redirectUri: 'Certilogo_S.p.A-Certilog-certil-jhjxuyxyc',
    // },
    // certilogo-app 2
    ebay: {
      url: 'https://auth.ebay.com/oauth2',
      clientId: 'Certilog-certilog-PRD-7975725d3-eb0bb396',
      redirectUri: 'Certilogo_S.p.A-Certilog-certil-vzpnnin',
    },
  },
  googleTagManagerPreview: 'env-172',
  googleTagManagerAuth: '8ADXWwsdV31N4HlSHsNJ_g',
};
