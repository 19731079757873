import { isMobile } from 'utils/utils';

/* eslint-disable global-require */
const config = {
  development: require('./config.development'),
  staging: require('./config.staging'),
  production: require('./config.production'),
  'staging-cn': require('./config.staging-cn'),
  'production-cn': require('./config.production-cn'),
  testenv: require('./config.testenv'),
};

const defaultConfig = {
  channel: isMobile ? 'mobile' : 'web',
  cdnUrl: 'https://s3.eu-central-1.amazonaws.com/certilogo-cdn',
  discoverUrl: 'https://discover.certilogo.com',
  googleAnalyticsId: 'UA-8083427-13',
  googleTagManagerId: 'GTM-MBFMG3Q',
  localesUrl: 'https://certilogocdn.blob.core.windows.net/locales/authenticator',
  clgInputQty: 12,
};

export default {
  ...defaultConfig,
  ...config[process.env.REACT_APP_ENV].default,
};
