import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { Stepper, Text } from '@ebay-certilogo/design-system-web';
import { useParams, useLocation } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import useColorType from 'components/hooks/useColorType';
import history from 'utils/history';
import usePageType from 'components/hooks/usePageType';
import { createRequest } from './actions';
import messages from './messages';

import saga from './saga';
import { selectFakeReportLoading, selectFakeReportData } from './selectors';
import User from './User';
import Retailer from './Retailer';
import Purchase from './Purchase';
import Recap from './Recap';
import Complete from './Complete';
import { Title, Wrapper } from './styles';
import RecallModal from './RecallModal';

const actualStep = {
  user: 1,
  retailer: 2,
  purchase: 3,
};

/* eslint-disable */
const steps = {
  user: (props) => <User {...props} />,
  retailer: (props) => <Retailer {...props} />,
  purchase: (props) => <Purchase {...props} />,
  recap: (props) => <Recap {...props} />,
  complete: (props) => <Complete {...props} colorType="accent" />,
};
/* eslint-enable */

function FakeReport() {
  const colorType = useColorType('fakeReport');
  useInjectSaga({ key: 'fakeReport', saga });
  const location = useLocation();
  const recall = useMemo(() => {
    const urlParams = new URLSearchParams(location?.search);
    return urlParams.get('recall');
  }, [location]);
  const { authenticationId } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(createRequest({ authenticationId }));
  }, [authenticationId]);

  const isLoading = useSelector(selectFakeReportLoading);
  const data = useSelector(selectFakeReportData);

  usePageType('fakeReport');

  if (isLoading || isEmpty(data)) return <LoadingIndicator />;

  return (
    <Wrapper>
      <Title colorType={colorType}>
        <FormattedMessage {...messages.title} />
      </Title>
      {data.status !== 'complete' && (
        <>
          <Text colorType={colorType} textAlign="center" marginBottom="m">
            <FormattedMessage {...messages.text1} />
          </Text>
          {actualStep[data.status] && (
            <Stepper totalSteps={3} actualStep={actualStep[data.status]} colorType={colorType} />
          )}
        </>
      )}
      {steps[data.status]({
        data,
        id: data.id,
        status: data.status,
      })}
      <RecallModal
        colorType={colorType}
        type={recall}
        onClose={() => {
          history.replace(location.pathname);
        }}
      />
    </Wrapper>
  );
}

FakeReport.propTypes = {
  colorType: PropTypes.string,
};

export default FakeReport;
