export default {
  'SI-AntiFraud': {
    title: {
      id: 'entry_purpose_stone.title1',
      defaultMessage: 'How do you want to use Certilogo today?',
    },
  },
  ResellWithEbayPotentialBuyer: {
    before: {
      id: 'resell_with_ebay.purpose.button1',
      defaultMessage: 'A listed product',
    },
    after: {
      id: 'resell_with_ebay.purpose.button2',
      defaultMessage: 'My own product',
    },
  },
};
