import React from 'react';
import PropTypes from 'prop-types';
import types from './types';

function FormView({ data: { __component, ...data }, ...rest }) {
  // eslint-disable-next-line no-underscore-dangle
  const Component = types[__component];
  if (Component) {
    return <Component {...{ ...data, ...rest }} />;
  }
  return null;
}

FormView.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  info: PropTypes.array,
};

export default FormView;
