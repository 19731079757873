import { defineMessages } from 'react-intl';

export default defineMessages({
  signup: {
    id: 'login_signup.title1',
    defaultMessage: 'Sign Up',
  },
  login: {
    id: 'login_signup.title2',
    defaultMessage: 'Login',
  },
  error: {
    id: 'login_signup_error2.text1',
    defaultMessage: 'Your email seems invalid',
  },
  description: {
    id: 'login_signup_error2.text2',
    defaultMessage:
      'Don’t worry, maybe it’s a typo, please check again and choose the email address you want to sign up with:',
  },
  link: {
    id: 'login_signup_error2.text3',
    defaultMessage: 'Write again your email address',
  },
});
