import React from 'react';
import PropTypes from 'prop-types';
import history from 'utils/history';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import Button from 'components/cms/dataTypes/Button';
import messages from './messages';

function ClaimOwnership({
  button,
  logEventName = 'claim-ownership',
  propsBag: { logger } = {},
  propsBag,
  componentType,
  colorType,
  gtm,
}) {
  const handleOnClick = () => {
    history.push(`/claim-ownership`, { sessionId: propsBag?.id });

    if (logger) {
      logger({
        action: 'click',
        type: componentType,
        step: 'finish',
        start: 1,
        finish: 1,
        gtm,
        event: { type: 'service-clg', name: logEventName },
      });
    }
  };

  return (
    <Button onClick={handleOnClick} {...button} colorType={button?.colorType || colorType}>
      <FormattedMessageMarkdown {...messages.button} />
    </Button>
  );
}

ClaimOwnership.propTypes = {
  status: PropTypes.string,
  countryCode: PropTypes.string,
  componentType: PropTypes.string,
  style: PropTypes.object,
  button: PropTypes.object,
  colorType: PropTypes.string,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  gtm: PropTypes.object,
};

export default ClaimOwnership;
