import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function ErrorMessage({ type }) {
  return <FormattedMessage {...messages[type]} />;
}

ErrorMessage.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ErrorMessage;
