import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Text } from '@ebay-certilogo/design-system-web';
import { useDispatch } from 'react-redux';
import { codeLoginResponse } from 'containers/Auth/actions';
import { useCountDown } from 'ahooks';
import messages from '../messages';

function CountDownMessage({ colorType, timeToWait, error }) {
  const dispatch = useDispatch();
  const [countdown] = useCountDown({
    leftTime: timeToWait * 1000,
    onEnd: () => dispatch(codeLoginResponse({ error: null, timeToWait: null })),
  });
  return (
    <Text colorType={colorType} marginVertical="m" textAlign="center">
      <FormattedMessage
        {...messages.errors[error]}
        values={{ timeToWait: (countdown / 1000).toFixed(0) }}
      />
    </Text>
  );
}

CountDownMessage.propTypes = {
  colorType: PropTypes.string,
  error: PropTypes.string,
  timeToWait: PropTypes.number,
};

export default CountDownMessage;
