import React from 'react';
import PropTypes from 'prop-types';
import { Heading as HeadingCertilogo } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import isEmpty from 'lodash/isEmpty';

function Heading({ text, type, style, propsBag = {}, colorType, ...rest }) {
  return !!text && !isEmpty(text) ? (
    <HeadingCertilogo
      type={type}
      colorType={colorType}
      alignSelf={style?.textAlign}
      {...style}
      {...rest}
    >
      <FormattedMessage id={text} defaultMessage={text} values={propsBag} colorType={colorType} />
    </HeadingCertilogo>
  ) : null;
}

Heading.propTypes = {
  colorType: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  propsBag: PropTypes.object,
};

export default Heading;
