import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Spring } from '@react-spring/web';
import { Text as TextCertilogo } from '@ebay-certilogo/design-system-web';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import pick from 'lodash/pick';
import { isNil } from 'lodash';

const mapAlignSelfOnTextAlign = {
  left: 'flex-start',
  right: 'flex-end',
  center: 'center',
};

function Text({ text, size, weight, style, temporization, propsBag = {}, colorType, ...rest }) {
  const [show, setShow] = useState(!temporization);
  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, temporization);
  }, []);

  const isAvailable = useMemo(() => !isNil(text) && text !== '', [text]);

  return text ? (
    <Spring from={{ opacity: temporization ? 0 : 1 }} to={{ opacity: show ? 1 : 0 }}>
      {(props) => (
        <TextCertilogo
          color
          style={props}
          size={size}
          weight={weight}
          colorType={colorType}
          alignSelf={style && mapAlignSelfOnTextAlign[style.textAlign]}
          {...style}
          {...rest}
        >
          {isAvailable && (
            <FormattedMessageMarkdown
              id={text}
              defaultMessage={text}
              values={propsBag}
              colorType={colorType}
              style={pick(style, ['color', 'backgroundColor'])}
              styleProps={{
                size,
                weight,
                textAlign: style?.textAlign,
                color: style?.color,
                letterSpacing: style?.letterSpacing,
              }}
            />
          )}
        </TextCertilogo>
      )}
    </Spring>
  ) : null;
}

Text.propTypes = {
  colorType: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
  weight: PropTypes.string,
  style: PropTypes.object,
  temporization: PropTypes.number,
  propsBag: PropTypes.object,
};

export default Text;
