import { defineMessages } from 'react-intl';

export default defineMessages({
  signUp: {
    title: {
      id: 'login_new.title1',
      defaultMessage: 'Sign in',
    },
    description: {
      id: 'login_new.text1',
      defaultMessage: 'You’re almost there, please register to proceed',
    },
    facebook: {
      id: 'signup.button1',
      defaultMessage: 'Sign in with Facebook',
    },
    google: {
      id: 'signup.button2',
      defaultMessage: 'Sign in with Google',
    },
    apple: {
      id: 'signup.button3',
      defaultMessage: 'Sign in with Apple',
    },
    ebay: {
      id: 'signup.button4',
      defaultMessage: 'Sign in with eBay',
    },
  },
  placeholder: {
    id: 'signup.placeholder1',
    defaultMessage: 'or use a valid email address',
  },
  placeholderCn: {
    id: 'signup.cn.placeholder1',
    defaultMessage: 'use a valid email address',
  },
  rightsDescription: {
    id: 'signup.text4a',
    defaultMessage:
      'By signing up, you agree to our [Terms]({termsUrl}) and [Privacy policy]({privacyUrl}). {linebreak}You confirm you’re at least 16 years old. We’ll never post to Facebook or Google+ without your permission.',
  },
  errors: {
    facebook: {
      default: {
        id: 'signup.error1',
        defaultMessage: 'Facebook error, try later...',
      },
    },
    google: {
      default: {
        id: 'signup.error2',
        defaultMessage: 'Google error, try later...',
      },
    },
    apple: {
      default: {
        id: 'signup.error3',
        defaultMessage: 'Apple error, try later...',
      },
    },
    ebay: {
      default: {
        id: 'signup.error3',
        defaultMessage: 'Ebay error, try later...',
      },
      access_denied: {
        id: 'signup.errors.ebay.permissions_denied',
        defaultMessage: 'Ebay error, permissions denied',
      },
    },
    email: {
      default: {
        id: 'login_signup_error2.text1',
        defaultMessage: 'Your email seems invalid',
      },
      format: {
        id: 'login_signup_error1.text1',
        defaultMessage: 'Error, this is not a valid format',
      },
    },
  },
});
