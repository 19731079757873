import React, { useCallback, useEffect, useState } from 'react';
import { Button, Text, Heading, Flex, ProgressBarTimer } from '@ebay-certilogo/design-system-web';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import history from 'utils/history';
import { useTheme } from 'styled-components';
import { downloadRequest } from 'containers/Warranty/actions';
import { transparentize } from 'polished';
import messages from './messages';

function Complete({
  colorType,
  authenticationId,
  data: {
    data: { token },
    props: { isEmail },
  },
  data,
}) {
  const dispatch = useDispatch();
  const [downloaded, setDownloaded] = useState();
  const theme = useTheme();
  const sessionId = data?.authentication?.sessionId;
  const goToConnectConsole = useCallback(() => history.push(`/connect/${sessionId}`), [sessionId]);

  useEffect(() => {
    dispatch(unsetBack());
  }, [authenticationId]);

  const handleDownload = () => {
    dispatch(downloadRequest({ token }));
    setDownloaded(true);
  };

  return downloaded ? (
    <Flex justifyContent="center" flexDirection="column">
      <Heading type="big" textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.done} />
      </Heading>
      <Text textAlign="center" colorType={colorType} marginBottom="m">
        <FormattedMessage {...messages.downloadComplete} />
      </Text>
      <ProgressBarTimer
        colorType={colorType}
        interval={5000}
        onComplete={goToConnectConsole}
        strokeColor={color({ theme, colorType })}
        trailColor={transparentize(0.8, color({ theme, colorType }))}
        marginTop="m"
      />
    </Flex>
  ) : (
    <>
      <Text textAlign="center" colorType={colorType} marginTop="l" marginBottom="m">
        <FormattedMessage {...messages.text1} />
      </Text>
      {isEmail && (
        <Text textAlign="center" colorType={colorType} marginBottom="l">
          <FormattedMessage {...messages.text2} />
        </Text>
      )}
      <Button colorType={colorType} onClick={handleDownload} noMargin>
        <FormattedMessage {...messages.download} />
      </Button>
      <Button
        arrow={false}
        buttonType="link"
        colorType={colorType}
        onClick={goToConnectConsole}
        noMargin
      >
        <FormattedMessage {...messages.cta} />
      </Button>
    </>
  );
}

Complete.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    authentication: PropTypes.object,
    data: PropTypes.object,
    props: PropTypes.object,
    page: PropTypes.string,
    userData: PropTypes.object,
    productData: PropTypes.object,
  }),
};

export default Complete;
