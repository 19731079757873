import { Flex } from '@ebay-certilogo/design-system-web';
import styled, { css } from 'styled-components';
import { addCssProp, contentWrapper } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const ExternalWrapper = styled(Flex)`
  flex-shrink: 0;
  ${({ isSticky, isVisible }) =>
    isSticky &&
    css`
      position: ${isVisible ? 'relative' : 'fixed'};
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0);
      width: 100%;
    `}
  ${contentWrapper}
  ${addCssProp}
`;

export const Wrapper = styled(Flex)`
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: hidden;
  ${addCssProp}
`;

export const Cell = styled(Flex)`
  flex-grow: 1;
  ${({ width }) =>
    width &&
    css`
      width: ${width};
      max-width: ${width};
    `}
`;

export const OverlayContents = styled(Flex)`
  position: absolute;
  inset: 0;
`;
