import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import get from 'lodash/get';
import withColorType from 'components/HOC/withColorType';
import { selectGooglePlaceType } from 'containers/App/selectors';
import StoreRetailerInput from './RetailerInput';

function RetailerStore({ colorType, data, authenticationId }) {
  const { status, props } = data;
  const googlePlaceType = useSelector(selectGooglePlaceType);
  const dispatch = useDispatch();
  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);
  const latitude = get(props, 'location.latitude');
  const longitude = get(props, 'location.longitude');

  return (
    <StoreRetailerInput
      colorType={colorType}
      authenticationId={authenticationId}
      status={status}
      positionCall={
        get(data, 'props.geolocation') === 'auto' && {
          latitude,
          longitude,
        }
      }
      getCurrentPositionActive={get(data, 'props.geolocation') === 'auto'}
      required={get(data, 'props.required')}
      googlePlaceType={googlePlaceType}
    />
  );
}

RetailerStore.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    props: PropTypes.object,
  }),
};

export default withColorType(RetailerStore);
