import React from 'react';
import PropTypes from 'prop-types';
import { FileUpload } from '@ebay-certilogo/design-system-web';
import { Library, ButtonFooter } from '../styles';

function LibrarySelect({ onChange, colorType }) {
  const handleLibrarySelection = (file) => {
    onChange(file);
  };
  return (
    <FileUpload
      name="library-image"
      style={{ display: 'inline' }}
      onChange={handleLibrarySelection}
      type="library"
      // eslint-disable-next-line react/no-unstable-nested-components
      buttonComponent={(props) => (
        <ButtonFooter width={45} height={45} backgroundOpacity={1} {...props} colorType={colorType}>
          <Library colorType={colorType} />
        </ButtonFooter>
      )}
    />
  );
}

LibrarySelect.propTypes = {
  colorType: PropTypes.string,
  onChange: PropTypes.func,
};

export default LibrarySelect;
