import React from 'react';
// import { Button } from '@ebay-certilogo/design-system-web';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { selectConf } from 'containers/App/selectors';
import Button from 'components/cms/dataTypes/Button';

export default function FlowButton(props) {
  const conf = useSelector(selectConf);
  const { colorType, ...flowButton } = get(conf, 'authenticationConf.flowButton', {});
  if (!isNil(flowButton.colorType)) {
    flowButton.colorType = colorType;
  }
  return <Button {...props} {...flowButton} />;
}
