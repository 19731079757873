import { defineMessages } from 'react-intl';

export default defineMessages({
  online: {
    id: 'fake_report.selectStore.online',
    defaultMessage: 'Online',
  },
  inStore: {
    id: 'fake_report.selectStore.inStore',
    defaultMessage: 'In store',
  },
});
