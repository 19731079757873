import React from 'react';
import PropTypes from 'prop-types';
import { Div } from '@ebay-certilogo/design-system-web';
import types from './types/index';
import Header from '../Header';

function Question({
  colorType,
  title,
  data: { id, questionType, sentence },
  data,
  propsBag,
  onSubmit = () => {},
  ...rest
}) {
  const Component = types[questionType];

  const handleChange = (value) => {
    onSubmit(value);
  };

  if (!Component) {
    return null;
  }
  return (
    <>
      <Header colorType={colorType} propsBag={propsBag} title={title} text={sentence} />
      <Div margin={{ size: 'm' }}>
        <Component
          {...data}
          {...rest}
          colorType={colorType}
          propsBag={propsBag}
          onChange={handleChange}
          key={id}
        />
      </Div>
    </>
  );
}

Question.propTypes = {
  data: PropTypes.objectOf({
    questionType: PropTypes.string.isRequired,
  }),
  colorType: PropTypes.string,
  title: PropTypes.string,
  propsBag: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default Question;
