import React from 'react';
import usePortal from 'react-useportal';

function Portal(props) {
  const { Portal: PortalWrapper } = usePortal({
    bindTo: document && document.getElementById('layout-root-portal'),
  });
  return <PortalWrapper {...props} />;
}

export default Portal;
