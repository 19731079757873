import styled from 'styled-components';
import { Link as _link } from 'react-router-dom';
import { Text } from '@ebay-certilogo/design-system-web';

export const Link = styled(Text).attrs({
  as: _link,
})`
  display: inline-block;
`;

export default Link;
