import { put } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import config from 'config';
import { downloadResponse } from '../actions';

export default function* downloadWarranty({ payload: { token } }) {
  const url = `warranty/v1/download/${token}`;

  try {
    FileSaver.saveAs(`${config.api.hostname}/${url}`, `warranty_${token}`);
    yield put(downloadResponse());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
