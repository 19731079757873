import { all, call, put, select, take } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import {
  imageUploadResponse,
  flowRequest,
  imageDeleteResponse,
  imageListResponse,
  imageListRequest,
  getAuthenticationSilentRequest,
  authenticationResponse,
} from '../actions';
import { selectAuthenticationData, selectAuthenticationImageUploadCount } from '../selectors';

export const imageUpload = function* imageUpload({
  payload: { source, sourceId, file: fileData, position, acquisitionType, qrString },
}) {
  const url = 'image/v4/upload';
  const params = new FormData();
  params.append('file', fileData);
  params.append('source', source);
  params.append('sourceId', sourceId);
  params.append('position', position);
  params.append('acquisitionType', acquisitionType);
  if (qrString) {
    params.append('qrString', qrString);
  }
  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      formData: params,
    });
    yield put(imageUploadResponse(response));
    yield put(imageListRequest({ source, sourceId }));
    yield put(
      getAuthenticationSilentRequest({ authenticationId: sourceId, disableRedirect: true }),
    );
    yield all([take(imageListResponse), take(authenticationResponse)]);
    const uploadedImagesCount = yield select(selectAuthenticationImageUploadCount);
    const authenticationData = yield select(selectAuthenticationData);
    const maxImages = get(authenticationData, 'props.image.max');
    const forcible = get(authenticationData, 'props.image.forcible');
    if (uploadedImagesCount >= maxImages || (forcible && response.force))
      yield put(
        flowRequest({
          action: 'next',
          status: 'image_upload',
          authenticationId: sourceId,
        }),
      );
    if (!response.force && forcible) yield put(push('?', { tryAgain: true }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
};

export const imageDelete = function* imageDelete({ payload: { source, sourceId, imageId } }) {
  const url = 'image/v4/delete';
  try {
    const response = yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        source,
        sourceId,
        imageId,
      },
    });
    yield put(imageDeleteResponse(response));
    yield put(imageListRequest({ source, sourceId }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
};

export const imageList = function* imageList({ payload: { source, sourceId } }) {
  const url = 'image/v4/list';
  try {
    const response = yield call(fetchWrapper, {
      method: 'GET',
      url,
      params: {
        source,
        sourceId,
      },
      withQuery: true,
    });
    yield put(imageListResponse({ data: response }));
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
};
