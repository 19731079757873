import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import { Heading, Text } from '@ebay-certilogo/design-system-web';
import { Formik } from 'formik';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import DtButton from 'components/cms/dataTypes/Button';
import Form from '../form/FormComponent';
import FormInputType from '../cms/form/FormInputType';
import { createValidationSchema } from '../cms/form/dynamicValidation';
import messages from './messages';
import { Wrapper, Question, Answer, FormInputWrapper } from './styles';

function Survey({
  colorType,
  data,
  onSubmit,
  submitLoading,
  slim,
  onTouchStart,
  onTouchEnd,
  submitLabel,
  propsBag,
  complete: _complete,
  completeBody,
  completeMessage: _completeMessage,
}) {
  const title = get(data, 'title');
  const questions = get(data, 'survey_questions');
  const submitButton = data?.submitButton;
  const completeMessage = !isEmpty(data?.completeMessage)
    ? data?.completeMessage
    : _completeMessage;
  const validationSchema = createValidationSchema(flatMap(questions, ({ answer }) => answer));
  const complete = !isNil(data?.complete) ? data?.complete : _complete;
  return (
    !isEmpty(data) && (
      <Wrapper>
        {complete ? (
          completeBody || (
            <Heading colorType={colorType} textAlign="center">
              <FormattedMessage
                id={completeMessage || messages.completeMessage.id}
                defaultMessage={completeMessage || messages.completeMessage.defaultMessage}
                values={propsBag}
              />
            </Heading>
          )
        ) : (
          <>
            {!!title && (
              <Text colorType={colorType} size="m" weight="extrabold" marginBottom="m">
                <FormattedMessage id={title} defaultMessage={title} values={propsBag} />
              </Text>
            )}
            <Formik initialValues={{}} validationSchema={validationSchema} onSubmit={onSubmit}>
              {({ dirty, handleSubmit }) => (
                <Form>
                  {map(questions, ({ question, answer, answerInline }, i) => (
                    <Question key={`${question}-${i}`} elevation={questions.length + 10 - i}>
                      <Heading
                        colorType={colorType}
                        type={slim ? 'h3' : 'h2'}
                        margin={{ size: 's', position: 'bottom' }}
                      >
                        <FormattedMessage
                          id={question}
                          defaultMessage={question}
                          values={propsBag}
                        />
                      </Heading>
                      <Answer answerInline={answerInline} multipleAnswers={answer.length > 1}>
                        {map(answer, ({ __component, _id, ...rest }) => (
                          <FormInputWrapper
                            multipleAnswers={answer.length > 1}
                            slim={slim}
                            key={_id}
                          >
                            <FormInputType
                              type={__component}
                              data={rest}
                              key={_id}
                              colorType={colorType}
                              propsBag={propsBag}
                              onTouchStart={onTouchStart}
                              onTouchEnd={onTouchEnd}
                            />
                          </FormInputWrapper>
                        ))}
                      </Answer>
                    </Question>
                  ))}
                  <DtButton
                    type="button"
                    onClick={handleSubmit}
                    loading={submitLoading}
                    elevation={-1}
                    noMargin
                    {...submitButton}
                    text={
                      !isEmpty(submitButton?.text)
                        ? submitButton?.text
                        : submitLabel || messages.submitLabel.id
                    }
                    colorType={submitButton?.colorType || colorType}
                    buttonType={!dirty && 'transparent'}
                    propsBag={propsBag}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
      </Wrapper>
    )
  );
}
Survey.propTypes = {
  data: PropTypes.object,
  colorType: PropTypes.string,
  submitLabel: PropTypes.node,
  submitLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  slim: PropTypes.bool,
  onTouchStart: PropTypes.func,
  onTouchEnd: PropTypes.func,
  complete: PropTypes.bool,
  completeMessage: PropTypes.string,
  completeBody: PropTypes.node,
  propsBag: PropTypes.object,
};
export default Survey;
