import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormattedMessage } from 'react-intl';
import { Button } from '@ebay-certilogo/design-system-web';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { selectProxyLoading } from 'containers/Proxy/selectors';

import Form from 'components/form/FormComponent';
import ErrorMessage from 'components/form/ErrorMessage';
import messages from '../messages';
import CodeInputField from './CodeInputField';
import { serviceRequest } from '../actions';

const validationSchema = Yup.object().shape({
  code: Yup.string()
    .nullable()
    .matches(/^(\d{12}|\d{14}|\d{16}|\d{18}|\d{20})$/)
    .required(<ErrorMessage type="required" />),
});

function CodeInput({ colorType, flow }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectProxyLoading);

  const handleSubmit = ({ code: inputCode }) => {
    if (!isLoading) {
      dispatch(serviceRequest({ code: inputCode, origin: 'typed', flow }));
    }
  };

  return (
    <Formik
      initialValues={{ code: null }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({ dirty, errors }) => {
        return (
          <Form>
            <Field
              name="code"
              component={CodeInputField}
              colorType={colorType}
              showErrorLabel={false}
            />
            <Button
              loading={isLoading}
              type="submit"
              margin={{ size: 'l', position: 'bottom' }}
              disabled={!!(errors || {}).code || !dirty}
              colorType={colorType}
            >
              <FormattedMessage {...messages.submitButton} />
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

CodeInput.propTypes = {
  colorType: PropTypes.string,
  flow: PropTypes.string,
};

export default CodeInput;
