import { call, put } from 'redux-saga/effects';
import fetchWrapper from 'utils/Api/fetchWrapper';
import { sendResponse } from '../actions';

export default function* send({ payload: { type, answer, id, service, surveyId, sessionId } }) {
  const url = 'survey/save';
  try {
    yield call(fetchWrapper, {
      method: 'POST',
      url,
      params: {
        type,
        answer,
        id,
        service,
        surveyId,
        sessionId,
      },
    });
    yield put(sendResponse());
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
    yield put(sendResponse());
  }
}
