import {
  Facebook,
  Instagram,
  Youtube,
  X,
  Pinterest,
  Wechat,
  Weibo,
  Linkedin,
  Vimeo,
  Spotify,
  Soundcloud,
  AppleMusic,
  Telegram,
  TikTok,
  Vk,
  Whatsapp,
  Red,
} from '@ebay-certilogo/design-system-web/dist/components/icons';

export default {
  facebook: {
    icon: Facebook,
    target: '_blank',
  },
  instagram: {
    icon: Instagram,
    target: '_blank',
  },
  youtube: {
    icon: Youtube,
    target: '_blank',
  },
  pinterest: {
    icon: Pinterest,
    target: '_blank',
  },
  twitter: {
    icon: X,
    target: '_blank',
  },
  wechat: {
    icon: Wechat,
    target: '_self',
  },
  weibo: {
    icon: Weibo,
    target: '_blank',
  },
  linkedin: {
    icon: Linkedin,
    target: '_blank',
  },
  vimeo: {
    icon: Vimeo,
    target: '_blank',
  },
  spotify: {
    icon: Spotify,
    target: '_blank',
  },
  soundcloud: {
    icon: Soundcloud,
    target: '_blank',
  },
  applemusic: {
    icon: AppleMusic,
    target: '_blank',
  },
  telegram: {
    icon: Telegram,
    target: '_blank',
  },
  tiktok: {
    icon: TikTok,
    target: '_blank',
  },
  vk: {
    icon: Vk,
    target: '_blank',
  },
  whatsapp: {
    icon: Whatsapp,
    target: '_blank',
  },
  red: {
    icon: Red,
    target: '_blank',
  },
};
