import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Heading, Text, Modal } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function RecallModal({ type, colorType, onClose }) {
  const messagesType = messages[type];
  const [isOpen, setIsOpen] = useState(!!type);
  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} close onClose={handleOnClose} colorType={colorType}>
      {isOpen && (
        <>
          <Heading colorType={colorType} type="h2" textAlign="center">
            <FormattedMessage {...messagesType.title} />
          </Heading>
          <Text colorType={colorType} textAlign="center" marginBottom="l">
            <FormattedMessage {...messagesType.text} />
          </Text>
        </>
      )}
    </Modal>
  );
}

RecallModal.propTypes = {
  colorType: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func,
};

export default RecallModal;
