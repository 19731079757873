import React, { useEffect, useState } from 'react';
import { Button, FormFormik, Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { setBack } from 'containers/App/actions';
import {
  selectAuthLoginLoading,
  selectErrors,
  selectAuthData,
  selectPrivacyPolicySignupConfig,
} from 'containers/Auth/selectors';
import { loginRequest, setAuthErrors } from 'containers/Auth/actions';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import _Link from 'components/Link';
import Form from 'components/form/FormComponent';
import ErrorMessage from 'components/form/ErrorMessage';
import { isChina } from 'utils/utils';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import TermsAndConditions from 'components/TermsAndConditions';
import Fields from '../../SetData/Fields';
import messages from './messages';
import PwdInput from './PwdInput';

export const Link = styled(_Link)`
  display: block;
  text-align: center;
`;

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, <FormattedMessage {...messages.pswErrorSignUp} />)
    .required(<ErrorMessage type="required" />),
});

const validationSchemaChina = Yup.object().shape({
  password: Yup.string()
    .min(6, <FormattedMessage {...messages.pswErrorSignUp} />)
    .required(<ErrorMessage type="required" />),
  pipl: Yup.bool()
    .required(<ErrorMessage type="required" />)
    .oneOf([true], <ErrorMessage type="required" />),
  cnPrivacyTerms: Yup.bool()
    .required(<ErrorMessage type="required" />)
    .oneOf([true], <ErrorMessage type="required" />),
});

function PSWLogin({ colorType, intl, type, primaryBrand, secondaryBrand }) {
  const privacyPolicySignupConfig = useSelector(selectPrivacyPolicySignupConfig);
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);

  const data = useSelector(selectAuthData);
  const email = get(data, 'user.email');
  const setData = get(data, 'setData');
  const errorsServer = useSelector(selectErrors);

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  const handleSubmit = ({ password, ...userData }) => {
    setSubmitted(true);
    dispatch(
      loginRequest({
        email,
        login: type === 'login',
        redirect: true,
        password,
        setData,
        userData: setData && userData,
      }),
    );
  };

  const isLoading = useSelector(selectAuthLoginLoading) && submitted;

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      validationSchema={isChina && type === 'signUp' ? validationSchemaChina : validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldError, errors, handleBlur, dirty }) => {
        useEffect(() => {
          if (dirty) dispatch(setAuthErrors());
        }, [dirty]);
        if (isEmpty(errors) && (errorsServer || [])[0] === 'login.email.errors.password.invalid')
          setFieldError(
            'password',
            <FormattedMessage
              {...messages[type === 'signUp' ? 'pswErrorSignUp' : 'pswErrorLogin']}
            />,
          );

        return (
          <Form>
            <Field
              name="password"
              component={PwdInput}
              colorType={colorType}
              placeholder={intl.formatMessage(messages[`placeholder_${type}`])}
              overrideSubmitted
              onBlur={handleBlur}
            />
            {type === 'login' && (
              <Text
                as={Link}
                to="forgot-password"
                colorType={colorType}
                weight="extrabold"
                textAlign="right !important"
                marginBottom="s"
              >
                <FormattedMessage {...messages.forgot} />
              </Text>
            )}
            {isChina && type === 'signUp' && (
              <>
                <Field
                  name="pipl"
                  component={FormFormik.Checkbox}
                  colorType={colorType}
                  text={
                    <FormattedMessageMarkdown
                      {...messages.pipl}
                      colorType={colorType}
                      values={{
                        termsUrl: privacyPolicySignupConfig?.tcDocument?.url,
                        privacyUrl: privacyPolicySignupConfig?.privacyDocument?.url,
                      }}
                    />
                  }
                />
                <Field
                  name="cnPrivacyTerms"
                  component={FormFormik.Checkbox}
                  colorType={colorType}
                  text={
                    <TermsAndConditions
                      colorType={colorType}
                      type="signup"
                      primaryBrand={primaryBrand}
                      secondaryBrand={secondaryBrand}
                    />
                  }
                />
              </>
            )}
            {setData && <Fields colorType={colorType} />}
            <Button type="submit" colorType={colorType} loading={isLoading}>
              <FormattedMessage {...messages[`submitButton_${type}`]} />
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
}

PSWLogin.propTypes = {
  colorType: PropTypes.string,
  intl: PropTypes.object,
  type: PropTypes.string,
  primaryBrand: PropTypes.string,
  secondaryBrand: PropTypes.string,
};

export default injectIntl(PSWLogin);
