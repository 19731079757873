import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_creation_intro.title1',
    defaultMessage: 'Buyers want to know if your product is authentic!',
  },
  text: {
    id: 'soa_creation_intro.text1',
    defaultMessage:
      'Creating a Certilogo Seal of Authentication lets them check the CLG Code before deciding to buy.',
  },
  takePhotoMessage: {
    id: 'soa_creation_intro.text3',
    defaultMessage: "You'll need to take photos of your product, keep it handy.",
  },
  button: {
    id: 'soa_creation_intro.button1',
    defaultMessage: 'Request a seal',
  },
});
