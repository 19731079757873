import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useParams, useRouteMatch, useLocation } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import Route from 'components/Route';
import Switch from 'components/Switch';
import { get } from 'lodash';
import usePageType from 'components/hooks/usePageType';
import { selectColorTypes } from 'containers/App/selectors';
import { push } from 'connected-react-router';
import useHeaderUrl from 'components/hooks/useHeaderUrl';
import { getAuthenticationRequest, flowRequest, createAuthenticationRequest } from './actions';
import saga from './saga';
import { selectAuthenticationLoading, selectAuthenticationData } from './selectors';

import Onboarding from './states/Onboarding';
import Purpose from './states/Purpose';
import Where from './states/Where';
import RetailerOnline from './states/RetailerOnline';
import RetailerStore from './states/RetailerStore';
import Optin from './states/Optin';
import SoaProductDetails from './states/SoaProductDetails';
import SelectBrand from './states/SelectBrand';
import AdditionalInfo from './states/AdditionalInfo';
import MarketplaceAlias from './states/MarketplaceAlias';
import ImageUpload from './states/ImageUpload';
import Unconfirmed from './states/Unconfirmed';
import Complete from './states/Complete';
import Login from './states/Login';
import SoaRequest from './states/SoaRequest';
import ShortResponse from './states/ShortResponse';
import AutoNext from './states/AutoNext';

function Authentication() {
  usePageType('authentication');
  useInjectSaga({ key: 'authentication', saga });
  const dispatch = useDispatch();
  const colorTypes = useSelector(selectColorTypes);
  const colorTypeAuthentication = colorTypes.authentication;
  const { path } = useRouteMatch();
  const { authenticationId } = useParams();
  const location = useLocation();
  const isLoading = useSelector(selectAuthenticationLoading);
  const data = useSelector(selectAuthenticationData);

  const action = get(location, 'state.action');
  const sessionId = get(location, 'state.sessionId');
  const flow = get(location, 'state.flow');
  useHeaderUrl({ service: data?.service, sessionId: data?.sessionId });

  useEffect(() => {
    if (action)
      dispatch(
        flowRequest({
          action,
          authenticationId,
        }),
      );
  }, [action]);

  useEffect(() => {
    if (!action && !isLoading) {
      if (authenticationId) {
        dispatch(getAuthenticationRequest({ authenticationId }));
      } else if (sessionId) {
        dispatch(createAuthenticationRequest({ sessionId, flow }));
      } else {
        dispatch(push('/code'));
      }
    }
  }, []);

  if (isLoading || action) return <LoadingIndicator colorType={colorTypeAuthentication} />;

  return (
    <Switch>
      <Route path={`${path}/onboarding`}>
        <Onboarding
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/auto_next`}>
        <AutoNext
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/purpose`}>
        <Purpose
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/where`}>
        <Where
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/optin`}>
        <Optin
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/retailer_store`}>
        <RetailerStore
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/retailer_online`}>
        <RetailerOnline
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/select_brand`}>
        <SelectBrand
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/additional_info`}>
        <AdditionalInfo
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/soa_request`}>
        <SoaRequest
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/soa_product_details`}>
        <SoaProductDetails
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/marketplace_alias`}>
        <MarketplaceAlias
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/image_upload`}>
        <ImageUpload
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/short_response`}>
        <ShortResponse
          colorType={colorTypes.authenticationShortResponse}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/unconfirmed`}>
        <Unconfirmed colorType="accent" authenticationId={authenticationId} data={data || {}} />
      </Route>
      <Route path={`${path}/complete`}>
        <Complete
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
      <Route path={`${path}/login`}>
        <Login
          colorType={colorTypeAuthentication}
          authenticationId={authenticationId}
          data={data || {}}
        />
      </Route>
    </Switch>
  );
}

Authentication.propTypes = {
  colorType: PropTypes.string,
};

export default Authentication;
