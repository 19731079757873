import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import { selectIsAuthenticated } from 'containers/Auth/selectors';
import LoadingIndicator from 'components/LoadingIndicator';
import { Redirect } from 'react-router-dom';
import withColorType from 'components/HOC/withColorType';

function Login({ data, authenticationId }) {
  const { status } = data;
  const dispatch = useDispatch();
  const isAutenticated = useSelector(selectIsAuthenticated);
  const primaryBrand = data?.primaryBrand;
  const secondaryBrand = data?.tmBrand;
  const countryCode = data?.props?.countryCode;

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (authenticationId && isAutenticated) {
      dispatch(
        flowRequest({
          action: 'next',
          status,
          authenticationId,
          data: {},
        }),
      );
    }
  }, [authenticationId]);

  return !isAutenticated ? (
    <Redirect
      to={{ pathname: '/auth/sign-up', state: { primaryBrand, secondaryBrand, countryCode } }}
    />
  ) : (
    <LoadingIndicator />
  );
}

Login.propTypes = {
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    primaryBrand: PropTypes.object,
    tmBrand: PropTypes.object,
    props: PropTypes.shape({
      countryCode: PropTypes.string,
    }),
  }),
};

export default withColorType(Login);
