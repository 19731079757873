import React from 'react';
import PropTypes from 'prop-types';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import useLocale from 'components/hooks/useLocale';
import { isEmpty } from 'lodash';

function DateInput({ id, name, placeholder, label, colorType, ...rest }) {
  const intl = useIntl();
  const locale = useLocale();

  return (
    <Field
      name={name || id}
      component={FormFormik.DatePicker}
      colorType={colorType}
      placeholder={
        !isEmpty(placeholder) &&
        intl.formatMessage({ id: placeholder, defaultMessage: placeholder })
      }
      label={!isEmpty(label) && <FormattedMessage id={label} defaultMessage={label} />}
      locale={locale}
      {...rest}
    />
  );
}

DateInput.propTypes = {
  id: PropTypes.string,
  colorType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

export default DateInput;
