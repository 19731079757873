import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Text, Modal } from '@ebay-certilogo/design-system-web';
import { selectChangeEmailLoading, selectUser } from 'containers/Auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { changeEmailRequest } from 'containers/Auth/actions';
import messages from './messages';

function ResendCode({ colorType }) {
  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const email = useSelector(selectUser);
  const isLoading = useSelector(selectChangeEmailLoading);

  const handleResendCode = () => {
    setSubmitted(true);
    dispatch(changeEmailRequest({}));
  };

  useEffect(() => {
    if (submitted && !isLoading) {
      setIsModalOpen(true);
    }
  }, [isLoading, submitted]);

  return (
    <>
      <Button
        buttonType="link"
        colorType={colorType}
        arrow={false}
        noMargin
        onClick={handleResendCode}
        loading={isLoading}
      >
        <FormattedMessage {...messages.link} />
      </Button>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} close colorType={colorType}>
        <Text colorType={colorType} weight="extrabold" marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.title} />
        </Text>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.text1} values={{ email }} />
        </Text>
      </Modal>
    </>
  );
}

ResendCode.propTypes = {
  colorType: PropTypes.string,
};

export default ResendCode;
