import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Heading,
  Text,
  CompleteBackground,
} from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import withColorType from 'components/HOC/withColorType';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import messages from './messages';
import SendEmailForm from './SendEmailForm';

function Unconfirmed({ colorType, data, authenticationId }) {
  const { status } = data;
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleOnSuccess = () => {
    setIsOpen(false);
  };

  return (
    <CompleteBackground>
      <Heading type="big" textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.title} />
      </Heading>
      <Heading type="h1" textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.subtitle} />
      </Heading>
      <Text textAlign="center" colorType={colorType}>
        <FormattedMessage {...messages.description} />
      </Text>
      <Button buttonType="link" colorType={colorType} arrow={false} onClick={() => setIsOpen(true)}>
        <FormattedMessage {...messages.link} />
      </Button>
      <Modal colorType="dark" isOpen={isOpen} onClose={() => setIsOpen(false)} fullScreen close>
        <HeaderBox
          colorType="dark"
          title={
            <Heading type="h2" colorType={colorType} themeField="primaryAccentColor">
              <FormattedMessage {...messages.modal.box.title} />
            </Heading>
          }
          description={<FormattedMessage {...messages.modal.box.description} />}
        />
        <SendEmailForm
          authenticationId={authenticationId}
          onSuccess={handleOnSuccess}
          colorType="dark"
        />
      </Modal>
    </CompleteBackground>
  );
}

Unconfirmed.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
};

export default withColorType(Unconfirmed);
