import { Line } from 'rc-progress';
import styled from 'styled-components';
import { primaryColor } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { transparentize } from 'polished';

const LineStyle = styled(Line).attrs((props) => ({
  strokeColor: primaryColor(props),
  trailColor: transparentize(0.9, primaryColor(props)),
}))``;

export default LineStyle;
