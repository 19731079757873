import React from 'react';
import PropTypes from 'prop-types';
import { List, Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function NotFoundRow({ colorType }) {
  return (
    <List.Row
      colorType={colorType}
      body={
        <Text colorType={colorType} weight="semibold" margin={{ size: 's', position: 'vertical' }}>
          <FormattedMessage {...messages.notFound} />
        </Text>
      }
    />
  );
}

NotFoundRow.propTypes = {
  colorType: PropTypes.string,
};

export default NotFoundRow;
