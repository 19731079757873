import { call, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import withQueryUtils from 'with-query';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import config from 'config';
import { loginResponse, logout, setRedirect } from 'containers/Auth/actions';
import history from 'utils/history';
import getHeaders from './getHeaders';
import { getFetchHeaders } from './utils';

const hostname = config.api.hostname;

const validateResponse = (response) => {
  if (!response.ok) {
    return Promise.reject(response);
  }
  return response;
};

const readResponseAsJSON = (response) => {
  const json = response.json();
  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(json);
  }
  return Promise.reject(response);
};

const readResponseAsIMAGE = (response) => {
  const blob = response.blob();

  if (response.status >= 200 && response.status < 400) {
    return Promise.resolve(blob);
  }
  return Promise.reject(response);
};

const handleResponseError = (response) =>
  new Promise((resolve, reject) => {
    response
      .text() // response.json() doesn't appear to support sensible error handling of non-JSON
      .then((text) => {
        let jsonData = {};
        try {
          jsonData = JSON.parse(text); // try to do our own parsing of the json here
        } catch (err) {
          // no-op
        }
        return jsonData;
      })
      .then((packet) => {
        const error = { packet, response };
        return reject(error);
      });
  });

export const fetchWrapper = ({
  url,
  options: { method = 'GET', headers, body } = {},
  formData,
  withQuery,
  authenticated,
  image,
}) => {
  if (image)
    return fetch(url.includes('http:') ? url : `${hostname}/${url}`, {
      method,
      body: body ? JSON.stringify({ ...body }) : formData || undefined,
      headers: getFetchHeaders({ url, headers, formData, authenticated }),
    })
      .then(validateResponse)
      .then(readResponseAsIMAGE)
      .then((images) => URL.createObjectURL(images))
      .catch(handleResponseError);

  if (withQuery)
    return fetch(withQueryUtils(url.includes('http:') ? url : `${hostname}/${url}`, body), {
      method,
      headers: getFetchHeaders({ url, headers, formData, authenticated }),
    })
      .then(validateResponse)
      .then(readResponseAsJSON)
      .catch(handleResponseError);

  return fetch(url.includes('http:') ? url : `${hostname}/${url}`, {
    method,
    body: body ? JSON.stringify({ ...body }) : formData || undefined,
    headers: getFetchHeaders({ url, headers, formData, authenticated }),
  })
    .then(validateResponse)
    .then(readResponseAsJSON)
    .catch(handleResponseError);
};

function* ApiWrapper({
  url,
  method = 'GET',
  params,
  noCheckJWT,
  authenticated,
  formData,
  withQuery,
  image,
  unhandle,
}) {
  const headers = yield call(getHeaders, { noCheckJWT });

  const options = {
    method,
    headers,
    body: params || undefined,
  };

  // eslint-disable-next-line no-useless-catch
  try {
    const response = yield call(fetchWrapper, {
      url,
      options,
      formData,
      withQuery,
      authenticated,
      image,
    });

    const { authData } = response;
    if (authData) {
      if (isEmpty(authData)) {
        yield put(logout());
      } else {
        yield put(loginResponse({ data: authData }));
      }
    }

    return response;
  } catch (res) {
    if (!unhandle && (res === '500' || get(res, 'response.status') === 500)) {
      yield put(push('/generic-error'));
    }

    if ([401, 403].includes(get(res, 'response.status'))) {
      yield put(setRedirect(history.location.pathname));
      yield put(push(`/auth/sign-up`));
    }

    throw res;
  }
}

export default ApiWrapper;
