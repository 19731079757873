import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { StoreOnline } from '@ebay-certilogo/design-system-web/dist/components/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import Lists from 'components/Lists';
import Link from 'components/Link';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import defaultMessages from './messages';
import flowMessages from './flowMessages';

function SelectAlias({ colorType, data, handleSubmit }) {
  const dispatch = useDispatch();

  const { state } = useLocation();

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
  }, []);

  console.log({ data });

  const overrideMessages = flowMessages[data?.flow] || {};
  const messages = useMemo(
    () => ({
      ...defaultMessages,
      ...overrideMessages,
    }),
    [data],
  );

  return (
    <>
      <HeaderBox monochrome colorType={colorType} text={<FormattedMessage {...messages.title} />} />
      <Lists
        colorType={colorType}
        clickable
        onClick={({ alias, marketplace: { id } }) => handleSubmit({ soaAlias: alias, store: id })}
        left={<StoreOnline width={18} height={18} colorType={colorType} />}
        accessoryRow="alias"
        type="oneRow"
        bordered
        data={state || []}
        marginTop="l"
        marginBottom="l"
      />

      <Link
        to={{
          pathname: 'insert_alias',
          state: {
            marketplaceId: state[0].marketplace.id,
          },
        }}
        colorType={colorType}
      >
        <FormattedMessage {...messages.link} />
      </Link>
    </>
  );
}

SelectAlias.propTypes = {
  colorType: PropTypes.string,
  handleSubmit: PropTypes.func,
  data: PropTypes.shape({
    flow: PropTypes.string,
  }),
};

export default SelectAlias;
