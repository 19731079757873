import Text from './Text';
import Image from './Image';
import Date from './Date';

export default {
  'form-input-types.checkbox': Text,
  'form-input-types.radio': Text,
  'form-input-types.select': Text,
  'form-input-types.slider': Text,
  'form-input-types.text': Text,
  'form-input-types.date-input': Date,
  'form-input-types.image-input': Image,
};
