import React, { useEffect } from 'react';
import { FormFormik, Button, Heading, Text, Div } from '@ebay-certilogo/design-system-web';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pick from 'lodash/pick';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateRequest } from 'containers/FakeReport/actions';
import ErrorMessage from 'components/form/ErrorMessage';
import Form from 'components/form/FormComponent';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import useLocale from 'components/hooks/useLocale';
import CardClg from 'components/CardClg';
import styled from 'styled-components';
import { subYears } from 'date-fns';
import messages from './messages';

const { TextInput, DatePicker } = FormFormik;

const FieldWrapper = styled(Div).attrs({
  marginBottom: 'l',
})``;

const validation = {
  givenName: Yup.string()
    .nullable()
    .required(<ErrorMessage type="required" />),
  familyName: Yup.string()
    .nullable()
    .required(<ErrorMessage type="required" />),
  birthdate: Yup.date()
    .nullable()
    .required(<ErrorMessage type="required" />),
};

const validationSchema = Yup.object().shape(validation);
const onlineValidationSchema = Yup.object().shape({
  ...validation,
  authentication: Yup.object().shape({
    store: Yup.object().shape({
      url: Yup.string().required(<ErrorMessage type="required" />),
    }),
  }),
});

function New({ id, status, data }) {
  const dispatch = useDispatch();
  const locale = useLocale();
  const isOnline = get(data, 'authentication.store.online');
  useEffect(() => {
    if (data) {
      dispatch(unsetBack());
    }
  }, [data]);

  const handleSubmit = ({ goToHome, ...values }) => {
    const params = pick(values, ['givenName', 'familyName', 'birthdate']);
    dispatch(updateRequest({ params, action: 'next', id, status, goToHome }));
  };
  return (
    <CardClg>
      {({ colorType: cardColorType }) => (
        <>
          <Heading type="h3" colorType={cardColorType} marginBottom="s">
            <FormattedMessage {...messages.title} />
          </Heading>
          <Text colorType={cardColorType} size="s" marginBottom="m">
            <FormattedMessage {...messages.text1} />
          </Text>
          <Formik
            initialValues={data}
            validationSchema={isOnline ? onlineValidationSchema : validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values }) => (
              <Form>
                <FieldWrapper>
                  <Field
                    name="givenName"
                    component={TextInput}
                    label={<FormattedMessage {...messages.givenName} />}
                    colorType={cardColorType}
                  />
                </FieldWrapper>
                <FieldWrapper>
                  <Field
                    name="familyName"
                    component={TextInput}
                    label={<FormattedMessage {...messages.familyName} />}
                    colorType={cardColorType}
                  />
                  <FieldWrapper />
                  <Field
                    name="birthdate"
                    component={DatePicker}
                    label={<FormattedMessage {...messages.birthdate} />}
                    colorType={cardColorType}
                    locale={locale}
                    datePickerProps={{
                      shownDate: !values.birthdate && subYears(new Date(), 18),
                    }}
                  />
                </FieldWrapper>
                <Button colorType={cardColorType} type="submit" noMargin>
                  <FormattedMessage {...messages.continue} />
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </CardClg>
  );
}

New.propTypes = {
  colorType: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object,
};

export default New;
