import React, { useEffect, useState } from 'react';
import { Text, FormFormik } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import { Formik, Field } from 'formik';
import { countBy, get, pickBy, keys as _keys, mapValues } from 'lodash';
import Form from 'components/form/FormComponent';
import withColorType from 'components/HOC/withColorType';
import FlowButton from 'containers/Authentication/components/FlowButton';
import HeaderBox from 'containers/Authentication/components/HeaderBox';
import FormattedMessageMarkdown from 'components/FormattedMessageMarkdown';
import messages from './messages';
import { ToggleWrapper, BulkToggle, FieldWrapper } from './styles';

const { Toggle } = FormFormik;

function Optin({ colorType, data, authenticationId }) {
  const { status, props: { config } = {} } = data;
  const dispatch = useDispatch();
  const [bulkToggle, setBulkToggle] = useState(false);

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = (values) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: values,
      }),
    );
  };

  const activeFields = {
    marketingOptin: config?.marketingOptin,
    profilingOptin: config?.profilingOptin,
    secondaryMarketingOptin: config?.secondaryMarketingOptin,
    secondaryProfilingOptin: config?.secondaryProfilingOptin,
  };
  const activeFieldsPick = pickBy(activeFields, (value) => value === true);
  const activeFieldsFlat = _keys(activeFieldsPick);
  const countActiveFields = countBy(activeFields, (value) => value === true);
  const showBulkToggle = config?.page?.selectAll && countActiveFields.true > 1;
  const primaryBrandName = get(config?.parameters?.primaryBrand, 'name');
  const secondaryBrandName = get(config?.parameters?.secondaryBrand, 'name');
  const initialValues = mapValues(activeFieldsPick, () => false);
  const defaultMessagesValues = {
    primaryBrand: primaryBrandName,
    secondaryBrand: secondaryBrandName,
    primaryBrandPrivacyUrl: config?.primaryBrandPrivacyDocument?.url,
    secondaryBrandPrivacyUrl: config?.secondaryBrandPrivacyDocument?.url,
  };

  return (
    <>
      <HeaderBox
        monochrome
        colorType={colorType}
        title={
          config?.page?.title && (
            <Text size="m" weight="extrabold" colorType={colorType}>
              <FormattedMessageMarkdown
                id={config?.page?.title}
                defaultMessage={config?.page?.title}
                values={defaultMessagesValues}
                colorType={colorType}
              />
            </Text>
          )
        }
        subtitle={
          config?.page?.text && (
            <Text size="xxs" colorType={colorType}>
              <FormattedMessageMarkdown
                id={config?.page?.text}
                defaultMessage={config?.page?.text}
                values={defaultMessagesValues}
                colorType={colorType}
              />
            </Text>
          )
        }
      />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values, setFieldValue }) => {
          const handleBulkChange = (value) => {
            activeFieldsFlat.forEach((name) => {
              setFieldValue(name, value);
            });
          };
          const countChecked = countBy(values, (value) => value === true);
          setBulkToggle(countChecked.true === countActiveFields.true);
          return (
            <Form>
              {(config?.marketingOptin || config?.secondaryMarketingOptin) && (
                <>
                  {config?.page?.marketingText && (
                    <Text
                      colorType={colorType}
                      size="xxs"
                      margin={{ size: 's', position: 'bottom' }}
                    >
                      <FormattedMessageMarkdown
                        id={config?.page?.marketingText}
                        defaultMessage={config?.page?.marketingText}
                        values={defaultMessagesValues}
                      />
                    </Text>
                  )}
                  <ToggleWrapper>
                    {config?.marketingOptin && (
                      <FieldWrapper fullWidth={!config?.secondaryMarketingOptin}>
                        <Field
                          name="marketingOptin"
                          id="marketingOptin"
                          text={
                            <Text size="xxs" colorType={colorType}>
                              {config?.page?.primaryMarketingToggle ? (
                                <FormattedMessageMarkdown
                                  id={config?.page?.primaryMarketingToggle}
                                  defaultMessage={config?.page?.primaryMarketingToggle}
                                  values={defaultMessagesValues}
                                />
                              ) : (
                                primaryBrandName
                              )}
                            </Text>
                          }
                          component={Toggle}
                          colorType={colorType}
                          noMargin
                        />
                      </FieldWrapper>
                    )}
                    {config?.secondaryMarketingOptin && (
                      <FieldWrapper fullWidth={!config?.marketingOptin}>
                        <Field
                          name="secondaryMarketingOptin"
                          id="secondaryMarketingOptin"
                          text={
                            <Text size="xxs" colorType={colorType}>
                              {config?.page?.secondaryMarketingToggle ? (
                                <FormattedMessageMarkdown
                                  id={config?.page?.secondaryMarketingToggle}
                                  defaultMessage={config?.page?.secondaryMarketingToggle}
                                  values={defaultMessagesValues}
                                />
                              ) : (
                                secondaryBrandName
                              )}
                            </Text>
                          }
                          component={Toggle}
                          colorType={colorType}
                          noMargin
                        />
                      </FieldWrapper>
                    )}
                  </ToggleWrapper>
                </>
              )}
              {(config?.profilingOptin || config?.secondaryProfilingOptin) && (
                <>
                  {config?.page?.profilingText && (
                    <Text
                      colorType={colorType}
                      size="xxs"
                      margin={{ size: 's', position: 'bottom' }}
                    >
                      <FormattedMessageMarkdown
                        id={config?.page?.profilingText}
                        defaultMessage={config?.page?.profilingText}
                        values={defaultMessagesValues}
                      />
                    </Text>
                  )}
                  <ToggleWrapper>
                    {config?.profilingOptin && (
                      <FieldWrapper fullWidth={!config?.secondaryProfilingOptin}>
                        <Field
                          name="profilingOptin"
                          id="profilingOptin"
                          text={
                            <Text size="xxs" colorType={colorType}>
                              {config?.page?.primaryProfilingToggle ? (
                                <FormattedMessageMarkdown
                                  id={config?.page?.primaryProfilingToggle}
                                  defaultMessage={config?.page?.primaryProfilingToggle}
                                  values={defaultMessagesValues}
                                />
                              ) : (
                                primaryBrandName
                              )}
                            </Text>
                          }
                          component={Toggle}
                          colorType={colorType}
                          noMargin
                        />
                      </FieldWrapper>
                    )}
                    {config?.secondaryProfilingOptin && (
                      <FieldWrapper fullWidth={!config?.profilingOptin}>
                        <Field
                          name="secondaryProfilingOptin"
                          id="secondaryProfilingOptin"
                          text={
                            <Text size="xxs" colorType={colorType}>
                              {config?.page?.secondaryProfilingToggle ? (
                                <FormattedMessageMarkdown
                                  id={config?.page?.secondaryProfilingToggle}
                                  defaultMessage={config?.page?.secondaryProfilingToggle}
                                  values={defaultMessagesValues}
                                />
                              ) : (
                                secondaryBrandName
                              )}
                            </Text>
                          }
                          component={Toggle}
                          colorType={colorType}
                          noMargin
                        />
                      </FieldWrapper>
                    )}
                  </ToggleWrapper>
                </>
              )}
              {showBulkToggle && (
                <ToggleWrapper>
                  <BulkToggle
                    name="bulkToggle"
                    id="bulkToggle"
                    text={
                      <Text size="xxs" colorType={colorType} style={{ textTransform: 'uppercase' }}>
                        <FormattedMessageMarkdown
                          {...messages[bulkToggle ? 'deselectAll' : 'selectAll']}
                          values={defaultMessagesValues}
                        />
                      </Text>
                    }
                    checked={bulkToggle}
                    colorType={colorType}
                    onChange={handleBulkChange}
                    noMargin
                  />
                </ToggleWrapper>
              )}
              <FlowButton type="submit" colorType={colorType} noMargin>
                <FormattedMessageMarkdown {...messages.button} />
              </FlowButton>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

Optin.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    props: PropTypes.object,
  }),
};

export default withColorType(Optin);
