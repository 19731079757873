/* eslint-disable no-unsafe-optional-chaining */
// eslint-disable-next-line import/prefer-default-export
export const calculateImageDimensions = ({ image, maxHeight, maxWidth, scale = 1 }) => {
  const landscape = image?.width >= image?.height;
  let bgCalcHeight = image?.height >= maxHeight ? maxHeight : image?.height;
  let bgCalcWidth = (image?.width * bgCalcHeight) / image?.height;

  if (landscape) {
    bgCalcWidth = image?.width >= maxWidth ? maxWidth : image?.width;
    bgCalcHeight = (image?.height * bgCalcWidth) / image?.width;
  }
  return {
    width: bgCalcWidth * scale,
    height: bgCalcHeight * scale,
  };
};
