import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'settings.delete_confirm',
    defaultMessage: 'Are you sure to delete {email}?',
  },
  buttonOpen: {
    id: 'settings.delete_account',
    defaultMessage: 'Delete user',
  },
  buttonYes: {
    id: 'settings.yes_continue',
    defaultMessage: 'Yes',
  },
  buttonNo: {
    id: 'settings.back',
    defaultMessage: 'No',
  },
});
