import { get, find } from 'lodash';

export const paymentMethods = [
  { label: 'creditCard', value: 0 },
  { label: 'bankTransfer', value: 1 },
  { label: 'cash', value: 2 },
  { label: 'paypal', value: 3 },
  { label: 'other', value: 4 },
  { label: 'mastercard', value: 5 },
  { label: 'amex', value: 6 },
  { label: 'diners', value: 7 },
  { label: 'visa', value: 8 },
];

export const getPaymentMethodLabel = (value) => {
  const label = get(find(paymentMethods, { value }), 'label') || '';
  return {
    id: `fake-report.paymentMethod.${label}`,
    defaultMessage: label,
  };
};

export const currencyOptions = [
  { label: 'EUR', value: 'EUR' },
  { label: 'USD', value: 'USD' },
  { label: 'GBP', value: 'GBP' },
  { label: 'JPY', value: 'JPY' },
  { label: 'CNY', value: 'CNY' },
  { label: 'RUB', value: 'RUB' },
  { label: 'CAD', value: 'CAD' },
  { label: 'AUD', value: 'AUD' },
];
