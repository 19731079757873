import { defineMessages } from 'react-intl';

const modalBeforeAuthentication = defineMessages({
  title: {
    id: 'resell_with_ebay.feature_widget.modal_before_authentication.title1',
    defaultMessage: "Validate your product's authenticity before listing it.",
  },
  text1: {
    id: 'resell_with_ebay.feature_widget.modal_before_authentication.text1',
    defaultMessage:
      'Click continue to sign in to Certilogo using your ebay account and authenticate your product.',
  },
  text2: {
    id: 'resell_with_ebay.feature_widget.modal_before_authentication.text2',
    defaultMessage: "If you don't have an account yet, you'll be able to create one.",
  },
  button: {
    id: 'resell_with_ebay.feature_widget.modal_before_authentication.button',
    defaultMessage: 'Continue',
  },
});

const manage = defineMessages({
  button: {
    id: 'resell_with_ebay.feature_widget.manage.button',
    defaultMessage: 'Resell with ebay',
  },
});

const complete = defineMessages({
  button: {
    id: 'resell_with_ebay.feature_widget.complete.button',
    defaultMessage: 'Your listing',
  },
});

export const statusesMessages = {
  manage,
  complete,
};

export default defineMessages({
  button: {
    id: 'resell_with_ebay.feature_widget.button',
    defaultMessage: 'Resell with ebay',
  },
  modalBeforeAuthentication,
});
