import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'soa_split.title1',
    defaultMessage:
      'Selling on an online marketplace? Build trust with a Certilogo Seal of Authentication (SoA)',
  },
  buttonYes: {
    id: 'soa_split.button1',
    defaultMessage: 'Tell me more',
  },
  buttonNo: {
    id: 'soa_split.text1',
    defaultMessage: 'Maybe later',
  },
});
