import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'where_active_soa_5.title1',
    defaultMessage: 'Insert the name of the seller you wish to buy from',
  },
  continue: {
    id: 'where_active_soa_5.button1',
    defaultMessage: 'Continue',
  },
  placeholder: {
    id: 'where_active_soa_5.placeholder1',
    defaultMessage: 'Name of the seller',
  },
});
