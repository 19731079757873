import useImage from 'use-image';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';
import { Image as KonvaImage, Group } from 'react-konva';
import { useHoverDirty, useLongPress } from 'react-use';
import close from './Close.svg';

const scaleBy = 1.01;

function getDistance(p1, p2) {
  return Math.sqrt(p2.x - p1.x ** 2 + (p2.y - p1.y ** 2));
}

function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

function IndividualSticker({ image, onDelete, onDragEnd }) {
  const imageRef = useRef(null);
  const isHovered = useHoverDirty(imageRef);
  const [stickerImage] = useImage(image.src, 'anonymous');
  const [deleteImage] = useImage(close);
  const [showDeleteButton, setShowDeleteButton] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const stageRef = useRef(null);
  let lastCenter = null;
  let lastDist = 0;

  const onLongPress = () => {
    if (!isDragging) {
      setShowDeleteButton(!showDeleteButton);
    }
  };

  image.resetButtonRef.current = () => {
    setShowDeleteButton(false);
  };
  const longPressEvent = useLongPress(onLongPress, { delay: 500 });

  const stickerWidth = image.width;
  const stickerHeight = stickerImage ? (image.width * stickerImage.height) / stickerImage.width : 0;

  useEffect(() => {
    if (isHovered) {
      setShowDeleteButton(true);
    } else {
      setTimeout(() => {
        setShowDeleteButton(false);
      }, 2000);
    }
  }, [isHovered]);

  const zoomStage = (event) => {
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      stage.position(newPos);
    }
  };

  const handleTouch = (e) => {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];
    const stage = stageRef.current;
    if (stage !== null) {
      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        const p1 = {
          x: touch1.clientX,
          y: touch1.clientY,
        };
        const p2 = {
          x: touch2.clientX,
          y: touch2.clientY,
        };

        if (!lastCenter) {
          lastCenter = getCenter(p1, p2);
          return;
        }
        const newCenter = getCenter(p1, p2);

        const dist = getDistance(p1, p2);

        if (!lastDist) {
          lastDist = dist;
        }

        // local coordinates of center point
        const pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX(),
        };

        const scale = stage.scaleX() * (dist / lastDist);

        stage.scaleX(scale);
        stage.scaleY(scale);

        // calculate new position of the stage
        const dx = newCenter.x - lastCenter.x;
        const dy = newCenter.y - lastCenter.y;

        const newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy,
        };

        stage.position(newPos);

        lastDist = dist;
        lastCenter = newCenter;
      }
    }
  };

  const handleTouchEnd = () => {
    lastCenter = null;
    lastDist = 0;
  };

  return (
    <Group
      draggable
      x={image.x}
      y={image.y}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={(event) => {
        setIsDragging(false);
        onDragEnd(event);
      }}
      onWheel={zoomStage}
      onTouchMove={handleTouch}
      onTouchEnd={handleTouchEnd}
      ref={stageRef}
    >
      <KonvaImage
        ref={imageRef}
        width={image.width}
        height={stickerHeight}
        image={stickerImage}
        {...longPressEvent}
      />
      {showDeleteButton && !isDragging && (
        <KonvaImage
          onTouchStart={onDelete}
          onClick={onDelete}
          image={deleteImage}
          width={15}
          height={15}
          offsetX={-stickerWidth + 15}
          fill="#fff"
        />
      )}
    </Group>
  );
}

IndividualSticker.propTypes = {
  image: PropTypes.string,
  onDelete: PropTypes.func,
  onDragEnd: PropTypes.func,
};

export default IndividualSticker;
