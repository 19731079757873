/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { margin, fontSize } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { Toggle, Div } from '@ebay-certilogo/design-system-web';

export const ToggleWrapper = styled(Div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${fontSize('xxs')}
  ${margin('s', 'bottom')}
`;

export const FieldWrapper = styled(Div)`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : '50%')};
`;

export const BulkToggle = styled(Toggle)`
  ${margin('s', 'bottom')}
`;
