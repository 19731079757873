import React, { useState, useCallback, useEffect } from 'react';
import { push } from 'connected-react-router';
import { Button } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { getStoresRequest, addStoreRequest } from 'containers/Authentication/actions';
import {
  selectAuthenticationStoresLoading,
  selectAuthenticationStores,
} from 'containers/Authentication/selectors';
import Form from 'components/form/FormComponent';
import StoreSuggest from '../../StoreSuggest';
import messages from './messages';

const debounce = (fn, delay) => {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

const validationSchemaInsert = Yup.object().shape({
  url: Yup.string().matches(
    // eslint-disable-next-line no-useless-escape
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
    {
      message: <FormattedMessage {...messages.urlError} />,
    },
  ),
});

const validationSchemaSearch = Yup.object().shape({
  url: Yup.string(),
});

function SearchStores({ colorType, data, authenticationId, setInputValue, inputValue, intl }) {
  const { status } = data;
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(null);
  const { url } = useRouteMatch();

  const storesLoading = useSelector(selectAuthenticationStoresLoading);
  const stores = useSelector(selectAuthenticationStores);

  useEffect(() => {
    if (inputValue) {
      dispatch(getStoresRequest({ url: inputValue, online: true }));
      setIsOpen(true);
    }
  }, []);

  const handleSubmit = ({ url: urlValue }) => {
    dispatch(
      addStoreRequest({
        params: { url: urlValue, online: true },
        authenticationId,
        status,
        soa: true,
      }),
    );
  };

  const selectedStore = (item) => {
    dispatch(
      push('marketplace_alias/insert_alias', {
        marketplaceId: item.id,
      }),
    );
  };

  const placeStoreCalls = (value) => {
    dispatch(getStoresRequest({ url: value, online: true }));
  };

  const debounceCallback = useCallback(debounce(placeStoreCalls, 500), []);

  return (
    <Formik
      initialValues={{
        url: inputValue,
      }}
      validationSchema={stores && isEmpty(stores) ? validationSchemaInsert : validationSchemaSearch}
      onSubmit={handleSubmit}
    >
      <Form>
        <Field
          name="url"
          component={StoreSuggest}
          url={url}
          colorType={colorType}
          searchCallBack={debounceCallback}
          selectedCallBack={({ item }) => {
            selectedStore(item);
          }}
          setInputValue={setInputValue}
          storesLoading={storesLoading}
          stores={stores}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          iconInsertActive={stores && isEmpty(stores)}
          notFound={
            <Button colorType={colorType} type="submit" noMargin>
              <FormattedMessage {...messages.go} />
            </Button>
          }
          placeholder={intl.formatMessage(messages.placeholder)}
        />
      </Form>
    </Formik>
  );
}

SearchStores.propTypes = {
  intl: PropTypes.object,
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
  }),
  setInputValue: PropTypes.func,
  inputValue: PropTypes.string,
};

export default injectIntl(SearchStores);
