import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectSaga } from 'utils/injectSaga';
import AppLoader from 'components/AppLoader';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { setLogo } from 'containers/App/actions';
import usePageType from 'components/hooks/usePageType';
import useColorType from 'components/hooks/useColorType';
import useHeaderUrl from 'components/hooks/useHeaderUrl';
import { interaction as dynamicComponentInteraction } from 'utils/reduxBeaconGtm/actions/dynamicComponents';
import Console from './Console';
import saga from './saga';
import { getConnectRequest, resetConnect } from './actions';
import { selectConnectLoading, selectConnectData } from './selectors';

function Connect() {
  useInjectSaga({ key: 'connect', saga });
  const colorType = useColorType('connect');
  const dispatch = useDispatch();
  const { sessionId } = useParams();
  const [pageType, setPageType] = useState('whitelabel');
  usePageType('connect', pageType);
  useHeaderUrl({ service: 'connect', sessionId });

  useEffect(() => {
    if (sessionId) {
      dispatch(resetConnect());
      dispatch(getConnectRequest({ sessionId }));
    }
    return () => dispatch(resetConnect());
  }, [sessionId]);

  const isLoading = useSelector(selectConnectLoading);
  const data = useSelector(selectConnectData);

  useEffect(() => {
    if (data) {
      const { logoLight, logoDark } = data?.connect || {};
      if (logoLight || logoDark) {
        dispatch(setLogo({ light: logoLight, dark: logoDark }));
      }
      setPageType(data.connect?.branded ? 'whitelabel' : 'default');
    }
  }, [data]);

  if (isLoading || !data) return <AppLoader colorType={colorType} />;

  const logger = (log) => {
    dispatch(
      dynamicComponentInteraction({
        ...log,
        sessionId,
      }),
    );
  };

  const propsBag = {
    id: sessionId,
    service: 'connect',
    certilogoCode: get(data, 'info.certilogoCode'),
    primaryBrand: get(data, 'info.primaryBrandName'),
    tmBrand: get(data, 'info.tmBrandName'),
    logger,
    authentication: data.authentication,
  };

  return <Console colorType={colorType} data={data?.connect} propsBag={propsBag} />;
}

Connect.propTypes = {
  colorType: PropTypes.string,
};

export default Connect;
