/* eslint-disable global-require */
/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
// const get = require('lodash/get');
// const browserLanguage = require('in-browser-language');

export const DEFAULT_LOCALE = 'en';

// const appLocales = ['de', 'en'];
export const appLocales = ['de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'pt', 'ru', 'zh'];

// const originalTranslationMessages = appLocales.reduce((acc, lang) => {
//   console.log(lang);

//   return {
//     ...acc,
//     // eslint-disable-next-line global-require
//     [lang]: require(`./translations/${lang}.json`),
//   };
// });

const originalTranslationMessages = {
  en: require('./translations/en.json'),
  it: require('./translations/it.json'),
};

if (!Intl.PluralRules) {
  require('@formatjs/intl-pluralrules/polyfill');

  require(`@formatjs/intl-pluralrules/dist/locale-data/en`); // Add locale data for en
  require(`@formatjs/intl-pluralrules/dist/locale-data/de`); // Add locale data for de
  require(`@formatjs/intl-pluralrules/dist/locale-data/es`); // Add locale data for es
  require(`@formatjs/intl-pluralrules/dist/locale-data/fr`); // Add locale data for fr
  require(`@formatjs/intl-pluralrules/dist/locale-data/it`); // Add locale data for it
  require(`@formatjs/intl-pluralrules/dist/locale-data/ja`); // Add locale data for ja
  require(`@formatjs/intl-pluralrules/dist/locale-data/ko`); // Add locale data for ko
  require(`@formatjs/intl-pluralrules/dist/locale-data/pt`); // Add locale data for pt
  require(`@formatjs/intl-pluralrules/dist/locale-data/ru`); // Add locale data for ru
  require(`@formatjs/intl-pluralrules/dist/locale-data/zh`); // Add locale data for zh
  // appLocales.forEach((lang) => {
  //   // eslint-disable-next-line global-require
  //   require(`@formatjs/intl-pluralrules/dist/locale-data/${lang}`); // Add locale data for de
  // });
}

export const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, originalTranslationMessages[DEFAULT_LOCALE])
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

// export const translationMessages = appLocales.reduce((acc, lang) => ({
//   ...acc,
//   [lang]: formatTranslationMessages(lang, originalTranslationMessages[lang]),
// }));

export const translationMessages = {
  en: formatTranslationMessages('en', originalTranslationMessages.en),
  it: formatTranslationMessages('it', originalTranslationMessages.it),
};
