import Radio from './Radio';
import Checkbox from './Checkbox';
import Select from './Select';
import Slider from './Slider';
import Text from './Text';
import DateInput from './DateInput';
import ImageInput from './ImageInput';

export default {
  'form-input-types.checkbox': Checkbox,
  'form-input-types.radio': Radio,
  'form-input-types.select': Select,
  'form-input-types.slider': Slider,
  'form-input-types.text': Text,
  'form-input-types.date-input': DateInput,
  'form-input-types.image-input': ImageInput,
};
