import { defineMessages } from 'react-intl';

export default defineMessages({
  ResellWithEbayPotentialBuyer: {
    afterPurchase: {
      text: {
        id: 'resell_with_ebay.soa_product_details.text_after_purchase',
        defaultMessage: 'Does the information above correspond to your product?',
      },
    },
    beforePurchase: {
      text: {
        id: 'resell_with_ebay.soa_product_details.text_before_purchase',
        defaultMessage: 'Does the information above match the listing you would like to verify?',
      },
    },
  },
});
