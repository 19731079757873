import Text from 'components/cms/dataTypes/Text';
import RichText from 'components/cms/dataTypes/RichText';
import Image from 'components/cms/dataTypes/Image';
import Button from 'components/cms/dataTypes/Button';
import ButtonSlim from 'components/cms/dataTypes/ButtonSlim';
import Modal from 'components/cms/dataTypes/Modal';
import Accordion from 'components/cms/dataTypes/Accordion';
import Tabs from 'components/cms/dataTypes/Tabs';
import Slider from 'components/cms/dataTypes/Slider';
import Heading from 'components/cms/dataTypes/Heading';
import Social from 'components/cms/dataTypes/Social';
import Soa from 'components/cms/featureWidgets/Soa';
import FakeReport from 'components/cms/featureWidgets/FakeReport';
import Survey from 'components/cms/featureWidgets/Survey';
import Reason from 'components/cms/featureWidgets/Reason';
import PictureSocialShare from 'components/cms/featureWidgets/PictureSocialShare';
import UrlComposer from 'components/cms/featureWidgets/UrlComposer';
import CodeReview from 'components/cms/featureWidgets/CodeReview';
import Warranty from 'components/cms/featureWidgets/Warranty';
import ImageTextRow from 'components/cms/dataTypes/ImageTextRow';
import ImageTextOverlay from 'components/cms/dataTypes/ImageTextOverlay';
import Video from 'components/cms/dataTypes/Video';
import LookBook from 'components/cms/dataTypes/LookBook';
import Grid from 'components/cms/dataTypes/Grid';
import ConnectionPanel from 'components/cms/dataTypes/ConnectionPanel';
import ViumeSurvey from 'components/cms/thirdPartyWidgets/ViumeSurvey';
import LoaderTrigger from 'components/cms/dataTypes/LoaderTrigger';
import ResellWithEbay from 'components/cms/featureWidgets/ResellWithEbay';
import ClaimOwnership from 'components/cms/featureWidgets/ClaimOwnership';

export default {
  'data-types.heading': Heading,
  'data-types.text': Text,
  'data-types.rich-text': RichText,
  'data-types.image': Image,
  'data-types.button': Button,
  'data-types.button-slim': ButtonSlim,
  'data-types.modal': Modal,
  'data-types.accordion': Accordion,
  'data-types.tabs': Tabs,
  'data-types.slider': Slider,
  'data-types.social': Social,
  'data-types.image-text-row': ImageTextRow,
  'data-types.image-text-overlay': ImageTextOverlay,
  'data-types.video': Video,
  'data-types.grid': Grid,
  'data-types.look-book': LookBook,
  'data-types.connection-panel': ConnectionPanel,
  'data-types.loader-trigger': LoaderTrigger,
  'feature-widgets.soa': Soa,
  'feature-widgets.fake-report': FakeReport,
  'feature-widgets.survey': Survey,
  'feature-widgets.reason': Reason,
  'feature-widgets.picture-social-share': PictureSocialShare,
  'feature-widgets.url-composer': UrlComposer,
  'feature-widgets.code-review': CodeReview,
  'feature-widgets.warranty': Warranty,
  'feature-widgets.resell-with-ebay': ResellWithEbay,
  'feature-widgets.claim-ownership': ClaimOwnership,
  'third-party-widgets.viume-survey': ViumeSurvey,
};
