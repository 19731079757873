import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import history from 'utils/history';
import { FormattedMessage } from 'react-intl';
import { ButtonSimple, Text } from '@ebay-certilogo/design-system-web';
import { Home } from '@ebay-certilogo/design-system-web/dist/components/icons';

export const Button = styled(ButtonSimple).attrs({
  size: 'l',
})`
  margin: 0;
  height: 70px;
  width: 70px;
`;

function ButtonHomeConnect({ sessionId, ...rest }) {
  return (
    <Button colorType="dark" onClick={() => history.push(`/connect/${sessionId}`)} {...rest}>
      <Text size="xxs" colorType="light">
        <Home />
        <FormattedMessage id="response.cta.consolehome" defaultMessage="HOME" />
      </Text>
    </Button>
  );
}

ButtonHomeConnect.propTypes = {
  sessionId: PropTypes.string,
};

export default ButtonHomeConnect;
