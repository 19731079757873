/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';
import { padding, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

export const Wrapper = styled(Div)`
  background: #ebebeb;
  ${padding('m')}
`;

export const Divider = styled(Div)`
  border: 1px solid ${color};
  ${margin('s', 'vertical')}
`;
