import { defineMessages } from 'react-intl';

export default defineMessages({
  text1: {
    id: 'warranty_complete.text1',
    defaultMessage: 'Now you can download your product warranty',
  },
  text2: {
    id: 'warranty_complete.text2',
    defaultMessage: 'For your convenience, we have sent it to your email address, too.',
  },
  download: {
    id: 'warranty_complete.download',
    defaultMessage: 'Download',
  },
  cta: {
    id: 'warranty_complete.cta',
    defaultMessage: 'Back',
  },
  done: {
    id: 'warranty_download.title1',
    defaultMessage: 'Done',
  },
  downloadComplete: {
    id: 'warranty_download.text1',
    defaultMessage: 'Download complete',
  },
});
