import React from 'react';
import PropTypes from 'prop-types';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { isEmpty } from 'lodash';

function Checkbox({ id, name, label, colorType, propsBag, ...rest }) {
  return (
    <Field
      name={name || id}
      component={FormFormik.Checkbox}
      text={
        !isEmpty(label) && <FormattedMessage id={label} defaultMessage={label} values={propsBag} />
      }
      colorType={colorType}
      {...rest}
    />
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  colorType: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  propsBag: PropTypes.object,
};

export default Checkbox;
