import React, { useEffect, useState } from 'react';
import { Switch, useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import Route from 'components/Route';
import withColorType from 'components/HOC/withColorType';
import SelectAlias from './SelectAlias';
import InsertAlias from './InsertAlias';
import StartSoa from './StartSoa';

function MarketplaceAlias({ colorType, data, authenticationId }) {
  if (!data || !data.props.marketplaceAlias) return <LoadingIndicator />;
  const { status } = data;
  const dispatch = useDispatch();

  const { path, isExact } = useRouteMatch();
  const [inputValue, setInputValue] = useState(null);

  useEffect(() => {
    dispatch(
      setBack({
        back: flowRequest({
          action: 'prev',
          status,
          authenticationId,
        }),
      }),
    );
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: values,
      }),
    );
  };

  const groupByData = groupBy(data.props.marketplaceAlias, 'marketplace.name');
  const groupData = uniqBy(
    data.props.marketplaceAlias.map(({ marketplace: { name } }) => ({ name })),
    'name',
  );

  const selectMarketplace = (store) => {
    dispatch(push('marketplace_alias/select_alias', groupByData[store]));
  };

  return (
    <>
      {isExact && (
        <StartSoa
          colorType={colorType}
          authenticationId={authenticationId}
          data={data || {}}
          inputValue={inputValue}
          setInputValue={setInputValue}
          groupData={groupData}
          selectMarketplace={selectMarketplace}
          status={status}
        />
      )}

      <Switch>
        <Route path={`${path}/select_alias`}>
          <SelectAlias colorType={colorType} handleSubmit={handleSubmit} data={data} />
        </Route>
        <Route path={`${path}/insert_alias`}>
          <InsertAlias colorType={colorType} handleSubmit={handleSubmit} data={data} />
        </Route>
      </Switch>
    </>
  );
}

MarketplaceAlias.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    props: PropTypes.shape({
      marketplaceAlias: PropTypes.array,
    }),
  }),
};

export default withColorType(MarketplaceAlias);
