import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { NavLink as _navLink, useLocation } from 'react-router-dom';
import { Text, Heading } from '@ebay-certilogo/design-system-web';
import { padding } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import messages from './messages';

export const Link = styled(Text).attrs(({ overrideComponent }) => ({
  as: overrideComponent || _navLink,
}))`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding-top: 5px;
  min-height: 60px;

  ${({ direction }) => css`
    align-items: ${direction === 'left' ? 'flex-start' : 'flex-end'};
  `}

  &.selected {
    ${({ direction, theme, colorType }) => css`
      border-${direction}: 6px solid ${theme[colorType].primaryAccentColor};
      ${padding('s', direction)}
    `}

    span, h1 {
      color: ${({ theme, colorType }) => theme[colorType].primaryAccentColor} !important;
    }
  }
`;

function ButtonHighlight({
  colorType,
  url,
  label,
  direction,
  isSelected,
  overrideComponent,
  derscription,
  title,
}) {
  const { pathname } = useLocation();
  return (
    <Link
      to={url}
      direction={direction}
      activeClassName="selected"
      colorType={colorType}
      className={isSelected && 'selected'}
      overrideComponent={overrideComponent}
    >
      {title && (
        <Heading as="h1" colorType={colorType} marginBottom="s">
          {title}
        </Heading>
      )}
      {label && (
        <Text as="span" colorType={colorType} size="m" weight="extrabold" marginBottom="s">
          {label}
        </Text>
      )}

      {(derscription || (url && (url.includes(pathname) || isSelected))) && (
        <Text colorType={colorType} size="xs" marginBottom="s">
          {derscription || <FormattedMessage {...messages.continue} />}
        </Text>
      )}
    </Link>
  );
}

ButtonHighlight.propTypes = {
  colorType: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.node,
  label: PropTypes.node,
  direction: PropTypes.string,
  isSelected: PropTypes.bool,
  derscription: PropTypes.node,
  overrideComponent: PropTypes.node,
};

export default ButtonHighlight;
