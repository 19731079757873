import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Text, Div, Image } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { map } from 'lodash';
import Button from 'components/cms/dataTypes/Button';
import Header from '../Header';
import DemoModal from './DemoModal';

function Response({
  colorType,
  title: surveyTitle,
  data: {
    results: { title, text, imgSrc, ctas, more },
  },
  propsBag,
}) {
  const [showDemoModal, setShowDemoModal] = useState(false);
  const handleCtaButton = (obj) => {
    if (obj.action === 'demo_modal') {
      setShowDemoModal(true);
    }
  };
  return (
    <Div textAlign="center">
      <Header colorType={colorType} propsBag={propsBag} title={surveyTitle} text={title} />
      <Div margin={{ size: 'm' }}>
        <Text colorType={colorType} weight="semibold" textAlign="center" marginBottom="m">
          <FormattedMessage id={text || 'id'} defaultMessage={text} values={propsBag} />
        </Text>
        <Image src={imgSrc} style={{ width: '60%' }} marginBottom="m" />
        {map(ctas, (cta) => (
          <Button
            colorType={colorType}
            buttonType="link"
            size="s"
            marginBottom="m"
            arrow={false}
            url={cta.url}
            target="_blank"
            onClick={() => handleCtaButton(cta)}
          >
            <FormattedMessage id={cta.text || 'id'} defaultMessage={cta.text} values={propsBag} />
          </Button>
        ))}
        {more && (
          <Button
            colorType={colorType}
            buttonType="link"
            size="s"
            marginBottom="m"
            arrow={false}
            url={more.url}
            target="_blank"
            onClick={() => handleCtaButton(more)}
          >
            <FormattedMessage id={more.text || 'id'} defaultMessage={more.text} values={propsBag} />
          </Button>
        )}
      </Div>
      <DemoModal isOpen={showDemoModal} setModal={setShowDemoModal} colorType={colorType} />
    </Div>
  );
}

Response.propTypes = {
  data: PropTypes.object.isRequired,
  colorType: PropTypes.string,
  title: PropTypes.string,
  propsBag: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default Response;
