import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import { useLocation } from 'react-router-dom';
import { forEach } from 'lodash';
import history from 'utils/history';
import Card from './Card';
import { Wrapper, Slider } from './styles';

function ResponseCarousel({
  colorType,
  data,
  cardsType = 'cards',
  propsBag: { logger },
  propsBag,
  ...rest
}) {
  const { cards } = data;
  const ref = useRef();
  const sliderLength = (cards || []).length;

  const sliderProps = {
    simple: {
      infinite: true,
      arrows: false,
      dots: sliderLength > 1,
    },
    cards: {
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      centerPadding: '60px',
      arrows: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: '30px',
            arrows: false,
          },
        },
      ],
    },
  };
  const { hash } = useLocation();

  const mapCards = {};
  forEach(cards, ({ data: { name } }, index) => {
    mapCards[`#${name}`] = index;
  });

  const goToCard = (tag) => {
    ref.current.slickGoTo(mapCards[tag]);
  };

  useEffect(() => {
    if (hash) {
      goToCard(hash);
      logger({
        event: { type: 'swipe', name: 'swipe-response-card', category: 'swipe' },
        params: { target: hash },
      });
    }
  }, [ref, hash]);

  return (
    <Wrapper {...rest}>
      <Slider
        colorType={colorType}
        cardsType={cardsType}
        ref={ref}
        afterChange={(index) => history.push(`#${cards[index].data.name}`)}
        length={sliderLength}
        {...sliderProps[cardsType]}
      >
        {map(cards, (card) => (
          <Card
            colorType={cardsType === 'simple' ? colorType : 'dark'}
            data={card.data}
            key={card.id}
            cardType={cardsType}
            propsBag={propsBag}
            {...rest}
          />
        ))}
      </Slider>
    </Wrapper>
  );
}

ResponseCarousel.propTypes = {
  data: PropTypes.object,
  propsBag: PropTypes.object,
  colorType: PropTypes.string,
  cardsType: PropTypes.string,
};

export default ResponseCarousel;
