import { join } from 'lodash';
import config from 'config';

const {
  oauth: { ebay },
} = config;

const oauthUrls = {
  US: { url: 'https://signin.ebay.com', siteId: 0 }, // eBay United States
  CA: { url: 'https://signin.ebay.ca', siteId: 2 }, // eBay Canada
  GB: { url: 'https://signin.ebay.co.uk', siteId: 3 }, // eBay United Kingdom
  AU: { url: 'https://signin.ebay.com.au', siteId: 15 }, // eBay Australia
  FR: { url: 'https://signin.ebay.fr', siteId: 71 }, // eBay France
  DE: { url: 'https://signin.ebay.de', siteId: 77 }, // eBay Germany
  IT: { url: 'https://signin.ebay.it', siteId: 101 }, // eBay Italy
};

const getOauthUrlByCountryCode = (countryCode) => oauthUrls[countryCode]?.url || oauthUrls.US.url;

export default (countryCode = 'US') => {
  const scopes = [
    'https://api.ebay.com/oauth/api_scope/commerce.identity.readonly',
    'https://api.ebay.com/oauth/api_scope/commerce.identity.email.readonly',
    'https://api.ebay.com/oauth/api_scope/commerce.identity.name.readonly',
    'https://api.ebay.com/oauth/scope/listing.draft@partner',
  ];
  const scope = join(scopes, ' ');
  const absoluteUrl = getOauthUrlByCountryCode(countryCode);

  window.location.href = `${absoluteUrl}/authorize?client_id=${ebay.clientId}&redirect_uri=${ebay.redirectUri}&response_type=code&scope=${scope}`;
};
