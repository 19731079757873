import React, { useEffect, useMemo } from 'react';
import { Text } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { flowRequest } from 'containers/Authentication/actions';
import { setBack } from 'containers/App/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import withColorType from 'components/HOC/withColorType';
import FlowButton from 'containers/Authentication/components/FlowButton';
import defaultMessages from './messages';
import flowMessages from './flowMessages';
import TopBox from './TopBox';

function SoaProductDetails({ colorType, data, authenticationId }) {
  if (!data) return <LoadingIndicator />;
  const { status } = data;
  const dispatch = useDispatch();

  const ownership = data.props?.ownership;
  const overrideMessages = useMemo(() => {
    const flowType = data.flow;
    const ownershipKey = ownership ? 'afterPurchase' : 'beforePurchase';
    return flowMessages[flowType]?.[ownershipKey] || {};
  }, [data.flow, ownership]);

  const messages = useMemo(
    () => ({
      ...defaultMessages,
      ...overrideMessages,
    }),
    [overrideMessages],
  );

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: flowRequest({
            action: 'prev',
            status,
            authenticationId,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = (soaProductMatch) => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
        data: {
          soaProductMatch,
        },
      }),
    );
  };

  return (
    <>
      <TopBox colorType={colorType} data={data} />
      <Text colorType={colorType} size="m" marginBottom="m" weight="extrabold">
        <FormattedMessage {...messages.text} />
      </Text>
      <FlowButton onClick={() => handleSubmit(true)} colorType={colorType} noMargin>
        <FormattedMessage {...messages.yes} />
      </FlowButton>
      <FlowButton onClick={() => handleSubmit(false)} colorType={colorType} noMargin>
        <FormattedMessage {...messages.no} />
      </FlowButton>
    </>
  );
}

SoaProductDetails.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    flow: PropTypes.string,
    props: PropTypes.shape({
      ownership: PropTypes.bool,
      soaType: PropTypes.string,
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default withColorType(SoaProductDetails);
