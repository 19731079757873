import React, { useEffect } from 'react';
import { Button, ViewInput, Heading, Image } from '@ebay-certilogo/design-system-web';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateRequest } from 'containers/FakeReport/actions';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { setBack } from 'containers/App/actions';
import { format, parseISO } from 'date-fns';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import CardClg from 'components/CardClg';
import messages from './messages';

const BoxWrapper = styled(ViewInput.BoxWrapper)`
  border: 0;
  border-bottom: 1px solid ${color};
  border-radius: 0;
`;

function Recap({ id, status, data }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: updateRequest({
            action: 'prev',
            id,
            status,
          }),
        }),
      );
    }
  }, [data]);

  const handleSubmit = () => {
    dispatch(updateRequest({ action: 'next', id, status }));
  };

  const handleEdit = () => {
    dispatch(
      updateRequest({
        action: 'edit',
        id,
        status,
      }),
    );
  };

  const dataOrEmptySymbol = (el) => `${el || '-'}`;

  return (
    <CardClg>
      {({ colorType: cardColorType }) => (
        <>
          <Heading type="h2" colorType={cardColorType}>
            <FormattedMessage {...messages.buyer.title} />
          </Heading>
          <BoxWrapper colorType={cardColorType}>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.buyer.givenName} />}
            >
              {dataOrEmptySymbol(data.givenName)}
            </ViewInput>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.buyer.familyName} />}
            >
              {dataOrEmptySymbol(data.familyName)}
            </ViewInput>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.buyer.dateOfBirth} />}
            >
              {data.birthdate ? format(parseISO(data.birthdate), 'dd/MM/yyyy') : '-'}
            </ViewInput>
          </BoxWrapper>
          <Heading type="h2" colorType={cardColorType}>
            <FormattedMessage {...messages.retailer.title} />
          </Heading>
          <BoxWrapper colorType={cardColorType}>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.retailer.retailerType.label} />}
            >
              <FormattedMessage
                {...messages.retailer.retailerType[data.store?.url ? 'online' : 'inStore']}
              />
            </ViewInput>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.retailer.retailerInfo} />}
            >
              {dataOrEmptySymbol(data.store?.name)}
              <br />
              {dataOrEmptySymbol(data.store?.address)}
            </ViewInput>
          </BoxWrapper>
          <Heading type="h2" colorType={cardColorType}>
            <FormattedMessage {...messages.purchase.title} />
          </Heading>
          <BoxWrapper colorType={cardColorType}>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.purchase.price} />}
            >
              {data.currency} {dataOrEmptySymbol(data.price)}
            </ViewInput>
            <ViewInput
              colorType={cardColorType}
              label={<FormattedMessage {...messages.purchase.receipt} />}
            >
              {data.receiptImagePath ? <Image width="71px" src={data.receiptImagePath} /> : '-'}
            </ViewInput>
          </BoxWrapper>
          <Button colorType={cardColorType} onClick={handleSubmit}>
            <FormattedMessage {...messages.send} />
          </Button>
          <Button
            type="button"
            buttonType="link"
            colorType={cardColorType}
            onClick={handleEdit}
            arrow={false}
          >
            <FormattedMessage {...messages.edit} />
          </Button>
        </>
      )}
    </CardClg>
  );
}

Recap.propTypes = {
  colorType: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object,
};

export default Recap;
