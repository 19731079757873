import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Div } from '@ebay-certilogo/design-system-web';
import { PoweredBy as SvgComponent } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { selectGlobalColorType } from 'containers/App/selectors';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';

export const Wrapper = styled(Div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: ${color};
`;

export const SvgStyled = styled(SvgComponent).attrs({ height: 20 })`
  height: 20px;
`;

function PoweredBy(props) {
  const colorType = useSelector(selectGlobalColorType);
  return (
    <Wrapper colorType={colorType}>
      <SvgStyled colorType={colorType} {...props} />
    </Wrapper>
  );
}

export default PoweredBy;
