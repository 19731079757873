import { defineMessages } from 'react-intl';

export default defineMessages({
  text: {
    id: 'where_active_soa_1.text1',
    defaultMessage: 'Is this the product you see on the listing?',
  },
  yes: {
    id: 'where_active_soa_1.button1',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'where_active_soa_1.button2',
    defaultMessage: 'No',
  },
});
