import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Text, Modal } from '@ebay-certilogo/design-system-web';
import {
  selectChangeEmailLoading,
  selectCodeLoginRequestError,
  selectUserData,
} from 'containers/Auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { codeLoginRequest } from 'containers/Auth/actions';
import messages from './messages';
import CountDownMessage from './CountDownMessage';

function ResendCode({ colorType }) {
  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const requestError = useSelector(selectCodeLoginRequestError);
  const email = userData?.email;
  const isLoading = useSelector(selectChangeEmailLoading);

  const handleResendCode = () => {
    setSubmitted(true);
    dispatch(codeLoginRequest({ email }));
  };

  useEffect(() => {
    if (submitted && !isLoading) {
      setIsModalOpen(true);
    }
  }, [isLoading, submitted]);

  return (
    <>
      {requestError?.timeToWait ? (
        <CountDownMessage
          colorType={colorType}
          error="too_many_requests"
          timeToWait={requestError?.timeToWait}
        />
      ) : (
        <Button
          buttonType="link"
          colorType={colorType}
          arrow={false}
          noMargin
          onClick={handleResendCode}
          loading={isLoading}
        >
          <FormattedMessage {...messages.link} />
        </Button>
      )}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} close colorType={colorType}>
        <Text colorType={colorType} weight="extrabold" marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.title} />
        </Text>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.text1} values={{ email }} />
        </Text>
      </Modal>
    </>
  );
}

ResendCode.propTypes = {
  colorType: PropTypes.string,
};

export default ResendCode;
