import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'fake_report.complete.title',
    defaultMessage: 'Request Received',
  },
  sentEmailMessages: {
    id: 'fake_report.complete.text1',
    defaultMessage: 'The Refund Assistance Report has been send to your email address:',
  },
});
