import { defineMessages } from 'react-intl';

export default defineMessages({
  marketplace: {
    id: 'resell_with_ebay.where_active_soa_1.marketplace',
    defaultMessage: 'Marketplace',
  },
  seller: {
    id: 'resell_with_ebay.where_active_soa_1.seller',
    defaultMessage: 'Seller',
  },
});
