import { defineMessages } from 'react-intl';

export default defineMessages({
  create: {
    label: {
      id: 'feature_widget_warranty.label',
      defaultMessage: 'Activate your warranty',
    },
  },
  download: {
    label: {
      id: 'feature_widget_warranty.label',
      defaultMessage: 'Claim your warranty',
    },
  },
});
