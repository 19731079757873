/* eslint-disable react/jsx-no-literals */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import DynamicComponent from 'components/cms/DynamicComponent';
import { withTheme } from 'styled-components';
import { Wrapper, Style } from './styles';

function Card({
  data,
  colorType,
  cardType,
  height,
  propsBag: { logger, authentication } = {},
  propsBag,
}) {
  if (data) {
    const { contents, verticalCenter, style } = data;

    return (
      <Wrapper colorType={colorType}>
        <Style
          colorType={colorType}
          verticalCenter={verticalCenter}
          className="Card"
          cardType={cardType}
          response={authentication.response}
          height={height}
          {...style}
        >
          {_.map(contents, ({ __component, _id, ...rest }) => (
            <DynamicComponent
              colorType={colorType}
              type={__component}
              data={rest}
              key={_id}
              logger={logger}
              propsBag={propsBag}
            />
          ))}
        </Style>
      </Wrapper>
    );
  }
  return null;
}

Card.propTypes = {
  data: PropTypes.object.isRequired,
  colorType: PropTypes.bool,
  verticalCenter: PropTypes.bool,
  cardType: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.object,
  height: PropTypes.number,
  propsBag: PropTypes.object,
};

export default withTheme(Card);
