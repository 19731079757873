import { defineMessages } from 'react-intl';

const login = defineMessages({
  button: {
    id: 'login_new_data.button1',
    defaultMessage: 'Continue',
  },
});

const profilePage = defineMessages({
  button: {
    id: 'my_profile.button1',
    defaultMessage: 'Save',
  },
});

const defaultMessages = defineMessages({
  title: {
    id: 'login_new.additional.title1',
    defaultMessage: 'Customize your profile',
  },
  text1: {
    id: 'login_new.additional.text1',
    defaultMessage: 'Optional fields',
  },
  text2: {
    id: 'login_new.additional.text2',
    defaultMessage: 'Completa il tuo profilo con le seguenti informazioni facoltative',
  },
  placeholderName: {
    id: 'login_new_data.placeholder1',
    defaultMessage: 'First name',
  },
  placeholderLastname: {
    id: 'login_new_data.placeholder2',
    defaultMessage: 'Last name',
  },
  placeholderBirthDate: {
    id: 'login_new_data.text3',
    defaultMessage: 'Birth date',
  },
  placeholderGenderMale: {
    id: 'login_new_data.text4',
    defaultMessage: 'M',
  },
  placeholderGenderFemale: {
    id: 'login_new_data.text5',
    defaultMessage: 'F',
  },
  placeholderGenderNotListed: {
    id: 'login_new_data.text6',
    defaultMessage: 'Not Listed',
  },
});

export default {
  ...defaultMessages,
  login,
  profilePage,
};
