import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { injectIntl } from 'react-intl';
import { FormFormik } from '@ebay-certilogo/design-system-web';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import { setOptinRequest } from 'containers/Auth/actions';
import Form from 'components/form/FormComponent';
import FormikAutoSave from 'components/FormikAutoSave';

const validationSchema = Yup.object().shape({
  value: Yup.bool(),
});

function BrandOptinForm({ colorType, id, type = 'marketingOptin', value, label }) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(setOptinRequest({ id, type, value: values.value }));
  };

  return (
    <Formik
      initialValues={{ value }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
    >
      <Form>
        <Field
          name="value"
          text={label}
          component={FormFormik.Toggle}
          thick
          colorType={colorType}
          id={`${id}-${type}`}
        />
        <FormikAutoSave onSave={handleSubmit} />
      </Form>
    </Formik>
  );
}

BrandOptinForm.propTypes = {
  colorType: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.bool,
};

export default injectIntl(BrandOptinForm);
