import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';

export const ListWrapper = styled(Div)`
  height: 160px;
  overflow: scroll;
`;

export default {
  ListWrapper,
};
