import React from 'react';
import PropTypes from 'prop-types';
import Markdown from 'components/Markdown';

function RichText({ text, style, ...rest }) {
  return (
    <div {...style} {...rest}>
      <Markdown>{text}</Markdown>
    </div>
  );
}

RichText.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
};

export default RichText;
