import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Modal, Heading, Text, Image } from '@ebay-certilogo/design-system-web';
import { useSelector } from 'react-redux';
import { selectLogoDark } from 'containers/App/selectors';
import HelpModal from '../HelpModal';
import { Header, Body } from './styles';

function UploadSorryModal({ colorType, isOpen, closeModal, messages }) {
  const logo = useSelector(selectLogoDark);
  const [helpModalIsOpen, setHelpModalIsOpen] = useState(false);
  return (
    <Modal colorType="dark" isOpen={isOpen} onClose={() => closeModal()} page disablePortal>
      <Header>
        <Image src={logo} height={40} />
      </Header>
      <Body colorType={colorType}>
        <Heading themeField="primaryAccentColor" colorType="dark">
          <FormattedMessage id={messages.sorry.title} defaultMessage={messages.sorry.title} />
        </Heading>
        <Text colorType="dark" marginBottom="s">
          <FormattedMessage id={messages.sorry.text} defaultMessage={messages.sorry.text} />
        </Text>
        <Button colorType="dark" arrow={false} noMargin onClick={() => closeModal()}>
          <FormattedMessage id={messages.sorry.button} defaultMessage={messages.sorry.button} />
        </Button>
        <Button
          colorType="dark"
          buttonType="link"
          arrow={false}
          onClick={() => setHelpModalIsOpen(true)}
        >
          <FormattedMessage id={messages.sorry.link} defaultMessage={messages.sorry.link} />
        </Button>
        <HelpModal
          isOpen={helpModalIsOpen}
          onClose={() => setHelpModalIsOpen(false)}
          messages={messages.help}
          disablePortal
        />
      </Body>
    </Modal>
  );
}

UploadSorryModal.propTypes = {
  colorType: PropTypes.string,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  messages: PropTypes.object,
};

export default injectIntl(UploadSorryModal);
