import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { unsetBack } from 'containers/App/actions';
import history from 'utils/history';
import ProgressBarTimer from 'components/ProgressBarTimer';
import { useTheme } from 'styled-components';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { transparentize } from 'polished';
import { Div } from '@ebay-certilogo/design-system-web';
import Svg from 'react-inlinesvg';
import { Title, Text } from './styles';
import messages from './messages';
import DoneAnimation from './done_animation.svg';

function Complete({ colorType, data }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  useEffect(() => {
    dispatch(unsetBack());
  }, []);
  const sessionId = data?.authentication?.sessionId;
  const goToConnectConsole = useCallback(() => history.push(`/connect/${sessionId}`), [sessionId]);

  return (
    <>
      <Title type="big" colorType={colorType} marginBottom="l">
        <FormattedMessage {...messages.title} />
      </Title>
      <Div marginBottom="l" textAlign="center">
        <Svg src={DoneAnimation} width="215px" />
      </Div>
      <Text colorType={colorType} marginBottom="s">
        <FormattedMessage {...messages.sentEmailMessages} />
      </Text>
      <Text colorType={colorType}>{data?.email}</Text>
      <ProgressBarTimer
        colorType={colorType}
        interval={5000}
        onComplete={goToConnectConsole}
        strokeColor={color({ theme, colorType })}
        trailColor={transparentize(0.8, color({ theme, colorType }))}
        marginTop="m"
      />
    </>
  );
}

Complete.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.object,
};

export default Complete;
