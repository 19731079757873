import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'where.title1',
    defaultMessage: 'Where did you find this product?',
  },
  code: {
    id: 'where.text1',
    defaultMessage: 'CLG {code}',
  },
  store: {
    id: 'where.button1',
    defaultMessage: 'Store',
  },
  online: {
    id: 'where.button2',
    defaultMessage: 'Online',
  },
  gift: {
    id: 'where.button3',
    defaultMessage: 'Gift',
  },
});
