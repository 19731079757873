import React, { useEffect, useState, useRef } from 'react';
import { Button, Heading, Text, Div, Toggle } from '@ebay-certilogo/design-system-web';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { setBack } from 'containers/App/actions';
import { selectProfile } from 'containers/Auth/selectors';
import { getProfileRequest } from 'containers/Auth/actions';
import { updateRequest } from 'containers/FakeReport/actions';
import CardClg from 'components/CardClg';
import messages from './messages';
import SelectStore from './SelectStore';

function Retailer({ id, status, data }) {
  const selectStoreRef = useRef();
  const dispatch = useDispatch();
  const [isToggleChecked, setIsToggleChecked] = useState(true);
  const [isChangeStoreOpen, setIsChangeStoreOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState(false);
  const profile = useSelector(selectProfile);
  const isButtonDisabled = !(selectedStore && isToggleChecked);

  const handleSelectStore = (store) => {
    setSelectedStore({
      id: store.id,
      name: store.name,
      placeId: store.place_id,
      address: store.formatted_address,
      url: store.url,
    });
    setIsChangeStoreOpen(false);
  };

  useEffect(() => {
    if (data) {
      dispatch(
        setBack({
          back: updateRequest({
            action: 'prev',
            id,
            status,
          }),
        }),
      );
      const existingStore = data?.store || data?.authentication?.store;
      setSelectedStore({
        id: existingStore?.id,
        name: existingStore?.name,
        address: existingStore?.address,
        url: existingStore?.url,
      });
    }
  }, [data]);

  useEffect(() => {
    setIsChangeStoreOpen(!selectedStore);
  }, [selectedStore]);

  useEffect(() => {
    if (isEmpty(profile)) {
      dispatch(getProfileRequest());
    }
  }, []);

  const handleSubmit = () => {
    if (selectedStore && isToggleChecked) {
      dispatch(
        updateRequest({
          params: {
            store: {
              id: selectedStore.id,
              placeId: selectedStore.placeId,
              type: selectedStore.url ? 'online' : 'offline',
            },
          },
          action: 'next',
          id,
          status,
        }),
      );
    }
  };

  useEffect(() => {
    if (isChangeStoreOpen) {
      // eslint-disable-next-line no-unused-expressions
      selectStoreRef?.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [isChangeStoreOpen, selectStoreRef]);

  return (
    <CardClg>
      {({ colorType: cardColorType }) => (
        <>
          <Heading type="h2" colorType={cardColorType}>
            <FormattedMessage {...messages.title} />
          </Heading>
          <Text colorType={cardColorType} size="s" marginBottom="m">
            <FormattedMessage {...messages.text1} />
          </Text>
          {selectedStore && !isChangeStoreOpen && (
            <Div marginBottom="l">
              <Text colorType={cardColorType} marginBottom="s">
                <FormattedMessage {...messages.text2} />
              </Text>
              <Div marginLeft="s">
                <Text colorType={cardColorType}>{selectedStore.name}</Text>
                <Text colorType={cardColorType}>{selectedStore.address || selectedStore.url}</Text>
              </Div>
            </Div>
          )}
          <Toggle
            text={
              <Text colorType={cardColorType} size="s">
                <FormattedMessage {...messages.toggle} />
              </Text>
            }
            checked={isToggleChecked}
            colorType={cardColorType}
            onChange={(value) => setIsToggleChecked(value)}
          />
          <Div marginTop="m">
            {isChangeStoreOpen ? (
              <Div ref={selectStoreRef}>
                <SelectStore colorType={cardColorType} onSelect={handleSelectStore} />
              </Div>
            ) : (
              <>
                <Button
                  colorType={cardColorType}
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  <FormattedMessage {...messages.continue} />
                </Button>
                <Button
                  buttonType="link"
                  colorType={cardColorType}
                  onClick={() => setIsChangeStoreOpen(true)}
                  arrow={false}
                >
                  <FormattedMessage {...messages.changeStoreButton} />
                </Button>
              </>
            )}
          </Div>
        </>
      )}
    </CardClg>
  );
}

Retailer.propTypes = {
  colorType: PropTypes.string,
  status: PropTypes.string,
  id: PropTypes.number,
  data: PropTypes.object,
};

export default Retailer;
