import { defineMessages } from 'react-intl';

export default defineMessages({
  authentic: {
    done: {
      title: {
        id: 'authentic_3_Survey_6.title1',
        defaultMessage: 'Done',
      },
    },
    button: {
      id: 'authentic_3_Survey_5.button1',
      defaultMessage: 'Send',
    },
  },
  fake: {
    done: {
      title: {
        id: 'fake_survey_6.title1',
        defaultMessage: 'Done',
      },
    },
    button: {
      id: 'fake_survey_5.button1',
      defaultMessage: 'Send',
    },
  },
});
