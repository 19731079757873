import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Button, Text, Modal } from '@ebay-certilogo/design-system-web';
import messages from './messages';
import SendEmailForm from './SendEmailForm';

function ChangeEmail({ colorType }) {
  const [isChangeMailModalOpen, setIsChangeMailModalOpen] = useState(false);
  return (
    <>
      <Button
        buttonType="link"
        colorType={colorType}
        arrow={false}
        noMargin
        onClick={() => setIsChangeMailModalOpen(true)}
      >
        <FormattedMessage {...messages.link} />
      </Button>
      <Modal
        isOpen={isChangeMailModalOpen}
        onClose={() => setIsChangeMailModalOpen(false)}
        close
        colorType={colorType}
      >
        <Text colorType={colorType} weight="extrabold" marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.title} />
        </Text>
        <Text colorType={colorType} marginBottom="m" textAlign="center">
          <FormattedMessage {...messages.text1} />
        </Text>
        <SendEmailForm colorType={colorType} onSuccess={() => setIsChangeMailModalOpen(false)} />
      </Modal>
    </>
  );
}

ChangeEmail.propTypes = {
  colorType: PropTypes.string,
};

export default ChangeEmail;
