import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ButtonSimple, FormFormik } from '@ebay-certilogo/design-system-web';
import { EyeClose, EyeOpen, Lock } from '@ebay-certilogo/design-system-web/dist/components/icons';

const { TextInput } = FormFormik;

function PwdInput({ colorType, ...props }) {
  const [passwordShown, setPassowrdShown] = useState(false);

  return (
    <TextInput
      passwordShown={passwordShown ? 'text' : 'password'}
      colorType={colorType}
      icon={<Lock width={22} height={22} colorType={colorType} />}
      rightAccessory={
        <ButtonSimple
          type="button"
          backgroundOpacity={1}
          onClick={() => setPassowrdShown(!passwordShown)}
          style={{ padding: 0 }}
        >
          {passwordShown ? (
            <EyeOpen width={22} height={22} colorType={colorType} />
          ) : (
            <EyeClose width={22} height={22} colorType={colorType} />
          )}
        </ButtonSimple>
      }
      {...props}
    />
  );
}

PwdInput.propTypes = {
  colorType: PropTypes.string,
};

export default PwdInput;
