import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import { Text, Box, Flex, Button } from '@ebay-certilogo/design-system-web';
import { useDispatch, useSelector } from 'react-redux';
import { transparentize } from 'polished';
import { selectOptinLoading, selectOptin } from 'containers/Auth/selectors';
import { getOptinRequest } from 'containers/Auth/actions';
import { capitalize, compact, filter, flatMap, get, isNil } from 'lodash';
import { setBack } from 'containers/App/actions';
import LoadingIndicator from 'components/LoadingIndicator';
import styled from 'styled-components';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import ButtonComponent from 'components/ButtonComponent';
import { selectBrandedConf } from 'containers/App/selectors';
import BrandOptinForm from './BrandOptinForm';
import messages from './messages';

const BrandHeader = styled(Flex)`
  background: ${(props) => transparentize(0.9, color(props))};
  padding: 5px 10px;
`;

const BrandText = styled(Text)`
  text-transform: uppercase;
`;

const BrandWrapper = styled(Flex)`
  flex-direction: column;
`;

const OPTIN_TYPES = ['marketing', 'profiling'];

function OptinSettings({ colorType }) {
  const dispatch = useDispatch();
  const [showAll, setShowAll] = useState(false);
  const isLoading = useSelector(selectOptinLoading);
  const optins = useSelector(selectOptin);
  const conf = useSelector(selectBrandedConf);
  const brandOptins = filter(optins, {
    primaryBrand: { id: parseInt(conf?.primaryBrand) },
  });

  const selectedBrandOptins = useMemo(() => compact(brandOptins), [brandOptins]);
  const restOptins = useMemo(
    () => filter(optins, ({ id }) => !flatMap(brandOptins, 'id').includes(id)),
    [optins, brandOptins],
  );

  useEffect(() => {
    dispatch(
      setBack({
        back: 'history',
      }),
    );
    dispatch(getOptinRequest());
  }, []);

  useEffect(() => {
    if (selectedBrandOptins.length === 0) {
      setShowAll(true);
    }
  }, [selectedBrandOptins]);

  const renderOptins = useCallback(
    (_optins) =>
      map(_optins, (optin) => {
        const brandType = optin.secondaryBrand ? 'secondary' : 'primary';
        const brandId = get(optin, `${brandType}Brand.id`);
        const brandName = get(optin, `${brandType}Brand.name`);
        const privacyUrl = get(optin, `config.${brandType}BrandPrivacyDocument.url`);

        return (
          <BrandWrapper margin={{ size: 'm', position: 'bottom' }} key={brandId}>
            <BrandHeader colorType={colorType}>
              <BrandText colorType={colorType}>{brandName}</BrandText>
              {!!privacyUrl && (
                <ButtonComponent
                  href={privacyUrl}
                  target="_blank"
                  colorType={colorType}
                  component={Text}
                  size="xxs"
                  underline
                  style={{ textDecoration: 'underline', alignItems: 'center', display: 'flex' }}
                >
                  <FormattedMessage {...messages.privacyUrl} />
                </ButtonComponent>
              )}
            </BrandHeader>
            {OPTIN_TYPES.map((type) => {
              const typeKey = `${type}Optin`;
              const value = get(optin, typeKey);
              const label = get(optin, `config.${brandType}${capitalize(type)}Toggle`);
              return (
                !isNil(value) && (
                  <BrandOptinForm
                    id={optin.id}
                    type={typeKey}
                    value={value}
                    colorType={colorType}
                    key={`${optin.id}-${type}`}
                    label={label}
                  />
                )
              );
            })}
          </BrandWrapper>
        );
      }),
    [selectedBrandOptins, restOptins],
  );

  if (isLoading) return <LoadingIndicator />;

  if (!optins?.length) return null;

  const buttonViewAll = restOptins.length > 0 && (
    <Button
      colorType={colorType}
      buttonType="link"
      arrow={false}
      onClick={() => setShowAll(true)}
      size="s"
    >
      <FormattedMessage {...messages.viewAllBrands} />
    </Button>
  );

  return (
    <>
      <Box
        colorType={colorType}
        description={<FormattedMessage {...messages.title} />}
        margin={{ size: 'm', position: 'bottom' }}
        minHeight="0"
      />
      <Text colorType={colorType} margin={{ size: 'm', position: 'bottom' }}>
        <FormattedMessage {...messages.description} />
      </Text>
      {renderOptins(selectedBrandOptins)}
      {showAll ? renderOptins(restOptins) : buttonViewAll}
    </>
  );
}

OptinSettings.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.object,
  intl: PropTypes.object,
};

export default OptinSettings;
