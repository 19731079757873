export default {
  basename: '',
  api: {
    hostname: 'https://api2.certilogo.com',
    cms: '/cms-fury',
  },
  oauth: {
    facebook: {
      appId: '291589737561980',
      fields: ['id', 'email'],
    },
    google: {
      clientId: '504927295040-8l4in3p8bpbcak9sc1tgbd5u8a0e1urv.apps.googleusercontent.com',
    },
    apple: {
      clientId: 'com.certilogo.authenticator-mystique',
    },
    // certilogo-app
    ebay: {
      url: 'https://auth.ebay.com/oauth2',
      clientId: 'Certilog-certilog-PRD-75bb1c1d3-64c6fa64',
      redirectUri: 'Certilogo_S.p.A-Certilog-certil-dsxsnlgo',
    },
    // certilogo-app 2
    // ebay: {
    //   url: 'https://auth.ebay.com/oauth2',
    //   clientId: 'Certilog-certilog-PRD-7975725d3-eb0bb396',
    //   redirectUri: 'Certilogo_S.p.A-Certilog-certil-vzpnnin',
    // },
  },
  googleAnalyticsId: 'UA-8083427-2',
};
