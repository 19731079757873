import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectAuthDomain = () => (state) => state.auth;

export const selectAuthLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'loading'),
);
export const selectAuthData = createSelector(selectAuthDomain(), (state) => get(state, 'data'));

export const selectUserData = createSelector(selectAuthDomain(), (state) =>
  get(state, 'data.user'),
);

export const selectAuthLoginLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'loginLoading'),
);

export const selectAuthForgotLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'forgotloading'),
);

export const selectTokenVerifyLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'tokenVerifyLoading'),
);

export const selectTokenVerifyMail = createSelector(selectAuthDomain(), (state) =>
  get(state, 'tokenVerifyMail'),
);

export const selectPasswordUpdateLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'passwordUpdateLoading'),
);

export const selectProfileLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'profileLoading'),
);

export const selectProfile = createSelector(selectAuthDomain(), (state) => get(state, 'profile'));

export const selectOptinLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'optin.loading'),
);

export const selectPrivacyPolicySignupConfig = createSelector(selectAuthDomain(), (state) =>
  get(state, 'privacyPolicySignupConfig.data'),
);

export const selectOptin = createSelector(selectAuthDomain(), (state) => get(state, 'optin.data'));

export const selectRedirectUrl = createSelector(selectAuthDomain(), (state) =>
  get(state, 'redirect.url'),
);
export const selectRedirectState = createSelector(selectAuthDomain(), (state) =>
  get(state, 'redirect.state'),
);

export const selectRedirectErrorUrl = createSelector(selectAuthDomain(), (state) =>
  get(state, 'redirectError.url'),
);
export const selectRedirectErrorState = createSelector(selectAuthDomain(), (state) =>
  get(state, 'redirectError.state'),
);

export const selectErrors = createSelector(selectAuthDomain(), (state) => get(state, 'errors'));

export const selectIsAuthenticated = createSelector(selectAuthDomain(), (state) =>
  get(state, 'isAuthenticated'),
);
export const selectJwt = createSelector(selectAuthDomain(), (state) => get(state, 'jwt'));

export const selectUser = createSelector(selectAuthDomain(), (state) => get(state, 'user'));

export const selectRoles = createSelector(selectAuthDomain(), (state) => get(state, 'roles'));

export const selectQaBrands = createSelector(selectAuthDomain(), (state) => get(state, 'qaBrands'));

export const selectIsConfirmed = createSelector(selectAuthDomain(), (state) =>
  get(state, 'isConfirmed'),
);

export const selectConfirmEmailLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'confirmEmail.loading'),
);

export const selectConfirmEmailErrors = createSelector(selectAuthDomain(), (state) =>
  get(state, 'confirmEmail.errors'),
);

export const selectChangeEmailLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'changeEmail.loading'),
);

export const selectChangeEmailErrors = createSelector(selectAuthDomain(), (state) =>
  get(state, 'changeEmail.errors'),
);

export const selectCodeLoginLoading = createSelector(selectAuthDomain(), (state) =>
  get(state, 'codeLogin.loading'),
);

export const selectCodeLoginData = createSelector(selectAuthDomain(), (state) =>
  get(state, 'codeLogin.data'),
);

export const selectCodeLoginError = createSelector(selectAuthDomain(), (state) =>
  get(state, 'codeLogin.error'),
);

export const selectCodeLoginRequestError = createSelector(selectAuthDomain(), (state) =>
  get(state, 'codeLogin.requestError'),
);

export const selectBrand = createSelector(selectAuthDomain(), (state) => get(state, 'brand'));
