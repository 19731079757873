import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@ebay-certilogo/design-system-web';
import { FormattedMessage } from 'react-intl';
import { Image, Wrapper } from './styles';
import messages from './messages';

const SoaRwe = ({ colorType, data }) => {
  const marketplaceName = data?.props?.marketplaceAliases[0]?.marketplace?.name || '-';
  const sellerName = data?.props?.marketplaceAliases[0]?.alias || '-';

  return (
    <Wrapper colorType={colorType} textAlign="center" background="#fff">
      <Image colorType={colorType} url={data?.props?.image?.url} />
      <Text colorType={colorType} marginTop="s">
        <FormattedMessage {...messages.marketplace} />: <strong>{marketplaceName}</strong>
      </Text>
      <Text colorType={colorType}>
        <FormattedMessage {...messages.seller} />: <strong>{sellerName}</strong>
      </Text>
    </Wrapper>
  );
};

SoaRwe.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.object,
};

export default SoaRwe;
