import React from 'react';
import PropTypes from 'prop-types';
import { Image, ImageWrapper } from './styles';

const Soa = ({ colorType, data }) => (
  <ImageWrapper colorType={colorType}>
    <Image colorType={colorType} url={data?.props?.image?.url} />
  </ImageWrapper>
);

Soa.propTypes = {
  colorType: PropTypes.string,
  data: PropTypes.shape({
    props: PropTypes.shape({
      image: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
};

export default Soa;
