import styled, { css } from 'styled-components';
import { Slider as _Slider, Div } from '@ebay-certilogo/design-system-web';
import { media } from '@ebay-certilogo/design-system-web/dist/shared/styles';

export const Wrapper = styled(Div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 -20px;
`;

export const Slider = styled(_Slider)(({ cardsType, length }) => {
  return css`
    display: flex;
    flex-direction: column;
    margin-top: auto;
    ${cardsType === 'simple' &&
    css`
      flex: 1;
    `}
    .slick-slider {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: hidden;
    }
    .slick-list {
      display: flex;
      flex: 1;
      flex-direction: column;
      ${cardsType === 'cards' &&
      length > 1 &&
      media.tablet`
        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -200px;
          width: 200px;
          bottom: 0;
          box-shadow: 0px 0px 20px 10px rgb(43 39 39 / 60%);
          border-radius: 65px;
          z-index: 2;
        }
        &:after {
          right: -200px;
          left: auto;
        }
      `};
    }
    .slick-track {
      display: flex !important;
      flex: 1;
      align-items: center;
    }
    .slick-slide {
      height: inherit !important;
      flex: 1;
      flex-direction: column;
      > div {
        height: 100%;
      }
    }
    .slick-arrow {
      height: 100%;
      opacity: 0;
    }
    .Card {
      ${cardsType === 'cards' &&
      css`
        transform: scale(0.9);
      `}
    }
    .slick-center {
      .Card {
        opacity: 1;
        ${cardsType !== 'simple' &&
        css`
          transform: scale(1);
          /* height: calc(${(window.innerHeight / 100) * 65}px + 20px); */
        `}
      }
    }
    .slick-dots {
      margin: 0;
    }
  `;
});
