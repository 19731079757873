import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Div, ProgressBarTimer } from '@ebay-certilogo/design-system-web';
import { makeAction } from '../Button/actions';

function LoaderTrigger({
  temporization,
  url,
  action,
  blank,
  style,
  colorType,
  gtm,
  logEventName = 'progress trigger',
  propsBag,
  propsBag: { logger } = {},
  completeAction,
}) {
  const handleOnComplete = useCallback(() => {
    if (url) {
      Object.assign(document.createElement('a'), {
        target: blank && '_blank',
        rel: 'noopener noreferrer',
        href: url,
      }).click();
    } else if (action) {
      makeAction(action, { completeAction, propsBag });
    }
    if (logger) {
      logger({
        action: 'auto-trigger',
        type: 'progress-trigger',
        gtm,
        start: 1,
        finish: 1,
        event: { type: 'auto-trigger', name: logEventName },
      });
    }
  }, [url, blank, action, logger, logEventName]);

  return (
    <Div {...style}>
      <ProgressBarTimer
        colorType={colorType}
        interval={temporization}
        onComplete={handleOnComplete}
      />
    </Div>
  );
}

LoaderTrigger.propTypes = {
  colorType: PropTypes.string,
  temporization: PropTypes.number,
  url: PropTypes.string,
  action: PropTypes.string,
  blank: PropTypes.bool,
  logEventName: PropTypes.string,
  propsBag: PropTypes.object,
  style: PropTypes.object,
  completeAction: PropTypes.func,
  gtm: PropTypes.object,
};

export default LoaderTrigger;
