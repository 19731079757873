import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import OnboardingComponent from 'components/Onboarding';
import { logCta } from 'containers/App/actions';
import { flowRequest } from 'containers/Authentication/actions';
import { selectPoweredBy } from 'containers/App/selectors';
import withColorType from 'components/HOC/withColorType';

function Onboarding({ colorType, data, authenticationId }) {
  const { status, props } = data;
  const onboarding = get(props, 'onboarding');
  const poweredBy = useSelector(selectPoweredBy);
  const dispatch = useDispatch();
  const logger = (log) => {
    dispatch(logCta({ authenticationId, ...log }));
  };

  const completeAction = () => {
    dispatch(
      flowRequest({
        action: 'next',
        status,
        authenticationId,
      }),
    );
  };

  const propsBag = {
    service: 'protect',
    certilogoCode: get(data, 'certilogoCode'),
    primaryBrand: get(data, 'primaryBrand.name'),
    tmBrand: get(data, 'tmBrand.name'),
    logger,
  };

  return (
    <OnboardingComponent
      colorType={colorType}
      data={onboarding}
      completeAction={completeAction}
      propsBag={propsBag}
      poweredBy={poweredBy}
    />
  );
}

Onboarding.propTypes = {
  colorType: PropTypes.string,
  authenticationId: PropTypes.string,
  data: PropTypes.shape({
    status: PropTypes.string,
    props: PropTypes.object,
  }),
};

export default withColorType(Onboarding);
