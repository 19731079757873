import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectSurveyDomain = () => (state) => state.survey;

export const selectSurveyLoading = createSelector(selectSurveyDomain(), (state) =>
  get(state, 'loading'),
);

export const selectSurveySendLoading = createSelector(selectSurveyDomain(), (state) =>
  get(state, 'sendLoading'),
);

export const selectSurveyData = ({ id, service }) =>
  createSelector(selectSurveyDomain(), (state) => get(state, `data.${id}${service}`));
