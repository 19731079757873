/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { Div } from '@ebay-certilogo/design-system-web';
import { animated } from '@react-spring/web';
import { extrabold, margin } from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { color } from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import ButtonHomeConnect from 'components/ButtonHomeConnect';

export const Wrapper = styled(Div)`
  display: flex;
  flex: 1;
  flex-direction: column;
  color: ${color};
`;

export const ResponseWrapper = styled(Div)`
  z-index: 1;
`;

export const Response = styled(animated.div)`
  text-align: center;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s;
  z-index: 2;
  justify-self: center;
  margin: auto;
  color: ${color};
  ${extrabold()}
  ${margin('m', 'bottom')}
`;

export const ResponseCarouselWrapper = styled(Div)`
  margin-top: auto;
  transition: opacity 2s;
  z-index: 1;
`;

export const ButtonHome = styled(ButtonHomeConnect)`
  position: absolute;
  bottom: 40px;
  right: 0;
  z-index: 1000;
`;
