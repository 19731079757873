import styled, { css } from 'styled-components';
import { Div, ProgressBarTimer } from '@ebay-certilogo/design-system-web';
import {
  addCssProp,
  contentWrapper,
  media,
  spacing,
} from '@ebay-certilogo/design-system-web/dist/shared/styles';
import { getImage } from 'utils/images';

export const Wrapper = styled(Div)`
  height: 100%;
  ${addCssProp}
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      margin: 0 -${spacing.margin.mobile.m}px;
      width: auto;
    `}
  margin-top: -${spacing.margin.mobile.m}px;
`;

export const Background = styled(Div)`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  pointer-events: none;
  ${addCssProp}
  ${({ backgroundImage }) =>
    backgroundImage &&
    css`
      background-image: url(${getImage(backgroundImage)});
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
    `}
`;

export const ContentWrapper = styled(Div)`
  position: relative;
  margin: auto;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${({ verticalCenter }) =>
    verticalCenter &&
    css`
      justify-content: center;
      margin-top: -60px;
      ${media.tablet`
        margin-top: -75px;
      `};
    `}
  ${contentWrapper};
`;

export const ProgressBar = styled(ProgressBarTimer)`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0;
  margin: 0;
`;
