import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { List, Text } from '@ebay-certilogo/design-system-web';
import isFunction from 'lodash/isFunction';
import {
  primaryColor,
  secondaryColor,
} from '@ebay-certilogo/design-system-web/dist/shared/styles/themes/selectors';
import { LocationArrow } from '@ebay-certilogo/design-system-web/dist/components/icons';

const RightText = styled(Text)`
  white-space: nowrap;
`;
const Row = styled(List.Row)`
  color: ${primaryColor};
  padding: 10px;
  padding-right: 0;
  margin-bottom: 10px;
  min-height: 80px;
  ${({ highlight }) =>
    highlight
      ? css`
          border: 1px solid ${primaryColor};
          background: ${primaryColor};
          color: ${secondaryColor};
        `
      : css`
          border: 1px solid ${primaryColor};
          background: transparent;
        `}
`;

function PlaceRow({
  colorType,
  title,
  description,
  left,
  right,
  onClick,
  accessoryRowRight,
  bordered,
  rowPadding,
  item,
  titleProps,
  descriptionProps,
  distance,
  distanceProps,
  highlight,
}) {
  return (
    <Row
      colorType={colorType}
      highlight={highlight}
      padding={rowPadding || { size: 's', position: 'vertical' }}
      noMargin={bordered}
      onClick={onClick}
      clickable
      left={isFunction(left) ? left(item) : left}
      right={
        (accessoryRowRight && (
          <RightText colorType={colorType} weight="extrabold" size="s">
            {right}
          </RightText>
        )) ||
        isFunction(right)
          ? right({ item, colorType, themeField: highlight ? 'secondaryColor' : 'primaryColor' })
          : right
      }
      body={
        <>
          <Text
            colorType={colorType}
            themeField={highlight ? 'secondaryColor' : 'primaryColor'}
            weight="extrabold"
            marginRight="s"
            {...titleProps}
          >
            {title}
          </Text>
          {distance && (
            <Text
              colorType={colorType}
              themeField={highlight ? 'secondaryColor' : 'primaryColor'}
              weight="normal"
              size="s"
              marginRight="s"
              {...distanceProps}
            >
              <LocationArrow
                colorType={colorType}
                themeField={highlight ? 'secondaryColor' : 'primaryColor'}
                style={{ marginRight: 5 }}
              />
              {distance}
            </Text>
          )}
          <Text
            colorType={colorType}
            themeField={highlight ? 'secondaryColor' : 'primaryColor'}
            weight="normal"
            size="s"
            marginRight="s"
            {...descriptionProps}
          >
            {description}
          </Text>
        </>
      }
    />
  );
}

PlaceRow.propTypes = {
  colorType: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  left: PropTypes.node,
  right: PropTypes.node,
  onClick: PropTypes.func,
  accessoryRowRight: PropTypes.string,
  bordered: PropTypes.bool,
  rowPadding: PropTypes.object,
  item: PropTypes.object,
  titleProps: PropTypes.object,
  descriptionProps: PropTypes.object,
  highlight: PropTypes.bool,
  distanceProps: PropTypes.object,
  distance: PropTypes.string,
};

export default PlaceRow;
