import React, { useState } from 'react';
import { GoogleColor } from '@ebay-certilogo/design-system-web/dist/components/icons';
import { useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { loginRequest } from 'containers/Auth/actions';
import { GoogleButton, SocialIcon } from '../styles';
import messages from '../messages';

function GoogleLogin({ colorType, type }) {
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const responseGoogle = (response) => {
    setError(null);
    if (response.type === 'error') {
      setError({ google: { error: 'default' } });
    } else {
      dispatch(
        loginRequest({
          network: 'google',
          token: response.access_token,
          version: 3,
          redirect: true,
        }),
      );
    }
  };

  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    flow: 'implicit',
  });

  return (
    <GoogleButton
      disabled={!!error}
      onClick={() => login()}
      colorType={colorType}
      icon={
        <SocialIcon disabled={!!error}>
          <GoogleColor width={30} height={30} />
        </SocialIcon>
      }
    >
      <FormattedMessage {...messages[type].google} />
    </GoogleButton>
  );

  // return (
  //   <GoogleLoginRN
  //     clientId={google.clientId}
  //     render={({ onClick }) => (
  //       <GoogleButton
  //         disabled={!!error}
  //         onClick={onClick}
  //         colorType={colorType}
  //         icon={
  //           <SocialIcon disabled={!!error}>
  //             <GoogleColor width={30} height={30} />
  //           </SocialIcon>
  //         }
  //       >
  //         <FormattedMessage {...messages[type].google} />
  //       </GoogleButton>
  //     )}
  //     buttonText="Login"
  //     onSuccess={responseGoogle}
  //     onFailure={responseGoogle}
  //     cookiePolicy="single_host_origin"
  //     disabled={!!error}
  //   />
  // );
}

GoogleLogin.propTypes = {
  colorType: PropTypes.string,
  type: PropTypes.string,
  setError: PropTypes.func,
};

export default GoogleLogin;
