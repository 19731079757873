import { defineMessages } from 'react-intl';

export default defineMessages({
  emailPlaceholder: {
    id: 'complete_unconfirmed_check.text1',
    defaultMessage: 'Enter your email address',
  },
  submitButton: {
    id: 'complete_unconfirmed_check.button1',
    defaultMessage: 'Confirm',
  },
});
