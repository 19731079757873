import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { changeColorType } from 'containers/App/actions';
import map from 'lodash/map';
import DynamicComponent from 'components/cms/DynamicComponent';
import { Modal } from '@ebay-certilogo/design-system-web';
import ContentsGroup from 'components/cms/ContentsGroup';
import isNil from 'lodash/isNil';
import { omit, pick } from 'lodash';
import { Wrapper, ContentWrapper, ProgressBar, Background } from './styles';

function Console({
  data: {
    contents,
    fullWidth = true,
    verticalCenter,
    style,
    backgroundImage,
    openingModal,
    openingModalColorType = 'light',
    openingModalContents,
    openingModalTemporization,
  },
  data,
  propsBag = {},
  ...rest
}) {
  const dispatch = useDispatch();
  const contentRef = useRef();
  const [isOpeningModalOpen, setIsOpeningModalOpen] = useState(openingModal);
  const colorType = data.colorType || rest.colorType;
  const modalColorType = openingModalColorType;

  useEffect(() => {
    dispatch(changeColorType({ colorType }));
  }, [colorType]);

  return (
    <Wrapper ref={contentRef} fullWidth={fullWidth} {...omit(style, ['background'])}>
      <Background backgroundImage={backgroundImage} {...pick(style, ['background'])} />
      <ContentWrapper verticalCenter={verticalCenter}>
        {map(contents, ({ __component, _id, ...restContent }) => (
          <DynamicComponent
            type={__component}
            data={restContent}
            key={_id}
            propsBag={propsBag}
            colorType={colorType}
          />
        ))}
      </ContentWrapper>
      <Modal
        colorType={modalColorType}
        isOpen={isOpeningModalOpen}
        onClose={() => setIsOpeningModalOpen(false)}
        close
      >
        <ProgressBar
          interval={
            !isNil(openingModalTemporization) &&
            openingModalTemporization > 0 &&
            openingModalTemporization
          }
          onComplete={() => setIsOpeningModalOpen(false)}
          colorType={modalColorType}
        />
        {map(openingModalContents, ({ contents_group: contentsGroupData }) => (
          <ContentsGroup
            data={contentsGroupData}
            propsBag={{ ...propsBag, showModal: setIsOpeningModalOpen }}
            colorType={modalColorType}
          />
        ))}
      </Modal>
    </Wrapper>
  );
}

Console.propTypes = {
  data: PropTypes.object.isRequired,
  colorType: PropTypes.string,
  fullWidth: PropTypes.bool,
  propsBag: PropTypes.object,
};

export default Console;
