import { select } from 'redux-saga/effects';
import { selectConf } from '../selectors';

export default function* loadConfWebFont() {
  try {
    const conf = yield select(selectConf);
    if (conf?.webFontConf) {
      // eslint-disable-next-line no-undef
      yield WebFont.load(conf?.webFontConf);
    }
  } catch (err) {
    // eslint-disable-next-line
    console.log('error', err);
  }
}
