import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';
import { Flex } from '@ebay-certilogo/design-system-web';

const Wrapper = styled(Flex)`
  width: 100%;
  flex-shrink: 0;
  ${({ cover }) =>
    cover &&
    css`
      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    `}
`;

function VideoComponent({
  url,
  autoplay,
  muted,
  controls,
  cover,
  onEnd = () => {},
  onHalf = () => {},
  ...rest
}) {
  const [half, setHalf] = useState(false);

  const handleOnProgress = ({ played }) => {
    if (played > 0.5 && !half) {
      setHalf(true);
      onHalf();
    }
  };

  return (
    <Wrapper cover={cover}>
      <ReactPlayer
        url={url}
        config={{
          youtube: {
            playerVars: {
              autoplay,
              controls,
            },
          },
          vimeo: {
            playerOptions: {
              autoplay,
              controls,
              responsive: true,
              playsinline: true,
            },
          },
        }}
        playing={autoplay}
        muted={muted}
        autoplay={autoplay}
        controls={controls}
        width="100%"
        height={cover ? '100%' : 'auto'}
        onEnded={onEnd}
        onProgress={handleOnProgress}
        allow="autoplay; fullscreen; picture-in-picture"
        {...rest}
      />
    </Wrapper>
  );
}

VideoComponent.propTypes = {
  url: PropTypes.string,
  autoplay: PropTypes.bool,
  muted: PropTypes.bool,
  controls: PropTypes.bool,
  cover: PropTypes.bool,
  onEnd: PropTypes.func,
  onHalf: PropTypes.func,
};

export default VideoComponent;
